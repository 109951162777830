import store from "app/store";
import APIUtils from "utils/API.utils";
import { showToast } from "components/Toast/toast.slice";
import CONSTANTS from "common/constants";
import { logger } from "utils/logger.utils";
import config from "../config";

const API = new APIUtils();

export const addUser = async (
    hospitalId: string | number,
    emailId: any,
    setValidateDisabled: any,
    isEUP?: any,
    isEmsAdmin?: any,
    isAhaAdmin?: any,
    registryId?: any,
    isCorporation?: any,
    corporationId?: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const reqBody: any = {
                email: emailId,
            };
            if (isEmsAdmin || isEUP) {
                reqBody.hospitalId = hospitalId;
                reqBody.userType = CONSTANTS.USER_ROLES.HOSPITAL_ADMIN;
            } else if (isAhaAdmin && registryId) {
                reqBody.registryId = registryId;
                reqBody.userType = CONSTANTS.USER_ROLES.REGISTRY_ADMIN;
            } else if (isAhaAdmin && corporationId) {
                reqBody.corporationId = corporationId;
                reqBody.userType = CONSTANTS.USER_ROLES.CORPORATION_ADMIN;
            } else if (isAhaAdmin) {
                reqBody.hospitalId = hospitalId;
                reqBody.userType = CONSTANTS.USER_ROLES.HOSPITAL_ADMIN;
            } else if (registryId) {
                reqBody.registryId = registryId;
                reqBody.userType = CONSTANTS.USER_ROLES.REGISTRY_ADMIN;
            } else if (isCorporation) {
                reqBody.hospitalId = hospitalId;
                reqBody.userType = CONSTANTS.USER_ROLES.HOSPITAL_ADMIN;
            } else if (corporationId) {
                reqBody.corporationId = corporationId;
                reqBody.userType = CONSTANTS.USER_ROLES.CORPORATION_ADMIN;
            }
            const apiEndPoint = `${
                config[config.env].apiEndpoints.accounts
            }/manageUsers`;
            const APIRes = await API.POST(apiEndPoint, reqBody);
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                setValidateDisabled(false);
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const deleteUser = async (
    hospitalId: string | number,
    userId: any,
    isEUP?: any,
    isEmsAdmin?: any,
    isAhaAdmin?: any,
    registryId?: any,
    isCorporation?: any,
    corporationId?: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        const reqBody: any = {
            userId,
        };
        if (isEmsAdmin || isEUP) {
            reqBody.hospitalId = hospitalId;
            reqBody.userType = CONSTANTS.USER_ROLES.HOSPITAL_ADMIN;
        } else if (isAhaAdmin && registryId) {
            reqBody.registryId = registryId;
            reqBody.userType = CONSTANTS.USER_ROLES.REGISTRY_ADMIN;
        } else if (isAhaAdmin && corporationId) {
            reqBody.corporationId = corporationId;
            reqBody.userType = CONSTANTS.USER_ROLES.CORPORATION_ADMIN;
        } else {
            switch (true) {
                case isAhaAdmin:
                    reqBody.hospitalId = hospitalId;
                    reqBody.userType = CONSTANTS.USER_ROLES.HOSPITAL_ADMIN;
                    break;
                case registryId != null || undefined:
                    reqBody.registryId = registryId;
                    reqBody.userType = CONSTANTS.USER_ROLES.REGISTRY_ADMIN;
                    break;
                case isCorporation:
                    reqBody.hospitalId = hospitalId;
                    reqBody.userType = CONSTANTS.USER_ROLES.HOSPITAL_ADMIN;
                    break;
                case corporationId != null || undefined:
                    reqBody.corporationId = corporationId;
                    reqBody.userType = CONSTANTS.USER_ROLES.CORPORATION_ADMIN;
                    break;
                default:
                    logger(
                        "No matching condition found for user role assignment."
                    );
                    break;
            }
        }
        try {
            const apiEndPoint = `${
                config[config.env].apiEndpoints.accounts
            }/manageUsers`;
            const APIRes = await API.DELETE(apiEndPoint, reqBody);
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getUserLogsFromUserManagement = async (
    hospitalId: string | number,
    pageNumber: number,
    pageSize: number,
    corporationId?: any,
    registryId?: any
): Promise<any> => {
    try {
        const baseApiUrl = `${
            config[config.env].apiEndpoints.accounts
        }/manageUsers/logs`;
        let apiEndPoint = `${baseApiUrl}?`;

        if (corporationId) {
            apiEndPoint += `corporationId=${corporationId}&`;
        } else if (hospitalId) {
            apiEndPoint += `hospitalId=${hospitalId}&`;
        } else if (registryId) {
            apiEndPoint += `registryId=${registryId}&`;
        }

        apiEndPoint += `pageSize=${pageSize}&pageNumber=${pageNumber}`;
        const APIRes = await API.GET(apiEndPoint);
        if (APIRes.success === true) {
            return APIRes;
        }
        throw APIRes.error;
    } catch (error: any) {
        store.dispatch(showToast(error));
        throw error;
    }
};
