import CONSTANTS from "common/constants";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    cstDateFormat,
    dateToYYYYMMMDDWithTime,
    hospitalApprovalStatus,
    measureStatusClass,
} from "common/utils";
import { HospitalTableWrapper } from "components/AdminHospitalTable/styled";
import { Link } from "react-router-dom";
import "./style.css";
import { titleCase } from "utils/common.utils";
import SearchFilter from "components/SearchFilter";

interface Props {
    tab: string;
    pageNumber: number;
    hospitals: any;
    actionURL: string;
    isTableLoading: boolean;
    isActive: boolean;
    isDeactivated: boolean;
    setSortInd: any;
    sortInd: any;
    close: any;
    isPending?: any;
    stateCd?: any;
    awardCd?: any;
    measureCd?: any;
    emsInd?: any;
}

export const EMSAgencyTable = ({
    tab,
    pageNumber,
    hospitals,
    actionURL,
    isTableLoading,
    isActive,
    isDeactivated,
    setSortInd,
    sortInd,
    close,
    isPending,
    stateCd,
    awardCd,
    measureCd,
    emsInd,
}: Props) => {
    const dispatch = useDispatch();
    const searchFilter = useSelector((state: any) => {
        return state.searchFilter;
    });
    const [isSearch, setIsSearch] = useState<boolean>(false);

    const [noDataCheck, setNoDataCheck] = useState<boolean>(true);
    const shouldDisplayAward = (award: string, status: string) => {
        return award && !(status == CONSTANTS.IN_REVIEW && isDeactivated);
    };
    useEffect(() => {
        const stateCheck = stateCd === CONSTANTS.ALL;
        const searchCheck = searchFilter.searchKey === "";
        const awardCheck =
            searchFilter.award === CONSTANTS.ALL ||
            searchFilter.award === CONSTANTS.ALL_VALUE ||
            searchFilter.award === undefined;
        const measureCheck =
            searchFilter.measure === CONSTANTS.ALL ||
            searchFilter.measure === CONSTANTS.ALL_VALUE ||
            searchFilter.measure === undefined;
        setNoDataCheck(
            !isSearch && stateCheck && awardCheck && measureCheck && searchCheck
        );
    }, [searchFilter, stateCd, awardCd, measureCd]);

    return (
        <HospitalTableWrapper
            className={`hospital-status-table w-100 ${
                isPending ? "pending-hospital-status-table" : " "
            }`}
        >
            <table
                className={`aui-responsive-status-table ${
                    isTableLoading ? CONSTANTS.TABLE_SPINNER : ""
                }`}
            >
                <thead>
                    <tr>
                        <th scope="col">
                            <div className="aui-th justify-content-between">
                                Organization Details
                            </div>
                        </th>
                        <th scope="col">
                            <div className="aui-th justify-content-between">
                                Location Details
                            </div>
                        </th>
                        {!isPending && (
                            <th scope="col">
                                <div className="aui-th justify-content-between">
                                    Award
                                </div>
                            </th>
                        )}
                        <th scope="col">
                            <div className="aui-th justify-content-between">
                                Status
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {hospitals.length > 0 ? (
                        hospitals.map((hospital: any) => {
                            return (
                                <tr
                                    key={hospital.hospitalId}
                                    className={`${hospitalApprovalStatus(tab)}`}
                                >
                                    <td data-title="Agency Details">
                                        <div className="aui-td">
                                            <div>
                                                <Link
                                                    to={{
                                                        pathname: `${actionURL}${hospital.hospitalId}/program/${hospital.programsId}/category/${hospital.categoryId}`,
                                                        state: {
                                                            backToOrganizationManagement:
                                                                "/emsadmin/organization-management",
                                                            adminOrganizationMangemnetTab:
                                                                tab,
                                                            adminOrganizationMangemnetPage:
                                                                pageNumber,
                                                            pageSort: sortInd,
                                                            facilityName:
                                                                hospital.hospitalName,
                                                        },
                                                    }}
                                                    onClick={() => {
                                                        localStorage.setItem(
                                                            "resetFilter",
                                                            "false"
                                                        );
                                                    }}
                                                    className="btn-text-link-uline"
                                                >
                                                    {hospital.hospitalName}
                                                </Link>
                                                {hospital?.emsId && (
                                                    <div className="mt-1">
                                                        <span aria-hidden="true">
                                                            AHA EMS ID
                                                        </span>
                                                        <span className="sr-only">
                                                            A H A E M S I D
                                                        </span>
                                                        :{" "}
                                                        <span>
                                                            {hospital?.emsId}
                                                        </span>{" "}
                                                        | Code:{" "}
                                                        <span>
                                                            {
                                                                hospital.facilityCode
                                                            }
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </td>
                                    <td data-title="Location Details">
                                        <div className="aui-td">
                                            <div className="corp-loc-detail">
                                                <div>
                                                    <span>Location: </span>
                                                    {hospital.countryName}
                                                </div>
                                                <div className="mt-1">
                                                    <span>State: </span>
                                                    {hospital.stateName}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    {!isPending && (
                                        <td data-title="Program Type">
                                            <div className="aui-td d-flex flex-column align-items-start">
                                                {shouldDisplayAward(
                                                    hospital?.award,
                                                    hospital?.hospitalApprovalStatus
                                                ) ? (
                                                    <div>
                                                        <div>
                                                            {hospital?.award}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <span>None</span>
                                                    </div>
                                                )}
                                                <div className="mt-1 ">
                                                    {hospital.measureIndicator ? (
                                                        <div
                                                            className={`measure-indicator mt-3 mt-sm-0 ${measureStatusClass(
                                                                hospital.measureIndicator
                                                            )}`}
                                                        >
                                                            {
                                                                hospital.measureIndicator
                                                            }
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </td>
                                    )}
                                    <td data-title="Status">
                                        <div className="aui-td">
                                            {isDeactivated
                                                ? "Deactivated"
                                                : titleCase(
                                                      hospital.programApprovalStatus
                                                  )}
                                            {hospital.isActive &&
                                                hospital.programApprovalStatus ===
                                                    CONSTANTS.IN_REVIEW && (
                                                    <div
                                                        id={`reviewed-info-${hospital.hospitalId}`}
                                                        className="reviewed-tooltip"
                                                    >
                                                        <button
                                                            data-container={`#reviewed-info-${hospital.hospitalId}`}
                                                            type="button"
                                                            className="aha-icon-warning-round warning-help"
                                                            data-toggle="tooltip"
                                                            data-placement="right"
                                                            data-trigger="hover focus"
                                                            data-html="true"
                                                            title={`<strong class="mb-2">Reviewed By</strong><div>${
                                                                hospital.reviewedBy
                                                            }<br/>on ${dateToYYYYMMMDDWithTime(
                                                                hospital.reviewedDate
                                                            )}</div>`}
                                                        >
                                                            <span className="sr-only">
                                                                Reviewed By
                                                            </span>
                                                        </button>
                                                    </div>
                                                )}
                                        </div>
                                    </td>
                                </tr>
                            );
                        })
                    ) : noDataCheck ? (
                        <tr className="aui-status-table-emptystate">
                            <td colSpan={isPending ? 3 : 4}>
                                <div className="aui-td" role="alert">
                                    No data to display
                                </div>
                            </td>
                        </tr>
                    ) : (
                        <tr className="aui-status-table-emptystate">
                            <td colSpan={isPending ? 3 : 4}>
                                <div className="aui-td" role="alert">
                                    No organization matches your search.
                                    <br />
                                    Please try modifying your search criteria
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </HospitalTableWrapper>
    );
};

export default EMSAgencyTable;
