import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import HospitalDetails from "pages/Admin/FacilityDetails/HospitalDetails";
import ProgramsDetails from "pages/Admin/FacilityDetails/ProgramDetails";
import BillingDetails from "pages/Admin/FacilityDetails/BillingDetails";
import PaymentDetails from "pages/Admin/FacilityDetails/PaymentDetails";
import AgreementDetails from "pages/Admin/FacilityDetails/AgreementDetails";
import { useDispatch } from "react-redux";
import HospitalProgramAgreementPage from "pages/HospitalProgramAgreement/HospitalProgramAgreementPage";
import PaymentSummaryPage from "pages/Payment/PaymentSummaryPage";
import axios from "axios";
import Tab from "components/Tab";
import { FacilitiesDetailsWrapper } from "./styled";
import CorporationTabs from "../CorporationMenu/CorporationMenu";
import FacilitiesNav from "./FacilitiesNav";
import { getHospitalById } from "../../../api/hospitalApi";
import { setPageLoadingStatus } from "../../../components/Loader/loader.slice";
import CONSTANTS from "../../../common/constants";
import { getHospitalProgramDetails } from "../../../api/hospitalProgramsAPI";
import {
    getAssetDetails,
    getHospitalProgramCertificate,
} from "../../../api/certificateAPI";
import {
    exportDetails,
    parseJwt,
    redirectionForErrorCode,
} from "../../../common/utils";
import { getSubscriptionDetails } from "../../../api/adminDashboardAPI";
import { getBillingDetails, getProratePrice } from "../../../api/paymentAPI";
import { showToast } from "../../../components/Toast/toast.slice";
import "styles/arrow-tab.scss";

export const FacilitiesDetails = () => {
    const history = useHistory();
    const { hospitalId, programId, categoryId }: any = useParams();
    const [hospital, setHospital] = useState<any>({});
    const [lastEditedData, setLastEditedData] = useState<any>(null);
    const [activeTab, setActiveTab] = useState<any>(null);
    const [registryInfo, setRegistryInfo] = useState<any>({});
    const [registryQuestionData, setRegistryQuestionData] = useState<any>({});
    const [agreementInfo, setAgreementInfo] = useState<any>({});
    const [paymentInfo, setPaymentInfo] = useState<any>({});
    const [programData, setProgramData] = useState<any>();
    const [billingInfo, setBillingInfo] = useState<any>({});
    const [certificateData, setCertificateData] = useState<any>();
    const [agreementUploaded, setAgreementUploaded] = useState<boolean>(false);
    const [billingComplete, setBillingComplete] = useState<boolean>(false);
    const [paymentComplete, setPaymentComplete] = useState<boolean>(false);
    const [billingContactName, setBillingContactName] = useState<any>("");
    const [price, setPrice] = useState<number>();
    const [orderId, setOrderId] = useState<any>();
    const [mounted, setMounted] = useState<boolean>(false);
    const [isPaymentTabReq, setIsPaymentTabReq] = useState<boolean>(false);
    const dispatch = useDispatch();
    const location = useLocation();
    const userAccessToken = window.localStorage.getItem("userAccessToken");
    const isCorporation =
        userAccessToken &&
        parseJwt(userAccessToken).role_code ===
            CONSTANTS.USER_ROLES.CORPORATION_ADMIN;
    const [selectedTab, setSelectedTab] = useState<any>(0);

    const CorporationDetailhandleSelectedTab = (value: any, index: number) => {
        setSelectedTab(value);
        setActiveTab(index);
    };

    useEffect(() => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        if (!mounted) {
            Promise.all([
                getHospitalById(hospitalId),
                getHospitalProgramDetails(programId, hospitalId),
                getProratePrice(programId, categoryId),
            ])
                .then((response: any) => {
                    if (
                        response[0].success &&
                        response[1].success &&
                        response[2].success
                    ) {
                        if (location?.state?.tab != CONSTANTS.DEACTIVATED) {
                            getHospitalProgramCertificate(
                                hospitalId,
                                programId,
                                categoryId
                            )
                                .then((res: any) => {
                                    setCertificateData(
                                        res.data.programCertficate
                                    );
                                    localStorage.setItem(
                                        "selectedHospitalExpiryDate",
                                        res.data.programCertficate
                                            ?.programCertificateExpiryDate
                                    );
                                })
                                .catch((error: any) => {
                                    if (isCorporation) {
                                        redirectionForErrorCode(
                                            CONSTANTS.ROUTES
                                                .CORPORATION_DASHBOARD,
                                            error,
                                            history,
                                            2000
                                        );
                                    }
                                });
                        }
                        localStorage.setItem(
                            "selectedHospitalCountryId",
                            response[0].data.hospital.countryId
                        );
                        setHospital(response[0].data.hospital);
                        modifyLastEditedData(response[0].data.hospital);
                        setProgramData(response[1].data);
                        if (
                            response[2].statusCode == 200 &&
                            response[2].data &&
                            response[2].data.programCategoryPrice
                        ) {
                            setPrice(
                                response[2].data.programCategoryPrice.price
                            );
                        }
                        dispatch(
                            setPageLoadingStatus({ isPageLoading: false })
                        );
                        setMounted(true);
                    }
                })
                .catch(() => {
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                });
        }
    }, []);
    useEffect(() => {
        if (Object.keys(hospital).length !== 0) {
            if (hospital.countryCode !== CONSTANTS.DOMESTIC_COUNTRY_CODE)
                setIsPaymentTabReq(true);
        }
    }, [hospital]);

    useEffect(() => {
        if (mounted) {
            dispatch(setPageLoadingStatus({ isPageLoading: true }));
            getHospitalProgramDetails(programId, hospitalId)
                .then((response: any) => {
                    if (response.success) {
                        setProgramData(response.data);
                        dispatch(
                            setPageLoadingStatus({ isPageLoading: false })
                        );
                    }
                })
                .catch(() => {
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                });
        }
    }, [agreementUploaded, mounted]);

    useEffect(() => {
        if (mounted) {
            dispatch(setPageLoadingStatus({ isPageLoading: true }));
            getBillingDetails(hospitalId, categoryId)
                .then((response: any) => {
                    if (response.success) {
                        setBillingContactName(
                            response.data?.billingDetails.billingContactName
                        );
                        dispatch(
                            setPageLoadingStatus({ isPageLoading: false })
                        );
                    }
                })
                .catch(() => {
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                });
        }
    }, [billingComplete, mounted]);

    useEffect(() => {
        getSubscriptionDetails(hospitalId, programId, categoryId)
            .then(async (response: any) => {
                if (response.success) {
                    setOrderId(response.data.orderId);
                }
            })
            .catch(() => {
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
            });
    }, [paymentComplete]);

    useEffect(() => {
        programData && modifyLastEditedData(programData);
    }, [programData]);

    useEffect(() => {
        paymentInfo && modifyLastEditedData(paymentInfo);
    }, [paymentInfo]);

    const close = () => {
        history.push(getReturnPath());
    };

    const exportFacilityData = () => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        getAssetDetails(CONSTANTS.EXPORT_FACILITY_TEMPLATE, true).then(
            async (presignedURL: any) => {
                if (
                    presignedURL.data?.assets?.[0].isDownloadable &&
                    presignedURL.data?.assets?.[0].presignedUrl
                ) {
                    const iframe: any = document.createElement("iframe");
                    iframe.setAttribute("height", "0");
                    iframe.setAttribute("width", "0");
                    iframe.className = "hiddenFrame";

                    try {
                        const axiosResponse = await axios({
                            method: "get",
                            url: presignedURL.data?.assets?.[0].presignedUrl,
                        });

                        if (axiosResponse.data) {
                            const templateHtml = axiosResponse.data;
                            /* add iframe to the main document for canvas creation and print */
                            document.body.appendChild(iframe);

                            iframe.onload = async () => {
                                await exportDetails(
                                    hospital,
                                    programData,
                                    registryQuestionData,
                                    registryInfo,
                                    agreementInfo,
                                    billingInfo,
                                    paymentInfo
                                );
                                const toast = {
                                    message:
                                        "Organization details successfully exported.",
                                    code: "Success:",
                                    type: "success",
                                };
                                dispatch(showToast(toast));

                                dispatch(
                                    setPageLoadingStatus({
                                        isPageLoading: false,
                                    })
                                );
                            };

                            iframe.contentWindow.document.open();
                            iframe.contentWindow.document.write(templateHtml);
                            iframe.contentWindow.document.close();
                        }
                    } catch {
                        const toast = {
                            message: "Export template doesn't exist.",
                        };

                        dispatch(showToast(toast));
                        dispatch(
                            setPageLoadingStatus({ isPageLoading: false })
                        );
                    }
                }
            }
        );
    };

    const modifyLastEditedData = (currentData: any) => {
        if (currentData?.updatedBy?.firstName && currentData?.updatedAt) {
            setLastEditedData(currentData);
        }
    };

    const handleHospitalDataChange = (modifiedData: any) => {
        setHospital(modifiedData);
        modifyLastEditedData(modifiedData);
    };

    const handleProgramDataChange = (modifiedData: any) => {
        setProgramData(modifiedData);
        modifyLastEditedData(modifiedData);
    };

    const handleAgreementDataChange = (modifiedData: any) => {
        setAgreementInfo(modifiedData);
        modifyLastEditedData(modifiedData);
    };

    const handlePaymentDataChange = (modifiedData: any) => {
        setPaymentInfo(modifiedData);
        modifyLastEditedData(modifiedData);
    };

    const handleBillingDataChange = (modifiedData: any) => {
        setBillingInfo(modifiedData);
        modifyLastEditedData(modifiedData);
    };

    const getActiveTab = () => {
        switch (location?.state?.tab) {
            case CONSTANTS.PENDING:
                return "corporationPendingFacilties";
            case CONSTANTS.REGISTRED:
                return "corporationFaciltyRegistered";
            case CONSTANTS.RENEWING:
                return "corporationRenewingFacilties";
            case CONSTANTS.CERTIFIED:
                return "corporationFaciltyCertified";
            case CONSTANTS.DEACTIVATED:
                return "corporationDeactivatedFacilties";
            default:
                return "";
        }
    };

    const getReturnPath = () => {
        switch (location?.state?.tab) {
            case CONSTANTS.PENDING:
                return "/corporation/pending-approval";
            case CONSTANTS.REGISTRED:
                return "/corporation/registered-organization";
            case CONSTANTS.CERTIFIED:
                return "/corporation/certified-organization";
            case CONSTANTS.RENEWING:
                return "/corporation/renewed-organizations";
            case CONSTANTS.DEACTIVATED:
                return "/corporation/deactivated-organization";
            case CONSTANTS.BULKDOCUMENT:
                return "/corporation/bulk-document-submission";
            default:
                return "";
        }
    };

    const corporationDetailsTabsOnContent = [
        {
            id: "corpOrgDetails",
            title: "Organization Details",
            content: (
                <div
                    className="pt-3 justify-content-start program-list flex-column"
                    id="corporationDetails"
                >
                    <div className="tabContent">
                        <HospitalDetails
                            hospital={hospital}
                            setHospital={handleHospitalDataChange}
                            setActiveTab={setActiveTab}
                            activeTab={activeTab}
                            close={close}
                            isCorporation={isCorporation}
                        />
                    </div>
                </div>
            ),
        },
        {
            id: "corpProgramDetails",
            title: "Program Details",
            content: (
                <div
                    className="pt-3 justify-content-start program-list flex-column"
                    id="corporationPrograms"
                >
                    <div className="tabContent">
                        <div>
                            <ProgramsDetails
                                setActiveTab={setActiveTab}
                                activeTab={activeTab}
                                programData={programData}
                                close={close}
                                setRegistryInfo={setRegistryInfo}
                                setRegistryQuestionData={
                                    setRegistryQuestionData
                                }
                                setProgramData={handleProgramDataChange}
                                hospital={hospital}
                                isCorporation={isCorporation}
                            />
                        </div>
                    </div>
                </div>
            ),
        },
        {
            id: "corpAgreementDetails",
            title: "Agreement Details",
            content: (
                <div
                    className="pt-3 justify-content-start program-list flex-column"
                    id="corporationAgreement"
                >
                    <div className="tabContent">
                        <div>
                            {orderId ? (
                                <AgreementDetails
                                    hospital={hospital}
                                    setActiveTab={setActiveTab}
                                    activeTab={activeTab}
                                    close={close}
                                    setAgreementInfo={handleAgreementDataChange}
                                    isCorporation={isCorporation}
                                />
                            ) : (
                                <HospitalProgramAgreementPage
                                    history={history}
                                    isCorporation={isCorporation}
                                    close={close}
                                    setActiveTab={setActiveTab}
                                    activeTab={activeTab}
                                    setAgreementUploaded={setAgreementUploaded}
                                    agreementUploaded={agreementUploaded}
                                    handleAgreementDataChange={
                                        handleAgreementDataChange
                                    }
                                />
                            )}
                        </div>
                    </div>
                </div>
            ),
        },
        {
            id: "corpBillingDetails",
            title: "Billing Details",
            disabled: !programData?.agreementReferenceId,
            content: (
                <div
                    className="pt-3 justify-content-start program-list flex-column"
                    id="corporationBilling"
                >
                    <div className="tabContent">
                        <div>
                            {orderId || paymentComplete ? (
                                <BillingDetails
                                    hospital={hospital}
                                    setActiveTab={setActiveTab}
                                    activeTab={activeTab}
                                    close={close}
                                    setBillingInfo={handleBillingDataChange}
                                    orderId={orderId}
                                    isCorporation={isCorporation}
                                />
                            ) : (
                                <PaymentSummaryPage
                                    history={undefined}
                                    isCorporation={isCorporation}
                                    setActiveTab={setActiveTab}
                                    activeTab={activeTab}
                                    close={close}
                                    setBillingComplete={setBillingComplete}
                                    setPaymentComplete={setPaymentComplete}
                                    paymentComplete={paymentComplete}
                                />
                            )}
                        </div>
                    </div>
                </div>
            ),
        },
        isPaymentTabReq && {
            id: "corpPaymentDetails",
            title: "Payment Details",
            disabled: !programData?.agreementReferenceId,
            content: (
                <div
                    className="pt-3 justify-content-start program-list flex-column"
                    id="corporationPayment"
                >
                    <div className="tabContent">
                        <div>
                            <PaymentDetails
                                setActiveTab={setActiveTab}
                                activeTab={activeTab}
                                close={close}
                                setPaymentInfo={handlePaymentDataChange}
                                hospital={hospital}
                                price={price}
                                isCorporation={isCorporation}
                                setPaymentComplete={setPaymentComplete}
                                paymentComplete={paymentComplete}
                            />
                        </div>
                    </div>
                </div>
            ),
        },
    ].filter(Boolean);

    return (
        <FacilitiesDetailsWrapper className="corporation-container">
            <CorporationTabs activeTab={getActiveTab()} />
            <div className="container pt-lg-3 pb-5">
                <FacilitiesNav
                    returnPath={location?.state}
                    hospital={hospital}
                    programData={programData}
                    certificateData={certificateData}
                    lastEditedData={lastEditedData}
                    isCorporation={isCorporation}
                    exportFacilityData={exportFacilityData}
                    activeMenu="corporationFacilityDetails"
                />
                <div className="orgdetails-mgnt-tab aui-accordion-arrowtab">
                    <div
                        id="corpMgntDetails"
                        aria-hidden="true"
                        className="sr-only"
                    >
                        Organization Management Details
                    </div>
                    <Tab
                        handleSelectedTab={CorporationDetailhandleSelectedTab}
                        tabTitle={corporationDetailsTabsOnContent}
                        tabGroup="corpDetails"
                        selectedActiveTab={activeTab}
                        tabLabel="corpMgntDetails"
                    />
                </div>
            </div>
        </FacilitiesDetailsWrapper>
    );
};

export default FacilitiesDetails;
