import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState,
    useRef,
} from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { exportDetails, showModal, dismissModal } from "common/utils";
import store from "app/store";
import { getAssetDetails } from "api/certificateAPI";
import CONSTANTS from "common/constants";
import axios from "axios";
import { showToast } from "components/Toast/toast.slice";
import { useDispatch } from "react-redux";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { BreadCrumb } from "components/BreadCrumb";
import MoreActions from "components/MoreActions/MoreActions";
import FocusTrap from "focus-trap-react";
import { getArchivalAccordionDatesAPI } from "api/archivalDocumentIntegrationAPI";
import Tab from "components/Tab";
import PaymentDetails from "./PaymentDetails";
import AgreementDetails from "./AgreementDetails";
import BillingDetails from "./BillingDetails";
import ProgramsDetails from "./ProgramDetails";
import HospitalDetails from "./HospitalDetails";
import FacilityDetailsBanner from "../HospitalBanner/FacilityDetailsBanner";
import { FacilityDetailsWrapper } from "./styled";
import "styles/arrow-tab.scss";

import {
    approveHospital,
    deactivateHospital,
    getSubscriptionDetails,
} from "../../../api/adminDashboardAPI";

interface paymentProps {
    paymentStatus: string;
    modeOfPayment: string;
    verifiedBy: string;
}

interface Props {
    hospital: any;
    returnPath: any;
    programData: any;
    certificateData?: any;
    updateHospitalInfo?: any;
    detailsInd?: boolean;
}

export const FacilityDetails = forwardRef((props: Props, ref) => {
    const [hospital, setHospital] = useState<any>(props.hospital);
    const [billingInfo, setBillingInfo] = useState<any>({});
    const [paymentInfo, setPaymentInfo] = useState<any>({});
    const [registryInfo, setRegistryInfo] = useState<any>({});
    const [agreementInfo, setAgreementInfo] = useState<any>({});
    const [programData, setProgramData] = useState<any>(props.programData);
    const [registryQuestionData, setRegistryQuestionData] = useState<any>({});
    const [activeTab, setActiveTab] = useState<any>(null);
    const [lastEditedData, setLastEditedData] = useState<any>(null);
    const [archivedDate, setArchivedDate] = useState<any>();
    const [breadcrumbItems, setBreadcrumbItems] = useState<any>([]);
    const [orderId, setOrderId] = useState<any>();
    const [activateFacilityActivateInd, setActivateFacilityActivateInd] =
        useState<boolean>(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const params: any = useParams();
    const mangangementTab = location.state?.bT
        ? location.state?.bT
        : CONSTANTS.ACTIVE;

    const mangangementPage = location.state?.bP ? location.state?.bP : 1;
    const [selectedTab, setSelectedTab] = useState<any>(0);
    const modalRef = useRef<HTMLDivElement | null>(null);
    const activateContentRef = useRef<HTMLDivElement | null>(null);

    const OrgnizationDetailhandleSelectedTab = (value: any, index: number) => {
        setSelectedTab(value);
        setActiveTab(index);
    };

    useEffect(() => {
        setHospital(props.hospital);
        props.hospital && modifyLastEditedData(props.hospital);
        if (
            !props.returnPath?.state?.hRP &&
            !props.returnPath?.state?.adminfacilityMangemnetTab &&
            !location?.state?.adminfacilityMangemnetTab
        ) {
            const elementsArray = [];
            elementsArray.push(
                {
                    label: "Dashboard",
                    returnPath: props.returnPath,
                },
                {
                    label: "Registration Details",
                    returnPath: props.returnPath,
                }
            );
            setBreadcrumbItems(elementsArray);
        }
        getSubscriptionDetails(
            params.hospitalId,
            params.programId,
            params.categoryId
        )
            .then((response: any) => {
                if (response.success) {
                    setOrderId(response.data.orderId);
                }
            })
            .catch((error: any) => {
                dispatch(showToast(error));
            });
    }, [props.hospital]);

    useEffect(() => {
        if (hospital.approvalStatus === CONSTANTS.PENDING) {
            getArchivalAccordionDatesAPI(params.hospitalId).then((res: any) => {
                if (res.statusCode == 200) {
                    const archivalDate = res.data.archiveDates;
                    setArchivedDate(
                        res.data.archiveDates[archivalDate.length - 1]
                    );
                }
            });
        }
    }, []);

    useEffect(() => {
        const elementsArray = [];
        if (props.returnPath?.corporationId && props.returnPath?.state?.hRP) {
            elementsArray.push(
                {
                    label: "Corporation Management",
                    returnPath: {
                        pathname: "/corporation/corporation-management",
                        state: {
                            t: mangangementTab,
                            p: mangangementPage,
                        },
                    },
                },
                {
                    label: props.returnPath.state.corporationName,
                    returnPath: {
                        pathname: `/corporation/corporation-management/corporation-details/${props.returnPath.corporationId}`,
                        state: {
                            bT: mangangementTab,
                            bP: mangangementPage,
                        },
                    },
                },
                {
                    label: "Organizations Registered",
                    returnPath: props.returnPath,
                },
                {
                    label: props.returnPath.state.facilityName,
                    returnPath: props.returnPath,
                }
            );
        } else if (
            props.returnPath?.registryId &&
            props.returnPath?.state?.hRP
        ) {
            elementsArray.push(
                {
                    label: "Registry Management",
                    returnPath: "/registry/registry-management",
                },
                {
                    label: "Organizations Registered",
                    returnPath: props.returnPath,
                },
                {
                    label: "Registration Details",
                    returnPath: props.returnPath,
                }
            );
        } else if (
            props.returnPath?.state?.adminfacilityMangemnetTab ||
            location.state.adminfacilityMangemnetTab
        ) {
            elementsArray.push(
                {
                    label: "Organization Management",
                    returnPath: location?.state?.pathname
                        ? location?.state?.pathname
                        : props.returnPath,
                },
                {
                    label:
                        (props.returnPath?.state?.adminfacilityMangemnetTab ||
                            location?.state?.adminfacilityMangemnetTab) ==
                        CONSTANTS.PENDING
                            ? "Pending Approval"
                            : (props.returnPath?.state
                                  ?.adminfacilityMangemnetTab ||
                                  location?.state?.adminfacilityMangemnetTab) ==
                              CONSTANTS.REGISTRED
                            ? "Registered Organization"
                            : (props.returnPath?.state
                                  ?.adminfacilityMangemnetTab ||
                                  location?.state?.adminfacilityMangemnetTab) ==
                              CONSTANTS.CERTIFIED
                            ? "Certified Organization"
                            : "Deactivated Organization",
                    returnPath: location?.state?.pathname
                        ? {
                              pathname: location?.state?.pathname,
                              state: {
                                  adminfacilityMangemnetTab:
                                      location.state.adminfacilityMangemnetTab,
                              },
                          }
                        : props.returnPath,
                },
                {
                    label: location.state.facilityName,
                    returnPath: props.returnPath,
                }
            );
        }
        setBreadcrumbItems(elementsArray);
    }, [props.returnPath, location?.state?.backToOrganizationManagement]);

    useEffect(() => {
        setProgramData(props.programData);
        props.programData && modifyLastEditedData(props.programData);
    }, [props.programData]);

    useEffect(() => {
        paymentInfo && modifyLastEditedData(paymentInfo);
    }, [paymentInfo]);

    useEffect(() => {
        props.updateHospitalInfo && props.updateHospitalInfo(hospital);
    }, [hospital]);

    const modifyLastEditedData = (currentData: any) => {
        if (currentData?.updatedBy?.firstName && currentData?.updatedAt) {
            setLastEditedData(currentData);
        }
    };

    const handleHospitalDataChange = (modifiedData: any) => {
        setHospital(modifiedData);
        modifyLastEditedData(modifiedData);
    };

    const handleProgramDataChange = (modifiedData: any) => {
        setProgramData(modifiedData);
        modifyLastEditedData(modifiedData);
    };

    const handleBillingDataChange = (modifiedData: any) => {
        setBillingInfo(modifiedData);
        modifyLastEditedData(modifiedData);
    };

    const handlePaymentDataChange = (modifiedData: any) => {
        setPaymentInfo(modifiedData);
        modifyLastEditedData(modifiedData);
    };

    const handleAgreementDataChange = (modifiedData: any) => {
        setAgreementInfo(modifiedData);
        modifyLastEditedData(modifiedData);
    };

    const close = () => {
        history.push(props.returnPath || "/admin/hospital-management");
    };

    const exportFacilityData = () => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        getAssetDetails(CONSTANTS.EXPORT_FACILITY_TEMPLATE, true).then(
            async (presignedURL: any) => {
                if (
                    presignedURL.data?.assets?.[0].isDownloadable &&
                    presignedURL.data?.assets?.[0].presignedUrl
                ) {
                    const iframe: any = document.createElement("iframe");
                    iframe.setAttribute("height", "0");
                    iframe.setAttribute("width", "0");
                    iframe.className = "hiddenFrame";

                    try {
                        const axiosResponse = await axios({
                            method: "get",
                            url: presignedURL.data?.assets?.[0].presignedUrl,
                        });

                        if (axiosResponse.data) {
                            const templateHtml = axiosResponse.data;
                            /* add iframe to the main document for canvas creation and print */
                            document.body.appendChild(iframe);

                            iframe.onload = async () => {
                                await exportDetails(
                                    hospital,
                                    props.programData,
                                    registryQuestionData,
                                    registryInfo,
                                    agreementInfo,
                                    billingInfo,
                                    paymentInfo
                                );
                                const toast = {
                                    message:
                                        "Organization details successfully exported.",
                                    code: "Success:",
                                    type: "success",
                                };
                                dispatch(showToast(toast));

                                dispatch(
                                    setPageLoadingStatus({
                                        isPageLoading: false,
                                    })
                                );
                            };

                            iframe.contentWindow.document.open();
                            iframe.contentWindow.document.write(templateHtml);
                            iframe.contentWindow.document.close();
                        }
                    } catch {
                        const toast = {
                            message: "Export template doesn't exist.",
                        };

                        dispatch(showToast(toast));
                        dispatch(
                            setPageLoadingStatus({ isPageLoading: false })
                        );
                    }
                }
            }
        );
    };
    const sortDropdown = [
        {
            label: CONSTANTS.EXPORT,
            value: CONSTANTS.EXPORT,
            className: "aha-icon-export menu-cst-icon",
        },
    ];
    if (orderId !== null) {
        sortDropdown.push({
            label:
                hospital.approvalStatus === CONSTANTS.PENDING
                    ? CONSTANTS.APPROVE
                    : CONSTANTS.ACTIVATE,
            value:
                hospital.approvalStatus === CONSTANTS.PENDING
                    ? CONSTANTS.APPROVE
                    : CONSTANTS.ACTIVATE,
            className: "aha-icon-tick-mark mr-2 font-red",
        });
    }
    if (hospital.approvalStatus !== CONSTANTS.PENDING) {
        sortDropdown.push({
            label: CONSTANTS.ARCHIVED,
            value: CONSTANTS.ARCHIVED,
            className: "aha-icon-archived menu-cst-icon",
        });
    }
    sortDropdown.push({
        label: CONSTANTS.USER_MANAGEMENT,
        value: CONSTANTS.USER_MANAGEMENT,
        className: "aha-icon-users-group mr-2 menu-cst-icon",
    });

    const callParentOnSelect = (value: any) => {
        if (value === CONSTANTS.EXPORT) {
            return exportFacilityData();
        }
        if (value === CONSTANTS.APPROVE) {
            return approveFacility();
        }
        if (value === CONSTANTS.ACTIVATE) {
            return activateFacilityConfirmationPopupModal();
        }
        if (value === CONSTANTS.ARCHIVED) {
            history.push(
                `/admin/organization-management/archived-view/${params.hospitalId}/program/${params.programId}/category/${params.categoryId}`,
                {
                    customState: props.returnPath,
                    isArchived: true,
                    facilityName: hospital.facilityName,
                    pathname: "/admin/organization-management",
                    search: location?.state?.search,
                    isDeactived: true,
                }
            );
        }
        if (value === CONSTANTS.USER_MANAGEMENT) {
            history.push(
                `/admin/organization-management/organization-user-management`,
                {
                    adminfacilityMangemnetTab:
                        (hospital.isActive == false
                            ? CONSTANTS.DEACTIVATED
                            : props.returnPath?.state
                                  ?.adminfacilityMangemnetTab) ||
                        hospital.approvalStatus,
                    hospitalId: hospital.hospitalId,
                    programId: params.programId,
                    facilityName: hospital.facilityName,
                    backToOrganizationManagement:
                        "/admin/organization-management",
                    backReturn: `/admin/organization-management/approve-organization/${params.hospitalId}/program/${params.programId}/category/${params.categoryId}`,
                }
            );
        }
    };
    const approveFacility = () => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        approveHospital(
            hospital.hospitalId,
            params.programId,
            params.categoryId,
            "APPROVED"
        )
            .then((response) => {
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
                if (response.success && response.statusCode === 200) {
                    dispatch(
                        showToast({
                            visibility: true,
                            title: "Organization Approved:",
                            message: `Organization ${hospital.facilityName} successfully approved for the ${programData.programCategoryName} program.`,
                            type: "success",
                        })
                    );
                    close();
                }
            })
            .catch((error) => {
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
                store.dispatch(showToast(error));
            });
    };
    const activateFacilityConfirmationPopupModal = () => {
        setActivateFacilityActivateInd(true);
        showModal();
    };
    const activateFacilityConfirmationDismissModal = () => {
        setActivateFacilityActivateInd(false);
        dismissModal();
    };
    const activateFacility = async () => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        const activateResult = await deactivateHospital(
            hospital.facilityCode,
            true,
            hospital.hospitalId,
            null,
            parseInt(params.categoryId, 10)
        );
        if (activateResult.success) {
            dispatch(setPageLoadingStatus({ isPageLoading: false }));
            const toast = {
                message: `${hospital.facilityName} successfully activated.`,
                code: "Success:",
                type: "success",
            };
            dispatch(showToast(toast));
            close();
            activateFacilityConfirmationDismissModal();
            dispatch(setPageLoadingStatus({ isPageLoading: false }));
        }
    };
    useImperativeHandle(ref, () => ({
        exportFacilityData,
        close,
    }));

    const organizationDetailsTabsOnContent = [
        {
            id: "hospitalDetails",
            title: "Organization Details",
            content: (
                <div
                    className="pt-3 justify-content-start program-list flex-column"
                    id="orgDetails"
                >
                    <div className="tabContent">
                        <HospitalDetails
                            hospital={hospital}
                            setHospital={handleHospitalDataChange}
                            setActiveTab={setActiveTab}
                            activeTab={activeTab}
                            close={close}
                            orderId={orderId}
                        />
                    </div>
                </div>
            ),
        },
        {
            id: "programDetails",
            title: "Program Details",
            content: (
                <div
                    className="pt-3 justify-content-start program-list flex-column"
                    id="orgPrograms"
                >
                    <div className="tabContent">
                        <div>
                            <ProgramsDetails
                                setActiveTab={setActiveTab}
                                activeTab={activeTab}
                                programData={props.programData}
                                close={close}
                                setRegistryInfo={setRegistryInfo}
                                setRegistryQuestionData={
                                    setRegistryQuestionData
                                }
                                setProgramData={handleProgramDataChange}
                                hospital={hospital}
                                orderId={orderId}
                            />
                        </div>
                    </div>
                </div>
            ),
        },
        {
            id: "agreementDetails",
            title: "Agreement Details",
            disabled: programData && !programData?.agreementReferenceId,
            content: (
                <div
                    className="pt-3 justify-content-start program-list flex-column"
                    id="orgAgreementDetails"
                >
                    <div className="tabContent">
                        <div>
                            <AgreementDetails
                                hospital={hospital}
                                setActiveTab={setActiveTab}
                                activeTab={activeTab}
                                close={close}
                                setAgreementInfo={handleAgreementDataChange}
                                orderId={orderId}
                            />
                        </div>
                    </div>
                </div>
            ),
        },
        {
            id: "billingDetails",
            title: "Billing Details",
            disabled: programData && !programData?.agreementReferenceId,
            content: (
                <div
                    className="pt-3 justify-content-start program-list flex-column"
                    id="orgBilling"
                >
                    <div className="tabContent">
                        <div>
                            <BillingDetails
                                paymentInfo={paymentInfo}
                                hospital={hospital}
                                setActiveTab={setActiveTab}
                                activeTab={activeTab}
                                close={close}
                                setBillingInfo={handleBillingDataChange}
                                orderId={orderId}
                                detailsInd={props.detailsInd}
                            />
                        </div>
                    </div>
                </div>
            ),
        },
        {
            id: "paymentDetails",
            title: "Payment Details",
            disabled: !(
                paymentInfo?.paymentStatus == CONSTANTS.COMPLETED ||
                (paymentInfo?.paymentStatus == CONSTANTS.PENDING &&
                    paymentInfo?.modeOfPayment == CONSTANTS.PAYMENT_OFFLINE) ||
                Object.keys(paymentInfo).length === 0
            ),
            content: (
                <div
                    className="pt-3 justify-content-start program-list flex-column"
                    id="orgPayment"
                >
                    <div className="tabContent">
                        <div>
                            <PaymentDetails
                                detailsInd={props.detailsInd}
                                setActiveTab={setActiveTab}
                                activeTab={activeTab}
                                close={close}
                                setPaymentInfo={handlePaymentDataChange}
                                hospital={hospital}
                                programCategoryName={
                                    programData?.programCategoryName
                                }
                                certificateExpiryDate={
                                    props.certificateData
                                        ?.programCertificateExpiryDate
                                }
                            />
                        </div>
                    </div>
                </div>
            ),
        },
    ].filter(Boolean);

    useEffect(() => {
        if (activateFacilityActivateInd && activateContentRef.current) {
            activateContentRef.current.setAttribute("tabIndex", "-1");
            activateContentRef.current.focus();
        }
        const handleKeyDown = (event: any) => {
            if (event.key === "Escape") {
                activateFacilityConfirmationDismissModal();
            }
        };
        const handleMouseDown = (event: any) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setActivateFacilityActivateInd(false);
                dismissModal();
            }
            if (modalRef.current && modalRef.current.contains(event.target)) {
                activateContentRef.current?.removeAttribute("tabIndex");
            }
        };

        if (activateFacilityActivateInd) {
            document.addEventListener("keydown", handleKeyDown);
            document.addEventListener("mousedown", handleMouseDown);
        }

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
            document.removeEventListener("mousedown", handleMouseDown);
        };
    }, [activateFacilityActivateInd]);

    return (
        <FacilityDetailsWrapper>
            {(location.state?.adminfacilityMangemnetTab === CONSTANTS.PENDING ||
                location.state?.adminfacilityMangemnetTab ==
                    CONSTANTS.DEACTIVATED) && (
                <>
                    <BreadCrumb breadcrumbItems={breadcrumbItems} />
                    <div className="d-flex justify-content-between align-items-center mb-4 fac-heading">
                        <h1 className="h2 mb-0 font-400 ">
                            Organization Management
                        </h1>

                        <div className="d-flex">
                            <div
                                id="moreActions"
                                className="d-flex align-items-center more-actions"
                            >
                                <div
                                    className="export-dropdown ml-2"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="More Actions"
                                    data-container="#moreActions"
                                >
                                    <MoreActions
                                        sortDropdown={sortDropdown}
                                        hospital={hospital}
                                        callParentOnSelect={callParentOnSelect}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {hospital.isActive === false && activateFacilityActivateInd && (
                <FocusTrap
                    focusTrapOptions={{
                        escapeDeactivates: false,
                        clickOutsideDeactivates: false,
                    }}
                >
                    <div
                        className="modal show fade aui-modal d-block"
                        tabIndex={-1}
                        aria-labelledby="modalLabel"
                        aria-modal="true"
                        role="dialog"
                    >
                        <div
                            className="modal-dialog modal-dialog-centered modal-sm"
                            ref={modalRef}
                        >
                            <div
                                className="modal-content"
                                id="activateContentRef"
                                ref={activateContentRef}
                            >
                                <div className="modal-body p-0">
                                    <div className="text-center">
                                        <span className="sr-only">
                                            Confirm Activate Organization
                                        </span>
                                        <p className="pb-2">
                                            Are you sure you want to
                                            activate&nbsp;
                                            <b>{hospital.facilityName}</b>{" "}
                                            organization?
                                        </p>
                                        <div className="mt-4 d-flex justify-content-center">
                                            <button
                                                className="btn btn-secondary btn-round btn-sm mx-2"
                                                aria-label="Cancel Activation and close the modal"
                                                onClick={
                                                    activateFacilityConfirmationDismissModal
                                                }
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                className="btn btn-primary btn-round btn-sm mx-2"
                                                onClick={activateFacility}
                                                aria-label="Confirm Activate"
                                            >
                                                Confirm
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </FocusTrap>
            )}
            <FacilityDetailsBanner
                hospital={hospital}
                programData={programData}
                lastEditedData={lastEditedData}
                exportFacilityDetailsData={exportFacilityData}
                certificateData={props.certificateData}
                close={close}
                orderId={orderId}
                isAdmin
                facilityId="detailsFacility"
                id="detailsSettings"
                confirmDeactivateModalId="detailsConfirmDeactiveModal"
                confirmRemoveModalId="detailsConfirmRemoveModal"
                detailsTitleBannerId="detailsTitleBanner"
            />
            <div className="orgdetails-mgnt-tab aui-accordion-arrowtab">
                <div id="orgMgntDetails" aria-hidden="true" className="sr-only">
                    Organization Management Details
                </div>
                <Tab
                    handleSelectedTab={OrgnizationDetailhandleSelectedTab}
                    tabTitle={organizationDetailsTabsOnContent}
                    tabGroup="orgDetails"
                    selectedActiveTab={activeTab}
                    tabLabel="orgMgntDetails"
                />
            </div>
        </FacilityDetailsWrapper>
    );
});

export default FacilityDetails;
