import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Sidebar from "../../Admin/Sidebar/Sidebar";
import { CorporateManagementWrapper } from "./styled";
import "../../styles/styles.scss";
import CorporationTable from "components/CorporationTable";
import { getCorporations } from "../../../api/corporationAPI";
import CONSTANTS from "../../../common/constants";
import Pagination, { IPagination } from "../../../components/Pagination";
import Tab from "components/Tab";

export const CorporationManagement = () => {
    const location = useLocation();
    const tabType = location.state?.t || location?.state?.bT;
    const page = location.state?.p;
    const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
    const [tab, setTab] = useState<string>(tabType || CONSTANTS.ACTIVE);
    const [pageNumber, setPageNumber] = useState<number>(
        page ? Number(page) : 1
    );
    const paginationSetLimit: number = 5;
    const defaultPagination: IPagination = {
        pageNumber: 1,
        pageSize: 25,
        isFirst: true,
        isLast: false,
        setPageNumber,
        paginationSetLimit,
    };
    const [pagination, setPagination] =
        useState<IPagination>(defaultPagination);
    const [activeCorporations, setActiveCorporations] = useState<any>([]);
    const [inactiveCorporations, setInactiveCorporations] = useState<any>([]);
    const [roleValue, setRoleValue] = useState<string>("none");
    const [mounted, setMounted] = useState<boolean>(false);
    const [selectedTab, setSelectedTab] = useState<any>(0);

    const handleSelectedTab = (value: any) => {
        setSelectedTab(value);
    };

    useEffect(() => {
        setIsTableLoading(true);
        localStorage.setItem("resetFilter", "true");
        getCorporations(tab, pageNumber, pagination.pageSize).then(
            (response: any) => {
                if (response.success && response.statusCode === 200) {
                    if (tab === CONSTANTS.ACTIVE) {
                        setActiveCorporations(response.data.corporations);
                    } else {
                        setInactiveCorporations(response.data.corporations);
                    }
                    setPagination({
                        ...response.data._pagination,
                        setPageNumber,
                    });
                }
                setIsTableLoading(false);
                if (mounted) {
                    setRoleValue("alert");
                }
                setMounted(true);
            }
        );
        window.scrollTo(0, 0);
    }, [tab, pageNumber]);

    const filteredTabsOnContent = [
        {
            id: CONSTANTS.ACTIVE,
            title: "Active Corporations",
            onClick: () => {
                setPageNumber(1);
                setPagination(defaultPagination);
                setTab(CONSTANTS.ACTIVE);
            },
            content: (
                <div
                    className="pt-3 justify-content-start program-list flex-column"
                    id="corpActive"
                >
                    <div className="tabContent">
                        <div>
                            {isTableLoading ? (
                                <div
                                    role="alert"
                                    aria-live="assertive"
                                    aria-label="Active corporations table is loading"
                                    className="sr-only"
                                />
                            ) : (
                                ""
                            )}
                            <CorporationTable
                                corporations={activeCorporations}
                                actionURL="/corporation/corporation-management/corporation-details"
                                isTableLoading={isTableLoading}
                                tab={tab}
                                pageNumber={pageNumber}
                                pagination={pagination}
                                tabType={CONSTANTS.FACILITY_TYPE.ACTIVE}
                                roleValue={roleValue}
                            />
                        </div>
                    </div>
                </div>
            ),
        },
        {
            id: CONSTANTS.INACTIVE,
            title: "Inactive Corporations",
            onClick: () => {
                setPageNumber(1);
                setPagination(defaultPagination);
                setTab(CONSTANTS.INACTIVE);
            },
            content: (
                <div
                    className="pt-3 justify-content-start program-list flex-column"
                    id="corpInactive"
                >
                    <div className="tabContent">
                        <div>
                            {isTableLoading ? (
                                <div
                                    role="alert"
                                    aria-live="assertive"
                                    aria-label="inactive corporations table is loading"
                                    className="sr-only"
                                />
                            ) : (
                                ""
                            )}
                            <CorporationTable
                                corporations={inactiveCorporations}
                                isTableLoading={isTableLoading}
                                tab={tab}
                                pageNumber={pageNumber}
                                actionURL="/corporation/corporation-management/corporation-details"
                                pagination={pagination}
                                tabType={CONSTANTS.FACILITY_TYPE.INACTIVE}
                                roleValue={roleValue}
                            />
                        </div>
                    </div>
                </div>
            ),
        },
    ].filter(Boolean);

    return (
        <CorporateManagementWrapper className="row no-gutters w-100 flex-grow-1">
            <Sidebar activeTab="corporateManagement" />
            <section className="d-flex admin-main-content container">
                <div className="row no-gutters w-100 admin-main">
                    <div className="col-12">
                        <div className="d-md-flex justify-content-md-between corp-magnt">
                            <h1
                                className="h2 font-400 mb-0"
                                id="corporationManagementTitle"
                            >
                                Corporation Management
                            </h1>
                            <Link
                                to={{
                                    pathname:
                                        "/corporation/corporation-management/corporation-details/add-corporation/new-corporation",
                                    state: { bT: tab, bP: pageNumber },
                                }}
                                className="btn btn-primary btn-round mt-4 mt-md-0"
                                aria-label="Add New Corporation"
                            >
                                <span aria-hidden="true">Add +</span>
                                <span className="sr-only">
                                    Add new Corporation
                                </span>
                            </Link>
                        </div>
                        <div className="corporation-tab">
                            <Tab
                                handleSelectedTab={handleSelectedTab}
                                tabTitle={filteredTabsOnContent}
                                tabGroup="corportaion"
                                tabLabel="corporationManagementTitle"
                                tab={tab}
                                setTab={setTab}
                            />
                        </div>
                        {pagination && Number(pagination.totalPages) > 0 ? (
                            <Pagination
                                pageNumber={pagination.pageNumber}
                                pageSize={pagination.pageSize}
                                totalCount={pagination.totalCount}
                                totalPages={pagination.totalPages}
                                isFirst={pagination.isFirst}
                                isLast={pagination.isLast}
                                setPageNumber={pagination.setPageNumber}
                                paginationSetLimit={paginationSetLimit}
                            />
                        ) : null}
                    </div>
                </div>
            </section>
        </CorporateManagementWrapper>
    );
};

export default CorporationManagement;
