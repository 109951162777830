import React, { Component, useEffect, useRef, useState } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import Pagination, { IPagination } from "components/Pagination";

import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { useDispatch } from "react-redux";
import { getNavigationByHospitalId, getUserHospitals } from "api/usersApi";
import TableRowCount from "components/TableRowCount";
import {
    calculatNumOfDaysBetweenDates,
    expireDataRowStatus,
    getCurrentQuarter,
    hospitalApprovalStatus,
    localDateToYYYYMMDDFormatString,
    wordSplit,
    showModal,
    dismissModal,
} from "common/utils";
import FocusTrap from "focus-trap-react";
import { NewOrganizationConfirmationWrapper } from "./styled";
import "styles/global.scss";
import "styles/expire-tooltip.scss";

interface Props {
    setAddOrganizationActivateInd: any;
    addOrganizationActivateInd: any;
    setFocusState: any;
}
const NewOrganizationConfirmation = (props: Props) => {
    const modalRef = useRef<HTMLDivElement | null>(null);
    const history = useHistory();
    const setHospitalDefaults = () => {
        history.push("/organization/register/new-organization");
        window.localStorage.setItem("isProgramApproved", "");
        window.localStorage.setItem("isHospitalDeActiveOrDeleted", "");
        props.setAddOrganizationActivateInd(false);
        dismissModal();
    };
    const modalInnerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (props.addOrganizationActivateInd && modalInnerRef.current) {
            modalInnerRef.current.setAttribute("tabIndex", "-1");
            modalInnerRef.current.focus();
        }
        const handleKeyDown = (event: any) => {
            if (event.key === "Escape") {
                props.setAddOrganizationActivateInd(false);
                dismissModal();
                props.setFocusState(true);
            }
        };
        const handleMouseDown = (event: any) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                props.setAddOrganizationActivateInd(false);
                dismissModal();
            }
            if (modalRef.current && modalRef.current.contains(event.target)) {
                modalInnerRef.current?.removeAttribute("tabIndex");
            }
        };
        if (props.addOrganizationActivateInd) {
            document.addEventListener("keydown", handleKeyDown);
            document.addEventListener("mousedown", handleMouseDown);
        }
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
            document.removeEventListener("mousedown", handleMouseDown);
        };
    }, [props.addOrganizationActivateInd]);

    const proceedAndDismissModal = () => {
        props.setAddOrganizationActivateInd(false);
        dismissModal();
        props.setFocusState(true);
    };

    return (
        <NewOrganizationConfirmationWrapper className="container">
            <FocusTrap
                focusTrapOptions={{
                    escapeDeactivates: false,
                    clickOutsideDeactivates: false,
                }}
            >
                {/* Add New Facility Confirmation Modal */}
                <div
                    className="facility-confirm-modal modal fade show aui-modal d-block"
                    role="dialog"
                    id="registerModal"
                    tabIndex={-1}
                    aria-labelledby="registerModalLabel"
                    aria-modal="true"
                >
                    <div
                        className="modal-dialog modal-dialog-centered modal-md"
                        ref={modalRef}
                    >
                        <div
                            className="modal-content"
                            ref={modalInnerRef}
                            id="modalInnerRef"
                        >
                            <div className="modal-body p-0">
                                <h2
                                    className="h5 text-center font-bold register-header"
                                    id="registerModalLabel"
                                >
                                    Registration Confirmation
                                </h2>
                                <p className="mb-4">
                                    Are you sure you want to{" "}
                                    <strong>Register a New Organization</strong>
                                    ? If you are part of an already{" "}
                                    <strong>Registered Organization</strong>,
                                    please connect with your organization&#39;s
                                    QCT leader before proceeding.
                                </p>
                                <div className="facility-confirm-btns d-flex justify-content-center flex-column flex-sm-row">
                                    <button
                                        className="btn btn-secondary btn-round"
                                        aria-label="Cancel Registering New organization"
                                        onClick={proceedAndDismissModal}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="btn btn-primary btn-round ml-sm-4 mt-4 mt-sm-0"
                                        data-dismiss="modal"
                                        onClick={setHospitalDefaults}
                                        aria-label="Proceed to Register a New organization"
                                    >
                                        Proceed
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </FocusTrap>
        </NewOrganizationConfirmationWrapper>
    );
};

export default NewOrganizationConfirmation;
