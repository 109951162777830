import styled from "styled-components";

export const TabWrapper = styled.div`
    .nav-item {
        button {
            border: none;
            background: transparent;
            padding: 10px 36px;
            border-bottom: 3px solid transparent;
            font-weight: 600;
            &:hover {
                border-color: transparent;
            }
            &.active {
                color: #c10e21;
                border-bottom: 3px solid #c10e21;
                background: transparent;
                pointer-events: none;
            }
        }
    }
    .tabContent {
        list-style-type: none;
        padding: 0;
        margin: 0;
        > *:last-child {
            .program-accordion-card {
                margin-bottom: 0;
            }
        }
    }
`;
