import React, { useEffect, useRef, useState } from "react";
import DocumentResultTable from "components/DocumentSubmission/DocumentResultTable";
import store from "app/store";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import DocumentServiceAPI from "api/documentsAPI";
import { getHospitalProgramDetails } from "api/hospitalProgramsAPI";
import { useLocation, useParams } from "react-router";
import { logger } from "utils/logger.utils";
import {
    localDateToYYYYMMDDFormatString,
    parseJwt,
    redirectionForErrorCode,
} from "common/utils";
import CONSTANTS from "common/constants";
import { IUploadDocForm } from "components/DocumentSubmission/UploadDocumentModalContent";
import AdminDocumentView from "components/DocumentSubmission/AdminDocumentView";
import {
    getArchivalAccordionDatesAPI,
    getArchivalDocumentAPI,
    getArchivalDocumentViewApi,
} from "api/archivalDocumentIntegrationAPI";
import { ArchivedDocumentWrapper } from "./styled";

interface Props {
    docArcivedDate: string;
    docApprovedDate: string;
    firstRenewingDate?: any;
    secondRenewingDate?: any;
}

export const ArchivedDocuments = ({
    docArcivedDate,
    docApprovedDate,
    firstRenewingDate,
    secondRenewingDate,
}: Props) => {
    const initUploadDocForm: IUploadDocForm = {
        title: "",
        programId: "",
        programName: "",
        uploadStandard: "###", // ### is sperating the standardId & SandardName
        uploadDescription: "",
        file: null,
        facilityMessage: "",
        fileUploadError: false,
        setFileUploadSuccess: false,
    };
    const [refreshTableDateNow, setRefreshTableDateNow] = useState<number>(0);
    const childRef = useRef<any>(null);
    const [documentDetails, setDocumentDetails] = useState<any>([]);
    const [selectedProgram, setSelectedProgram] = useState<string>("");
    const [uploadDocForm, setUploadDocForm] =
        useState<IUploadDocForm>(initUploadDocForm);
    const [uploadProgram, setUploadProgram] = useState<any>({
        programId: undefined,
        programCategoryId: undefined,
        programCategoryName: "",
        subscriptionStartDate: "",
        subscriptionEndDate: "",
    });
    const [uploadStandards, setUploadStandards] = useState<any>([]);
    const [document, setDocument] = useState<any>({});
    const [approvalStatus, setApprovalStatus] = useState<any>();
    const [approvedDate, setApprovedDate] = useState<string>(docApprovedDate);
    const [archivedDate, setArchivedDate] = useState<string>(docArcivedDate);
    const location = useLocation();
    const documentServiceAPI = new DocumentServiceAPI();
    const params = useParams<any>();
    const [formattedApprovedDate, setFormattedApprovedDate] = useState("");
    const [formattedArchivedLatestDate, setFormattedArchivedLatestDate] =
        useState("");
    const [selectedArchivedDate, setSelectedArchivedDate] = useState(null);
    const [archivedLatestDate, setArchivedLatestDate] = useState<any>(null);
    const [documentViewActivateInd, setDocumentViewActivateInd] =
        useState<boolean>(false);
    const userAccessToken = window.localStorage.getItem("userAccessToken");
    const isAdmin =
        userAccessToken &&
        parseJwt(userAccessToken).role_code === CONSTANTS.USER_ROLES.AHA_ADMIN;
    const viewDocument = async (referenceId: string, programId: number) => {
        setDocument({ referenceId, programId });
        store.dispatch(setPageLoadingStatus({ isPageLoading: true }));
        Promise.all([
            getArchivalDocumentViewApi(params?.hospitalId, referenceId),
            getHospitalProgramDetails(programId, params?.hospitalId),
        ])
            .then((responses: any) => {
                if (responses[0].success && responses[1].success) {
                    setDocumentDetails(responses[0].data.hospitalUploadDetails);
                    setSelectedProgram(responses[1].data.programCategoryName);
                }
                store.dispatch(setPageLoadingStatus({ isPageLoading: false }));
            })
            .catch((err) => {
                logger(err);
                redirectionForErrorCode(
                    CONSTANTS.ROUTES.CORPORATION_DASHBOARD,
                    err,
                    history,
                    2000,
                    true
                );
                store.dispatch(setPageLoadingStatus({ isPageLoading: false }));
            });
    };
    useEffect(() => {
        const archDate: any = localStorage.getItem("selectedArchivedDate");
        setArchivedDate(archDate);
        return () => {
            localStorage.removeItem("selectedArchivedDate");
        };
    }, []);
    useEffect(() => {
        if (docArcivedDate && docArcivedDate !== "undefined") {
            store.dispatch(setPageLoadingStatus({ isPageLoading: true }));
            setArchivedDate(docArcivedDate);
            getArchivalAccordionDatesAPI(params.hospitalId).then((res: any) => {
                if (res.statusCode == 200) {
                    const latestArchiveDate =
                        res.data.archiveDates[res.data.archiveDates.length - 1];
                    setArchivedLatestDate(latestArchiveDate);
                    if (res.data.archiveDates.length > 1) {
                        setApprovedDate(
                            res.data.archiveDates[
                                res.data.archiveDates.length - 2
                            ]
                        );
                    } else {
                        setApprovedDate(res.data.approvedDate);
                    }
                    if (selectedArchivedDate === null) {
                        setSelectedArchivedDate(latestArchiveDate);
                    }
                }
            });
            getArchivalDocumentAPI(params?.hospitalId, docArcivedDate).then(
                (res: any) => {
                    if (res.statusCode === 200) {
                        if (res?.data?.documentData?.rows?.length !== 0) {
                            setApprovalStatus(
                                res?.data?.documentData?.rows[0]
                                    .lastApprovalStatus
                            );
                        } else {
                            setApprovalStatus("");
                        }
                        store.dispatch(
                            setPageLoadingStatus({ isPageLoading: false })
                        );
                    }
                }
            );
        }
    }, [docArcivedDate, archivedLatestDate]);
    useEffect(() => {
        if (firstRenewingDate !== null && secondRenewingDate !== null) {
            setFormattedApprovedDate(
                localDateToYYYYMMDDFormatString(firstRenewingDate)
            );
            setFormattedArchivedLatestDate(
                localDateToYYYYMMDDFormatString(secondRenewingDate)
            );
        }
    }, [firstRenewingDate, secondRenewingDate]);

    return (
        <ArchivedDocumentWrapper className="archived-main-content">
            {localStorage.getItem("selectedArchivedDate") !== null &&
            localStorage.getItem("selectedArchivedDate") !== "undefined" ? (
                <div className="year-period">
                    Archived Documents : {formattedApprovedDate} to{" "}
                    {formattedArchivedLatestDate}
                </div>
            ) : (
                <div className="d-flex">Archived Data</div>
            )}
            <div className="d-flex org-heading align-items-center">
                <h1 className="h2 font-400 mb-0">
                    {location?.state?.facilityName}
                </h1>
                {approvalStatus && (
                    <div className="org-status ml-4">{approvalStatus}</div>
                )}
            </div>
            <div className="mt-4 archive-inner-container">
                <DocumentResultTable
                    isArchivedDocuments
                    isEUP={!isAdmin}
                    viewDocument={viewDocument}
                    refreshTableDateNow={refreshTableDateNow}
                    setRefreshTableDateNow={setRefreshTableDateNow}
                    documentsInd
                    setUploadProgram={setUploadProgram}
                    setUploadStandards={setUploadStandards}
                    ref={childRef}
                    setDocumentViewActivateInd={setDocumentViewActivateInd}
                />
                {documentViewActivateInd ? (
                    <AdminDocumentView
                        documentDetails={documentDetails}
                        setDocumentDetails={setDocumentDetails}
                        setRefreshTableDateNow={setRefreshTableDateNow}
                        selectedProgram={selectedProgram}
                        facilityName=""
                        viewDocument={viewDocument}
                        document={document}
                        documentViewActivateInd={documentViewActivateInd}
                        setDocumentViewActivateInd={setDocumentViewActivateInd}
                    />
                ) : null}
            </div>
        </ArchivedDocumentWrapper>
    );
};

export default ArchivedDocuments;
