import React, { useEffect, useState } from "react";
import UserManagement from "components/UserManagement";
import { getUserList } from "api/usersApi";
import { dismissModal, parseJwt, showModal } from "common/utils";
import CONSTANTS from "common/constants";
import { useLocation } from "react-router";
import {
    addUser,
    deleteUser,
    getUserLogsFromUserManagement,
} from "api/userManagementAPI";
import { useDispatch } from "react-redux";
import { showToast } from "components/Toast/toast.slice";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { BreadCrumb } from "components/BreadCrumb";
import { FacilityDetailsBanner } from "pages/Admin/HospitalBanner/FacilityDetailsBanner";
import { getHospitalProgramDetails } from "api/hospitalProgramsAPI";
import RegistryBanner from "components/RegistryBanner";
import CorporationBanner from "pages/Corporation/CorporateBanner/CorporationBanner";
import { IPagination } from "components/Pagination";
import { getHospitalById } from "../../../api/hospitalApi";
import { logger } from "../../../utils/logger.utils";
import { OrganizationManagementWrapper } from "../../EMSAdmin/OrganizationManagement/styled";
import Sidebar from "../Sidebar/Sidebar";

const AdminUserManagement = () => {
    const location = useLocation();
    const [users, setUsers] = useState<any>("");
    const [inputUser, setInputUser] = useState<any>("");
    const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
    const [isUsersResponseAvailable, setIsUsersResponseAvailable] =
        useState<boolean>(false);
    const userAccessToken = window.localStorage.getItem("userAccessToken");
    const hospitalId = location?.state?.hospitalId || null;
    const programId = location?.state?.programId || null;
    const [addUserInd, setAddUserInd] = useState<boolean>(false);
    const [deleteUserInd, setDeleteUserInd] = useState<boolean>(false);
    const isAdmin =
        userAccessToken &&
        parseJwt(userAccessToken).role_code === CONSTANTS.USER_ROLES.AHA_ADMIN;
    const [roleValue, setRoleValue] = useState<string>("none");
    const [mounted, setMounted] = useState<boolean>(false);
    const [breadcrumbItems, setBreadcrumbItems] = useState<any>([]);
    const [validateDisabled, setValidateDisabled] = useState<boolean>(false);
    const [userEmail, setUserEmail] = useState<any>("");
    const [userLogs, setUserLogs] = useState<any>([]);
    const [userName, setUserName] = useState<any>("");
    const [deleteUserId, setDeleteUserId] = useState<any>("");
    const dispatch = useDispatch();
    const [disabledInd, setDisabledInd] = useState<boolean>(false);
    const [addUserActivateInd, setAddUserActivateInd] =
        useState<boolean>(false);
    const [deleteUserActivateInd, setDeleteUserActivateInd] =
        useState<boolean>(false);
    const [hospital, setHospital] = useState<any>({});
    const [toggleState, settoggleState] = useState(false);
    const [programData, setProgramData] = useState<any>();
    const [lastEditedData, setLastEditedData] = useState<any>(null);
    const corporationId = location?.state?.corporationId;
    const backToCorporationManagement: Object = {
        pathname: `/corporation/corporation-management`,
        state: {
            bT: location?.state?.bT,
        },
    };
    const registryId = location?.state?.registryId;
    const registry = location?.state?.registry;
    const registryMangangementTab = location.state?.t
        ? location.state?.t
        : CONSTANTS.ACTIVE;
    const activeTabSidebar = corporationId
        ? "corporateManagement"
        : registryId
        ? "registryManagement"
        : "hospitalManagement";
    const backToRegistryManagement: Object = {
        pathname: `/registry/registry-management`,
        state: {
            t: location?.state?.t,
        },
    };
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [isInActive, setIsInActive] = useState<boolean>(false);
    const paginationSetLimit: number = 5;
    const defaultPagination: IPagination = {
        pageNumber: 1,
        pageSize: 25,
        isFirst: true,
        isLast: false,
        setPageNumber,
        paginationSetLimit,
    };
    const [pagination, setPagination] =
        useState<IPagination>(defaultPagination);
    const getFacilityDetails = () => {
        if (!hospitalId) return;
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        Promise.all([
            getHospitalById(hospitalId),
            getHospitalProgramDetails(programId, hospitalId),
        ])
            .then((response: any) => {
                if (response[0].success && response[1].success) {
                    setHospital(response[0].data.hospital);
                    modifyLastEditedData(response[0].data.hospital);
                    localStorage.setItem(
                        "selectedHospitalCountryCode",
                        response[0].data.hospital.countryCode
                    );
                    setProgramData(response[1].data);
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                }
            })
            .catch(() => {
                dispatch(setPageLoadingStatus({ isPageLoading: true }));
            });
    };

    const modifyLastEditedData = (currentData: any) => {
        if (currentData?.updatedBy?.firstName && currentData?.updatedAt) {
            setLastEditedData(currentData);
        }
    };

    useEffect(() => {
        getFacilityDetails();
        const elementsArray = [];
        let pathToReturn: any;
        let retrurnFacility: any;
        if (location.state?.adminfacilityMangemnetTab) {
            pathToReturn = {
                pathname: location?.state?.backToOrganizationManagement,
                state: {
                    adminfacilityMangemnetTab:
                        location?.state?.adminfacilityMangemnetTab,
                    adminOrganizationMangemnetPage:
                        location?.state?.adminOrganizationMangemnetPage,
                    pageSort: location?.state?.pageSort,
                },
            };
            retrurnFacility = {
                pathname: location?.state?.backReturn,
                state: {
                    adminfacilityMangemnetTab:
                        location?.state?.adminfacilityMangemnetTab,
                    facilityName: location?.state?.facilityName,
                    backToFaciclityManagement: location?.state?.backReturn,
                    pathname: location?.state?.backToOrganizationManagement,
                },
            };
            elementsArray.push(
                {
                    label: "Organization Management",
                    returnPath: pathToReturn,
                },
                {
                    label:
                        location?.state?.adminfacilityMangemnetTab ==
                        CONSTANTS.PENDING
                            ? "Pending Approval"
                            : location?.state?.adminfacilityMangemnetTab ==
                              CONSTANTS.REGISTRED
                            ? "Registered Organization"
                            : location?.state?.adminfacilityMangemnetTab ==
                              CONSTANTS.RENEWING
                            ? "Renewing Organization"
                            : location?.state?.adminfacilityMangemnetTab ==
                              CONSTANTS.CERTIFIED
                            ? "Certified Organization"
                            : "Deactivated Organization",
                    returnPath: pathToReturn,
                },
                {
                    label: location.state.facilityName,
                    returnPath: retrurnFacility,
                },
                {
                    label: "Organization User Management",
                }
            );
        } else if (corporationId) {
            if (location?.state?.bT == CONSTANTS.ACTIVE) {
                setIsInActive(false);
                elementsArray.push(
                    {
                        label: "Corporation Management",
                        returnPath: backToCorporationManagement,
                    },
                    {
                        label: "Active Corporation",
                        returnPath: backToCorporationManagement,
                    },
                    {
                        label: location?.state?.corporationName,
                        returnPath: {
                            pathname: `/corporation/corporation-management/corporation-details/${corporationId}`,
                            state: {
                                bT: location?.state?.corporationMangangementTab,
                                corporationName: location.state.corporationName,
                            },
                        },
                    },
                    {
                        label: "Corporation User Management",
                    }
                );
            } else if (location?.state?.bT == CONSTANTS.INACTIVE) {
                setIsInActive(true);
                elementsArray.push(
                    {
                        label: "Corporation Management",
                        returnPath: backToCorporationManagement,
                    },
                    {
                        label: "Inactive Corporation",
                        returnPath: backToCorporationManagement,
                    },
                    {
                        label: location?.state?.corporationName,
                        returnPath: {
                            pathname: `/corporation/corporation-management/corporation-details/${corporationId}`,
                            state: {
                                bT: location?.state?.bT,
                                corporationName:
                                    location?.state?.corporationName,
                            },
                        },
                    },
                    {
                        label: "Corporation User Management",
                    }
                );
            }
            setBreadcrumbItems(elementsArray);
        } else if (registryId) {
            if (registryMangangementTab == CONSTANTS.ACTIVE) {
                setIsInActive(false);
                elementsArray.push(
                    {
                        label: "Registry Management",
                        returnPath: backToRegistryManagement,
                    },
                    {
                        label: "Active Registries",
                        returnPath: backToRegistryManagement,
                    },
                    {
                        label: registry.registryName,
                        returnPath: {
                            pathname: `/registry/registry-management/registry-details/${registryId}`,
                            state: {
                                t: location?.state?.t,
                                registryName: registry.registryName,
                            },
                        },
                    },
                    {
                        label: "Registry User Management",
                    }
                );
            } else if (registryMangangementTab == CONSTANTS.INACTIVE) {
                setIsInActive(true);
                elementsArray.push(
                    {
                        label: "Registry Management",
                        returnPath: backToRegistryManagement,
                    },
                    {
                        label: "Inactive Registries",
                        returnPath: backToRegistryManagement,
                    },
                    {
                        label: registry.registryName,
                        returnPath: {
                            pathname: `/registry/registry-management/registry-details/${registryId}`,
                            state: {
                                t: location?.state?.t,
                                registryName: registry.registryName,
                                bT: registryMangangementTab,
                            },
                        },
                    },
                    {
                        label: "Registry User Management",
                    }
                );
            }
            setBreadcrumbItems(elementsArray);
        }
        setBreadcrumbItems(elementsArray);
    }, []);

    useEffect(() => {
        if (hospitalId) {
            setIsTableLoading(true);
            setIsUsersResponseAvailable(false);
            getUserList(hospitalId, 1, 5, false, inputUser)
                .then((response: any) => {
                    if (response.success) {
                        setUsers(response.data.users);
                    }
                    setIsUsersResponseAvailable(true);
                    setIsTableLoading(false);
                    if (mounted) {
                        setRoleValue("alert");
                    }
                    setMounted(true);
                    setAddUserInd(false);
                    setDeleteUserInd(false);
                })
                .catch((err) => {
                    logger(err);
                    setIsUsersResponseAvailable(true);
                    setIsTableLoading(false);
                    setAddUserInd(false);
                    setDeleteUserInd(false);
                });
            setAddUserInd(false);
            setDeleteUserInd(false);
        }
        if (registryId) {
            getUserList("", 1, 5, false, inputUser, false, false, registryId)
                .then((response: any) => {
                    if (response.success) {
                        setUsers(response.data.users);
                    }
                    setIsUsersResponseAvailable(true);
                    if (mounted) {
                        setRoleValue("alert");
                    }
                    setMounted(true);
                    setAddUserInd(false);
                    setDeleteUserInd(false);
                    setIsTableLoading(false);
                })
                .catch((err) => {
                    logger(err);
                    setIsUsersResponseAvailable(true);
                    setAddUserInd(false);
                    setDeleteUserInd(false);
                    setIsTableLoading(false);
                });
        }
    }, [addUserInd, deleteUserInd]);

    useEffect(() => {
        if (location?.state?.corporationId) {
            setIsTableLoading(true);
            setIsUsersResponseAvailable(false);
            getUserList(
                "",
                1,
                5,
                false,
                inputUser,
                "",
                location?.state?.corporationId
            )
                .then((response: any) => {
                    if (response.success) {
                        setIsUsersResponseAvailable(true);
                        setUsers(response.data.users);
                        setIsTableLoading(false);
                        if (mounted) {
                            setRoleValue("alert");
                        }
                        setMounted(true);
                        setAddUserInd(false);
                        setDeleteUserInd(false);
                    }
                })
                .catch((err) => {
                    logger(err);
                    setIsUsersResponseAvailable(true);
                    setIsTableLoading(false);
                });
        }
    }, [addUserInd, deleteUserInd]);

    useEffect(() => {
        if (hospitalId) {
            setIsTableLoading(true);
            setIsUsersResponseAvailable(false);
            setPagination(defaultPagination);
            setPageNumber(!toggleState ? 1 : pageNumber);
            getUserLogsFromUserManagement(
                hospitalId,
                pageNumber,
                pagination.pageSize
            )
                .then((response: any) => {
                    if (response.success) {
                        if (response.data._pagination) {
                            setPagination({
                                ...response.data._pagination,
                                setPageNumber,
                            });
                        }
                        setUserLogs(response.data.logsList);
                        setIsUsersResponseAvailable(true);
                    }
                    setIsTableLoading(false);
                })
                .catch((err) => {
                    logger(err);
                    setIsUsersResponseAvailable(true);
                    setIsTableLoading(false);
                });
        }
        if (location?.state?.corporationId) {
            setIsTableLoading(true);
            setIsUsersResponseAvailable(false);
            setPagination(defaultPagination);
            setPageNumber(!toggleState ? 1 : pageNumber);
            getUserLogsFromUserManagement(
                "",
                pageNumber,
                pagination.pageSize,
                location?.state?.corporationId
            )
                .then((response: any) => {
                    if (response.success) {
                        if (response.data._pagination) {
                            setPagination({
                                ...response.data._pagination,
                                setPageNumber,
                            });
                        }
                        setUserLogs(response.data.logsList);
                        setIsUsersResponseAvailable(true);
                    }
                    setIsTableLoading(false);
                })
                .catch((err) => {
                    logger(err);
                    setIsUsersResponseAvailable(true);
                    setIsTableLoading(false);
                });
        } else if (location?.state?.registryId) {
            setIsTableLoading(true);
            setIsUsersResponseAvailable(false);
            setPagination(defaultPagination);
            setPageNumber(!toggleState ? 1 : pageNumber);
            getUserLogsFromUserManagement(
                "",
                pageNumber,
                pagination.pageSize,
                "",
                registryId
            )
                .then((response: any) => {
                    if (response.success) {
                        if (response.data._pagination) {
                            setPagination({
                                ...response.data._pagination,
                                setPageNumber,
                            });
                        }
                        setUserLogs(response.data.logsList);
                        setIsUsersResponseAvailable(true);
                    }
                    setIsTableLoading(false);
                })
                .catch((err) => {
                    logger(err);
                    setIsUsersResponseAvailable(true);
                    setIsTableLoading(false);
                });
        }
    }, [addUserInd, deleteUserInd, pageNumber, toggleState]);

    const addUserDismissModal = () => {
        setAddUserActivateInd(false);
        setAddUserInd(true);
        dismissModal();
    };

    const deleteUserDismissModal = () => {
        setDeleteUserActivateInd(false);
        dismissModal();
    };

    const addUserDetails = async (event: any) => {
        event.preventDefault();
        setValidateDisabled(true);
        const userResult = await addUser(
            hospitalId,
            userEmail,
            setValidateDisabled,
            false,
            false,
            true,
            registryId,
            false,
            corporationId
        );

        if (userResult.success) {
            const toast = {
                message: `User ${userEmail} successfully added.`,
                code: "Success:",
                type: "success",
            };
            dispatch(showToast(toast));
            addUserDismissModal();
            dispatch(setPageLoadingStatus({ isPageLoading: false }));
        }
        setValidateDisabled(false);
    };

    const deleteUserData = async () => {
        setDisabledInd(true);
        setDeleteUserInd(true);
        try {
            const userResult = await deleteUser(
                Number(hospitalId),
                deleteUserId,
                false,
                false,
                true,
                registryId,
                false,
                corporationId
            );
            setDisabledInd(false);
            if (userResult.success) {
                const toast = {
                    message: `User ${userEmail} has been removed.`,
                    code: "Success:",
                    type: "success",
                };
                dispatch(showToast(toast));
                deleteUserDismissModal();
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
            }
        } catch (error: any) {
            setDisabledInd(false);
            logger("Error occurred while deleting user:", error);
            deleteUserDismissModal();
        }
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pageNumber]);

    return (
        <OrganizationManagementWrapper className="container-fluid p-0 d-flex flex-grow-1">
            <div className="row no-gutters w-100 flex-grow-1">
                <Sidebar activeTab={activeTabSidebar} />
                <section className="d-flex admin-main-content container align-items-start">
                    <div className="row no-gutters w-100 admin-main">
                        <BreadCrumb breadcrumbItems={breadcrumbItems} />
                        <div className="col-12">
                            <div className="d-md-flex justify-content-between dashboard-fac-magnt align-items-center">
                                <h1 className="h2 font-400 mb-0">
                                    {corporationId
                                        ? "Corporation Management"
                                        : registryId
                                        ? "Registry Management"
                                        : "Organization Management"}
                                </h1>
                            </div>
                            {corporationId ? (
                                <CorporationBanner
                                    corporationDetails={
                                        location?.state?.corporationDetails
                                    }
                                />
                            ) : registryId ? (
                                <RegistryBanner
                                    registryName={registry.registryName}
                                    registryCode={registry.registryCode}
                                    countryName={registry.countryName}
                                />
                            ) : (
                                <FacilityDetailsBanner
                                    hospital={hospital}
                                    programData={programData}
                                    lastEditedData={lastEditedData}
                                    close={close}
                                    isAdmin
                                />
                            )}
                            <div className="user-management">
                                <UserManagement
                                    users={users}
                                    corporationId={corporationId}
                                    registryId={registryId}
                                    isTableLoading={isTableLoading}
                                    inputUser={inputUser}
                                    setInputUser={setInputUser}
                                    isUsersResponseAvailable={
                                        isUsersResponseAvailable
                                    }
                                    roleValue={roleValue}
                                    pagination={pagination}
                                    addUserDetails={addUserDetails}
                                    setUserEmail={setUserEmail}
                                    userEmail={userEmail}
                                    userName={userName}
                                    setUserName={setUserName}
                                    setDeleteUserId={setDeleteUserId}
                                    deleteUserData={deleteUserData}
                                    disabledInd={disabledInd}
                                    setAddUserActivateInd={
                                        setAddUserActivateInd
                                    }
                                    addUserActivateInd={addUserActivateInd}
                                    setDeleteUserActivateInd={
                                        setDeleteUserActivateInd
                                    }
                                    settoggleState={settoggleState}
                                    userLogs={userLogs}
                                    deleteUserActivateInd={
                                        deleteUserActivateInd
                                    }
                                    addUserDismissModal={addUserDismissModal}
                                    deleteUserDismissModal={
                                        deleteUserDismissModal
                                    }
                                    isAdmin
                                    isInActive={isInActive}
                                    logsPagination={pagination}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </OrganizationManagementWrapper>
    );
};

export default AdminUserManagement;
