import React, { useEffect, useState } from "react";
import CONSTANTS from "common/constants";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showToast } from "components/Toast/toast.slice";
import { getBillingDetails, updateBillingDetails } from "api/paymentAPI";
import { logger } from "utils/logger.utils";
import { redirectionForErrorCode } from "common/utils";
import { FacilityDetailsWrapper } from "./styled";
import "styles/toggle-switch.scss";

interface Props {
    hospital: any;
    setActiveTab: any;
    activeTab: any;
    close: any;
    setBillingInfo: any;
    orderId: any;
    isCorporation?: any;
    isEUP?: any;
    detailsInd?: boolean;
    paymentInfo?: any;
}

export const BillingDetails = ({
    hospital,
    setActiveTab,
    activeTab,
    close,
    setBillingInfo,
    orderId,
    isCorporation,
    isEUP,
    detailsInd,
    paymentInfo,
}: Props) => {
    const [editMode, setEditMode] = useState<boolean>(false);
    const params: any = useParams();
    const [billingDetails, setBillingDetails] = useState<any>("");
    const [disabled, setDisabled] = useState<boolean>(false);
    const [prevBillingState, setPrevBillingState] = useState<any>("");
    const [buttonState, setButtonState] = useState<boolean>(true);
    const dispatch = useDispatch();
    const history = useHistory();
    const selectedCountryName = localStorage.getItem("selectedCountryName");
    useEffect(() => {
        getBillingPageDetails();
    }, []);
    const getBillingPageDetails = () => {
        getBillingDetails(params.hospitalId, params.categoryId).then(
            (response: any) => {
                if (response.success) {
                    setPrevBillingState(response.data.billingDetails);
                    setBillingDetails(response.data.billingDetails);
                    setBillingInfo(response.data.billingDetails);
                } else {
                    dispatch(showToast(response.error));
                }
            }
        );
    };

    useEffect(() => {
        if (detailsInd) {
            getBillingPageDetails();
        }
    }, [detailsInd]);

    const jumpToNextTab = () => {
        setActiveTab(activeTab === null ? 1 : activeTab + 1);
        window.scrollTo(0, CONSTANTS.SCROLLTO_VALUE);
    };

    const jumpToPrevTab = () => {
        if (activeTab > 0) {
            setActiveTab(activeTab - 1);
            window.scrollTo(0, CONSTANTS.SCROLLTO_VALUE);
        }
    };

    const handleChange = (event: any) => {
        setBillingDetails({
            ...billingDetails,
            [event.target.name]:
                event.target.type == "textarea" ||
                event.target.name === "facilityName" ||
                event.target.name === "city" ||
                event.target.name === "state"
                    ? event.target.value.replace(/[><]/g, "")
                    : event.target.value,
        });
        setButtonState(false);
    };
    const handleEditbutton = () => {
        setBillingDetails({ ...prevBillingState });
        setEditMode(!editMode);
        setButtonState(true);
    };

    const handleSave = (event: any) => {
        event.preventDefault();
        setDisabled(true);
        const reqBody = { ...billingDetails };
        delete reqBody.updatedAt;
        delete reqBody.updatedBy;
        updateBillingDetails(params.hospitalId, params.categoryId, reqBody)
            .then((response: any) => {
                if (response.success) {
                    setPrevBillingState(billingDetails);
                    reqBody.updatedAt = null;
                    reqBody.updatedBy = null;

                    if (
                        response &&
                        response.data &&
                        response.data.updatedResult &&
                        response.data.updatedResult.updatedAt
                    ) {
                        reqBody.updatedAt =
                            response.data.updatedResult.updatedAt;
                    }

                    if (
                        response &&
                        response.data &&
                        response.data.updatedResult &&
                        response.data.updatedResult.updatedBy
                    ) {
                        reqBody.updatedBy =
                            response.data.updatedResult.updatedBy;
                    }
                    const toast = {
                        message: "Data successfully updated.",
                        code: "Success:",
                        type: "success",
                    };

                    setEditMode(!editMode);
                    dispatch(showToast(toast));
                    setDisabled(false);
                    setBillingInfo(reqBody);
                } else {
                    setDisabled(false);
                }
            })
            .catch((error: any) => {
                setDisabled(false);
                redirectionForErrorCode(
                    CONSTANTS.ROUTES.CORPORATION_DASHBOARD,
                    error,
                    history,
                    2000
                );
                if (error && error.details && error.details.length) {
                    error.details.filter((detail: any, index: any) => {
                        if (detail.target === "facilityName") {
                            error.details[index].target = "BilledFor";
                            error.details[index].message = error.details[
                                index
                            ].message.replace("facilityName", "BilledFor");
                        }
                    });
                    dispatch(showToast(error));
                }
            });
    };

    return (
        <FacilityDetailsWrapper aria-label="Billing Detail tab Content">
            <div className="fd-title-border d-flex justify-content-between">
                <h3 className="h4 font-500">Billing Details</h3>
                {((orderId && hospital.isActive == true) ||
                    (isCorporation && hospital.isActive == true)) && (
                    <div className="edit-btn-toggle">
                        <input
                            type="checkbox"
                            id="editBillingDetails"
                            className="edit-btn-checkbox"
                            role="button"
                            aria-pressed={editMode}
                            onClick={handleEditbutton}
                        />
                        {editMode ? (
                            <label
                                htmlFor="editBillingDetails"
                                className="toggle-label"
                            >
                                Cancel Edit
                            </label>
                        ) : (
                            <label
                                htmlFor="editBillingDetails"
                                className="toggle-label"
                            >
                                <i
                                    className="aha-icon-edit toggle-edit"
                                    title="Edit Billing Details"
                                    aria-hidden="true"
                                />
                                <span className="sr-only">Edit</span>
                            </label>
                        )}
                        {editMode ? (
                            <div
                                aria-live="polite"
                                className="d-block d-md-none sr-only"
                            >
                                Cancel editing billing details
                            </div>
                        ) : (
                            <div
                                aria-live="polite"
                                className="d-block d-md-none sr-only"
                            >
                                Edit billing details
                            </div>
                        )}
                    </div>
                )}
            </div>
            {!editMode ? (
                <div className="fd-table mb-5 pb-5">
                    <table className="aui-responsive-table aui-table-cols">
                        <tbody>
                            <tr>
                                <th scope="row" className="aui-row-th">
                                    Organization Name
                                </th>
                                <td className="aui-row-td">
                                    {billingDetails.facilityName}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" className="aui-row-th">
                                    Billing Contact Name
                                </th>
                                <td className="aui-row-td">
                                    {billingDetails.billingContactName
                                        ? billingDetails.billingContactName
                                        : "N/A"}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" className="aui-row-th">
                                    Billing Address
                                </th>
                                <td className="aui-row-td">
                                    {billingDetails.billingAddress}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" className="aui-row-th">
                                    City
                                </th>
                                <td className="aui-row-td">
                                    {billingDetails.city}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" className="aui-row-th">
                                    State / Province
                                </th>
                                <td className="aui-row-td">
                                    {billingDetails.state}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" className="aui-row-th">
                                    Location
                                </th>
                                <td className="aui-row-td">
                                    {billingDetails.countryName}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" className="aui-row-th">
                                    Zip Code / Postal Code
                                </th>
                                <td className="aui-row-td">
                                    {billingDetails.zipCode}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" className="aui-row-th">
                                    Phone Number
                                </th>
                                <td className="aui-row-td">
                                    {billingDetails.phoneNumber}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" className="aui-row-th">
                                    Email Address
                                </th>
                                <td className="aui-row-td">
                                    {billingDetails.emailAddress}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {!isEUP && (
                        <div className="fb-table-btn d-flex justify-content-center py-4 flex-wrap">
                            <button
                                type="button"
                                className="btn btn-secondary btn-round fd-btn-1"
                                onClick={close}
                                aria-describedby="admin-billingdetails"
                            >
                                Close
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary btn-round fd-btn-2"
                                onClick={jumpToPrevTab}
                                aria-describedby="admin-agreementdetails"
                            >
                                Previous
                            </button>
                            {isCorporation &&
                                selectedCountryName !==
                                    CONSTANTS.DOMESTIC_COUNTRY_NAME && (
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-round fd-btn-3"
                                        onClick={jumpToNextTab}
                                        aria-describedby="admin-paymentdetails"
                                        disabled={
                                            paymentInfo?.paymentStatus ==
                                                CONSTANTS.COMPLETED ||
                                            (paymentInfo?.paymentStatus ==
                                                CONSTANTS.PENDING &&
                                                paymentInfo?.modeOfPayment ==
                                                    CONSTANTS.PAYMENT_OFFLINE)
                                        }
                                    >
                                        Next
                                    </button>
                                )}
                            {!isCorporation && !isEUP && (
                                <button
                                    type="button"
                                    className="btn btn-primary btn-round fd-btn-3"
                                    onClick={jumpToNextTab}
                                    aria-describedby="admin-paymentdetails"
                                    disabled={
                                        !(
                                            paymentInfo?.paymentStatus ==
                                                CONSTANTS.COMPLETED ||
                                            (paymentInfo?.paymentStatus ==
                                                CONSTANTS.PENDING &&
                                                paymentInfo?.modeOfPayment ==
                                                    CONSTANTS.PAYMENT_OFFLINE)
                                        )
                                    }
                                >
                                    Next
                                </button>
                            )}
                        </div>
                    )}
                </div>
            ) : (
                <div className="fd-form" aria-label="billing detail">
                    <form onSubmit={handleSave}>
                        <fieldset className="row" disabled={disabled}>
                            <div className="col-lg-10">
                                <div className="d-flex flex-row-reverse">
                                    <div className="pb-3 mand-field">
                                        <sup>*</sup>
                                        mandatory fields
                                    </div>
                                </div>
                                <div className="form-group row required">
                                    <div className="col-md-4 form-label">
                                        <label
                                            htmlFor="billingFor"
                                            className="label-form"
                                        >
                                            Organization Name
                                            <sup>*</sup>
                                        </label>
                                    </div>
                                    <div className="col-md-8">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="facilityName"
                                            id="billingFor"
                                            required
                                            onChange={handleChange}
                                            value={billingDetails.facilityName}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row required">
                                    <div className="col-md-4 form-label">
                                        <label
                                            htmlFor="billingContactName"
                                            className="label-form"
                                        >
                                            Billing Contact Name
                                            <sup>*</sup>
                                        </label>
                                    </div>
                                    <div className="col-md-8">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="billingContactName"
                                            id="billingContactName"
                                            pattern={
                                                CONSTANTS.REGEX_ALLOW_ALPHANUMERIC_AND_SPACE_ONLY
                                            }
                                            required
                                            onChange={handleChange}
                                            defaultValue={
                                                billingDetails.billingContactName
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="form-group row required">
                                    <div className="col-md-4 form-label">
                                        <label
                                            htmlFor="billingAddress"
                                            className="label-form"
                                        >
                                            Billing Address
                                            <sup>*</sup>
                                        </label>
                                    </div>
                                    <div className="col-md-8">
                                        <textarea
                                            rows={3}
                                            className="form-control"
                                            name="billingAddress"
                                            id="billingAddress"
                                            required
                                            onChange={handleChange}
                                            defaultValue={
                                                billingDetails.billingAddress
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="form-group row required">
                                    <div className="col-md-4 form-label">
                                        <label
                                            htmlFor="city"
                                            className="label-form"
                                        >
                                            City<sup>*</sup>
                                        </label>
                                    </div>
                                    <div className="col-md-8">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="city"
                                            id="city"
                                            required
                                            onChange={handleChange}
                                            value={billingDetails.city}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row required">
                                    <div className="col-md-4 form-label">
                                        <label
                                            htmlFor="state"
                                            className="label-form"
                                        >
                                            State / Province
                                            <sup>*</sup>
                                        </label>
                                    </div>
                                    <div className="col-md-8">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="state"
                                            id="state"
                                            required
                                            onChange={handleChange}
                                            value={billingDetails.state}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row required">
                                    <div className="col-md-4 form-label">
                                        <label
                                            htmlFor="countryName"
                                            className="label-form"
                                        >
                                            Location
                                            <sup>*</sup>
                                        </label>
                                    </div>
                                    <div className="col-md-8">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="countryName"
                                            id="countryName"
                                            pattern={
                                                CONSTANTS.REGEX_ALLOW_ALPHANUMERIC_AND_SPACE_ONLY
                                            }
                                            required
                                            onChange={handleChange}
                                            defaultValue={
                                                billingDetails.countryName
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="form-group row required">
                                    <div className="col-md-4 form-label">
                                        <label
                                            htmlFor="zipCode"
                                            className="label-form"
                                        >
                                            Zip Code / Postal Code
                                            <sup>*</sup>
                                        </label>
                                    </div>
                                    <div className="col-md-8">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="zipCode"
                                            id="zipCode"
                                            minLength={2}
                                            maxLength={9}
                                            pattern="\d*"
                                            required
                                            onChange={handleChange}
                                            defaultValue={
                                                billingDetails.zipCode
                                            }
                                            aria-describedby="billingZipcode"
                                        />
                                        <div
                                            className="form-help"
                                            id="billingZipcode"
                                        >
                                            (Enter 2 to 9 characters)
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row required">
                                    <div className="col-md-4 form-label">
                                        <label
                                            htmlFor="phoneNumber"
                                            className="label-form"
                                        >
                                            Phone Number
                                            <sup>*</sup>
                                        </label>
                                    </div>
                                    <div className="col-md-8">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="phoneNumber"
                                            id="phoneNumber"
                                            minLength={10}
                                            maxLength={20}
                                            pattern="\+?\d*"
                                            required
                                            onChange={handleChange}
                                            defaultValue={
                                                billingDetails.phoneNumber
                                            }
                                            aria-describedby="billingPhone"
                                        />
                                        <div
                                            className="form-help"
                                            id="billingPhone"
                                        >
                                            (Enter 10 to 20 characters)
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row required">
                                    <div className="col-md-4 form-label">
                                        <label
                                            htmlFor="emailAddress"
                                            className="label-form"
                                        >
                                            Email Address
                                            <sup>*</sup>
                                        </label>
                                    </div>
                                    <div className="col-md-8">
                                        <input
                                            type="email"
                                            className="form-control"
                                            name="emailAddress"
                                            id="emailAddress"
                                            pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$"
                                            required
                                            onChange={handleChange}
                                            defaultValue={
                                                billingDetails.emailAddress
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="fb-table-btn row mx-0 fb-btn-edit">
                                    <div className="col-md-8 offset-md-4 px-0 px-md-3 mb-4 d-flex justify-content-start flex-column flex-sm-row">
                                        <button
                                            type="button"
                                            className="btn btn-round btn-secondary fd-btn-1"
                                            aria-label="Cancel Editing billing Details"
                                            onClick={handleEditbutton}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            disabled={buttonState}
                                            className={`btn btn-round btn-primary fd-btn-2 ${
                                                disabled
                                                    ? CONSTANTS.BUTTON_SPINNER
                                                    : ""
                                            }                    `}
                                            aria-label="Save billing Details"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            )}
        </FacilityDetailsWrapper>
    );
};

export default BillingDetails;
