import { showModal, dismissModal } from "common/utils";
import FocusTrap from "focus-trap-react";
import React, { useEffect, useRef } from "react";

export interface IDynamicModal {
    modalText: any;
    dismissText: string;
    actionText: string;
    actionFunction: any;
    setPayOfflineInd: any;
    payOfflineInd: any;
    setFocusState: any;
}

const DynamicModal = (props: IDynamicModal) => {
    const modalRef = useRef<HTMLDivElement | null>(null);
    const modalInnerRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        if (props.payOfflineInd && modalInnerRef.current) {
            modalInnerRef.current.setAttribute("tabIndex", "-1");
            modalInnerRef.current.focus();
        }
        const handleKeyDown = (event: any) => {
            if (event.key === "Escape") {
                props.setPayOfflineInd(false);
                dismissModal();
                props.setFocusState(true);
            }
        };
        const handleMouseDown = (event: any) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                props.setPayOfflineInd(false);
                dismissModal();
            }
            if (modalRef.current && modalRef.current.contains(event.target)) {
                modalInnerRef.current?.removeAttribute("tabIndex");
            }
        };
        if (props.payOfflineInd) {
            document.addEventListener("keydown", handleKeyDown);
            document.addEventListener("mousedown", handleMouseDown);
        }
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
            document.removeEventListener("mousedown", handleMouseDown);
        };
    }, [props.payOfflineInd]);

    const proceedAndDismissModal = () => {
        props.setPayOfflineInd(false);
        dismissModal();
        props.setFocusState(true);
    };
    return (
        <FocusTrap
            focusTrapOptions={{
                escapeDeactivates: false,
                clickOutsideDeactivates: false,
            }}
        >
            <div
                className="modal fade show aui-modal d-block"
                id="confirmationModal"
                tabIndex={-1}
                aria-labelledby="modalConfirmationLabel"
                aria-modal="true"
                role="dialog"
            >
                <div
                    className="modal-dialog modal-dialog-centered modal-sm"
                    ref={modalRef}
                >
                    <div
                        className="modal-content"
                        ref={modalInnerRef}
                        id="modalInnerRef"
                    >
                        <div className="modal-body p-0">
                            <div className="text-center">
                                <div
                                    id="modalConfirmationLabel"
                                    className="sr-only"
                                >
                                    Offline payment confirmation
                                </div>
                                <div>{props.modalText}</div>
                                <div className="mt-4 d-flex justify-content-center">
                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-round btn-sm mx-2 mt-3 mt-sm-2"
                                        aria-label="Cancel offline payment"
                                        onClick={proceedAndDismissModal}
                                    >
                                        {props.dismissText}
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-round btn-sm mx-2 mt-3 mt-sm-2"
                                        onClick={() => {
                                            props.actionFunction();
                                            proceedAndDismissModal();
                                        }}
                                        aria-label={`${props.actionText} offline payment`}
                                    >
                                        {props.actionText}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </FocusTrap>
    );
};

export default DynamicModal;
