import styled from "styled-components";

export const UploadDocumentModalContentWrapper = styled.div`
    .doc-submit-btn {
        .btn {
            width: 100%;
            @media only screen and (min-width: 768px) {
                width: auto;
            }
        }
    }
    .nofocus-indicator[tabindex="-1"] {
        &:focus {
            outline: none !important;
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
        }
        &:hover {
            cursor: default;
        }
    }
`;
