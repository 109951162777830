import React, { useEffect, useRef, useState } from "react";
import { HospitalManagementDetailsWrapper } from "pages/Admin/RegisteredHospitalDetails/styled";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { BreadCrumb } from "components/BreadCrumb";
import Pagination, { IPagination } from "components/Pagination";
import EMSListingTable from "components/EMSListingTable";
import TableRowCount from "components/TableRowCount";
import SortTableColumnDropdown from "components/SortTableColumnDropdown";
import Tab from "components/Tab";
import CONSTANTS from "common/constants";
import SearchFilter from "components/SearchFilter";
import { getStates } from "api/countriesAPI";
import { emsLogsDetails } from "../../../api/emsAPI";
import { getAwardCategories } from "../../../api/certificateAPI";
import EMSAdminSidebar from "../Sidebar/Sidebar";

export interface IMeasure {
    measureId: number;
    measureName: string;
    description: string;
    numerator: number;
    denominator: number;
}

export const OrganizationLogsList = () => {
    // REDUX variables
    const dispatch = useDispatch();
    const location = useLocation();
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pendingHospitals, setPendingHospitals] = useState<any>([]);
    const [registeredHospitals, setRegisteredHospitals] = useState<any>([]);
    const [totalHospitals, setTotalHospitals] = useState<any>([]);
    const [deactivatedHospitals, setDeactivatedHospitals] = useState<any>([]);
    const [awardedHospitals, setAwardedHospitals] = useState<any>([]);
    const [selectedTab, setSelectedTab] = useState<any>(1);
    const [roleValue, setRoleValue] = useState<string>("none");
    const paginationSetLimit: number = 5;
    const [countDetail, setCountDetail] = useState<any>({});
    const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
    const searchFilter = useSelector((state: any) => {
        return state.searchFilter;
    });
    const [awardCategories, setAwardCategories] = useState<any>([]);
    const [stateCd, setStateCd] = useState<any>(searchFilter.logsStateCode);
    const [awardCategory, setAwardCategory] = useState<any>(searchFilter.award);
    const [clearFilters, setClearFilters] = useState<boolean>(false);
    const [sortInd, setSortInd] = useState<any>(location?.state?.pageSort);
    const [stateDropdown, setStateDropdown] = useState<any>([]);
    const [isAwardLoading, setIsAwardLoading] = useState<boolean>(false);
    const [isStateLoading, setIsStateLoading] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<any>(
        searchFilter.logsSearchKey
    );
    const [stateValue, setStateValue] = useState<any>(searchFilter.stateCode);
    const [awardValue, setAwardValue] = useState<any>(searchFilter.award);
    const [orderValue, setOrderValue] = useState<any>(CONSTANTS.DESC);
    const [orderByValue, setOrderByValue] = useState<any>(
        CONSTANTS.DEFAULT_ORDER_BY
    );
    const [filterInd, setFilterInd] = useState<boolean>(false);
    const defaultPagination: IPagination = {
        pageNumber: 1,
        pageSize: 25,
        isFirst: true,
        isLast: false,
        setPageNumber,
        paginationSetLimit,
    };
    const setDefaultPagination = () => {
        setPageNumber(1);
        setPagination(defaultPagination);
    };
    const previousTab = useRef(selectedTab);

    // RFC STATE
    const [returnPath, setReturnPath] = useState<any>(null);
    const [breadcrumbItems, setBreadcrumbItems] = useState<any>([]);
    const [mounted, setMounted] = useState<boolean>(false);
    const [pagination, setPagination] =
        useState<IPagination>(defaultPagination);
    const [pendingPagination, setPendingPagination] =
        useState<IPagination>(defaultPagination);
    const [registeredPagination, setRegisteredPagination] =
        useState<IPagination>(defaultPagination);
    const [awardedPagination, setAwardedPagination] =
        useState<IPagination>(defaultPagination);
    const [deactivatedPagination, setDeactivatedPagination] =
        useState<IPagination>(defaultPagination);
    const [totalPagination, setTotalPagination] =
        useState<IPagination>(defaultPagination);
    const serviceYear = `Organization List ${location.state.serviceYear}`;

    const handleSelectedTab = (value: any) => {
        setFilterInd(true);
        setSelectedTab(value);
        setPageNumber(1);
    };
    const sortIndValue = (value: any) => {
        setSortInd(value);
    };

    useEffect(() => {
        if (filterInd) {
            const titleObject = filteredTabsOnContent.find(
                (item) => item.id === selectedTab
            );
            switch (titleObject?.id) {
                case 1:
                    setTotalPagination({ ...defaultPagination });
                    break;
                case 2:
                    setPendingPagination({ ...defaultPagination });
                    break;
                case 3:
                    setRegisteredPagination({ ...defaultPagination });
                    break;
                case 4:
                    setAwardedPagination({ ...defaultPagination });
                    break;
                case 5:
                    setDeactivatedPagination({ ...defaultPagination });
                    break;
                default:
                    break;
            }
            setFilterInd(false);
        }
    }, [filterInd]);
    const getStateData = () => {
        setIsStateLoading(true);
        const states = [{ label: "All", value: CONSTANTS.ALL }];
        getStates("US").then((response) => {
            if (response?.data) {
                response.data.map((item: any) => {
                    states.push({
                        label: item.name,
                        value: item.name,
                    });
                });
                setStateDropdown(states);
                setIsStateLoading(false);
            }
        });
    };

    const getAwardDropdownData = () => {
        setIsAwardLoading(true);
        const awards = [{ label: "All", value: CONSTANTS.ALL_VALUE }];
        getAwardCategories(
            CONSTANTS.EMS_PROGRAM_ID,
            CONSTANTS.EMS_PROGRAM_CATEGORY_ID
        ).then((response) => {
            if (response?.data) {
                response.data.map((item: any) => {
                    awards.push({
                        label: item,
                        value: item,
                    });
                });
                const updatedAwards = [
                    ...awards,
                    { label: "No Award", value: "No Award" },
                    { label: "N/A", value: "N/A" },
                    { label: "None", value: "None" },
                ];
                setAwardCategories(updatedAwards);
                setIsAwardLoading(false);
            }
        });
    };

    useEffect(() => {
        const elementsArray: any = [];
        let pathToReturn: any;
        pathToReturn = {
            pathname: location?.state?.backToOrganizationLogs,
            state: {
                emsAdminOrganizationLogsTab:
                    location?.state?.emsAdminOrganizationLogsTab,
                emsAdminOrganizationLogsPage:
                    location?.state?.emsAdminOrganizationLogsPage,
                pageSort: location?.state?.pageSort,
            },
        };
        setReturnPath(pathToReturn);

        elementsArray.push(
            {
                label: "Organization Logs",
                returnPath: pathToReturn,
            },
            {
                label: serviceYear,
                returnPath: "",
            }
        );
        setBreadcrumbItems(elementsArray);
        setMounted(true);
        getAwardDropdownData();
        getStateData();
    }, []);
    const year = location.state.serviceYear;
    const getemslogsdetails = ({
        award,
        state,
        search,
        order,
        orderBy,
        pageInd,
    }: any) => {
        const titleObject = filteredTabsOnContent.find(
            (item) => item.id === selectedTab
        );
        setAwardValue(award !== undefined ? award : searchFilter.award);
        setStateValue(state || stateCd);
        setSearchValue(
            search !== undefined ? search : searchFilter.logsSearchKey
        );
        setOrderValue(order || CONSTANTS.DESC);
        setOrderByValue(orderBy || CONSTANTS.DEFAULT_ORDER_BY);
        setIsTableLoading(true);
        emsLogsDetails(
            year,
            titleObject?.title,
            pageInd ? 1 : pageNumber,
            pagination.pageSize,
            award !== undefined ? award : searchFilter.award,
            state || stateCd,
            search !== undefined ? search : searchFilter.logsSearchKey,
            order || orderValue,
            orderBy || orderByValue
        ).then((response: any) => {
            if (response.success) {
                setCountDetail(response.data.countDataResult[0]);
                switch (titleObject?.id) {
                    case 1:
                        setTotalPagination({
                            ...response.data._pagination,
                            setPageNumber,
                        });
                        setTotalHospitals(
                            response.data.logsData.rows.map(
                                (hospital: any) => ({
                                    ...hospital,
                                    awardedBy:
                                        hospital.awardedBy.split(" ")[0] !==
                                            "null" &&
                                        hospital.awardedBy.split(" ")[1] !==
                                            "null"
                                            ? hospital.awardedBy
                                            : "N/A",
                                    award:
                                        (hospital.organizationStatus ==
                                            CONSTANTS.PENDING &&
                                            hospital.award == "N/A") ||
                                        (hospital.organizationStatus ==
                                            CONSTANTS.DEACTIVATED &&
                                            (hospital.award == "" ||
                                                hospital.award == null ||
                                                hospital.award == "null"))
                                            ? "None"
                                            : hospital.award,
                                })
                            )
                        );
                        break;
                    case 2:
                        setPendingPagination({
                            ...response.data._pagination,
                            setPageNumber,
                        });
                        setPendingHospitals(
                            response.data.logsData.rows.map(
                                (hospital: any) => ({
                                    ...hospital,
                                    awardedBy:
                                        hospital.awardedBy.split(" ")[0] !==
                                            "null" &&
                                        hospital.awardedBy.split(" ")[1] !==
                                            "null"
                                            ? hospital.awardedBy
                                            : "N/A",
                                    award:
                                        (hospital.organizationStatus ==
                                            CONSTANTS.PENDING &&
                                            hospital.award == "N/A") ||
                                        (hospital.organizationStatus ==
                                            CONSTANTS.DEACTIVATED &&
                                            (hospital.award == "" ||
                                                hospital.award == "null"))
                                            ? "None"
                                            : hospital.award,
                                })
                            )
                        );
                        break;
                    case 3:
                        setRegisteredPagination({
                            ...response.data._pagination,
                            setPageNumber,
                        });
                        setRegisteredHospitals(
                            response.data.logsData.rows.map(
                                (hospital: any) => ({
                                    ...hospital,
                                    awardedBy:
                                        hospital.awardedBy.split(" ")[0] !==
                                            "null" &&
                                        hospital.awardedBy.split(" ")[1] !==
                                            "null"
                                            ? hospital.awardedBy
                                            : "N/A",
                                    award:
                                        (hospital.organizationStatus ==
                                            CONSTANTS.PENDING &&
                                            hospital.award == "N/A") ||
                                        (hospital.organizationStatus ==
                                            CONSTANTS.DEACTIVATED &&
                                            (hospital.award == "" ||
                                                hospital.award == "null"))
                                            ? "None"
                                            : hospital.award,
                                })
                            )
                        );
                        break;
                    case 4:
                        setAwardedPagination({
                            ...response.data._pagination,
                            setPageNumber,
                        });
                        setAwardedHospitals(
                            response.data.logsData.rows.map(
                                (hospital: any) => ({
                                    ...hospital,
                                    awardedBy:
                                        hospital.awardedBy.split(" ")[0] !==
                                            "null" &&
                                        hospital.awardedBy.split(" ")[1] !==
                                            "null"
                                            ? hospital.awardedBy
                                            : "N/A",
                                    award:
                                        (hospital.organizationStatus ==
                                            CONSTANTS.PENDING &&
                                            hospital.award == "N/A") ||
                                        (hospital.organizationStatus ==
                                            CONSTANTS.DEACTIVATED &&
                                            (hospital.award == "" ||
                                                hospital.award == "null"))
                                            ? "None"
                                            : hospital.award,
                                })
                            )
                        );
                        break;
                    case 5:
                        setDeactivatedPagination({
                            ...response.data._pagination,
                            setPageNumber,
                        });
                        setDeactivatedHospitals(
                            response.data.logsData.rows.map(
                                (hospital: any) => ({
                                    ...hospital,
                                    awardedBy:
                                        hospital.awardedBy.split(" ")[0] !==
                                            "null" &&
                                        hospital.awardedBy.split(" ")[1] !==
                                            "null"
                                            ? hospital.awardedBy
                                            : "N/A",
                                    award:
                                        (hospital.organizationStatus ==
                                            CONSTANTS.PENDING &&
                                            hospital.award == "N/A") ||
                                        (hospital.organizationStatus ==
                                            CONSTANTS.DEACTIVATED &&
                                            (hospital.award == "" ||
                                                hospital.award == null ||
                                                hospital.award == "null"))
                                            ? "None"
                                            : hospital.award,
                                })
                            )
                        );
                        break;
                    default:
                        break;
                }
                setRoleValue("alert");
                setIsTableLoading(false);
                setClearFilters(false);
            }
        });
    };
    const filteredTabsOnContent = [
        {
            id: 1,
            title: "Total",
            count: countDetail.totalNoOfOrganization,
            content: (
                <div
                    className="pt-3 justify-content-start program-list flex-column logs-filter"
                    id="organizationStatusTotal"
                >
                    <SearchFilter
                        placeholder="By Organization Name / AHA EMS ID / Code"
                        programCategories={awardCategories}
                        programCategory={awardCategory}
                        setDefaultPagination={setDefaultPagination}
                        stateCd={stateCd}
                        setState={setStateCd}
                        setAwardCategory={setAwardCategory}
                        setClearFilters={setClearFilters}
                        setSortInd={setSortInd}
                        stateDropdown={stateDropdown}
                        isAwardLoading={isAwardLoading}
                        isStateLoading={isStateLoading}
                        getFacilitiesResult={getemslogsdetails}
                        clearFiltersInd={clearFilters}
                        returnPath={returnPath}
                        setFilterInd={setFilterInd}
                        emsAward
                    />
                    <ul className="tabContent">
                        <div className="mb-3 d-md-flex justify-content-between align-items-end">
                            <TableRowCount
                                isFirst={totalPagination.isFirst}
                                pageNumber={totalPagination.pageNumber}
                                pageSize={totalPagination.pageSize}
                                isLast={totalPagination.isLast}
                                totalCount={totalPagination.totalCount}
                                roleValue={roleValue}
                            />
                            <div className="mt-3 mt-md-0">
                                <SortTableColumnDropdown
                                    getHospitalsForAdminManage={emsLogsDetails}
                                    pageNumber={pageNumber}
                                    pageSize={totalPagination.pageSize}
                                    categoryId={
                                        CONSTANTS.EMS_PROGRAM_CATEGORY_ID
                                    }
                                    searchKey={searchFilter.logsSearchKey}
                                    sortIndValue={sortIndValue}
                                    sortByLabel=""
                                    id="totalSort"
                                    stateCd={stateCd}
                                    emsAward
                                    year={year}
                                    award={awardValue}
                                    state={stateValue}
                                    search={searchValue}
                                    setCountDetail={setCountDetail}
                                    setTotalHospitals={setTotalHospitals}
                                    setPagination={setTotalPagination}
                                    setRoleValue={setRoleValue}
                                    setIsTableLoading={setIsTableLoading}
                                    setPageNumber={setPageNumber}
                                    selectedTab={selectedTab}
                                    setOrderValue={setOrderValue}
                                    setOrderByValue={setOrderByValue}
                                    setFilterInd={setFilterInd}
                                />
                            </div>
                        </div>
                        {isTableLoading ? (
                            <div
                                role="alert"
                                aria-label="Total number table is loading"
                                className="sr-only"
                            />
                        ) : (
                            ""
                        )}
                        <EMSListingTable
                            hospitals={totalHospitals}
                            isTableLoading={isTableLoading}
                            pageNumber={pageNumber}
                            actionURL={`/emsadmin/organization-logs/logs-list/${year}`}
                            sortInd=""
                            close={close}
                            awardValue={awardValue}
                            stateValue={stateValue}
                            searchValue={searchValue}
                        />
                        <div>
                            {totalPagination &&
                            Number(totalPagination.totalPages) > 0 ? (
                                <Pagination
                                    pageNumber={totalPagination.pageNumber}
                                    pageSize={totalPagination.pageSize}
                                    totalCount={totalPagination.totalCount}
                                    totalPages={totalPagination.totalPages}
                                    isFirst={totalPagination.isFirst}
                                    isLast={totalPagination.isLast}
                                    setPageNumber={
                                        totalPagination.setPageNumber
                                    }
                                    paginationSetLimit={paginationSetLimit}
                                />
                            ) : null}
                        </div>
                    </ul>
                </div>
            ),
        },
        {
            id: 2,
            title: "Pending",
            count: countDetail.pendingFacilityCount,
            content: (
                <div
                    className="pt-3 justify-content-start program-list flex-column logs-filter"
                    id="organizationStatusPending"
                >
                    <SearchFilter
                        placeholder="By Organization Name / AHA EMS ID / Code"
                        programCategories={awardCategories}
                        programCategory={awardCategory}
                        setDefaultPagination={setDefaultPagination}
                        stateCd={stateCd}
                        setState={setStateCd}
                        setAwardCategory={setAwardCategory}
                        setClearFilters={setClearFilters}
                        setSortInd={setSortInd}
                        stateDropdown={stateDropdown}
                        isAwardLoading={isAwardLoading}
                        isStateLoading={isStateLoading}
                        getFacilitiesResult={getemslogsdetails}
                        clearFiltersInd={clearFilters}
                        returnPath={returnPath}
                        emsAward
                        setFilterInd={setFilterInd}
                    />
                    <ul className="tabContent">
                        <div className="mb-3 d-md-flex justify-content-between align-items-end">
                            <TableRowCount
                                isFirst={pendingPagination.isFirst}
                                pageNumber={pendingPagination.pageNumber}
                                pageSize={pendingPagination.pageSize}
                                isLast={pendingPagination.isLast}
                                totalCount={pendingPagination.totalCount}
                                roleValue={roleValue}
                            />
                            <div className="mt-3 mt-md-0">
                                <SortTableColumnDropdown
                                    getHospitalsForAdminManage={emsLogsDetails}
                                    pageNumber={pageNumber}
                                    pageSize={pendingPagination.pageSize}
                                    categoryId={
                                        CONSTANTS.EMS_PROGRAM_CATEGORY_ID
                                    }
                                    searchKey={searchFilter.logsSearchKey}
                                    sortIndValue={sortIndValue}
                                    sortByLabel=""
                                    id="pendingSort"
                                    stateCd={stateCd}
                                    emsAward
                                    year={year}
                                    award={awardValue}
                                    state={stateValue}
                                    search={searchValue}
                                    setCountDetail={setCountDetail}
                                    setPendingHospitals={setPendingHospitals}
                                    setPagination={setPendingPagination}
                                    setRoleValue={setRoleValue}
                                    setIsTableLoading={setIsTableLoading}
                                    setPageNumber={setPageNumber}
                                    selectedTab={selectedTab}
                                    setOrderValue={setOrderValue}
                                    setOrderByValue={setOrderByValue}
                                    setFilterInd={setFilterInd}
                                />
                            </div>
                        </div>
                        {isTableLoading ? (
                            <div
                                role="alert"
                                aria-label="pending approval table is loading"
                                className="sr-only"
                            />
                        ) : (
                            ""
                        )}
                        <EMSListingTable
                            hospitals={pendingHospitals}
                            isTableLoading={isTableLoading}
                            pageNumber={pageNumber}
                            actionURL={`/emsadmin/organization-logs/logs-list/${year}`}
                            sortInd=""
                            close={close}
                            awardValue={awardValue}
                            stateValue={stateValue}
                            searchValue={searchValue}
                        />
                        <div>
                            {pendingPagination &&
                            Number(pendingPagination.totalPages) > 0 ? (
                                <Pagination
                                    pageNumber={pendingPagination.pageNumber}
                                    pageSize={pendingPagination.pageSize}
                                    totalCount={pendingPagination.totalCount}
                                    totalPages={pendingPagination.totalPages}
                                    isFirst={pendingPagination.isFirst}
                                    isLast={pendingPagination.isLast}
                                    setPageNumber={
                                        pendingPagination.setPageNumber
                                    }
                                    paginationSetLimit={paginationSetLimit}
                                />
                            ) : null}
                        </div>
                    </ul>
                </div>
            ),
        },
        {
            id: 3,
            title: "Registered",
            count: countDetail.registeredFacilityCount,
            content: (
                <div
                    className="pt-3 justify-content-start program-list flex-column logs-filter"
                    id="organizationStatus"
                >
                    <SearchFilter
                        placeholder="By Organization Name / AHA EMS ID / Code"
                        programCategories={awardCategories}
                        programCategory={awardCategory}
                        setDefaultPagination={setDefaultPagination}
                        stateCd={stateCd}
                        setState={setStateCd}
                        setAwardCategory={setAwardCategory}
                        setClearFilters={setClearFilters}
                        setSortInd={setSortInd}
                        stateDropdown={stateDropdown}
                        isAwardLoading={isAwardLoading}
                        isStateLoading={isStateLoading}
                        getFacilitiesResult={getemslogsdetails}
                        clearFiltersInd={clearFilters}
                        returnPath={returnPath}
                        emsAward
                        setFilterInd={setFilterInd}
                    />
                    <ul className="tabContent">
                        <div className="mb-3 d-md-flex justify-content-between align-items-end">
                            <TableRowCount
                                isFirst={registeredPagination.isFirst}
                                pageNumber={registeredPagination.pageNumber}
                                pageSize={registeredPagination.pageSize}
                                isLast={registeredPagination.isLast}
                                totalCount={registeredPagination.totalCount}
                                roleValue={roleValue}
                            />
                            <div className="mt-3 mt-md-0">
                                <SortTableColumnDropdown
                                    getHospitalsForAdminManage={emsLogsDetails}
                                    pageNumber={pageNumber}
                                    pageSize={registeredPagination.pageSize}
                                    categoryId={
                                        CONSTANTS.EMS_PROGRAM_CATEGORY_ID
                                    }
                                    searchKey={searchFilter.logsSearchKey}
                                    sortIndValue={sortIndValue}
                                    sortByLabel=""
                                    id="registeredSort"
                                    stateCd={stateCd}
                                    emsAward
                                    year={year}
                                    award={awardValue}
                                    state={stateValue}
                                    search={searchValue}
                                    setCountDetail={setCountDetail}
                                    setRegisteredHospitals={
                                        setRegisteredHospitals
                                    }
                                    setPagination={setRegisteredPagination}
                                    setRoleValue={setRoleValue}
                                    setIsTableLoading={setIsTableLoading}
                                    setPageNumber={setPageNumber}
                                    selectedTab={selectedTab}
                                    setOrderValue={setOrderValue}
                                    setOrderByValue={setOrderByValue}
                                    setFilterInd={setFilterInd}
                                />
                            </div>
                        </div>
                        {isTableLoading ? (
                            <div
                                role="alert"
                                aria-label="registered organization table is loading"
                                className="sr-only"
                            />
                        ) : (
                            ""
                        )}
                        <EMSListingTable
                            hospitals={registeredHospitals}
                            isTableLoading={isTableLoading}
                            pageNumber={pageNumber}
                            actionURL={`/emsadmin/organization-logs/logs-list/${year}`}
                            sortInd=""
                            close={close}
                            awardValue={awardValue}
                            stateValue={stateValue}
                            searchValue={searchValue}
                        />
                        <div>
                            {registeredPagination &&
                            Number(registeredPagination.totalPages) > 0 ? (
                                <Pagination
                                    pageNumber={registeredPagination.pageNumber}
                                    pageSize={registeredPagination.pageSize}
                                    totalCount={registeredPagination.totalCount}
                                    totalPages={registeredPagination.totalPages}
                                    isFirst={registeredPagination.isFirst}
                                    isLast={registeredPagination.isLast}
                                    setPageNumber={
                                        registeredPagination.setPageNumber
                                    }
                                    paginationSetLimit={paginationSetLimit}
                                />
                            ) : null}
                        </div>
                    </ul>
                </div>
            ),
        },
        {
            id: 4,
            title: "Awarded",
            count: countDetail.awardedFacilityCount,
            content: (
                <div
                    className="pt-3 justify-content-start program-list flex-column logs-filter"
                    id="organizationStatus"
                >
                    <SearchFilter
                        placeholder="By Organization Name / AHA EMS ID / Code"
                        programCategories={awardCategories}
                        programCategory={awardCategory}
                        setDefaultPagination={setDefaultPagination}
                        stateCd={stateCd}
                        setState={setStateCd}
                        setAwardCategory={setAwardCategory}
                        setClearFilters={setClearFilters}
                        setSortInd={setSortInd}
                        stateDropdown={stateDropdown}
                        isAwardLoading={isAwardLoading}
                        isStateLoading={isStateLoading}
                        getFacilitiesResult={getemslogsdetails}
                        clearFiltersInd={clearFilters}
                        returnPath={returnPath}
                        emsAward
                        setFilterInd={setFilterInd}
                    />
                    <ul className="tabContent">
                        <div className="mb-3 d-md-flex justify-content-between align-items-end">
                            <TableRowCount
                                isFirst={awardedPagination.isFirst}
                                pageNumber={awardedPagination.pageNumber}
                                pageSize={awardedPagination.pageSize}
                                isLast={awardedPagination.isLast}
                                totalCount={awardedPagination.totalCount}
                                roleValue={roleValue}
                            />
                            <div className="mt-3 mt-md-0">
                                <SortTableColumnDropdown
                                    getHospitalsForAdminManage={emsLogsDetails}
                                    pageNumber={pageNumber}
                                    pageSize={awardedPagination.pageSize}
                                    categoryId={
                                        CONSTANTS.EMS_PROGRAM_CATEGORY_ID
                                    }
                                    searchKey={searchFilter.logsSearchKey}
                                    sortIndValue={sortIndValue}
                                    sortByLabel=""
                                    id="awardedSort"
                                    stateCd={stateCd}
                                    emsAward
                                    year={year}
                                    award={awardValue}
                                    state={stateValue}
                                    search={searchValue}
                                    setCountDetail={setCountDetail}
                                    setAwardedHospitals={setAwardedHospitals}
                                    setPagination={setAwardedPagination}
                                    setRoleValue={setRoleValue}
                                    setIsTableLoading={setIsTableLoading}
                                    setPageNumber={setPageNumber}
                                    selectedTab={selectedTab}
                                    setOrderValue={setOrderValue}
                                    setOrderByValue={setOrderByValue}
                                    setFilterInd={setFilterInd}
                                />
                            </div>
                        </div>
                        {isTableLoading ? (
                            <div
                                role="alert"
                                aria-label="awarded organization table is loading"
                                className="sr-only"
                            />
                        ) : (
                            ""
                        )}
                        <EMSListingTable
                            hospitals={awardedHospitals}
                            isTableLoading={isTableLoading}
                            pageNumber={pageNumber}
                            actionURL={`/emsadmin/organization-logs/logs-list/${year}`}
                            sortInd=""
                            close={close}
                            awardValue={awardValue}
                            stateValue={stateValue}
                            searchValue={searchValue}
                        />
                        <div>
                            {awardedPagination &&
                            Number(awardedPagination.totalPages) > 0 ? (
                                <Pagination
                                    pageNumber={awardedPagination.pageNumber}
                                    pageSize={awardedPagination.pageSize}
                                    totalCount={awardedPagination.totalCount}
                                    totalPages={awardedPagination.totalPages}
                                    isFirst={awardedPagination.isFirst}
                                    isLast={awardedPagination.isLast}
                                    setPageNumber={
                                        awardedPagination.setPageNumber
                                    }
                                    paginationSetLimit={paginationSetLimit}
                                />
                            ) : null}
                        </div>
                    </ul>
                </div>
            ),
        },
        {
            id: 5,
            title: "Deactivated",
            count: countDetail.deactivatedFacilityCount,
            content: (
                <div
                    className="pt-3 justify-content-start program-list flex-column logs-filter"
                    id="organizationStatus"
                >
                    <SearchFilter
                        placeholder="By Organization Name / AHA EMS ID / Code"
                        programCategories={awardCategories}
                        programCategory={awardCategory}
                        setDefaultPagination={setDefaultPagination}
                        stateCd={stateCd}
                        setState={setStateCd}
                        setAwardCategory={setAwardCategory}
                        setClearFilters={setClearFilters}
                        setSortInd={setSortInd}
                        stateDropdown={stateDropdown}
                        isAwardLoading={isAwardLoading}
                        isStateLoading={isStateLoading}
                        getFacilitiesResult={getemslogsdetails}
                        clearFiltersInd={clearFilters}
                        returnPath={returnPath}
                        setFilterInd={setFilterInd}
                        emsAward
                    />
                    <ul className="tabContent">
                        <div className="mb-3 d-md-flex justify-content-between align-items-end">
                            <TableRowCount
                                isFirst={deactivatedPagination.isFirst}
                                pageNumber={deactivatedPagination.pageNumber}
                                pageSize={deactivatedPagination.pageSize}
                                isLast={deactivatedPagination.isLast}
                                totalCount={deactivatedPagination.totalCount}
                                roleValue={roleValue}
                            />
                            <div className="mt-3 mt-md-0">
                                <SortTableColumnDropdown
                                    getHospitalsForAdminManage={emsLogsDetails}
                                    pageNumber={pageNumber}
                                    pageSize={deactivatedPagination.pageSize}
                                    categoryId={
                                        CONSTANTS.EMS_PROGRAM_CATEGORY_ID
                                    }
                                    searchKey={searchFilter.logsSearchKey}
                                    sortIndValue={sortIndValue}
                                    sortByLabel=""
                                    id="deactivatedSort"
                                    stateCd={stateCd}
                                    emsAward
                                    year={year}
                                    award={awardValue}
                                    state={stateValue}
                                    search={searchValue}
                                    setCountDetail={setCountDetail}
                                    setDeactivatedHospitals={
                                        setDeactivatedHospitals
                                    }
                                    setPagination={setDeactivatedPagination}
                                    setRoleValue={setRoleValue}
                                    setIsTableLoading={setIsTableLoading}
                                    setPageNumber={setPageNumber}
                                    selectedTab={selectedTab}
                                    setOrderValue={setOrderValue}
                                    setOrderByValue={setOrderByValue}
                                    setFilterInd={setFilterInd}
                                />
                            </div>
                        </div>
                        {isTableLoading ? (
                            <div
                                role="alert"
                                aria-label="deactivated organization table is loading"
                                className="sr-only"
                            />
                        ) : (
                            ""
                        )}
                        <EMSListingTable
                            hospitals={deactivatedHospitals}
                            isTableLoading={isTableLoading}
                            pageNumber={pageNumber}
                            actionURL={`/emsadmin/organization-logs/logs-list/${year}`}
                            sortInd=""
                            close={close}
                            awardValue={awardValue}
                            stateValue={stateValue}
                            searchValue={searchValue}
                        />
                        <div>
                            {deactivatedPagination &&
                            Number(deactivatedPagination.totalPages) > 0 ? (
                                <Pagination
                                    pageNumber={
                                        deactivatedPagination.pageNumber
                                    }
                                    pageSize={deactivatedPagination.pageSize}
                                    totalCount={
                                        deactivatedPagination.totalCount
                                    }
                                    totalPages={
                                        deactivatedPagination.totalPages
                                    }
                                    isFirst={deactivatedPagination.isFirst}
                                    isLast={deactivatedPagination.isLast}
                                    setPageNumber={
                                        deactivatedPagination.setPageNumber
                                    }
                                    paginationSetLimit={paginationSetLimit}
                                />
                            ) : null}
                        </div>
                    </ul>
                </div>
            ),
        },
    ].filter(Boolean);

    useEffect(() => {
        if (previousTab.current !== selectedTab) {
            setPagination(defaultPagination);
        }
        getemslogsdetails({
            award: awardValue,
            state: stateValue,
            order: orderValue,
        });
        if (mounted) {
            setRoleValue("alert");
            window.scrollTo(0, 0);
        }
        previousTab.current = selectedTab;
    }, [pageNumber, selectedTab]);

    return (
        <HospitalManagementDetailsWrapper className="container-fluid p-0 d-flex flex-grow-1">
            <div className="row no-gutters w-100 flex-grow-1">
                <EMSAdminSidebar activeTab="organizationLogs" />
                <section className="d-flex admin-main-content container select-text">
                    <div className="row no-gutters w-100 admin-main">
                        <div className="col-12">
                            <BreadCrumb breadcrumbItems={breadcrumbItems} />
                            <div
                                className="hospital-mgnt-header d-sm-flex flex-column justify-content-between align-items-start mb-4"
                                role="group"
                                aria-labelledby="organizationListTitle"
                            >
                                <h1
                                    className="h2 mb-4 font-400"
                                    id="organizationListTitle"
                                >
                                    {`Organization List 
                                    ${location.state?.serviceYear}`}
                                </h1>
                                <h2
                                    className="h6 mb-1 font-400"
                                    id="organizationCountTitle"
                                >
                                    Organization Count
                                </h2>
                                <div className="tab w-100">
                                    <Tab
                                        handleSelectedTab={handleSelectedTab}
                                        tabTitle={filteredTabsOnContent}
                                        emsLogsDeatils
                                        tabHeading="organizationCountTitle"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </HospitalManagementDetailsWrapper>
    );
};

export default OrganizationLogsList;
