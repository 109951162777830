import React, { useState, useRef, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import CONSTANTS from "common/constants";
import {
    dismissModal,
    localDateToYYYYMMDDFormatString,
    redirectionForErrorCode,
} from "common/utils";
import FocusTrap from "focus-trap-react";
import UploadDocumentContent, {
    IUploadDocForm,
} from "./UploadDocumentModalContent";
import DocumentDetails from "./DocumentDetails";
import store from "../../app/store";
import { setPageLoadingStatus } from "../Loader/loader.slice";
import DocumentServiceAPI from "../../api/documentsAPI";
import { showToast } from "../Toast/toast.slice";

const documentServiceAPI = new DocumentServiceAPI();

interface Props {
    documentDetails: any;
    setDocumentDetails: any;
    selectedProgram: string;
    uploadDocForm: IUploadDocForm;
    setUploadDocForm: any;
    initUploadDocForm: IUploadDocForm;
    uploadProgram: any;
    standards: any;
    setRefreshTableDateNow: any;
    viewDocument: any;
    document: any;
    documentViewActivateInd: any;
    setDocumentViewActivateInd: any;
}

const DocumentView = ({
    documentDetails,
    setDocumentDetails,
    selectedProgram,
    uploadDocForm,
    setUploadDocForm,
    initUploadDocForm,
    uploadProgram,
    standards,
    setRefreshTableDateNow,
    viewDocument,
    document,
    documentViewActivateInd,
    setDocumentViewActivateInd,
}: Props) => {
    const params: any = useParams();
    const hospitalId: any = params.hospitalId
        ? params.hospitalId
        : localStorage.getItem("selectedHospitalId");

    const [isResubmit, setIsResubmit] = useState<boolean>(false);
    const [deleteInd, setDeleteInd] = useState<boolean>(false);
    const [deleteDoc, setDeleteDoc] = useState<any>();
    const [dataDismissInd, setDataDismissInd] = useState<boolean>(true);

    const closeDocumentModal = useRef<HTMLButtonElement>(null);
    const noDeleteConfirmation = useRef<HTMLButtonElement>(null);
    const history = useHistory();
    const modalRef = useRef<HTMLDivElement | null>(null);
    const viewDocmodalInnerRef = useRef<HTMLDivElement | null>(null);
    const deleteConfirmation = useRef<HTMLDivElement | null>(null);
    const resubmitModalRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (documentViewActivateInd && viewDocmodalInnerRef.current) {
            viewDocmodalInnerRef.current.setAttribute("tabIndex", "-1");
            viewDocmodalInnerRef.current.focus();
        }
        if (documentViewActivateInd && deleteConfirmation.current) {
            deleteConfirmation.current.setAttribute("tabIndex", "-1");
            deleteConfirmation.current.focus();
        }
        if (documentViewActivateInd && resubmitModalRef.current) {
            resubmitModalRef.current.setAttribute("tabIndex", "-1");
            resubmitModalRef.current.focus();
        }
        const handleMouseDown = (event: any) => {
            if (modalRef.current && modalRef.current.contains(event.target)) {
                viewDocmodalInnerRef.current?.removeAttribute("tabIndex");
                deleteConfirmation.current?.removeAttribute("tabIndex");
                resubmitModalRef.current?.removeAttribute("tabIndex");
            }
        };
        if (documentViewActivateInd) {
            window.document.addEventListener("mousedown", handleMouseDown);
        }
        return () => {
            window.document.removeEventListener("mousedown", handleMouseDown);
        };
    }, [documentViewActivateInd, isResubmit, deleteInd]);

    const resubmitDocument = (documentDetail: any) => {
        setIsResubmit(true);
        setUploadDocForm({
            ...uploadDocForm,
            title: documentDetail.documentTitle,
            uploadStandard: `${documentDetail.standardId}###${documentDetail.standardName}`,
            uploadDescription: documentDetail.documentDescription,
            referenceId: documentDetail.referenceId,
        });
    };

    const deleteDocument = () => {
        store.dispatch(setPageLoadingStatus({ isPageLoading: true }));
        documentServiceAPI
            .delete(deleteDoc.id, hospitalId, true)
            .then((deleteResponse: any) => {
                store.dispatch(setPageLoadingStatus({ isPageLoading: false }));
                if (deleteResponse.success) {
                    const toast = {
                        message: `${deleteDoc.title} has been deleted.`,
                        code: "Success:",
                        type: "success",
                    };
                    setDeleteInd(false);
                    store.dispatch(showToast(toast));
                    if (dataDismissInd) {
                        setRefreshTableDateNow(Date.now());
                    } else {
                        viewDocument(document.referenceId, document.programId);
                    }
                    setDataDismissInd(true);
                    setDocumentViewActivateInd(false);
                    dismissModal();
                }
            })
            .catch((err: any) => {
                redirectionForErrorCode(
                    CONSTANTS.ROUTES.CORPORATION_DASHBOARD,
                    err,
                    history,
                    2000
                );
                store.dispatch(setPageLoadingStatus({ isPageLoading: false }));
                setDeleteInd(false);
                if (dataDismissInd) {
                    setRefreshTableDateNow(Date.now());
                } else {
                    viewDocument(document.referenceId, document.programId);
                }
                setDataDismissInd(true);
            });
    };

    const dismissUploadedDocument = () => {
        setDocumentViewActivateInd(false);
        dismissModal();
    };

    return (
        <>
            {!isResubmit && !deleteInd && (
                <FocusTrap
                    focusTrapOptions={{
                        escapeDeactivates: false,
                        clickOutsideDeactivates: false,
                        returnFocusOnDeactivate: true,
                    }}
                >
                    <div
                        className="modal fade show aui-modal uploadeddata-modal d-block"
                        id="viewUploadedData"
                        tabIndex={-1}
                        aria-labelledby="viewUploadedLabel"
                        aria-modal="true"
                        role="dialog"
                        data-backdrop="static"
                        data-keyboard="false"
                    >
                        <div
                            className="modal-dialog modal-dialog-centered modal-lg"
                            ref={modalRef}
                        >
                            <div
                                className="modal-content"
                                id="viewDocmodalInnerRef"
                                ref={viewDocmodalInnerRef}
                            >
                                <div className="modal-header">
                                    <h2 className="h4" id="viewUploadedLabel">
                                        Document Uploaded
                                    </h2>
                                    <button
                                        id="closeDocumentModal"
                                        ref={closeDocumentModal}
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close Document uploaded modal"
                                        onClick={() => {
                                            dismissUploadedDocument();
                                            setDocumentDetails([]);
                                            setRefreshTableDateNow(Date.now());
                                        }}
                                    >
                                        <span
                                            aria-hidden="true"
                                            className="aha-icon-cross"
                                        />
                                    </button>
                                </div>
                                <div className="modal-body p-0">
                                    {documentDetails !== undefined &&
                                        documentDetails.length > 0 && (
                                            <DocumentDetails
                                                isEUP
                                                documentDetails={
                                                    documentDetails
                                                }
                                                selectedProgram={
                                                    selectedProgram
                                                }
                                                resubmitDocument={
                                                    resubmitDocument
                                                }
                                                setDeleteInd={setDeleteInd}
                                                setDeleteDoc={setDeleteDoc}
                                                setDataDismissInd={
                                                    setDataDismissInd
                                                }
                                            />
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </FocusTrap>
            )}
            {isResubmit && (
                <FocusTrap
                    focusTrapOptions={{
                        escapeDeactivates: false,
                        clickOutsideDeactivates: false,
                        returnFocusOnDeactivate: true,
                    }}
                >
                    <div
                        className="modal fade show aui-modal uploadeddata-modal d-block"
                        id="viewUploadedData"
                        tabIndex={-1}
                        aria-labelledby="viewUploadedLabel"
                        aria-modal="true"
                        role="dialog"
                        data-backdrop="static"
                        data-keyboard="false"
                    >
                        <div
                            className="modal-dialog modal-dialog-centered modal-lg"
                            ref={modalRef}
                        >
                            <div id="resubmitModalRef" ref={resubmitModalRef}>
                                <UploadDocumentContent
                                    uploadProgram={uploadProgram}
                                    standards={standards}
                                    setRefreshTableDateNow={
                                        setRefreshTableDateNow
                                    }
                                    initUploadDocForm={initUploadDocForm}
                                    uploadDocForm={uploadDocForm}
                                    setUploadDocForm={setUploadDocForm}
                                    setIsResubmit={setIsResubmit}
                                    isResubmit={isResubmit}
                                    closeDocumentModal={closeDocumentModal}
                                    isEUP
                                />
                            </div>
                        </div>
                    </div>
                </FocusTrap>
            )}
            {deleteInd && (
                <FocusTrap
                    focusTrapOptions={{
                        escapeDeactivates: false,
                        clickOutsideDeactivates: false,
                        returnFocusOnDeactivate: true,
                    }}
                >
                    <div
                        className={`modal fade show aui-modal d-block ${
                            !deleteInd ? "uploadeddata-modal" : ""
                        }`}
                        id="viewUploadedData"
                        tabIndex={-1}
                        aria-labelledby="viewUploadedLabel"
                        aria-modal="true"
                        role="alertdialog"
                        data-backdrop="static"
                        data-keyboard="false"
                    >
                        <div
                            className="modal-dialog modal-dialog-centered modal-sm"
                            ref={modalRef}
                        >
                            <div
                                className="modal-content"
                                id="deleteConfirmation"
                                ref={deleteConfirmation}
                            >
                                <div className="modal-body p-0">
                                    <div className="text-center">
                                        <p id="modalDeleteLabel">
                                            Are you sure you want to delete
                                            {` ${deleteDoc.title}`} submitted on{" "}
                                            {localDateToYYYYMMDDFormatString(
                                                deleteDoc.createdAt
                                            )}
                                            ?
                                        </p>
                                        <div className="mt-4 d-flex justify-content-center">
                                            <button
                                                className="btn btn-secondary btn-round btn-sm mx-2"
                                                onClick={() => {
                                                    setDeleteInd(false);
                                                    setDataDismissInd(true);
                                                }}
                                                id="noDeleteConfirmation"
                                                ref={noDeleteConfirmation}
                                            >
                                                No
                                            </button>
                                            <button
                                                className="btn btn-primary btn-round btn-sm mx-2"
                                                onClick={deleteDocument}
                                            >
                                                Yes
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </FocusTrap>
            )}
        </>
    );
};

export default DocumentView;
