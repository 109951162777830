import APIUtils from "utils/API.utils";
import store from "app/store";
import { showToast } from "components/Toast/toast.slice";
import config from "../config";
import CONSTANTS from "../common/constants";

const API = new APIUtils();

const getEMSIds = async (
    pageSize: number,
    pageNumber: number,
    userCreated: boolean
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${
                    config[config.env].apiEndpoints.accounts
                }/ems/EmsIdListing?pageSize=${pageSize}&pageNumber=${pageNumber}&userCreated=${userCreated}`
            );
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getLogList = async (
    pageNumber: number,
    pageSize: number,
    order: string
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${
                    config[config.env].apiEndpoints.accounts
                }/bulkRenewals/logs?orderBy=serviceYear,${order}&pageSize=${pageSize}&pageNumber=${pageNumber}`
            );
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getAllAhaEmsIds = async (
    dispatch: any,
    setPageLoadingStatus: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const result = [];
            let isLast = false;
            let pageNumber = 1;
            while (!isLast) {
                const emsIds = await getEMSIds(150, pageNumber, false);
                if (dispatch) {
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                }
                isLast = emsIds.data._pagination.isLast;
                result.push(...emsIds.data.emsData);
                pageNumber++;
            }
            isLast = false;
            pageNumber = 1;
            while (!isLast) {
                const emsIds = await getEMSIds(150, pageNumber, true);
                isLast = emsIds.data._pagination.isLast;
                result.push(...emsIds.data.emsData);
                pageNumber++;
            }
            resolve({ data: result });
        } catch (error) {
            const err: any = error;
            reject(err);
        }
    });
};

export const createAgencyEmsId = async (EMSIDPayload: any): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const reqBody = {
                ...EMSIDPayload,
            };
            const url = `${
                config[config.env].apiEndpoints.accounts
            }/ems/createEmsId`;

            const APIRes = await API.POST(url, reqBody);

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const updateLockMeasures = async (payload: any): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.PATCH(
                `${config[config.env].apiEndpoints.accounts}/ems/lockMeasures`,
                payload
            );

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getMeasureLockData = async (
    startDate: any,
    endDate: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${
                    config[config.env].apiEndpoints.accounts
                }/ems/lockMeasures?startDate=${startDate}&endDate=${endDate}`
            );
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getEMSAwards = async (
    hospitalId: any,
    programId: any,
    categoryId: any,
    year: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${
                    config[config.env].apiEndpoints.accounts
                }/ems/hospitals/${hospitalId}/programs/${programId}/categories/${categoryId}/year/${year}/award
                `
            );

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const emsLogsDetails = async (
    year: any,
    title: any,
    pageNumber: number,
    pageSize: number,
    award: any,
    state: any,
    search: any,
    order: any,
    orderBy: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${
                    config[config.env].apiEndpoints.accounts
                }/ems/year/${year}?selectionType=${title}&pageSize=${pageSize}&pageNumber=${pageNumber}&award=${encodeURIComponent(
                    award
                )}&stateName=${state}&search=${search}&order=${order}&orderBy=${orderBy}
                `
            );

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getServiceYear = async (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${config[config.env].apiEndpoints.catalogs}/bulkRenew`
            );
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getRegisteredAndAwardedCount = async (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${
                    config[config.env].apiEndpoints.accounts
                }/ems/registeredAwardedCount`
            );
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const bulkRenewFacility = async (
    activeServiceYear: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const reqBody = {};
            const APIRes = await API.POST(
                `${
                    config[config.env].apiEndpoints.accounts
                }/bulkRenewals/${activeServiceYear}`,
                reqBody
            );
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getServiceYearListForHospital = async (
    hospitalId: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${
                    config[config.env].apiEndpoints.accounts
                }/ems/hospitals/${hospitalId}/serviceYears`
            );
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const getCertificationLabel = async (
    hospitalId: any,
    selectedYear: any
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${
                    config[config.env].apiEndpoints.accounts
                }/certificates/hospitals/${hospitalId}/serviceYear/${selectedYear}`
            );
            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};

export const saveCertificateAsDraft = async (
    certificateInfo: any,
    brandingMaterials: any,
    hospitalId: string | number,
    programId: string | number,
    categoryId: string | number,
    countryId: any,
    region: any
) => {
    try {
        brandingMaterials.map((material: any) => {
            delete material.assetName;
        });

        const reqBody: any = {
            facilityName: certificateInfo.facilityName?.slice(
                0,
                CONSTANTS.CERTIFICATE_FACILITY_NAME_LIMIT
            ),
            certificateName: certificateInfo.award,
            city: certificateInfo.city,
            state: certificateInfo.state,
            country: certificateInfo.countryName,
            grantedOn: certificateInfo.grantedOn,
            expiresOn: certificateInfo.expiryOn,
            certificateImage: "SaveAsDraft",
            brandingMaterials,
            countryId,
            region,
        };
        const APIRes = await API.POST(
            `${
                config[config.env].apiEndpoints.accounts
            }/ems/saveCertificateDrafts/hospitals/${hospitalId}/programs/${programId}/categories/${categoryId}
            `,
            reqBody
        );
        if (APIRes.success) {
            return APIRes;
        }
        throw APIRes.error;
    } catch (error) {
        const err: any = error;
        store.dispatch(showToast(err));
        throw error;
    }
};

export const getAwardLogsDetails = async (
    hospitalId: any,
    year: any,
    pageNumber: number,
    pageSize: number
): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const APIRes = await API.GET(
                `${
                    config[config.env].apiEndpoints.accounts
                }/certificates/hospitals/${hospitalId}/serviceYear/${year}/certificateLogs?pageSize=${pageSize}&pageNumber=${pageNumber}
                `
            );

            if (APIRes.success === true) {
                resolve(APIRes);
            } else {
                throw APIRes.error;
            }
        } catch (error) {
            const err: any = error;
            store.dispatch(showToast(err));
            reject(error);
        }
    });
};
