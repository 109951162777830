import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { localDateToYYYYMMDDFormatString } from "common/utils";
import { getArchivalAccordionDatesAPI } from "api/archivalDocumentIntegrationAPI";
import { logger } from "utils/logger.utils";
import { AccordionWrapper } from "./styled";

interface Props {
    activeTab: string;
    setContent: any;
    setDocsDate: any;
    setFacApprovedDate: any;
    setFirstRenewingDate?: any;
    setSecondRenewingDate?: any;
}
const AccordianSidebar = ({
    activeTab,
    setContent,
    setDocsDate,
    setFacApprovedDate,
    setFirstRenewingDate,
    setSecondRenewingDate,
}: Props) => {
    const [pageOption, setPageOption] = useState<any>(1);
    const params: any = useParams();
    const [accordianDate, setAccordianDate] = useState<any>();
    const location = useLocation();
    const [approveDate, setApproveDate] = useState<string>("");
    const [prevPath, setPrevPath] = useState<any>("");
    const [selectedDateIndex, setSelectedDateIndex] = useState<any>(null);
    const [selectedDocIndex, setSelectedDocIndex] = useState<any>(null);
    const handlePage = (
        event: any,
        option: any,
        date: any,
        approvDate: any,
        index: any
    ) => {
        const actives = document.querySelectorAll(".activeSideNav");
        [].forEach.call(actives, function (elem: any) {
            elem.classList.remove("activeSideNav");
        });
        if (option === "documents") {
            event.target.parentElement.classList.add("activeSideNav");
        } else {
            event.target.parentElement.classList.add("activeSideNav");
        }
        if (index == accordianDate.length - 1) {
            setFirstRenewingDate(approvDate);
            setSecondRenewingDate(date);
        } else {
            setFirstRenewingDate(accordianDate[index + 1]);
            setSecondRenewingDate(accordianDate[index]);
        }
        setContent(option);
        setSelectedDocIndex(index === selectedDocIndex ? null : index);
        setDocsDate(date);
        localStorage.setItem("selectedArchivedDate", date);
    };
    const countryId = location?.state?.selectedCountryId
        ? location?.state?.selectedCountryId
        : localStorage.getItem("selectedHospitalCountryId");
    useEffect(() => {
        getArchivalAccordionDatesAPI(params?.hospitalId)
            .then((res: any) => {
                if (res.statusCode === 200) {
                    setFirstRenewingDate(
                        res.data.archiveDates[1]
                            ? res.data.archiveDates[1]
                            : res.data.approvedDate
                    );
                    setSecondRenewingDate(res?.data?.archiveDates[0]);
                    setAccordianDate(res?.data?.archiveDates);
                    setApproveDate(res.data.approvedDate);
                    setContent("documents");
                    setDocsDate(res?.data?.archiveDates[0]);
                    setFacApprovedDate(res?.data?.approvedDate);
                    document
                        .getElementById("document-0")
                        ?.classList.add("activeSideNav");
                    localStorage.setItem(
                        "selectedArchivedDate",
                        res?.data?.archiveDates[0]
                    );
                    localStorage.setItem(
                        "selectedApprovedDate",
                        res?.data?.approvedDate
                    );
                }
            })
            .catch((err: any) => {
                logger(err);
            });
        switch (location.pathname.split("/")[1]) {
            case "admin":
                if (location?.state?.isDeactived) {
                    setPrevPath(
                        `/admin/organization-management/approve-organization/${params.hospitalId}/program/${params.programId}/category/${params.categoryId}`
                    );
                } else {
                    setPrevPath(
                        `/admin/organization-management/organization-details/${params.hospitalId}/program/${params.programId}/category/${params.categoryId}`
                    );
                }
                break;
            case "archived-view":
                setPrevPath("/dashboard");
                break;
            case "corporation":
                setPrevPath(
                    `/corporation/organization-details/${params.hospitalId}/program/${params.programId}/category/${params.categoryId}`
                );
                break;
            default:
                setPrevPath("");
        }
    }, []);
    useEffect(() => {
        setSelectedDateIndex(0);
        setSelectedDocIndex(0);
    }, []);
    const handleDateClick = (index: any) => {
        setSelectedDateIndex(index === selectedDateIndex ? null : index);
    };
    const createStateObject = () => {
        const linkState: any = {
            backToFaciclityManagement: location?.state?.pathname,
            adminfacilityMangemnetTab:
                location?.state?.customState?.state?.adminfacilityMangemnetTab,
            tab: location?.state?.tab,
            facilityName: location?.state?.facilityName,
            search: location?.state?.search,
            countryId,
        };

        if (location?.state?.customState?.pathname === "/admin/dashboard") {
            return { backToAdminDashboard: "/admin/dashboard" };
        }
        if (
            location?.state?.customState?.pathname ===
            `/registry/registry-management/registry-details/${location?.state?.registryId}`
        ) {
            return {
                pathname: `/registry/registry-management/registry-details/${location?.state?.registryId}`,
                registryId: location?.state?.registryId,
                registryName: location?.state?.customState?.state?.registryName,
                facilityName: location?.state?.facilityName,
            };
        }
        if (
            location?.state?.customState?.pathname ===
            `/corporation/corporation-management/corporation-details/${location?.state?.corporationId}`
        ) {
            return {
                pathname: `/corporation/corporation-management/corporation-details/${location?.state?.corporationId}`,
                corporationId: location?.state?.corporationId,
                corporationName:
                    location?.state?.customState?.state?.corporationName,
                facilityName: location?.state?.facilityName,
            };
        }
        return linkState;
    };
    const stateObject = createStateObject();
    return (
        <AccordionWrapper className="navbar-expand-lg " id="accordian-sidebar">
            <div className="d-flex justify-content-between align-items-center back-button">
                <div className=" mb-0 mb-lg-3">
                    <Link
                        to={{
                            pathname: prevPath,
                            state: stateObject,
                        }}
                        className="previous-page-link d-inline-block"
                        aria-label="back to previous page"
                    >
                        <i
                            className="aha-icon-arrow-left d-inline-block "
                            aria-hidden="true"
                        />
                        Back
                    </Link>
                </div>

                <button
                    className="navbar-toggler float-right divider"
                    type="button"
                    data-toggle="collapse"
                    data-target="#sideNavbar"
                    aria-controls="sideNavbar"
                    aria-expanded="false"
                    aria-label="Toggle navigation archive dates"
                >
                    <i className="aha-icon-hamburger-round" />
                </button>
            </div>
            <div className="collapse navbar-collapse" id="sideNavbar">
                <ul className="w-100 dropdown-ul" aria-label="archive dates">
                    {accordianDate &&
                        accordianDate.map((date: any, index: any) => (
                            <li
                                key={index}
                                id={`sidenav-accordion-dropdown-${index}`}
                                className="accordion-dropdown"
                            >
                                <button
                                    className="btn btn-text aui-sidenav-acc-header font-700"
                                    id={`sidenavHeading-${index}`}
                                    data-toggle="collapse"
                                    data-target={`#sidenavcollapse-${index}`}
                                    aria-expanded={
                                        index === selectedDateIndex
                                            ? "true"
                                            : "false"
                                    }
                                    aria-controls={`sidenavcollapse-${index}`}
                                    onClick={() => handleDateClick(index)}
                                >
                                    {localDateToYYYYMMDDFormatString(date)}
                                    <i className="acc-btn-arrow aha-icon-arrow-down" />
                                </button>
                                <ul
                                    id={`sidenavcollapse-${index}`}
                                    className={`aui-sidenav-acc-body collapse ${
                                        index === selectedDateIndex
                                            ? "show"
                                            : ""
                                    }`}
                                    aria-labelledby={`sidenavHeading-${index}`}
                                    data-parent={`#sidenav-accordion-dropdown-${index}`}
                                >
                                    <li id={`document-${index}`}>
                                        <button
                                            className="listing-items"
                                            onClick={(e) =>
                                                handlePage(
                                                    e,
                                                    "documents",
                                                    date,
                                                    approveDate,
                                                    index
                                                )
                                            }
                                            aria-current={
                                                activeTab === "documents" &&
                                                index === selectedDocIndex
                                                    ? "page"
                                                    : false
                                            }
                                            role="link"
                                        >
                                            Documents
                                        </button>
                                    </li>
                                    <li
                                        id={`certificate-${index}`}
                                        className=""
                                    >
                                        <button
                                            className="listing-items"
                                            onClick={(e) =>
                                                handlePage(
                                                    e,
                                                    "certificate",
                                                    date,
                                                    approveDate,
                                                    index
                                                )
                                            }
                                            aria-current={
                                                activeTab === "certification" &&
                                                index === selectedDocIndex
                                                    ? "page"
                                                    : false
                                            }
                                            role="link"
                                        >
                                            Certificate
                                        </button>
                                    </li>
                                </ul>
                            </li>
                        ))}
                </ul>
            </div>
        </AccordionWrapper>
    );
};

export default AccordianSidebar;
