import React, { createRef, useEffect, useState } from "react";
import Navigator from "components/Navigator";
import UserManagement from "components/UserManagement";
import RegistryTabs from "pages/Registry/RegistryMenu";
import { getUserList } from "api/usersApi";
import { dismissModal, parseJwt } from "common/utils";
import CorporationTabs from "pages/Corporation/CorporationMenu/CorporationMenu";
import CONSTANTS from "common/constants";
import { useLocation } from "react-router";
import { IPagination } from "components/Pagination";
import {
    addUser,
    deleteUser,
    getUserLogsFromUserManagement,
} from "api/userManagementAPI";
import { useDispatch } from "react-redux";
import { showToast } from "components/Toast/toast.slice";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { getHospitalById } from "../../api/hospitalApi";
import { logger } from "../../utils/logger.utils";

const UserManagementPage = () => {
    const [users, setUsers] = useState<any>("");
    const [inputUser, setInputUser] = useState<any>("");
    const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
    const [isUsersResponseAvailable, setIsUsersResponseAvailable] =
        useState<boolean>(false);
    const userAccessToken = window.localStorage.getItem("userAccessToken");
    const hospitalId: any = localStorage.getItem("selectedHospitalId");
    const programId: any = localStorage.getItem("selectedProgramId");
    const categoryId: any = localStorage.getItem("selectedCategoryId");
    const [addUserInd, setAddUserInd] = useState<boolean>(false);
    const [deleteUserInd, setDeleteUserInd] = useState<boolean>(false);
    const isEUP =
        userAccessToken &&
        parseJwt(userAccessToken).role_code ===
            CONSTANTS.USER_ROLES.HOSPITAL_ADMIN;
    const isCorporation =
        userAccessToken &&
        parseJwt(userAccessToken).role_code ==
            CONSTANTS.USER_ROLES.CORPORATION_ADMIN;
    const isRegistry =
        userAccessToken &&
        parseJwt(userAccessToken).role_code ==
            CONSTANTS.USER_ROLES.REGISTRY_ADMIN;
    const corporationId =
        userAccessToken && parseJwt(userAccessToken).corporation_id;
    const [roleValue, setRoleValue] = useState<string>("none");
    const [mounted, setMounted] = useState<boolean>(false);
    const [breadcrumbItems, setBreadcrumbItems] = useState<any>([]);
    const location = useLocation();
    const [userName, setUserName] = useState<any>("");
    const [validateDisabled, setValidateDisabled] = useState<boolean>(false);
    const [toggleState, settoggleState] = useState(false);
    const [userEmail, setUserEmail] = useState<any>("");
    const [deleteUserId, setDeleteUserId] = useState<any>("");
    const dispatch = useDispatch();
    const [disabledInd, setDisabledInd] = useState<boolean>(false);
    const [addUserActivateInd, setAddUserActivateInd] =
        useState<boolean>(false);
    const [deleteUserActivateInd, setDeleteUserActivateInd] =
        useState<boolean>(false);
    const registryId = userAccessToken && parseJwt(userAccessToken).registry_id;
    const [userLogs, setUserLogs] = useState<any>([]);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const paginationSetLimit: number = 5;
    const defaultPagination: IPagination = {
        pageNumber: 1,
        pageSize: 25,
        isFirst: true,
        isLast: false,
        setPageNumber,
        paginationSetLimit,
    };
    const [pagination, setPagination] =
        useState<IPagination>(defaultPagination);

    useEffect(() => {
        const elementsArray: any = [];
        localStorage.setItem("clearFilter", "true");
        if (isEUP) {
            getHospitalById(hospitalId).then((response: any) => {
                if (response.success) {
                    if (programId !== CONSTANTS.EMS_PROGRAM_ID) {
                        elementsArray.push(
                            {
                                label: "Home",
                                returnPath: {
                                    pathname: CONSTANTS.ROUTES.USER_DASHBOARD,
                                    state: {
                                        pageNum: location?.state?.pageNum,
                                        search: location?.state?.search,
                                    },
                                },
                            },
                            {
                                label: response.data?.hospital?.facilityName,
                                returnPath: {
                                    pathname: "/dashboard",
                                    state: {
                                        pageNum: location?.state?.pageNum,
                                        search: location?.state?.search,
                                    },
                                },
                            },
                            {
                                label: "Organization User Management",
                                returnPath: "",
                            }
                        );
                    } else {
                        elementsArray.push(
                            {
                                label: "Home",
                                returnPath: {
                                    pathname: CONSTANTS.ROUTES.USER_DASHBOARD,
                                    state: {
                                        pageNum: location?.state?.pageNum,
                                        search: location?.state?.search,
                                    },
                                },
                            },
                            {
                                label: response.data?.hospital?.facilityName,
                                returnPath: {
                                    pathname: `/organizationDetails/${hospitalId}/programs/${programId}/categories/${categoryId}`,
                                    state: {
                                        activateMeasuresTab: true,
                                        pageNum: location?.state?.pageNum,
                                        search: location?.state?.search,
                                    },
                                },
                            },
                            {
                                label: "Organization User Management",
                                returnPath: "",
                            }
                        );
                    }
                    setBreadcrumbItems(elementsArray);
                }
            });
        } else if (isCorporation) {
            elementsArray.push(
                {
                    label: "Home",
                    returnPath: "/corporation/dashboard",
                },
                {
                    label: "Corporation User Management",
                    returnPath: "",
                }
            );
            setBreadcrumbItems(elementsArray);
        } else if (isRegistry) {
            elementsArray.push(
                {
                    label: "Home",
                    returnPath: "/registry/dashboard",
                },
                {
                    label: "Registry User Management",
                    returnPath: "",
                }
            );
            setBreadcrumbItems(elementsArray);
        }
    }, []);

    const fetchUsers = async () => {
        try {
            setIsTableLoading(true);
            setIsUsersResponseAvailable(false);
            setPagination(defaultPagination);

            const response = await getUserList(
                hospitalId,
                1,
                5,
                isEUP,
                inputUser
            );

            if (response.success) {
                setUsers(response.data.users);
            }
        } catch (error) {
            logger(error);
        } finally {
            setIsUsersResponseAvailable(true);
            setIsTableLoading(false);
            if (mounted) setRoleValue("alert");
            setMounted(true);
            setAddUserInd(false);
            setDeleteUserInd(false);
        }
    };
    useEffect(() => {
        fetchUsers();
    }, [pageNumber]);

    useEffect(() => {
        const fetchUserLogs = async () => {
            setIsTableLoading(true);
            setPageNumber(!toggleState ? 1 : pageNumber);
            const response = await getUserLogsFromUserManagement(
                Number(hospitalId) > 0 ? hospitalId : "",
                pageNumber,
                pagination.pageSize,
                isRegistry ? "" : corporationId,
                isRegistry ? registryId : ""
            );

            if (response.success) {
                setUserLogs(response.data.logsList);
                setPagination({
                    ...response.data._pagination,
                    setPageNumber,
                });
            }
            setIsTableLoading(false);
        };

        fetchUserLogs();
    }, [pageNumber, toggleState]);

    const addUserDismissModal = () => {
        setAddUserActivateInd(false);
        dismissModal();
    };

    const deleteUserDismissModal = () => {
        setDeleteUserActivateInd(false);
        dismissModal();
    };
    const addUserDetails = async (event: any) => {
        event.preventDefault();
        setValidateDisabled(true);
        setAddUserInd(true);
        const userResult = await addUser(
            hospitalId,
            userEmail,
            setValidateDisabled,
            isEUP,
            false,
            false,
            registryId,
            false,
            corporationId
        );

        if (userResult.success) {
            const toast = {
                message: `User ${userEmail} successfully added.`,
                code: "Success:",
                type: "success",
            };
            dispatch(showToast(toast));
            addUserDismissModal();
            fetchUsers();
            dispatch(setPageLoadingStatus({ isPageLoading: false }));
        }
        setValidateDisabled(false);
    };

    const deleteUserData = async () => {
        setDisabledInd(true);
        setDeleteUserInd(true);

        try {
            const userResult = await deleteUser(
                hospitalId,
                deleteUserId,
                isEUP,
                false,
                false,
                registryId,
                false,
                corporationId
            );
            setDisabledInd(false);
            if (userResult.success) {
                const toast = {
                    message: `User ${userEmail} has been removed.`,
                    code: "Success:",
                    type: "success",
                };
                dispatch(showToast(toast));
                deleteUserDismissModal();
                fetchUsers();
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
            }
        } catch (error: any) {
            setDisabledInd(false);
            logger("Error occurred while deleting user:", error);
            deleteUserDismissModal();
            fetchUsers();
        }
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pageNumber]);

    return (
        <>
            {userAccessToken &&
            parseJwt(userAccessToken).role_code ==
                CONSTANTS.USER_ROLES.REGISTRY_ADMIN ? (
                <RegistryTabs activeTab="" />
            ) : (
                window.localStorage.getItem("isProgramApproved") == "true" &&
                window.localStorage.getItem("isHospitalDeActiveOrDeleted") ==
                    "false" &&
                window.localStorage.getItem("userAccessToken") &&
                parseJwt(window.localStorage.getItem("userAccessToken"))
                    .role_code == "HOSPITAL_ADMIN" && <Navigator tabName="" />
            )}
            {userAccessToken &&
                parseJwt(userAccessToken).role_code ==
                    CONSTANTS.USER_ROLES.CORPORATION_ADMIN && (
                    <CorporationTabs activeTab="" />
                )}
            <UserManagement
                users={users}
                isTableLoading={isTableLoading}
                inputUser={inputUser}
                setInputUser={setInputUser}
                isUsersResponseAvailable={isUsersResponseAvailable}
                roleValue={roleValue}
                pagination={pagination}
                breadcrumbItems={breadcrumbItems}
                addUserDetails={addUserDetails}
                userName={userName}
                setUserName={setUserName}
                setUserEmail={setUserEmail}
                userEmail={userEmail}
                setDeleteUserId={setDeleteUserId}
                settoggleState={settoggleState}
                deleteUserData={deleteUserData}
                disabledInd={disabledInd}
                setAddUserActivateInd={setAddUserActivateInd}
                addUserActivateInd={addUserActivateInd}
                setDeleteUserActivateInd={setDeleteUserActivateInd}
                deleteUserActivateInd={deleteUserActivateInd}
                addUserDismissModal={addUserDismissModal}
                deleteUserDismissModal={deleteUserDismissModal}
                userLogs={userLogs}
                isEUP={isEUP}
                isCorporation={isCorporation}
                corporationId={corporationId}
                isRegistry={isRegistry}
                registryId={registryId}
                logsPagination={pagination}
            />
        </>
    );
};

export default UserManagementPage;
