import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { getRegisteredProgram } from "api/registeredProgramAPI";
import CONSTANTS from "common/constants";
import { NavigatorWrapper } from "./styled";
import config from "../../config";

interface Props {
    tabName: string;
}

const Navigator = ({ tabName }: Props) => {
    const history = useHistory();
    const location = useLocation();
    const hospitalId: any = localStorage.getItem("selectedHospitalId");
    const programId = localStorage.getItem("selectedProgramId");
    const [programsId, setProgramsId] = useState<any>();
    const [hideMeasure, setHideMeasure] = useState<boolean>(false);

    const doRoute = (routeTabName: string) => {
        getRegisteredProgram(hospitalId)
            .then((response: any) => {
                if (response.statusCode == 200) {
                    if (response.data.hospitals.length) {
                        const { programId } = response.data.hospitals[0];
                        const { categoryId } = response.data.hospitals[0];
                        const routeString =
                            programId == CONSTANTS.EMS_PROGRAM_ID
                                ? `/organizationDetails/${hospitalId}/programs/${programId}/categories/${categoryId}`
                                : `/${routeTabName}/organization/${hospitalId}/programs/${programId}/categories/${categoryId}`;
                        history.push(routeString, {
                            activateMeasuresTab: routeTabName === "measures",
                            pageNum: location?.state?.pageNum,
                            search: location?.state?.search,
                        });
                    } else {
                        history.push("/dashboard");
                    }
                }
            })
            .catch((error) => alert(error));
    };
    useEffect(() => {
        setProgramsId(localStorage.getItem("selectedProgramId"));
        if (programsId == 16) {
            setHideMeasure(true);
        }
    }, [programsId]);

    return (
        <NavigatorWrapper className="aui-global-nav">
            <div className="aui-menu-tabs">
                <div className="aui-menu-tabs-container">
                    <div className="navbar-expand-lg">
                        <div className="d-flex flex-row-reverse aui-global-menubtn">
                            <button
                                type="button"
                                className="navbar-toggler btn-mainmenu-sm collapsed"
                                data-toggle="collapse"
                                data-target="#dashboardItems"
                                aria-controls="navbarDashboard"
                                aria-expanded="false"
                                aria-label="main menu navigation"
                            >
                                Menu
                                <i
                                    className="aha-icon-arrow-down"
                                    aria-hidden="true"
                                />
                            </button>
                        </div>
                        <nav id="navbarDashboard">
                            <ul
                                id="dashboardItems"
                                className="collapse navbar-collapse aui-global-menu"
                            >
                                {programId !== CONSTANTS?.EMS_PROGRAM_ID && (
                                    <li
                                        className={
                                            tabName == "dashboard"
                                                ? "active"
                                                : ""
                                        }
                                    >
                                        <Link
                                            to={{
                                                pathname: "/dashboard",
                                                state: {
                                                    pageNum:
                                                        location?.state
                                                            ?.pageNum,
                                                    search: location?.state
                                                        ?.search,
                                                },
                                            }}
                                            className="btn btn-text"
                                            aria-current={
                                                tabName == "dashboard"
                                                    ? "page"
                                                    : false
                                            }
                                        >
                                            Dashboard
                                        </Link>
                                    </li>
                                )}
                                {!hideMeasure && (
                                    <li
                                        className={
                                            tabName == "measures"
                                                ? "active"
                                                : ""
                                        }
                                    >
                                        <button
                                            type="button"
                                            className="btn btn-text"
                                            onClick={() => doRoute("measures")}
                                            aria-current={
                                                tabName == "measures"
                                                    ? "page"
                                                    : false
                                            }
                                            role="link"
                                        >
                                            Measures
                                        </button>
                                    </li>
                                )}
                                {programId !== CONSTANTS?.EMS_PROGRAM_ID && (
                                    <li
                                        className={
                                            tabName == "documents"
                                                ? "active"
                                                : ""
                                        }
                                    >
                                        <button
                                            type="button"
                                            className="btn btn-text"
                                            onClick={() => doRoute("documents")}
                                            aria-current={
                                                tabName == "documents"
                                                    ? "page"
                                                    : false
                                            }
                                            role="link"
                                        >
                                            Document Submission
                                        </button>
                                    </li>
                                )}
                                {config &&
                                config[config.env].featureConfig &&
                                config[config.env].featureConfig
                                    .certification == "ON" ? (
                                    <li
                                        className={
                                            tabName == "certificate"
                                                ? "active"
                                                : ""
                                        }
                                    >
                                        <button
                                            type="button"
                                            className="btn btn-text"
                                            onClick={() =>
                                                doRoute("certificate")
                                            }
                                            aria-current={
                                                tabName == "certificate"
                                                    ? "page"
                                                    : false
                                            }
                                            role="link"
                                        >
                                            {programId !==
                                            CONSTANTS?.EMS_PROGRAM_ID
                                                ? "Certificate"
                                                : "Award"}
                                        </button>
                                    </li>
                                ) : null}
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </NavigatorWrapper>
    );
};
export default Navigator;
