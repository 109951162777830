import React, { useEffect, useState } from "react";
import UserManagement from "components/UserManagement";
import { getUserList } from "api/usersApi";
import { dismissModal, parseJwt, showModal } from "common/utils";
import CONSTANTS from "common/constants";
import { useLocation } from "react-router";
import {
    addUser,
    deleteUser,
    getUserLogsFromUserManagement,
} from "api/userManagementAPI";
import { useDispatch } from "react-redux";
import { showToast } from "components/Toast/toast.slice";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { BreadCrumb } from "components/BreadCrumb";
import { FacilityDetailsBanner } from "pages/Admin/HospitalBanner/FacilityDetailsBanner";
import { getHospitalProgramDetails } from "api/hospitalProgramsAPI";
import { IPagination } from "components/Pagination";
import { getHospitalById } from "../../../api/hospitalApi";
import { logger } from "../../../utils/logger.utils";
import { OrganizationManagementWrapper } from "../OrganizationManagement/styled";

import Sidebar from "../Sidebar/Sidebar";

const EMSAdminUserManagement = () => {
    const location = useLocation();
    const [users, setUsers] = useState<any>("");
    const [inputUser, setInputUser] = useState<any>("");
    const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
    const [isUsersResponseAvailable, setIsUsersResponseAvailable] =
        useState<boolean>(false);
    const userAccessToken = window.localStorage.getItem("userAccessToken");
    const hospitalId = location?.state?.hospitalId || null;
    const programId = location?.state?.programId || null;
    const [addUserInd, setAddUserInd] = useState<boolean>(false);
    const [deleteUserInd, setDeleteUserInd] = useState<boolean>(false);
    const isEUP =
        userAccessToken &&
        parseJwt(userAccessToken).role_code ===
            CONSTANTS.USER_ROLES.HOSPITAL_ADMIN;
    const isEMSAdmin =
        userAccessToken &&
        parseJwt(userAccessToken).role_code === CONSTANTS.USER_ROLES.EMS_ADMIN;
    const [roleValue, setRoleValue] = useState<string>("none");
    const [mounted, setMounted] = useState<boolean>(false);
    const [userLogs, setUserLogs] = useState<any>([]);
    const [breadcrumbItems, setBreadcrumbItems] = useState<any>([]);
    const [validateDisabled, setValidateDisabled] = useState<boolean>(false);
    const [userName, setUserName] = useState<any>("");
    const [userEmail, setUserEmail] = useState<any>("");
    const [deleteUserId, setDeleteUserId] = useState<any>("");
    const dispatch = useDispatch();
    const [disabledInd, setDisabledInd] = useState<boolean>(false);
    const [addUserActivateInd, setAddUserActivateInd] =
        useState<boolean>(false);
    const [deleteUserActivateInd, setDeleteUserActivateInd] =
        useState<boolean>(false);
    const [hospital, setHospital] = useState<any>({});
    const [programData, setProgramData] = useState<any>();
    const [lastEditedData, setLastEditedData] = useState<any>(null);
    const [deleteLimitReachedInd, setDeleteLimitReachedInd] = useState(false);
    const [toggleState, settoggleState] = useState(false);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const paginationSetLimit: number = 5;
    const defaultPagination: IPagination = {
        pageNumber: 1,
        pageSize: 25,
        isFirst: true,
        isLast: false,
        setPageNumber,
        paginationSetLimit,
    };
    const [pagination, setPagination] =
        useState<IPagination>(defaultPagination);
    const getFacilityDetails = () => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        Promise.all([
            getHospitalById(hospitalId),
            getHospitalProgramDetails(programId, hospitalId),
        ])
            .then((response: any) => {
                if (response[0].success && response[1].success) {
                    setHospital(response[0].data.hospital);
                    modifyLastEditedData(response[0].data.hospital);
                    localStorage.setItem(
                        "selectedHospitalCountryCode",
                        response[0].data.hospital.countryCode
                    );
                    setProgramData(response[1].data);
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                }
            })
            .catch(() => {
                dispatch(setPageLoadingStatus({ isPageLoading: true }));
            });
    };

    const modifyLastEditedData = (currentData: any) => {
        if (currentData?.updatedBy?.firstName && currentData?.updatedAt) {
            setLastEditedData(currentData);
        }
    };

    useEffect(() => {
        getFacilityDetails();
        const elementsArray = [];
        let pathToReturn: any;
        let retrurnFacility: any;
        if (location.state?.adminOrganizationMangemnetTab) {
            pathToReturn = {
                pathname: location?.state?.backToOrganizationManagement,
                state: {
                    adminOrganizationMangemnetTab:
                        location?.state?.adminOrganizationMangemnetTab,
                    adminOrganizationMangemnetPage:
                        location?.state?.adminOrganizationMangemnetPage,
                    pageSort: location?.state?.pageSort,
                },
            };
            retrurnFacility = {
                pathname: location?.state?.backReturn,
                state: {
                    adminOrganizationMangemnetTab:
                        location?.state?.adminOrganizationMangemnetTab,
                    facilityName: location?.state?.facilityName,
                    backToOrganizationManagement:
                        location?.state?.backToOrganizationManagement,
                    pathname: location?.state?.backToOrganizationManagement,
                },
            };
            elementsArray.push(
                {
                    label:
                        location.state?.adminOrganizationMangemnetTab ==
                        CONSTANTS.PENDING
                            ? "Pending"
                            : location.state?.adminOrganizationMangemnetTab ==
                              CONSTANTS.REGISTRED
                            ? "Registered"
                            : location.state?.adminOrganizationMangemnetTab ==
                              CONSTANTS.CERTIFIED
                            ? "Awarded"
                            : "Deactivated",
                    returnPath: pathToReturn,
                },
                {
                    label: location.state.facilityName,
                    returnPath: retrurnFacility,
                },
                {
                    label: "Organization User Management",
                }
            );
        }
        setBreadcrumbItems(elementsArray);
    }, []);

    useEffect(() => {
        setIsTableLoading(true);
        setIsUsersResponseAvailable(false);
        setPagination(defaultPagination);
        setPageNumber(!toggleState ? 1 : pageNumber);
        Promise.all([
            getUserList(hospitalId, 1, 5, false, inputUser, isEMSAdmin),
            getUserLogsFromUserManagement(
                hospitalId,
                pageNumber,
                pagination.pageSize
            ),
        ])
            .then((response: any) => {
                if (response[0].success && response[1].success) {
                    setUsers(response[0].data.users);
                    setUserLogs(response[1].data.logsList);
                    setPagination({
                        ...response[1].data._pagination,
                        setPageNumber,
                    });
                }
                if (mounted) {
                    setRoleValue("alert");
                }
                setMounted(true);
                setAddUserInd(false);
                setDeleteUserInd(false);
                setIsUsersResponseAvailable(true);
                setIsTableLoading(false);
            })
            .catch((err) => {
                logger(err);
                setIsUsersResponseAvailable(true);
                setIsTableLoading(false);
                setAddUserInd(false);
                setDeleteUserInd(false);
            });
    }, [addUserInd, deleteUserInd, pageNumber, toggleState]);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pageNumber]);

    const addUserDismissModal = () => {
        setAddUserActivateInd(false);
        dismissModal();
    };

    const deleteUserDismissModal = () => {
        setDeleteUserActivateInd(false);
        dismissModal();
    };

    const addUserDetails = async (event: any) => {
        event.preventDefault();
        setValidateDisabled(true);
        setAddUserInd(true);
        const userResult = await addUser(
            hospitalId,
            userEmail,
            setValidateDisabled,
            false,
            isEMSAdmin
        );

        if (userResult.success) {
            const toast = {
                message: `User ${userEmail} successfully added.`,
                code: "Success:",
                type: "success",
            };
            dispatch(showToast(toast));
            addUserDismissModal();
            dispatch(setPageLoadingStatus({ isPageLoading: false }));
        }
        setValidateDisabled(false);
    };

    const deleteUserData = async () => {
        setDisabledInd(true);
        setDeleteUserInd(true);
        const userResult = await deleteUser(
            hospitalId,
            deleteUserId,
            false,
            isEMSAdmin
        );
        setDisabledInd(false);
        if (userResult.success) {
            const toast = {
                message: `User ${userEmail} has been removed.`,
                code: "Success:",
                type: "success",
            };
            dispatch(showToast(toast));
            deleteUserDismissModal();
            dispatch(setPageLoadingStatus({ isPageLoading: false }));
        }
    };

    return (
        <OrganizationManagementWrapper className="container-fluid p-0 d-flex flex-grow-1">
            <div className="row no-gutters w-100 flex-grow-1">
                <Sidebar activeTab="organizationManagement" />
                <section className="d-flex admin-main-content container align-items-start">
                    <div className="row no-gutters w-100 admin-main">
                        <BreadCrumb breadcrumbItems={breadcrumbItems} />
                        <div className="col-12">
                            <div className="d-md-flex justify-content-between dashboard-fac-magnt align-items-center">
                                <h1 className="h2 font-400 mb-0">
                                    Organization Management
                                </h1>
                            </div>
                            <FacilityDetailsBanner
                                hospital={hospital}
                                programData={programData}
                                lastEditedData={lastEditedData}
                                close={close}
                                isEmsAdmin
                            />
                            <div className="user-management">
                                <UserManagement
                                    users={users}
                                    isTableLoading={isTableLoading}
                                    inputUser={inputUser}
                                    setInputUser={setInputUser}
                                    isUsersResponseAvailable={
                                        isUsersResponseAvailable
                                    }
                                    roleValue={roleValue}
                                    pagination={pagination}
                                    addUserDetails={addUserDetails}
                                    setUserEmail={setUserEmail}
                                    userEmail={userEmail}
                                    userName={userName}
                                    setUserName={setUserName}
                                    setDeleteUserId={setDeleteUserId}
                                    deleteUserData={deleteUserData}
                                    disabledInd={disabledInd}
                                    setAddUserActivateInd={
                                        setAddUserActivateInd
                                    }
                                    addUserActivateInd={addUserActivateInd}
                                    setDeleteUserActivateInd={
                                        setDeleteUserActivateInd
                                    }
                                    userLogs={userLogs}
                                    settoggleState={settoggleState}
                                    deleteUserActivateInd={
                                        deleteUserActivateInd
                                    }
                                    addUserDismissModal={addUserDismissModal}
                                    deleteUserDismissModal={
                                        deleteUserDismissModal
                                    }
                                    isEMSAdmin
                                    isEUP={isEUP}
                                    logsPagination={pagination}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </OrganizationManagementWrapper>
    );
};

export default EMSAdminUserManagement;
