import React, { useEffect, useRef } from "react";
import FocusTrap from "focus-trap-react";
import { dismissModal } from "common/utils";
import UploadDocumentContent, {
    IUploadDocForm,
} from "./UploadDocumentModalContent";

interface Props {
    uploadProgram: any;
    standards: any;
    setRefreshTableDateNow: any;
    initUploadDocForm: IUploadDocForm;
    uploadDocForm: IUploadDocForm;
    setUploadDocForm: any;
    setuploadDocumentInd: any;
    uploadDocumentInd: any;
    isEUP: boolean;
    facilityCount?: any;
    facilityIds?: any;
    setCount?: any;
    setSelectAll?: any;
    setSelection?: any;
    setFacilityIds?: any;
}

const UploadDocument = (props: Props) => {
    const modalRef = useRef<HTMLDivElement | null>(null);
    const docModalInnerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (props.setuploadDocumentInd && docModalInnerRef.current) {
            docModalInnerRef.current.setAttribute("tabIndex", "-1");
            docModalInnerRef.current.focus();
        }
        const handleMouseDown = (event: any) => {
            if (modalRef.current && modalRef.current.contains(event.target)) {
                docModalInnerRef.current?.removeAttribute("tabIndex");
            }
        };
        if (props.uploadDocumentInd) {
            document.addEventListener("mousedown", handleMouseDown);
        }
        return () => {
            document.removeEventListener("mousedown", handleMouseDown);
        };
    }, [props.uploadDocumentInd]);
    return (
        <FocusTrap
            focusTrapOptions={{
                escapeDeactivates: false,
                clickOutsideDeactivates: false,
            }}
        >
            <div
                className="modal fade show aui-modal"
                style={{ display: props.uploadDocumentInd ? "block" : "none" }}
                id="uploadDocModal"
                tabIndex={-1}
                aria-labelledby="uploadDocModalLabel"
                aria-modal="true"
                role="dialog"
                data-backdrop="static"
                data-keyboard="false"
            >
                <div
                    className="modal-dialog modal-dialog-centered modal-lg"
                    ref={modalRef}
                >
                    <div ref={docModalInnerRef} id="docModalInnerRef">
                        <UploadDocumentContent
                            uploadProgram={props.uploadProgram}
                            standards={props.standards}
                            setRefreshTableDateNow={
                                props.setRefreshTableDateNow
                            }
                            initUploadDocForm={props.initUploadDocForm}
                            uploadDocForm={props.uploadDocForm}
                            setUploadDocForm={props.setUploadDocForm}
                            isEUP={props.isEUP}
                            facilityCount={props.facilityCount}
                            facilityIds={props.facilityIds}
                            setCount={props.setCount}
                            setSelectAll={props.setSelectAll}
                            setSelection={props.setSelection}
                            setFacilityIds={props.setFacilityIds}
                            setuploadDocumentInd={props.setuploadDocumentInd}
                        />
                    </div>
                </div>
            </div>
        </FocusTrap>
    );
};

export default UploadDocument;
