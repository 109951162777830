import CONSTANTS from "common/constants";
import { wordSplit } from "common/utils";
import TableRowCount from "components/TableRowCount";
import React from "react";
import { Link } from "react-router-dom";
import { RegistryTableWrapper } from "./styled";

interface Props {
    registries: any;
    actionURL: string;
    isTableLoading: boolean;
    tab: string;
    pageNumber: number;
    pagination: any;
    registryTab: string;
    roleValue: string;
}

export const RegistryTable = ({
    registries,
    actionURL,
    isTableLoading,
    tab,
    pageNumber,
    pagination,
    registryTab,
    roleValue,
}: Props) => {
    return (
        <RegistryTableWrapper className="mt-3 w-100">
            <TableRowCount
                isFirst={pagination.isFirst}
                pageNumber={pagination.pageNumber}
                pageSize={pagination.pageSize}
                isLast={pagination.isLast}
                totalCount={pagination.totalCount}
                facilityType={registryTab}
                registryInd
                roleValue={roleValue}
            />
            <div className="registry-table mt-4 w-100">
                <table
                    className={`aui-responsive-status-table ${
                        isTableLoading ? CONSTANTS.TABLE_SPINNER : ""
                    }`}
                >
                    <thead>
                        <tr>
                            <th scope="col">
                                <div className="aui-th">Registry Code</div>
                            </th>
                            <th scope="col">
                                <div className="aui-th">Registry Name</div>
                            </th>
                            <th scope="col">
                                <div className="aui-th">Email Address</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {registries.length > 0 &&
                            registries.map((registry: any, index: number) => {
                                return (
                                    <tr
                                        key={`registry-${registry.id}`}
                                        className={
                                            tab === "ACTIVE"
                                                ? "aui-table-status-success"
                                                : "aui-table-status-pending"
                                        }
                                    >
                                        <td data-title="Registry Code">
                                            <div className="aui-td">
                                                <span className="abbr-code">
                                                    {wordSplit(
                                                        registry.registryCode
                                                    )}
                                                </span>
                                            </div>
                                        </td>
                                        <td data-title="Registry Name">
                                            <div className="aui-td">
                                                <Link
                                                    to={{
                                                        pathname: `${actionURL}/${registry.id}`,
                                                        state: {
                                                            bT: tab,
                                                            bP: pageNumber,
                                                            registryName:
                                                                registry.registryName,
                                                        },
                                                    }}
                                                    className="btn-text-link-uline"
                                                >
                                                    {registry.registryName}
                                                </Link>
                                            </div>
                                        </td>
                                        <td data-title="Email">
                                            <div className="aui-td">
                                                {tab === CONSTANTS.ACTIVE
                                                    ? registry.registeredEmailId
                                                    : registry.primaryContactEmailAddress}
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
                <div className="table-emptystate-outer">
                    {registries.length < 1 && <div>No data to display</div>}
                </div>
            </div>
        </RegistryTableWrapper>
    );
};

export default RegistryTable;
