import React, { useEffect, useState } from "react";
import HospitalBanner from "pages/Admin/HospitalBanner/HospitalBanner";
import Navigator from "components/Navigator";
import AgreementDetails from "pages/Admin/FacilityDetails/AgreementDetails";
import { useDispatch } from "react-redux";
import { BreadCrumb } from "components/BreadCrumb";
import { useLocation } from "react-router-dom";
import CONSTANTS from "common/constants";
import { getHospitalProgramDetails } from "api/hospitalProgramsAPI";
import SettingSidebar from "../SettingSidebar/SettingSidebar";
import { AgreementDetailsWrapper } from "./styled";
import { setPageLoadingStatus } from "../../../components/Loader/loader.slice";
import { getHospitalById } from "../../../api/hospitalApi";
import { getRegisteredProgram } from "../../../api/registeredProgramAPI";
import "../styles/style.scss";

const AgreementDetailsSettings = () => {
    const dispatch = useDispatch();
    const [hospitalData, setHospitalData] = useState<any>("");
    const [programName, setProgramName] = useState<any>("");
    const [breadcrumbItems, setBreadcrumbItems] = useState<any>([]);
    const [categoryId, setCategoryId] = useState<any>();
    const [ahaEmsId, setAhaEmsId] = useState<any>();
    const hospitalId: any = localStorage.getItem("selectedHospitalId");
    const programId: any = localStorage.getItem("selectedProgramId");
    const location = useLocation();

    useEffect(() => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        getHospitalById(Number(hospitalId))
            .then((hospitalInfo: any) => {
                if (
                    hospitalInfo.success == true &&
                    hospitalInfo.data &&
                    hospitalInfo.data.hospital
                ) {
                    setHospitalData(hospitalInfo.data.hospital);
                    getHospitalProgramDetails(programId, hospitalId).then(
                        (result) => {
                            if (result.success == true && result.data) {
                                setAhaEmsId(result.data.emsId);
                            }
                        }
                    );
                    getRegisteredProgram(hospitalId)
                        .then((response: any) => {
                            if (response.success == true) {
                                dispatch(
                                    setPageLoadingStatus({
                                        isPageLoading: false,
                                    })
                                );
                                let program = "";
                                let programID = "";
                                let categoryID = "";
                                for (
                                    let index = 0;
                                    index < response.data.hospitals.length;
                                    index++
                                ) {
                                    program +=
                                        response.data.hospitals[index]
                                            .categoryName;
                                    programID +=
                                        response.data.hospitals[index]
                                            .programId;
                                    categoryID +=
                                        response.data.hospitals[index]
                                            .categoryId;
                                }
                                setProgramName(program);
                                setCategoryId(Number(categoryID));
                            }
                        })
                        .catch(() => {
                            dispatch(
                                setPageLoadingStatus({ isPageLoading: false })
                            );
                        });
                }
            })
            .catch(() => {
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
            });
    }, [hospitalId]);
    useEffect(() => {
        const elementsArray = [];
        if (programId !== CONSTANTS.EMS_PROGRAM_ID) {
            elementsArray.push(
                {
                    label: "Home",
                    returnPath: {
                        pathname: CONSTANTS.ROUTES.USER_DASHBOARD,
                        state: {
                            pageNum: location?.state?.pageNum,
                            search: location?.state?.search,
                        },
                    },
                },
                {
                    label: hospitalData.facilityName,
                    returnPath: {
                        pathname: "/dashboard",
                        state: {
                            pageNum: location?.state?.pageNum,
                            search: location?.state?.search,
                        },
                    },
                },
                {
                    label: "Organization Settings",
                }
            );
        } else {
            elementsArray.push(
                {
                    label: "Home",
                    returnPath: {
                        pathname: CONSTANTS.ROUTES.USER_DASHBOARD,
                        state: {
                            pageNum: location?.state?.pageNum,
                            search: location?.state?.search,
                        },
                    },
                },
                {
                    label: hospitalData.facilityName,
                    returnPath: {
                        pathname: `/organizationDetails/${hospitalId}/programs/${programId}/categories/${categoryId}`,
                        state: {
                            activateMeasuresTab: true,
                            pageNum: location?.state?.pageNum,
                            search: location?.state?.search,
                        },
                    },
                },
                {
                    label: "Organization Settings",
                }
            );
        }
        setBreadcrumbItems(elementsArray);
    }, [hospitalData.facilityName, categoryId]);

    return (
        <AgreementDetailsWrapper className="setting-page">
            <Navigator tabName="Settings" />
            <div className="container">
                <BreadCrumb breadcrumbItems={breadcrumbItems} />
                <h1 className="h3 mb-4">Organization Settings</h1>
                <HospitalBanner
                    hospitalName={hospitalData.facilityName}
                    programName={programName}
                    countryName={hospitalData.countryName}
                    ahaEmsId={ahaEmsId}
                    programId={programId}
                />
                <div className="d-lg-flex">
                    <SettingSidebar
                        activeTabItem="agreementDetails"
                        hospitalId={hospitalId}
                        programId={programId}
                        categoryId={categoryId}
                    />
                    <div className="setting-container mb-5">
                        <AgreementDetails
                            hospital={hospitalData}
                            setActiveTab={undefined}
                            activeTab={undefined}
                            close={undefined}
                            isEUP={Boolean(true)}
                        />
                    </div>
                </div>
            </div>
        </AgreementDetailsWrapper>
    );
};

export default AgreementDetailsSettings;
