import CONSTANTS from "common/constants";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { logger } from "utils/logger.utils";
import { BreadCrumb } from "components/BreadCrumb";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { useDispatch } from "react-redux";
import { getHospitalById } from "api/hospitalApi";
import { getHospitalProgramDetails } from "api/hospitalProgramsAPI";
import FacilityDetailsBanner from "pages/Admin/HospitalBanner/FacilityDetailsBanner";
import { dismissModal, parseJwt, showModal } from "common/utils";
import UserManagement from "components/UserManagement";
import { getUserList } from "api/usersApi";
import {
    addUser,
    deleteUser,
    getUserLogsFromUserManagement,
} from "api/userManagementAPI";
import { showToast } from "components/Toast/toast.slice";
import { IPagination } from "components/Pagination";
import CorporationTabs from "../CorporationMenu/CorporationMenu";
import { CorpUserManagmentWrapper } from "./styled";

const CorporationUserManagement = () => {
    const [breadcrumbItems, setBreadcrumbItems] = useState<any>([]);
    const [hospital, setHospital] = useState<any>({});
    const [programData, setProgramData] = useState<any>();
    const [addUserInd, setAddUserInd] = useState<boolean>(false);
    const [deleteUserInd, setDeleteUserInd] = useState<boolean>(false);
    const [lastEditedData, setLastEditedData] = useState<any>(null);
    const location = useLocation();
    const dispatch = useDispatch();
    const hospitalId = location?.state?.hospitalId || null;
    const programId = location?.state?.programId || null;
    const userAccessToken = window.localStorage.getItem("userAccessToken");
    const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
    const [mounted, setMounted] = useState<boolean>(false);
    const [users, setUsers] = useState<any>("");
    const [inputUser, setInputUser] = useState<any>("");
    const [userEmail, setUserEmail] = useState<any>("");
    const [isUsersResponseAvailable, setIsUsersResponseAvailable] =
        useState<boolean>(false);
    const [roleValue, setRoleValue] = useState<string>("none");
    const [pageNumber, setPageNumber] = useState<any>(1);
    const paginationSetLimit: number = 5;
    const defaultPagination: IPagination = {
        pageNumber: 1,
        pageSize: 25,
        isFirst: true,
        isLast: false,
        setPageNumber,
        paginationSetLimit,
    };
    const [pagination, setPagination] = useState<any>(defaultPagination);
    const [toggleState, settoggleState] = useState(false);
    const [deleteUserId, setDeleteUserId] = useState<any>("");
    const [disabledInd, setDisabledInd] = useState<boolean>(false);
    const [addUserActivateInd, setAddUserActivateInd] =
        useState<boolean>(false);
    const [deleteUserActivateInd, setDeleteUserActivateInd] =
        useState<boolean>(false);
    const [validateDisabled, setValidateDisabled] = useState<boolean>(false);
    const isCorporation =
        userAccessToken &&
        parseJwt(userAccessToken).role_code ===
            CONSTANTS.USER_ROLES.CORPORATION_ADMIN;
    const [userLogs, setUserLogs] = useState<any>([]);
    const [userName, setUserName] = useState<any>("");
    const getFacilityDetails = () => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        Promise.all([
            getHospitalById(hospitalId),
            getHospitalProgramDetails(programId, hospitalId),
        ])
            .then((response: any) => {
                if (response[0].success && response[1].success) {
                    setHospital(response[0].data.hospital);
                    modifyLastEditedData(response[0].data.hospital);
                    setProgramData(response[1].data);
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                }
            })
            .catch(() => {
                dispatch(setPageLoadingStatus({ isPageLoading: true }));
            });
    };
    const modifyLastEditedData = (currentData: any) => {
        if (currentData?.updatedBy?.firstName && currentData?.updatedAt) {
            setLastEditedData(currentData);
        }
    };
    const addUserDismissModal = () => {
        setAddUserActivateInd(false);
        dismissModal();
    };

    const deleteUserDismissModal = () => {
        setDeleteUserActivateInd(false);
        dismissModal();
    };
    const fetchAllUser = () => {
        setIsTableLoading(true);
        setIsUsersResponseAvailable(false);
        getUserList(
            hospitalId,
            1,
            5,
            false,
            "",
            false,
            false,
            false,
            isCorporation
        )
            .then((response: any) => {
                setUsers(response.data.users);
                setIsUsersResponseAvailable(true);
                setIsTableLoading(false);
                if (mounted) {
                    setRoleValue("alert");
                }
                setMounted(true);
                setAddUserInd(false);
            })
            .catch((err) => {
                logger(err);
                setIsUsersResponseAvailable(true);
                setIsTableLoading(false);
                setAddUserInd(false);
            });
    };
    const addUserDetails = async (event: any) => {
        event.preventDefault();
        setValidateDisabled(true);
        setAddUserInd(true);
        try {
            const userResult = await addUser(
                hospitalId,
                userEmail,
                setValidateDisabled,
                true,
                false,
                false
            );

            if (userResult.success) {
                const toast = {
                    message: `User ${userEmail} successfully added.`,
                    code: "Success:",
                    type: "success",
                };
                dispatch(showToast(toast));
                addUserDismissModal();
                fetchAllUser();
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
            }
        } catch (error: any) {
            setValidateDisabled(false);
        }
    };

    const deleteUserData = async () => {
        setDisabledInd(true);
        setDeleteUserInd(true);
        try {
            const userResult = await deleteUser(
                hospitalId,
                deleteUserId,
                null,
                false,
                false,
                null,
                true,
                null
            );
            setDisabledInd(false);
            if (userResult.success) {
                setIsTableLoading(true);
                const toast = {
                    message: `User ${userEmail} has been removed.`,
                    code: "Success:",
                    type: "success",
                };
                fetchAllUser();
                dispatch(showToast(toast));
                deleteUserDismissModal();
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
            }
        } catch (error: any) {
            setDisabledInd(false);
            logger("Error occurred while deleting user:", error);
            deleteUserDismissModal();
            fetchAllUser();
        }
    };
    const getReturnPath = () => {
        switch (location?.state?.tab) {
            case CONSTANTS.PENDING:
                return "/corporation/pending-approval";
            case CONSTANTS.REGISTRED:
                return "/corporation/registered-organization";
            case CONSTANTS.CERTIFIED:
                return "/corporation/certified-organization";
            case CONSTANTS.RENEWING:
                return "/corporation/renewed-organizations";
            case CONSTANTS.DEACTIVATED:
                return "/corporation/deactivated-organization";
            case CONSTANTS.BULKDOCUMENT:
                return "/corporation/bulk-document-submission";
            default:
                return "";
        }
    };
    const getReturnLabel = () => {
        switch (location?.state?.tab) {
            case CONSTANTS.PENDING:
                return "Pending Approval";
            case CONSTANTS.REGISTRED:
                return "Registered Organizations";
            case CONSTANTS.CERTIFIED:
                return "Certified Organizations";
            case CONSTANTS.RENEWING:
                return "Renewing Organizations";
            case CONSTANTS.DEACTIVATED:
                return "Deactivated Organizations";
            case CONSTANTS.BULKDOCUMENT:
                return "Document Submission";
            default:
                return "";
        }
    };
    useEffect(() => {
        const elementsArray = [];
        const retrurnFacility = {
            pathname: location?.state?.backToFacility,
            state: {
                tab: location?.state?.tab,
                facilityName: location?.state?.facilityName,
            },
        };
        if (location?.state?.tab) {
            elementsArray.push(
                {
                    label: getReturnLabel(),
                    returnPath: getReturnPath(),
                },
                {
                    label: location.state.facilityName,
                    returnPath: retrurnFacility,
                },
                {
                    label: CONSTANTS.USER_MANAGEMENT,
                }
            );
        }
        setBreadcrumbItems(elementsArray);
        getFacilityDetails();
    }, []);
    useEffect(() => {
        fetchAllUser();
    }, []);

    useEffect(() => {
        if (hospitalId) {
            setIsTableLoading(true);
            setIsUsersResponseAvailable(false);
            setPagination(defaultPagination);
            setPageNumber(!toggleState ? 1 : pageNumber);
            getUserLogsFromUserManagement(
                hospitalId,
                pageNumber,
                pagination.pageSize
            )
                .then((response: any) => {
                    if (response.success) {
                        if (response.data._pagination) {
                            setPagination({
                                ...response.data._pagination,
                                setPageNumber,
                            });
                        }
                        setUserLogs(response.data.logsList);
                        setIsUsersResponseAvailable(true);
                    }
                    setIsTableLoading(false);
                })
                .catch((err: any) => {
                    logger(err);
                    setIsUsersResponseAvailable(true);
                    setIsTableLoading(false);
                    setAddUserInd(false);
                    setDeleteUserInd(false);
                });
        }
    }, [pageNumber, toggleState]);

    const getActiveTab = () => {
        switch (location?.state?.tab) {
            case CONSTANTS.PENDING:
                return "corporationPendingFacilties";
            case CONSTANTS.REGISTRED:
                return "corporationFaciltyRegistered";
            case CONSTANTS.CERTIFIED:
                return "corporationFaciltyCertified";
            case CONSTANTS.DEACTIVATED:
                return "corporationDeactivatedFacilties";
            default:
                return "";
        }
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pageNumber]);
    return (
        <CorpUserManagmentWrapper className="corporation-container">
            <CorporationTabs activeTab={getActiveTab()} />
            <div className="container pt-lg-3 pb-5">
                <div className="row no-gutters w-100 flex-grow-1">
                    <section className="d-flex ">
                        <div className="row no-gutters w-100 ">
                            <BreadCrumb breadcrumbItems={breadcrumbItems} />
                        </div>
                    </section>
                    <div className="col-12">
                        <div className="d-md-flex justify-content-between dashboard-fac-magnt align-items-center">
                            <h1 className="h2 font-400 mb-3">
                                Organization Management
                            </h1>
                        </div>
                        <FacilityDetailsBanner
                            hospital={hospital}
                            programData={programData}
                            lastEditedData={lastEditedData}
                            close={close}
                            isCorporation={isCorporation}
                        />
                        <UserManagement
                            users={users}
                            isTableLoading={isTableLoading}
                            inputUser={inputUser}
                            setInputUser={setInputUser}
                            isUsersResponseAvailable={isUsersResponseAvailable}
                            roleValue={roleValue}
                            pagination={pagination}
                            addUserDetails={addUserDetails}
                            setUserEmail={setUserEmail}
                            userEmail={userEmail}
                            setDeleteUserId={setDeleteUserId}
                            deleteUserData={deleteUserData}
                            disabledInd={disabledInd}
                            setAddUserActivateInd={setAddUserActivateInd}
                            addUserActivateInd={addUserActivateInd}
                            setDeleteUserActivateInd={setDeleteUserActivateInd}
                            deleteUserActivateInd={deleteUserActivateInd}
                            addUserDismissModal={addUserDismissModal}
                            deleteUserDismissModal={deleteUserDismissModal}
                            userLogs={userLogs}
                            settoggleState={settoggleState}
                            isCorporation={isCorporation}
                            userName={userName}
                            setUserName={setUserName}
                            logsPagination={pagination}
                        />
                    </div>
                </div>
            </div>
        </CorpUserManagmentWrapper>
    );
};

export default CorporationUserManagement;
