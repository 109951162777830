import React, { useEffect, useState } from "react";
import store from "app/store";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { getHospitalProgramDetails } from "api/hospitalProgramsAPI";
import { useLocation, useParams, useHistory } from "react-router-dom";
import DocumentServiceAPI from "api/documentsAPI";
import { BreadCrumb } from "components/BreadCrumb";
import { DocumentSubmissionWrapper } from "../../styled/documetSubmissionStyled";
import DocumentView from "../../components/DocumentSubmission/DocumentViewAndDownload";
import DocumentResultTable from "../../components/DocumentSubmission/DocumentResultTable";
import { logger } from "../../utils/logger.utils";
import Navigator from "../../components/Navigator";
import { IUploadDocForm } from "../../components/DocumentSubmission/UploadDocumentModalContent";
import "styles/browser-customstyle.scss";
import CONSTANTS from "../../common/constants";
import { getHospitalById } from "../../api/hospitalApi";
import { parseJwt, redirectionForErrorCode } from "../../common/utils";

const documentServiceAPI = new DocumentServiceAPI();

interface Props {
    isCorporation?: any;
}

const DocumentSubmissionPage = ({ isCorporation }: Props) => {
    const params: any = useParams();
    const location = useLocation();
    const hospitalId: any = isCorporation
        ? params.hospitalId
        : localStorage.getItem("selectedHospitalId");
    const userAccessToken = window.localStorage.getItem("userAccessToken");
    const isEUP =
        userAccessToken &&
        parseJwt(userAccessToken).role_code ===
            CONSTANTS.USER_ROLES.HOSPITAL_ADMIN;
    // RefeshTable
    const [refreshTableDateNow, setRefreshTableDateNow] = useState<number>(0);
    // Upload Document - Dependecies
    const [uploadProgram, setUploadProgram] = useState<any>({
        programId: undefined,
        programCategoryId: undefined,
        programCategoryName: "",
        subscriptionStartDate: "",
        subscriptionEndDate: "",
    });
    const [uploadStandards, setUploadStandards] = useState<any>([]);

    // View Dcoument
    const [documentDetails, setDocumentDetails] = useState<any>([]);
    const [selectedProgram, setSelectedProgram] = useState<string>("");
    const [breadcrumbItems, setBreadcrumbItems] = useState<any>([]);
    const [document, setDocument] = useState<any>({});
    const [documentViewActivateInd, setDocumentViewActivateInd] =
        useState<boolean>(false);
    const [uploadedDocumentInd, setUploadedDocumentInd] =
        useState<boolean>(false);
    const history = useHistory();

    const initUploadDocForm: IUploadDocForm = {
        title: "",
        programId: "",
        programName: "",
        uploadStandard: "###", // ### is sperating the standardId & SandardName
        uploadDescription: "",
        file: null,
        facilityMessage: "",
        fileUploadError: false,
        setFileUploadSuccess: false,
    };
    const [uploadDocForm, setUploadDocForm] =
        useState<IUploadDocForm>(initUploadDocForm);

    useEffect(() => {
        if (isEUP) {
            const elementsArray: any = [];
            getHospitalById(hospitalId).then((response: any) => {
                if (response.success) {
                    elementsArray.push(
                        {
                            label: "Home",
                            returnPath: {
                                pathname: CONSTANTS.ROUTES.USER_DASHBOARD,
                                state: {
                                    pageNum: location?.state?.pageNum,
                                    search: location?.state?.search,
                                },
                            },
                        },
                        {
                            label: response.data?.hospital?.facilityName,
                            returnPath: {
                                pathname: "/dashboard",
                                state: {
                                    pageNum: location?.state?.pageNum,
                                    search: location?.state?.search,
                                },
                            },
                        },
                        {
                            label: "Documents",
                            returnPath: "",
                        }
                    );
                    setBreadcrumbItems(elementsArray);
                }
            });
        }
    }, []);

    const viewDocument = async (referenceId: string, programId: number) => {
        setDocument({ referenceId, programId });
        store.dispatch(setPageLoadingStatus({ isPageLoading: true }));
        Promise.all([
            documentServiceAPI.getByReferenceId(referenceId, hospitalId),
            getHospitalProgramDetails(programId, hospitalId),
        ])
            .then((responses: any) => {
                if (responses[0].success && responses[1].success) {
                    setDocumentDetails(responses[0].data.hospitalUploadDetails);
                    setSelectedProgram(responses[1].data.programCategoryName);
                }
                store.dispatch(setPageLoadingStatus({ isPageLoading: false }));
            })
            .catch((err) => {
                logger(err);
                redirectionForErrorCode(
                    CONSTANTS.ROUTES.CORPORATION_DASHBOARD,
                    err,
                    history,
                    2000,
                    true
                );
                store.dispatch(setPageLoadingStatus({ isPageLoading: false }));
            });
    };

    return (
        <DocumentSubmissionWrapper>
            {!isCorporation && <Navigator tabName="documents" />}
            <div className="container">
                {isEUP && (
                    <>
                        <div className="mb-n4">
                            <BreadCrumb breadcrumbItems={breadcrumbItems} />
                        </div>
                        <h1 className="h3 main-pg-heading">
                            Document Submission
                        </h1>
                    </>
                )}
            </div>
            <DocumentResultTable
                isEUP
                setUploadProgram={setUploadProgram}
                setUploadStandards={setUploadStandards}
                viewDocument={viewDocument}
                refreshTableDateNow={refreshTableDateNow}
                setRefreshTableDateNow={setRefreshTableDateNow}
                setDocumentViewActivateInd={setDocumentViewActivateInd}
            />
            {documentViewActivateInd ? (
                <DocumentView
                    documentDetails={documentDetails}
                    setDocumentDetails={setDocumentDetails}
                    setRefreshTableDateNow={setRefreshTableDateNow}
                    selectedProgram={selectedProgram}
                    uploadDocForm={uploadDocForm}
                    setUploadDocForm={setUploadDocForm}
                    initUploadDocForm={initUploadDocForm}
                    uploadProgram={uploadProgram}
                    standards={uploadStandards}
                    viewDocument={viewDocument}
                    document={document}
                    documentViewActivateInd={documentViewActivateInd}
                    setDocumentViewActivateInd={setDocumentViewActivateInd}
                />
            ) : null}
        </DocumentSubmissionWrapper>
    );
};

export default DocumentSubmissionPage;
