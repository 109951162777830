import React, { useEffect, useState } from "react";
import { HospitalDetailWrapper } from "./styled";
import { useLocation, useParams } from "react-router-dom";
import "../../styles/styles.scss";
import { getHospitalById } from "api/hospitalApi";
import { getHospitalProgramDetails } from "api/hospitalProgramsAPI";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { useDispatch } from "react-redux";
import { logger } from "../../../utils/logger.utils";
import Sidebar from "../Sidebar/Sidebar";
import FacilityDetails from "../FacilityDetails/FacilityDetails";
import CONSTANTS from "../../../common/constants";

export const ApproveHospital = () => {
    // REDUX variables
    const dispatch = useDispatch();

    // ROUTE VARIABLES
    const location = useLocation();
    const { hospitalId, programId }: any = useParams();

    // RFC STATE
    const [hospital, setHospital] = useState<any>({});
    const [programData, setProgramData] = useState<any>();
    const [returnPath, setReturnPath] = useState<any>(
        "/admin/hospital-management"
    );
    const corporationId = location.state?.corporationId
        ? location.state?.corporationId
        : null;
    const pageNumber = location.state?.hRP ? location.state?.hRP : null;
    const mangangementTab = location.state?.bT
        ? location.state?.bT
        : CONSTANTS.ACTIVE;
    const mangangementPage = location.state?.bP ? location.state?.bP : 1;
    const corporationName = location.state?.corporationName
        ? location.state?.corporationName
        : null;
    const facilityName = location.state?.facilityName
        ? location.state?.facilityName
        : null;

    useEffect(() => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        Promise.all([
            getHospitalById(hospitalId),
            getHospitalProgramDetails(programId, hospitalId),
        ])
            .then((response: any) => {
                if (response[0].success && response[1].success) {
                    setHospital(response[0].data.hospital);
                    setProgramData(response[1].data);
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                }
            })
            .catch((err) => {
                dispatch(setPageLoadingStatus({ isPageLoading: true }));
                logger(err);
            });
        let pathToReturn: any;
        if (corporationId) {
            pathToReturn = {
                corporationId,
                pathname: `/corporation/corporation-management/corporation-details/${corporationId}`,
                state: {
                    hRP: pageNumber,
                    p: pageNumber,
                    bT: mangangementTab,
                    bP: mangangementPage,
                    corporationName,
                    facilityName,
                },
            };
            setReturnPath(pathToReturn);
        }
        if (location?.state?.backToAdminDashboard) {
            setReturnPath({ pathname: location?.state?.backToAdminDashboard });
        }
        if (location?.state?.backToFaciclityManagement) {
            pathToReturn = {
                pathname: location?.state?.pathname
                    ? location?.state?.pathname
                    : location?.state?.backToFaciclityManagement,
                state: {
                    adminfacilityMangemnetTab:
                        location?.state?.adminfacilityMangemnetTab,
                    adminfacilityMangemnetPage:
                        location?.state?.adminfacilityMangemnetPage,
                    pageSort: location?.state?.pageSort,
                },
            };
            setReturnPath(pathToReturn);
        }
    }, []);

    return (
        <HospitalDetailWrapper className="container-fluid p-0 d-flex flex-grow-1">
            <div className="row no-gutters w-100 flex-grow-1">
                <Sidebar activeTab="hospitalManagement" />

                <section className="d-flex admin-main-content container select-text">
                    <div className="row no-gutters w-100 admin-main">
                        <FacilityDetails
                            hospital={hospital}
                            returnPath={returnPath}
                            programData={programData}
                        />
                    </div>
                </section>
            </div>
        </HospitalDetailWrapper>
    );
};

export default ApproveHospital;
