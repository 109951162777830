import React, { useEffect, useState } from "react";
import Pagination, { IPagination } from "components/Pagination";
import { getAwardLogsDetails } from "api/emsAPI";
import { logger } from "utils/logger.utils";
import TableRowCount from "components/TableRowCount";
import CONSTANTS from "common/constants";
import {
    dateToLocaleTimeString,
    localDateToYYYYMMDDFormatString,
    parseJwt,
} from "common/utils";
import { AwardLogsViewWrapper } from "./styled";

interface Props {
    hospitalId: any;
    awardYear: any;
}

export const OrganizationAwardLogsView = (props: Props) => {
    const [pageNumber, setPageNumber] = useState<number>(1);
    const paginationSetLimit: number = 25;
    const defaultPagination: IPagination = {
        pageNumber: 1,
        pageSize: 25,
        isFirst: true,
        isLast: false,
        setPageNumber,
        paginationSetLimit,
    };
    const [pagination, setPagination] =
        useState<IPagination>(defaultPagination);
    const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
    const [isUsersResponseAvailable, setIsUsersResponseAvailable] =
        useState<boolean>(false);
    const [awardLogs, setAwardLogs] = useState<any>([]);
    const userAccessToken = window.localStorage.getItem("userAccessToken");
    const roleCode = userAccessToken && parseJwt(userAccessToken).role_code;
    useEffect(() => {
        if (props?.hospitalId) {
            setIsTableLoading(true);
            setIsUsersResponseAvailable(false);
            setPagination(defaultPagination);
            new Promise(async (resolve, reject) => {
                const response = await getAwardLogsDetails(
                    props?.hospitalId,
                    props?.awardYear,
                    pageNumber,
                    pagination.pageSize ? pagination.pageSize : 25
                );
                resolve(response);
            })
                .then((response: any) => {
                    if (response.success) {
                        if (response.data?.awardLogs) {
                            setAwardLogs(response.data?.awardLogs);
                            setPagination({
                                ...response.data._pagination,
                                setPageNumber,
                            });
                        } else {
                            setAwardLogs([]);
                        }
                    }
                    setIsUsersResponseAvailable(true);
                    setIsTableLoading(false);
                })
                .catch((err) => {
                    logger(err);
                    setIsUsersResponseAvailable(true);
                    setIsTableLoading(false);
                });
        }
    }, [pageNumber, props?.awardYear]);
    return (
        <AwardLogsViewWrapper>
            <div className="user-logs-mgt">
                {isTableLoading ? (
                    <div
                        role="alert"
                        aria-live="assertive"
                        aria-label="View log table is loading"
                        className="sr-only"
                    />
                ) : (
                    ""
                )}
                {pagination && (
                    <TableRowCount
                        isFirst={pagination.isFirst}
                        pageNumber={pagination.pageNumber}
                        pageSize={pagination.pageSize}
                        isLast={pagination.isLast}
                        totalCount={pagination.totalCount}
                        logs
                    />
                )}
                <div className="award-logs-table mb-5 mt-3 w-100">
                    <table
                        className={`aui-responsive-status-table ${
                            isTableLoading ? CONSTANTS.TABLE_SPINNER : ""
                        }`}
                    >
                        <thead>
                            <tr>
                                <th scope="col">
                                    <div className="aui-th">Award Updated</div>
                                </th>
                                <th scope="col">
                                    <div className="aui-th">Comments</div>
                                </th>
                                <th scope="col">
                                    <div className="aui-th">Date & Time</div>
                                </th>
                                <th scope="col">
                                    <div className="aui-th">Executed By</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {awardLogs?.length > 0 &&
                                awardLogs?.map(
                                    (awardlog: any, index: number) => {
                                        return (
                                            <tr className="aui-table-status-normal">
                                                <td data-title="Award Updated">
                                                    <div className="aui-td">
                                                        <div>
                                                            <div className="mt-1 font-500">
                                                                <span>
                                                                    {
                                                                        awardlog.previousAward
                                                                    }
                                                                </span>
                                                                <span>
                                                                    {" "}
                                                                    <i
                                                                        className="aha-icon-dash-arrow-right corp-sort-down arrow-size mx-2"
                                                                        aria-hidden="true"
                                                                    />
                                                                </span>
                                                                <span>
                                                                    {
                                                                        awardlog.newAward
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td data-title="Comments">
                                                    <div className="aui-td ">
                                                        <span>
                                                            {awardlog.comments}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td data-title="Date & Time">
                                                    <div className="aui-td">
                                                        {localDateToYYYYMMDDFormatString(
                                                            new Date(
                                                                awardlog.changeDate
                                                            )
                                                        )}{" "}
                                                        |{" "}
                                                        {dateToLocaleTimeString(
                                                            awardlog.changeDate
                                                        )}
                                                    </div>
                                                </td>
                                                <td data-title="Executed By">
                                                    <div className="aui-td">
                                                        <div>
                                                            <div className="mt-1 font-500">
                                                                {
                                                                    awardlog.userFirstName
                                                                }{" "}
                                                                {
                                                                    awardlog.userLastName
                                                                }
                                                            </div>
                                                            <div className="">
                                                                {roleCode
                                                                    .replace(
                                                                        "_",
                                                                        " "
                                                                    )
                                                                    .replace(
                                                                        /\b\w+$/,
                                                                        (
                                                                            w: any
                                                                        ) =>
                                                                            w.charAt(
                                                                                0
                                                                            ) +
                                                                            w
                                                                                .slice(
                                                                                    1
                                                                                )
                                                                                .toLowerCase()
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    }
                                )}
                        </tbody>
                    </table>
                    <div
                        role="alert"
                        aria-atomic="true"
                        className="table-emptystate-outer"
                    >
                        {awardLogs && awardLogs?.length === 0 && (
                            <div>No data to display</div>
                        )}
                    </div>
                </div>
                {pagination && Number(pagination.totalPages) > 0 ? (
                    <Pagination
                        pageNumber={pagination.pageNumber}
                        pageSize={pagination.pageSize}
                        totalCount={pagination.totalCount}
                        totalPages={pagination.totalPages}
                        isFirst={pagination.isFirst}
                        isLast={pagination.isLast}
                        setPageNumber={pagination.setPageNumber}
                        paginationSetLimit={5}
                    />
                ) : null}
            </div>
        </AwardLogsViewWrapper>
    );
};

export default OrganizationAwardLogsView;
