import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { ProgramCard } from "./styled";

declare global {
    interface Window {
        userAccessToken: any;
        selectedCountry: any;
    }
}

const Card = (props: any) => {
    const titleRef = useRef<HTMLButtonElement | null>(null);
    const handleKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
        if (e.key === "ArrowDown" && props?.isExpanded === false) {
            e.preventDefault();
            if (props.onToggle) {
                props.onToggle();
            }
        } else if (e.key === "ArrowUp" && props?.isExpanded) {
            e.preventDefault();
            if (props.onToggle) {
                props.onToggle();
            }
        }
    };
    return (
        <ProgramCard className="program-accordion-card">
            <div id="programAccordion">
                <div className="card  p-0">
                    <div
                        className="card-header d-flex align-items-start justify-content-between card-program p-0 m-0"
                        onClick={props?.onToggle}
                        id={`tabTitle-${props.id}`}
                    >
                        <h3
                            className="h5 flex-grow-1 m-0"
                            id={`program-${props.id}`}
                        >
                            <button
                                ref={titleRef}
                                className="btn  text-left w-100 d-flex justify-content-between accordion-header align-items-center font-600"
                                type="button"
                                aria-expanded={
                                    props?.isExpanded ? "true" : "false"
                                }
                                aria-controls={`programdesc-${props.id}`}
                                onKeyDown={handleKeyDown}
                            >
                                <i
                                    className="aha-icon-heart-thin mt-0 mr-3 custom-icon-position"
                                    aria-hidden="true"
                                />
                                <span className="flex-grow-1 program-title pr-3">
                                    {props.title}
                                </span>
                                <i
                                    className={`aha-icon-down-arrow-thin ${
                                        props?.isExpanded ? "arrow-down" : ""
                                    }`}
                                />
                            </button>
                        </h3>
                    </div>
                    {props?.isExpanded && (
                        <div
                            className="program-description"
                            aria-labelledby={`program-${props.id}`}
                            data-parent="#programList"
                            id={`programdesc-${props.id}`}
                        >
                            <div className=" flex-grow-1">
                                <p className="mb-0">{props.description}</p>
                            </div>
                            <div className="d-flex align-items-center justify-content-end typecard-footer">
                                <Link
                                    to={`/program/register/${props.id}`}
                                    className="btn btn-round btn-sm btn-primary"
                                    aria-label={`Register for ${props.title}`}
                                >
                                    Register
                                </Link>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </ProgramCard>
    );
};

export default Card;
