import { logger } from "utils/logger.utils";

export interface IdbData {
    key: string;
    value: string;
}

function openDB(): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open("iDatabase", 4);
        request.onupgradeneeded = (event) => {
            const db = (event.target as IDBOpenDBRequest).result;
            if (!db.objectStoreNames.contains("idbStore")) {
                db.createObjectStore("idbStore", { keyPath: "key" });
            }
        };
        request.onsuccess = (event) => {
            const db = (event.target as IDBOpenDBRequest).result;
            resolve(db);
        };
        request.onerror = (event) => {
            reject(event);
        };
    });
}

export function addDataToDB(data: IdbData): Promise<void> {
    return new Promise((resolve, reject) => {
        openDB()
            .then((db) => {
                const transaction = db.transaction(["idbStore"], "readwrite");
                const objectStore = transaction.objectStore("idbStore");
                const request = objectStore.put(data);

                request.onsuccess = () => {
                    resolve();
                };

                request.onerror = (event) => {
                    reject(new Error("Error adding data"));
                };
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function getFromIdb(key: string): Promise<IdbData | undefined> {
    return new Promise((resolve, reject) => {
        openDB()
            .then((db) => {
                const transaction = db.transaction(["idbStore"], "readonly");
                const objectStore = transaction.objectStore("idbStore");
                const request = objectStore.get(key);

                request.onsuccess = (event) => {
                    const data = (event.target as IDBRequest).result as IdbData;
                    resolve(data);
                };

                request.onerror = (event) => {
                    reject(new Error("Error getting data"));
                };
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function delFromIdb(key: string): Promise<IdbData | undefined> {
    return new Promise((resolve, reject) => {
        openDB()
            .then((db) => {
                const transaction = db.transaction(["idbStore"], "readwrite");
                const objectStore = transaction.objectStore("idbStore");
                const deleteRequest = objectStore.delete(key);

                deleteRequest.onsuccess = function () {
                    logger("Data deleted successfully");
                };

                deleteRequest.onerror = function (event) {
                    logger("Error deleting data:");
                };
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function addToIdb(dataToAdd: any) {
    addDataToDB(dataToAdd)
        .then(() => {
            logger("Data added successfully!");
        })
        .catch((error) => {
            logger("Error adding data:", error);
        });
}
