import CONSTANTS from "common/constants";
import { sleep } from "common/utils";
import React, { createRef, useEffect, useState } from "react";
import "styles/custom-search-select.scss";

interface Props {
    items: any;
    selectedValue: any;
    callParentOnSelect: any;
    id: string;
    disabled?: boolean;
    isLoading?: boolean;
    label: any;
    mandatoryInd?: boolean;
    placeholder?: string;
    ariaInnerLabelValue?: any;
    ariaInnerLabel?: any;
    fixedBtn?: boolean;
    handleModal?: any;
    tab?: string;
}

const SearchDropdown = (props: Props) => {
    const {
        items,
        selectedValue,
        callParentOnSelect,
        id,
        disabled,
        isLoading,
        label,
        mandatoryInd,
        placeholder,
        ariaInnerLabel,
        ariaInnerLabelValue,
        fixedBtn,
        handleModal,
        tab,
    } = props;
    const [showItems, setShowItems] = useState<boolean>(false);
    const [showResultsInd, setShowResultsInd] = useState<boolean>(false);
    const [focusOptionIndex, setFocusOptionIndex] = useState<number>(0);
    const [searchedKey, setSearchedKey] = useState<string>(
        tab === CONSTANTS.PENDING
            ? "--"
            : selectedValue === CONSTANTS.ALL ||
              selectedValue === CONSTANTS.ALL_VALUE
            ? "All"
            : selectedValue
    );
    const [selectedItemName, setSelectedItemName] = useState<any>("");
    const [filteredItems, setFilteredItems] = useState<any>(items);
    const refsArray = filteredItems.map(() => createRef());
    const dropDownRef = createRef<HTMLInputElement>();
    const clearSearchRef = createRef<HTMLButtonElement>();
    const createAhaIDBtn = createRef<HTMLButtonElement>();

    const updateSelectedValue = (value: any) => {
        if (value) {
            if (items && items.length > 0) {
                items.forEach((element: any, index: number) => {
                    if (element.value === value) {
                        setSelectedItemName(element.label);
                        setFocusOptionIndex(index);
                    }
                });
            }
        }
    };

    useEffect(() => {
        setFocusOptionIndex(0);
        updateSelectedValue(selectedValue);
        const handleEvent = (e: any) => {
            if (
                !document
                    ?.getElementById(`dropdown-wrapper-${id}`)
                    ?.contains(document.getElementById(e.target.id))
            ) {
                resetOnCancel(); // Clicked outside the box
            }
        };
        document.addEventListener("click", handleEvent);
        setFilteredItems(items);
        setSearchedKey(
            tab === CONSTANTS.PENDING
                ? "--"
                : selectedValue === CONSTANTS.ALL ||
                  selectedValue === CONSTANTS.ALL_VALUE
                ? "All"
                : selectedItemName
        );
        return () => {
            document.removeEventListener("click", handleEvent);
        };
    }, [items, selectedValue, id, selectedItemName]);

    const resetOnCancel = () => {
        setShowItems(false);
        setFilteredItems(items);
        setSearchedKey(
            tab === CONSTANTS.PENDING
                ? "--"
                : selectedValue === CONSTANTS.ALL ||
                  selectedValue === CONSTANTS.ALL_VALUE
                ? "All"
                : selectedItemName
        );
        updateSelectedValue(selectedValue);
    };
    const inputId = `${id}`;
    const buttonId = `${id}-button`;
    const toggleDropDown = () => {
        if (disabled) return;
        if (showItems) {
            setSearchedKey(
                tab === CONSTANTS.PENDING
                    ? "--"
                    : selectedValue === CONSTANTS.ALL ||
                      selectedValue === CONSTANTS.ALL_VALUE
                    ? "All"
                    : selectedItemName
            );
        } else {
            setSearchedKey("");
        }
        setShowItems(!showItems);
        setFilteredItems(items);
    };

    const selectOption = (event: any, value: any) => {
        if (!value) {
            value = event.target?.innerText;
        }
        items.forEach((item: any, listIndex: number) => {
            if (item.value === value || item.label.trim() == value) {
                setFocusOptionIndex(listIndex);
                dropDownRef.current?.focus();
                value = item.value;
            }
        });
        setSearchedKey(
            tab === CONSTANTS.PENDING
                ? "--"
                : value === CONSTANTS.ALL || value === CONSTANTS.ALL_VALUE
                ? "All"
                : selectedItemName
        );
        callParentOnSelect(value);
        updateSelectedValue(value); // To discplay in component
        setShowItems(false);
    };

    const upAndDownOption = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.keyCode === 27 || event.keyCode === 9) {
            // On Escape Toggle Dropdown
            if (navigator.userAgent.toLowerCase().indexOf("firefox") === -1) {
                dropDownRef &&
                    dropDownRef.current &&
                    dropDownRef.current.focus();
            }
            resetOnCancel();
            return;
        }

        // On Key UP Arrow
        if (event.which === 38) {
            if (document?.activeElement?.id === "create-ams-id-btn") {
                dropDownRef?.current?.focus();
                setFocusOptionIndex(refsArray.length - 1);
                refsArray[refsArray.length - 1].current.focus();
            } else if (focusOptionIndex > 0) {
                const setIndex = focusOptionIndex - 1;
                setFocusOptionIndex(setIndex);
                refsArray[setIndex].current.focus();
            } else if (focusOptionIndex === 0) {
                dropDownRef &&
                    dropDownRef.current &&
                    dropDownRef.current.focus();
            }
        }
        // On Key Down Arrow
        if (
            event.which === 40 &&
            document?.activeElement?.id.endsWith("-emsId") &&
            focusOptionIndex === filteredItems.length - 1
        ) {
            createAhaIDBtn?.current?.focus();
        } else if (
            event.which === 40 &&
            focusOptionIndex < filteredItems.length - 1
        ) {
            const setIndex = focusOptionIndex + 1;
            setFocusOptionIndex(setIndex);
            refsArray[setIndex].current.focus();
        }
        // On Enter
        if (event.which === 13) {
            selectOption(event, undefined);
        }

        // On Home
        if (event.keyCode === 36) {
            setFocusOptionIndex(0);
            refsArray[0].current.focus();
        }

        // On End
        if (event.keyCode === 35) {
            if (document?.activeElement?.id.endsWith("-emsId")) {
                createAhaIDBtn?.current?.focus();
            } else {
                setFocusOptionIndex(refsArray.length - 1);
                refsArray[refsArray.length - 1].current.focus();
            }
        }
    };

    const handleKeyDown = (event: any) => {
        // On Key Down Arrow
        if (event.which === 40) {
            setFocusOptionIndex(0);
            refsArray[0].current.focus();
        }
        // On Enter
        if (event.which === 13) {
            event.preventDefault();
            if (disabled) return;
            if (!showItems) {
                setSearchedKey("");
            }
            setShowItems(true);
        }
        // On Escape
        if (event.keyCode === 27) {
            resetOnCancel();
        }
        // On Tab
        if (event.keyCode === 9) {
            if (showItems && searchedKey) {
                event.preventDefault();
                clearSearchRef?.current?.focus();
            } else {
                resetOnCancel();
            }
        }
    };

    const handleKeyDownForClear = (event: any) => {
        // On Enter
        if (event.which === 13) {
            setSearchedKey("");
            setFilteredItems(items);
            dropDownRef && dropDownRef.current && dropDownRef.current.focus();
        }
        // On Key Down Arrow
        if (event.which === 40) {
            setFocusOptionIndex(0);
            refsArray[0].current.focus();
        }
        // On Escape
        if (event.keyCode === 27 || event.keyCode === 9) {
            resetOnCancel();
        }
    };

    const handleChange = (event: any) => {
        event.target.value = event.target.value.replace(/[><]/g, "");
        setSearchedKey(event.target.value);
        let filteredItemsList;
        if (id === "emsId") {
            filteredItemsList =
                items.length > 0 &&
                items.filter(
                    (itemName: any) =>
                        itemName.label
                            .toLowerCase()
                            .indexOf(event.target.value.toLowerCase()) !== -1 ||
                        itemName.label === "Not listed"
                );
        } else {
            filteredItemsList =
                items.length > 0 &&
                items.filter(
                    (itemName: any) =>
                        itemName.label
                            .toLowerCase()
                            .indexOf(event.target.value.toLowerCase()) !== -1
                );
        }
        setFilteredItems(filteredItemsList);
        setShowResultsInd(true);
        sleep(8000).then(() => {
            setShowResultsInd(false);
        });
        setShowItems(true);
        setFocusOptionIndex(-1);
    };

    useEffect(() => {
        if (dropDownRef.current) {
            dropDownRef.current.scrollLeft = 0;
        }
    }, [dropDownRef]);

    return (
        <div>
            <div
                id={`dropdown-wrapper-${id}`}
                className={`cst-search-select ${
                    disabled ? "cst-search-select-disabled" : ""
                } ${isLoading ? "cst-search-select-loading" : ""}`}
            >
                {isLoading && (
                    <div className="sr-only" role="alert" aria-live="assertive">
                        {`${label} dropdown is loading`}
                    </div>
                )}{" "}
                {props.ariaInnerLabelValue && (
                    <span
                        id={`${id}-label`}
                        className="sr-only"
                        aria-hidden="true"
                    >
                        {ariaInnerLabelValue}
                    </span>
                )}
                <div className="cst-search-select-fld" id={`${id}-box`}>
                    <input
                        type="text"
                        ref={dropDownRef}
                        className={`cst-search-selected-item ${
                            id.split("-")[0] === "measureFilter"
                                ? "loader-space"
                                : ""
                        } ${showItems && searchedKey ? "pr-8" : ""}`}
                        id={id}
                        onClick={() => toggleDropDown()}
                        placeholder={placeholder}
                        value={searchedKey}
                        maxLength={20}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        autoComplete="off"
                        role="combobox"
                        aria-autocomplete="list"
                        aria-controls={`${id}-select-dropdown`}
                        aria-expanded={showItems}
                        data-active-option={
                            filteredItems[0]?.label
                                ? `${filteredItems[focusOptionIndex]?.label
                                      .toString()
                                      .replaceAll(" ", "")}-${id}`
                                : ``
                        }
                        required={mandatoryInd}
                    />
                    <div
                        className="cst-search-selected-item-firefox"
                        aria-hidden="true"
                    >
                        {searchedKey}
                    </div>
                    {showItems && searchedKey && (
                        <button
                            id={`clearSearch-${id}`}
                            type="button"
                            className="btn btn-text aha-icon-cross cst-search-clear"
                            aria-label="Clear Search"
                            ref={clearSearchRef}
                            onKeyDown={handleKeyDownForClear}
                            onClick={() => {
                                setSearchedKey("");
                                setFilteredItems(items);
                                dropDownRef &&
                                    dropDownRef.current &&
                                    dropDownRef.current.focus();
                            }}
                        />
                    )}
                    <button
                        className={`cst-search-arrow aha-icon-arrow-down ${
                            showItems
                                ? "cst-search-arrow-up"
                                : "cst-search-arrow-down"
                        }`}
                        tabIndex={-1}
                        id={buttonId}
                        onClick={() => toggleDropDown()}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        aria-controls={`${id}-select-dropdown`}
                        aria-expanded={showItems}
                        aria-label="dropdown"
                    />
                </div>
                <div
                    className="cst-search-select-dropdown"
                    style={{ display: showItems ? "block" : "none" }}
                >
                    <ul
                        tabIndex={-1}
                        role="listbox"
                        aria-labelledby={`${id}-label`}
                        id={`${id}-select-dropdown`}
                        aria-activedescendant={
                            filteredItems[0]?.label
                                ? `${filteredItems[focusOptionIndex]?.label
                                      .toString()
                                      .replaceAll(" ", "")}-${id}`
                                : ``
                        }
                    >
                        {filteredItems.length > 0 ? (
                            filteredItems.map((item: any, index: number) => (
                                <li
                                    key={`${item.label}-${index}`}
                                    data-index={index}
                                    id={`${item.label
                                        .toString()
                                        .replaceAll(" ", "")}-${id}`}
                                    onClick={(event) =>
                                        selectOption(event, item.value)
                                    }
                                    className="cst-search-item"
                                    aria-selected={focusOptionIndex === index}
                                    onKeyDown={upAndDownOption}
                                    ref={refsArray[index]}
                                    role="option"
                                    tabIndex={0}
                                    aria-label={
                                        item?.ariaLabel ? item.ariaLabel : ""
                                    }
                                    aria-disabled="false"
                                >
                                    {item.label}
                                </li>
                            ))
                        ) : (
                            <li
                                className="cst-search-select-emptystate"
                                role="alert"
                            >
                                No matches found
                                <span className="sr-only">
                                    for {searchedKey}
                                </span>
                            </li>
                        )}
                    </ul>
                    <div role="alert">
                        <span className="sr-only">
                            {filteredItems.length > 0 &&
                                showResultsInd &&
                                searchedKey &&
                                `${filteredItems.length} search results found
                                    for ${searchedKey}`}
                        </span>
                    </div>
                    {fixedBtn && (
                        <button
                            id="create-ams-id-btn"
                            onKeyDown={upAndDownOption}
                            ref={createAhaIDBtn}
                            type="button"
                            className="cst-search-item-btn"
                            onClick={handleModal}
                        >
                            <i className="aha-icon-add mr-1" />
                            Create New AHA EMS ID
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SearchDropdown;
