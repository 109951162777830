import React, { useEffect, useRef, useState } from "react";
import DocumentServiceAPI from "api/documentsAPI";
import store from "app/store";
import { showToast } from "components/Toast/toast.slice";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import CONSTANTS from "common/constants";
import { useParams } from "react-router-dom";
import { dismissModal, localDateToYYYYMMDDFormatString } from "common/utils";
import FocusTrap from "focus-trap-react";
import { DocumentSubmissionWrapper } from "../../styled/documetSubmissionStyled";
import { logger } from "../../utils/logger.utils";
import DocumentDetails from "./DocumentDetails";
import "styles/browser-customstyle.scss";

const documentServiceAPI = new DocumentServiceAPI();

interface Props {
    documentDetails: any;
    setDocumentDetails: any;
    setRefreshTableDateNow: any;
    selectedProgram: string;
    facilityName: string;
    viewDocument: any;
    document: any;
    documentViewActivateInd: any;
    setDocumentViewActivateInd: any;
}

const AdminDocumentView = ({
    documentDetails,
    setDocumentDetails,
    setRefreshTableDateNow,
    selectedProgram,
    facilityName,
    viewDocument,
    document,
    documentViewActivateInd,
    setDocumentViewActivateInd,
}: Props) => {
    const params: any = useParams();
    const hospitalId: any = params.hospitalId
        ? params.hospitalId
        : localStorage.getItem("selectedHospitalId");
    const closeAdminDocViewModal = useRef<HTMLButtonElement>(null);
    const closeAdminApproveRejectModal = useRef<HTMLButtonElement>(null);
    const revokeDocModal = useRef<HTMLDivElement>(null);
    const [status, setStatus] = useState<string>("");
    const [deleteInd, setDeleteInd] = useState<boolean>(false);
    const [deleteDoc, setDeleteDoc] = useState<any>();
    const [dataDismissInd, setDataDismissInd] = useState<boolean>(true);
    const modalRef = useRef<HTMLDivElement | null>(null);
    const noDeleteConfirm = useRef<HTMLButtonElement>(null);
    const deleteDocModal = useRef<HTMLDivElement>(null);
    const adminViewDocmodalInnerRef = useRef<HTMLDivElement | null>(null);

    const approveOrRejectDocument = async (event: any) => {
        event.preventDefault();
        if (documentDetails.length > 0) {
            store.dispatch(setPageLoadingStatus({ isPageLoading: true }));
            documentServiceAPI
                .approve(
                    documentDetails[0].hospitalId,
                    documentDetails[0].programId,
                    documentDetails[0].categoryId,
                    documentDetails[0].documentId,
                    status,
                    documentDetails[0].reviewComment
                        ? documentDetails[0].reviewComment
                        : ""
                )
                .then((response: any) => {
                    store.dispatch(
                        setPageLoadingStatus({ isPageLoading: false })
                    );
                    let message;
                    switch (status) {
                        case CONSTANTS.PENDING:
                            message = `${documentDetails[0].documentTitle} successfully moved back to PENDING.`;
                            break;
                        case CONSTANTS.REGISTRED:
                            message = `${documentDetails[0].documentTitle} successfully approved.`;
                            break;
                        default:
                            message = `${documentDetails[0].documentTitle} has been REJECTED.`;
                            break;
                    }
                    const toast = {
                        message,
                        code: "Success:",
                        type: "success",
                    };
                    setRefreshTableDateNow(Date.now());
                    store.dispatch(showToast(toast));
                    closeAdminApproveRejectModal &&
                        closeAdminApproveRejectModal.current &&
                        closeAdminApproveRejectModal.current.click();
                    closeAdminDocViewModal &&
                        closeAdminDocViewModal.current &&
                        closeAdminDocViewModal.current.click();
                })
                .catch((error: any) => {
                    logger("documentServiceAPI:", error);
                    store.dispatch(
                        setPageLoadingStatus({ isPageLoading: false })
                    );
                });
        }
    };

    const setReviewMessage = (event: any) => {
        if (documentDetails.length > 0) {
            documentDetails[0].reviewComment = event.target.value.replace(
                /[><]/g,
                ""
            );
            setDocumentDetails(documentDetails);
        }
    };

    const getHeaderByStatus = (documentStatus: string) => {
        if (documentStatus === "APPROVED") {
            return "Approve";
        }
        if (documentStatus === "REJECTED") {
            return "Reject";
        }
        return "";
    };

    const clearCommentsAndStatus = () => {
        setStatus("");
        if (documentDetails.length > 0) {
            documentDetails[0].reviewComment = "";
            setDocumentDetails(documentDetails);
        }
    };

    const deleteDocument = () => {
        store.dispatch(setPageLoadingStatus({ isPageLoading: true }));
        documentServiceAPI
            .delete(deleteDoc.id, hospitalId, true)
            .then((deleteResponse: any) => {
                store.dispatch(setPageLoadingStatus({ isPageLoading: false }));
                if (deleteResponse.success) {
                    const toast = {
                        message: `${deleteDoc.title} has been deleted.`,
                        code: "Success:",
                        type: "success",
                    };
                    setDeleteInd(false);
                    store.dispatch(showToast(toast));
                    if (dataDismissInd) {
                        setRefreshTableDateNow(Date.now());
                    } else {
                        viewDocument(document.referenceId, document.programId);
                    }
                    setDataDismissInd(true);
                    setDocumentViewActivateInd(false);
                    dismissModal();
                }
            })
            .catch(() => {
                store.dispatch(setPageLoadingStatus({ isPageLoading: false }));
                setDeleteInd(false);
                if (dataDismissInd) {
                    setRefreshTableDateNow(Date.now());
                } else {
                    viewDocument(document.referenceId, document.programId);
                }
                setDataDismissInd(true);
            });
    };

    const dismissUploadedDocument = () => {
        setDocumentViewActivateInd(false);
        dismissModal();
    };

    useEffect(() => {
        if (
            documentViewActivateInd &&
            status !== "" &&
            revokeDocModal.current
        ) {
            revokeDocModal.current.setAttribute("tabIndex", "-1");
            revokeDocModal.current?.focus();
        }
        if (documentViewActivateInd && deleteInd && deleteDocModal.current) {
            deleteDocModal.current.setAttribute("tabIndex", "-1");
            deleteDocModal.current?.focus();
        }
        if (documentViewActivateInd && adminViewDocmodalInnerRef.current) {
            adminViewDocmodalInnerRef.current?.setAttribute("tabIndex", "-1");
            adminViewDocmodalInnerRef.current?.focus();
        }
        const handleMouseDown = (event: any) => {
            if (modalRef.current && modalRef.current.contains(event.target)) {
                adminViewDocmodalInnerRef.current?.removeAttribute("tabIndex");
                revokeDocModal.current?.removeAttribute("tabIndex");
                deleteDocModal.current?.removeAttribute("tabIndex");
            }
        };
        if (documentViewActivateInd) {
            window.document.addEventListener("mousedown", handleMouseDown);
        }
        return () => {
            window.document.removeEventListener("mousedown", handleMouseDown);
        };
    }, [status, deleteInd, documentViewActivateInd]);

    return (
        <>
            {documentViewActivateInd && !deleteInd && status === "" && (
                <FocusTrap
                    focusTrapOptions={{
                        escapeDeactivates: false,
                        clickOutsideDeactivates: false,
                        returnFocusOnDeactivate: true,
                    }}
                >
                    <DocumentSubmissionWrapper
                        className={`modal fade show aui-modal d-block ${
                            !deleteInd && status === ""
                                ? "uploadeddata-modal"
                                : status !== ""
                                ? "doc-action-modal"
                                : ""
                        }`}
                        id="viewUploadedData"
                        tabIndex={-1}
                        aria-labelledby="viewUploadedLabel"
                        aria-modal="true"
                        role="dialog"
                        data-backdrop="static"
                        data-keyboard="false"
                    >
                        <div
                            className="modal-dialog modal-dialog-centered modal-lg"
                            ref={modalRef}
                        >
                            <div
                                className="modal-content"
                                id="adminViewDocmodalInnerRef"
                                ref={adminViewDocmodalInnerRef}
                            >
                                <div className="modal-header">
                                    <h2 className="h4" id="viewUploadedLabel">
                                        Document Uploaded
                                    </h2>
                                    <button
                                        id="closeAdminDocViewModal"
                                        ref={closeAdminDocViewModal}
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close Document uploaded modal"
                                        onClick={() => {
                                            dismissUploadedDocument();
                                            setDocumentDetails([]);
                                            setRefreshTableDateNow(Date.now());
                                        }}
                                    >
                                        <span
                                            aria-hidden="true"
                                            className="aha-icon-cross"
                                        />
                                    </button>
                                </div>
                                <div className="modal-body p-0">
                                    {documentDetails !== undefined &&
                                        documentDetails.length > 0 && (
                                            <>
                                                <DocumentDetails
                                                    documentDetails={
                                                        documentDetails
                                                    }
                                                    selectedProgram={
                                                        selectedProgram
                                                    }
                                                    setStatus={setStatus}
                                                    setDeleteInd={setDeleteInd}
                                                    setDeleteDoc={setDeleteDoc}
                                                    setDataDismissInd={
                                                        setDataDismissInd
                                                    }
                                                />
                                            </>
                                        )}
                                </div>
                            </div>
                        </div>
                    </DocumentSubmissionWrapper>
                </FocusTrap>
            )}
            {documentViewActivateInd && status !== "" && (
                <FocusTrap
                    focusTrapOptions={{
                        escapeDeactivates: false,
                        clickOutsideDeactivates: false,
                        returnFocusOnDeactivate: true,
                    }}
                >
                    {/* View uploaded data Modal */}
                    <DocumentSubmissionWrapper
                        className={`modal fade show aui-modal d-block ${
                            !deleteInd && status === ""
                                ? "uploadeddata-modal"
                                : status !== ""
                                ? "doc-action-modal"
                                : ""
                        }`}
                        id="viewUploadedData"
                        tabIndex={-1}
                        aria-labelledby="viewUploadedLabel"
                        aria-modal="true"
                        role="dialog"
                        data-backdrop="static"
                        data-keyboard="false"
                    >
                        <div
                            className="modal-dialog modal-dialog-centered modal-sm"
                            ref={modalRef}
                        >
                            {documentDetails && documentDetails.length > 0 && (
                                <div
                                    className="modal-content"
                                    id="revokeDocModal"
                                    ref={revokeDocModal}
                                >
                                    <div className="modal-header">
                                        <h2
                                            className="h8"
                                            id="approveRejectDocModalLabel"
                                        >
                                            {getHeaderByStatus(status) === ""
                                                ? `Revoke Document Status`
                                                : `${getHeaderByStatus(
                                                      status
                                                  )} Document`}
                                        </h2>
                                        <button
                                            id="closeAdminApproveRejectModal"
                                            ref={closeAdminApproveRejectModal}
                                            type="button"
                                            className="close"
                                            data-dismiss={
                                                status !== "" ? "" : "modal"
                                            }
                                            aria-label="Close Document uploaded modal"
                                            onClick={() =>
                                                clearCommentsAndStatus()
                                            }
                                        >
                                            <span
                                                aria-hidden="true"
                                                className="aha-icon-cross"
                                            />
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <p className="doc-action-content">
                                            {getHeaderByStatus(status) ? (
                                                <>
                                                    Do you really want to &quot;
                                                    {getHeaderByStatus(status)}
                                                    &quot; the document
                                                    submitted by{" "}
                                                    <span className="font-red">
                                                        {facilityName}?
                                                    </span>
                                                </>
                                            ) : (
                                                <>
                                                    Do you really want to change
                                                    the status of the document
                                                    submitted by{" "}
                                                    <span className="font-red">
                                                        {facilityName}
                                                    </span>{" "}
                                                    to &quot;Pending&quot;?
                                                </>
                                            )}
                                        </p>
                                        <form
                                            onSubmit={approveOrRejectDocument}
                                        >
                                            {status !== "PENDING" && (
                                                <div>
                                                    <div
                                                        className={`d-flex justify-content-between form-group m-0 align-items-end ${
                                                            status ===
                                                            "REJECTED"
                                                                ? "required"
                                                                : ""
                                                        }`}
                                                    >
                                                        <div className="mb-2">
                                                            <label
                                                                className="font-600"
                                                                htmlFor="approveRejectMessage"
                                                            >
                                                                Reviewer Message
                                                            </label>
                                                        </div>
                                                        {status ===
                                                            "REJECTED" && (
                                                            <div className="pb-2 mand-field">
                                                                <sup>*</sup>
                                                                mandatory field
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div>
                                                        <textarea
                                                            className="form-control"
                                                            id="approveRejectMessage"
                                                            maxLength={500}
                                                            name="rejectReviewComment"
                                                            rows={3}
                                                            onChange={
                                                                setReviewMessage
                                                            }
                                                            required={
                                                                status ===
                                                                "REJECTED"
                                                            }
                                                            aria-describedby="doc-reviewMsg"
                                                        />
                                                        <div
                                                            className="form-help"
                                                            id="doc-reviewMsg"
                                                        >
                                                            {status ===
                                                            "REJECTED"
                                                                ? "(Enter 1 to 500 characters)"
                                                                : "(Enter 0 to 500 characters)"}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="mt-4 mx-0 d-flex justify-content-end">
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary btn-round mr-4"
                                                    data-dismiss={
                                                        status !== ""
                                                            ? ""
                                                            : "modal"
                                                    }
                                                    onClick={() =>
                                                        clearCommentsAndStatus()
                                                    }
                                                >
                                                    No
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary btn-round"
                                                >
                                                    Yes
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            )}
                        </div>
                    </DocumentSubmissionWrapper>
                </FocusTrap>
            )}
            {documentViewActivateInd && deleteInd && (
                <FocusTrap
                    focusTrapOptions={{
                        escapeDeactivates: false,
                        clickOutsideDeactivates: false,
                        returnFocusOnDeactivate: true,
                    }}
                >
                    {/* View uploaded data Modal */}
                    <DocumentSubmissionWrapper
                        className={`modal fade show aui-modal d-block ${
                            !deleteInd && status === ""
                                ? "uploadeddata-modal"
                                : status !== ""
                                ? "doc-action-modal"
                                : ""
                        }`}
                        id="viewUploadedData"
                        tabIndex={-1}
                        aria-labelledby="viewUploadedLabel"
                        aria-modal="true"
                        role="alertdialog"
                        data-backdrop="static"
                        data-keyboard="false"
                    >
                        <div
                            className="modal-dialog modal-dialog-centered modal-sm"
                            ref={modalRef}
                        >
                            <div
                                className="modal-content"
                                id="deleteDocModal"
                                ref={deleteDocModal}
                            >
                                <div className="modal-body p-0">
                                    <div className="text-center">
                                        <p id="modalDeleteLabel">
                                            Are you sure you want to delete
                                            {` ${deleteDoc.title}`} submitted on{" "}
                                            {localDateToYYYYMMDDFormatString(
                                                deleteDoc.createdAt
                                            )}
                                            ?
                                        </p>
                                        <div className="mt-4 d-flex justify-content-center">
                                            <button
                                                id="noDeleteConfirm"
                                                ref={noDeleteConfirm}
                                                className="btn btn-secondary btn-round btn-sm mx-2"
                                                data-dismiss={
                                                    deleteInd ? "" : "modal"
                                                }
                                                onClick={() =>
                                                    setDeleteInd(false)
                                                }
                                            >
                                                No
                                            </button>
                                            <button
                                                className="btn btn-primary btn-round btn-sm mx-2"
                                                data-dismiss={
                                                    !dataDismissInd
                                                        ? ""
                                                        : "modal"
                                                }
                                                onClick={deleteDocument}
                                            >
                                                Yes
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DocumentSubmissionWrapper>
                </FocusTrap>
            )}
        </>
    );
};

export default AdminDocumentView;
