import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import CONSTANTS from "common/constants";
import { TabWrapper } from "./styled";

const Tab = forwardRef((props: any, ref: any) => {
    const {
        tabTitle,
        tabGroup,
        handleSelectedTab,
        selectedActiveTab,
        tabLabel,
        tab,
        setTab,
        readOnly,
        isMeasureMetricsValues,
        measureMetricHasValues,
        userData,
        confirmPopUp,
        setConfirmPopUP,
        setShowpopup,
        showModal,
        setIsEnterDataClicked,
        setReadOnly,
    } = props;
    const [selectedTab, setSelectedTab] = useState<any>(
        tabTitle[0]?.id || null
    );
    const [focusedTabIndex, setFocusedTabIndex] = useState<number | null>(null);
    const tabsRef = useRef<any>([]);
    const [showTabContent, setShowTabContent] = useState<boolean>(false);
    // If no tab is selected, set the first tab as the selected one
    useEffect(() => {
        if (tabTitle.length > 0 && selectedTab === null) {
            setSelectedTab(tabTitle[0].id);
        } else if (
            selectedActiveTab !== undefined &&
            selectedActiveTab !== null &&
            tabTitle.length > 0 &&
            selectedTab !== null
        ) {
            setSelectedTab(tabTitle[selectedActiveTab].id);
            setFocusedTabIndex(selectedActiveTab);
        } else if (tab && !showTabContent) {
            setSelectedTab(props.tab);
        }
    }, [tabTitle, selectedActiveTab]);

    // Focus the element when focusedTabIndex changes
    useEffect(() => {
        if (focusedTabIndex !== null) {
            const focusedElement = document.getElementById(
                `tab-${tabGroup}-${focusedTabIndex}`
            );
            if (focusedElement) {
                focusedElement.focus();
            }
        }
    }, [focusedTabIndex, tabGroup]);

    // Set focus to the selected tab
    useEffect(() => {
        // Find the index of the selected tab
        const selectedTabIndex = tabTitle.findIndex(
            (tab: any) => tab.id === selectedTab
        );
        tabsRef.current[selectedTabIndex]?.focus();
    }, [selectedTab]);

    // Handle tab click
    const handleTabClick = (tabId: any, index: any) => {
        if (tabId === "spreadsheet") {
            if (confirmPopUp) {
                setSelectedTab(tabId);
            } else if (!measureMetricHasValues) {
                setSelectedTab(tabId);
            } else if (userData && readOnly && !isMeasureMetricsValues) {
                setSelectedTab(tabId);
            }
        } else {
            setSelectedTab(tabId);
            if (tabId === "data") {
                setConfirmPopUP(false);
            }
        }
        setFocusedTabIndex(index);
        handleSelectedTab(tabId, index);
        if (tabTitle[index].onClick) {
            tabTitle[index]?.onClick(index);
        }
    };
    useImperativeHandle(ref, () => ({
        handleTabClick,
    }));

    // Handle keyboard navigation
    const handleKeyDown = (event: any, index: any) => {
        setShowTabContent(true);
        const findNextEnabledTab = (
            currentIndex: number,
            direction: number
        ) => {
            let newIndex = currentIndex;
            do {
                newIndex =
                    (newIndex + direction + tabTitle.length) % tabTitle.length;
            } while (tabTitle[newIndex].disabled && newIndex !== currentIndex);
            return newIndex;
        };
        tabsRef.current[index] = event.target;
        const updateTabFocusAndSelection = (tabIndex: number) => {
            setFocusedTabIndex(tabIndex);
            setSelectedTab(tabTitle[tabIndex].id);
            handleSelectedTab(tabTitle[tabIndex].id, tabIndex);
            if (setTab) {
                setTab(tabTitle[tabIndex].id);
            }
            // Force focus on Safari
            setTimeout(() => {
                tabsRef.current[tabIndex]?.focus();
            }, 0);
        };
        const handleTabNavigation = (
            currentTab: any,
            nextTab: any,
            action: any
        ) => {
            if (
                currentTab === CONSTANTS.DATA_MEASURE_TAB &&
                nextTab === CONSTANTS.UPLOAD_DATA_MEASURE_TAB &&
                !readOnly &&
                measureMetricHasValues
            ) {
                setShowpopup(true);
                showModal();
            } else if (
                currentTab === CONSTANTS.UPLOAD_DATA_MEASURE_TAB &&
                nextTab === CONSTANTS.DATA_MEASURE_TAB &&
                !readOnly &&
                confirmPopUp
            ) {
                setIsEnterDataClicked(true);
                setReadOnly(true);
                setConfirmPopUP(false);
            } else {
                updateTabFocusAndSelection(action);
            }
        };

        switch (event.key) {
            case "ArrowRight": {
                event.preventDefault();
                const nextIndex = findNextEnabledTab(index, 1);
                handleTabNavigation(
                    tabTitle[index]?.id,
                    tabTitle[nextIndex]?.id,
                    nextIndex
                );
                break;
            }
            case "ArrowLeft": {
                event.preventDefault();
                const previousIndex = findNextEnabledTab(index, -1);
                handleTabNavigation(
                    tabTitle[index]?.id,
                    tabTitle[previousIndex]?.id,
                    previousIndex
                );
                break;
            }
            case "Home": {
                event.preventDefault();
                const firstEnabledTab = findNextEnabledTab(-1, 1); // Start from -1 to find the first enabled tab
                updateTabFocusAndSelection(firstEnabledTab);
                break;
            }
            case "End": {
                event.preventDefault();
                const lastEnabledTab = findNextEnabledTab(tabTitle.length, -1); // Start from the end
                updateTabFocusAndSelection(lastEnabledTab);
                break;
            }
            default:
                break;
        }
    };

    return (
        <TabWrapper className="tab-bordered">
            <div className="tab-bordered">
                <ul
                    className="nav nav-tabs"
                    role="tablist"
                    aria-labelledby={tabLabel}
                >
                    {props?.tabTitle.map((tab: any, index: any) => (
                        <li
                            key={tab.id}
                            className="nav-item"
                            role="presentation"
                        >
                            <button
                                aria-live="polite"
                                className={`nav-link ${
                                    selectedTab === tab.id ? "active" : ""
                                }`}
                                id={`tab-${tabGroup}-${index}`} // Unique ID based on tab group
                                data-toggle={
                                    tab.id === "spreadsheet"
                                        ? confirmPopUp && "tab"
                                        : "tab"
                                }
                                type="button"
                                role="tab"
                                aria-controls={tab.id}
                                aria-selected={
                                    tab.id === "spreadsheet"
                                        ? selectedTab === tab.id && confirmPopUp
                                        : selectedTab === tab.id
                                }
                                onClick={() => handleTabClick(tab.id, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                tabIndex={selectedTab === tab.id ? 0 : -1}
                                disabled={tab?.disabled}
                                ref={tabsRef}
                            >
                                {props.emsLogsDeatils ? (
                                    <span className="d-block countnumber mb-1">
                                        {tab.count}
                                    </span>
                                ) : null}
                                <span>
                                    <span>{tab.title}</span>
                                </span>
                            </button>
                        </li>
                    ))}
                </ul>
                <div className="tab-content">
                    {props?.tabTitle.map((tab: any, index: number) => (
                        <div
                            key={tab.id}
                            className={`tab-pane fade ${
                                selectedTab === tab.id ? "show active" : ""
                            }`}
                            id={tab.id}
                            role="tabpanel"
                            aria-labelledby={`tab-${tabGroup}-${index}`}
                        >
                            {tab.content}
                        </div>
                    ))}
                </div>
            </div>
        </TabWrapper>
    );
});

export default Tab;
