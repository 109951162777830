import React, { useEffect, useRef, useState } from "react";
import store from "app/store";
import CONSTANTS from "common/constants";
import AgreementFileUpload from "components/AgreementFileUpload";
import { useDispatch } from "react-redux";
import { logger } from "utils/logger.utils";
import { useParams, useHistory } from "react-router-dom";
import {
    getAgreementDetails,
    getAgreementDownloadUrl,
    saveAgreementDetails,
} from "api/programAgreementAPI";
import {
    dateToYYYYMMDDFormatString,
    dateToYYYYMMDDPlaceholderFormatStringISO,
    localDateToYYYYMMDDFormatString,
    localDateToYYYYMMDDPlaceholderFormat,
    redirectionForErrorCode,
    dismissModal,
    showModal,
} from "common/utils";
import { showToast } from "components/Toast/toast.slice";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { approveHospital } from "api/adminDashboardAPI";
import FocusTrap from "focus-trap-react";
import { FacilityDetailsWrapper } from "./styled";
import "styles/toggle-switch.scss";

interface Props {
    hospital: any;
    setActiveTab: any;
    activeTab: any;
    close: any;
    setAgreementInfo?: any;
    isCorporation?: any;
    orderId?: any;
    isEUP?: any;
    emsAdmin?: any;
}

export const AgreementDetails = (props: Props) => {
    const dispatch = useDispatch();
    const params: any = useParams();
    // STATE Variables
    const [editMode, setEditMode] = useState<boolean>(false);
    const [agreementInfo, setAgreementInfo] = useState<any>({});
    const [signedDate, setSignedDate] = useState<any>("");
    const [agreementFileName, setAgreementFileName] = useState<any>("");
    const [finalAgreementFileName, setFinalAgreementFileName] =
        useState<any>("");
    const [disabled, setDisabled] = useState<any>(false);
    const [prevState, setPrevState] = useState<any>();
    const [hospital, setHospital] = useState<any>(props.hospital);
    const [emsAdmin, setEmsAdmin] = useState<any>(props.emsAdmin);
    const [buttonState, setButtonState] = useState<boolean>(true);
    const [userAgreementId, setUserAgreementId] = useState<any>("");
    const [isApprovalRestricted, setApprovalRestricted] =
        useState<boolean>(false);
    const history = useHistory();
    const [agreementUploadActivateInd, setAgreementUploadActivateInd] =
        useState<boolean>(false);
    const modalRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        setHospital(props.hospital);
    }, [props.hospital]);

    useEffect(() => {
        Promise.all([getAgreementDetails(params.programId, params.hospitalId)])
            .then((response: any) => {
                const agreementData = response[0].data;
                if (agreementData) {
                    if (agreementData.signedDate) {
                        setAgreementInfo(agreementData);
                        if (
                            agreementData.agreementReferenceId !==
                            agreementData.finalAgreementId
                        ) {
                            setUserAgreementId(agreementData.finalAgreementId);
                        }
                        if (props.setAgreementInfo) {
                            props.setAgreementInfo(agreementData);
                        }
                        setSignedDate(
                            agreementData.signedDate.includes("T00:00:00.000Z")
                                ? dateToYYYYMMDDFormatString(
                                      agreementData.signedDate
                                  )
                                : agreementData.signedDate
                        );
                    }
                    if (agreementData.agreementReferenceId) {
                        getUploadedAgreementInfo(
                            agreementData.agreementReferenceId
                        );

                        // Get final agreement name only if the agreementId is differs from facility uploaded agreementId
                        getFinalUploadedAgreementInfo(
                            agreementData.finalAgreementId
                        );
                    }
                }
            })
            .catch((error) => {
                logger("Program Agreement Page Error Occurred", error);
            });
    }, [hospital]);

    const submitAgreement = (event: any) => {
        event.preventDefault();
        setDisabled(true);
        const modifiedAgreementInfo = { ...agreementInfo };
        delete modifiedAgreementInfo.programCategoryId; // no need to update category id from agreement page
        delete modifiedAgreementInfo.programName; // no need to update program Name from agreement page
        delete modifiedAgreementInfo.updatedAt;
        delete modifiedAgreementInfo.updatedBy;
        if (props.isCorporation || props.emsAdmin) {
            delete modifiedAgreementInfo.signedDate;
        }
        delete modifiedAgreementInfo.agreementUploadedBy;
        saveAgreementDetails(
            modifiedAgreementInfo,
            params.programId,
            params.hospitalId
        )
            .then((data) => {
                if (data.success == true) {
                    const updatedData = { ...agreementInfo };
                    updatedData.updatedAt = null;
                    updatedData.updatedBy = null;

                    if (
                        data &&
                        data.data &&
                        data.data.updatedResult &&
                        data.data.updatedResult.updatedAt
                    ) {
                        updatedData.updatedAt =
                            data.data.updatedResult.updatedAt;
                    }
                    if (
                        data &&
                        data.data &&
                        data.data.updatedResult &&
                        data.data.updatedResult.updatedBy
                    ) {
                        updatedData.updatedBy =
                            data.data.updatedResult.updatedBy;
                    }
                    if (props.setAgreementInfo) {
                        props.setAgreementInfo(updatedData);
                    }
                    const toast = {
                        message: "Agreement details successfully updated.",
                        code: "Success:",
                        type: "success",
                    };
                    dispatch(showToast(toast));
                    setDisabled(false);
                    setEditMode(false);
                } else {
                    setDisabled(false);
                }
            })
            .catch((error) => {
                redirectionForErrorCode(
                    CONSTANTS.ROUTES.CORPORATION_DASHBOARD,
                    error,
                    history,
                    2000
                );
                setDisabled(false);
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
            });
    };

    const handleChange = (event: any) => {
        setButtonState(false);
        setAgreementInfo({
            ...agreementInfo,
            [event.target.name]:
                event.target.type == "checkbox"
                    ? event.target.checked
                    : event.target.name == "publicationName"
                    ? event.target.value.replace(/[><]/g, "")
                    : event.target.value.trim(),
        });
    };

    const setFileData = (data: any) => {
        setAgreementInfo({
            ...agreementInfo,
            finalAgreementId: data.uploadId,
        });
        setFinalAgreementFileName(data.fileName);
        setUserAgreementId(data.uploadId);
        setButtonState(false);
    };

    const getUploadedAgreementInfo = async (uploadId: any) => {
        const uploadedAgreementInfo: any = await getAgreementDownloadUrl(
            uploadId,
            params.hospitalId
        );
        setAgreementFileName(uploadedAgreementInfo.data.uploads.fileName);
        if (props.isEUP) {
            dispatch(
                setPageLoadingStatus({
                    isPageLoading: false,
                })
            );
        }
    };

    const getFinalUploadedAgreementInfo = async (uploadId: any) => {
        const uploadedAgreementInfo: any = await getAgreementDownloadUrl(
            uploadId,
            params.hospitalId
        );
        setFinalAgreementFileName(uploadedAgreementInfo.data.uploads.fileName);
    };

    const downloadUploadedAgreementInfo = async (uploadId: any) => {
        const uploadedAgreementInfo: any = await getAgreementDownloadUrl(
            uploadId,
            params.hospitalId
        );
        window.location.assign(uploadedAgreementInfo.data.uploads.fileUrl);
    };

    const cancelEdit = () => {
        setAgreementInfo(prevState.agreementInfo);
        setSignedDate(prevState.signedDate);
        setAgreementFileName(prevState.agreementFileName);
        setFinalAgreementFileName(prevState.finalAgreementFileName);
        setEditMode(!editMode);
    };

    const modifyEditMode = () => {
        setButtonState(true);
        if (!editMode) {
            setPrevState({
                agreementInfo,
                signedDate,
                agreementFileName,
                finalAgreementFileName,
            });
            setEditMode(!editMode);
        } else {
            cancelEdit();
        }
    };

    const modifySignedDate = (event: any) => {
        try {
            const date = new Date(event?.target?.value);
            const dateISO = date.toISOString();
            const todayDate = localDateToYYYYMMDDFormatString(new Date());
            const modifiedAgreementInfo = agreementInfo;
            modifiedAgreementInfo.signedDate =
                todayDate === dateISO?.split("T")[0] ? new Date() : dateISO;
            setAgreementInfo(modifiedAgreementInfo);
            setSignedDate(event?.target?.value);
            setButtonState(false);
        } catch (error) {
            const toast = {
                message: "Agreement Signed Date is Invalid.",
                code: "Error:",
            };
            dispatch(showToast(toast));
        }
    };

    const jumpToNextTab = () => {
        props.setActiveTab(props.activeTab === null ? 1 : props.activeTab + 1);
        window.scrollTo(0, CONSTANTS.SCROLLTO_VALUE);
    };

    const jumpToPrevTab = () => {
        if (props.activeTab > 0) {
            props.setActiveTab(props.activeTab - 1);
            window.scrollTo(0, CONSTANTS.SCROLLTO_VALUE);
        }
    };

    const getDisabledInd = () => {
        if (
            props.isCorporation &&
            agreementInfo.agreementUploadedBy ===
                CONSTANTS.USER_ROLES.CORPORATION_ADMIN
        ) {
            return false;
        }
        return true;
    };

    const approve = () => {
        setDisabled(true);
        approveHospital(
            hospital.hospitalId,
            params.programId,
            params.categoryId,
            "APPROVED"
        )
            .then((response) => {
                setDisabled(false);
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
                if (response.success && response.statusCode === 200) {
                    dispatch(
                        showToast({
                            visibility: true,
                            title: "Organization Approved:",
                            message: `Organization ${hospital.facilityName} successfully approved for the Mission: Lifeline® EMS Recognition program.`,
                            type: "success",
                        })
                    );
                    props.close();
                }
            })
            .catch((error) => {
                if (
                    error.message ===
                    CONSTANTS.LOCKED_MEASURE_FOR_ORGANIZATION_APPROVALS
                ) {
                    setApprovalRestricted(true);
                    showModal();
                } else {
                    store.dispatch(showToast(error));
                }
                setDisabled(false);
            });
    };

    const uploadAgreementShowModal = () => {
        setAgreementUploadActivateInd(true);
        showModal();
    };
    const uploadAgreementDismissModal = () => {
        setAgreementUploadActivateInd(false);
        dismissModal();
    };

    useEffect(() => {
        const handleMouseDown = (event: any) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                uploadAgreementDismissModal();
            }
        };

        if (agreementUploadActivateInd) {
            document.addEventListener("mousedown", handleMouseDown);
        }

        return () => {
            document.removeEventListener("mousedown", handleMouseDown);
        };
    }, [agreementUploadActivateInd]);

    const dismissMeasureLockedWaringPopUp = () => {
        setApprovalRestricted(false);
        showModal();
    };

    return (
        <FacilityDetailsWrapper aria-label="Agreement Detail tab Content">
            <div className="fd-title-border d-flex justify-content-between">
                <h3 className="h4 font-500">Agreement Details</h3>
                {(props.orderId || props.isCorporation || props.isEUP) &&
                    hospital.isActive == true && (
                        <div className="edit-btn-toggle">
                            <input
                                type="checkbox"
                                id="editAgreementDetails"
                                className="edit-btn-checkbox"
                                role="button"
                                aria-pressed={editMode}
                                onClick={modifyEditMode}
                            />
                            {editMode ? (
                                <label
                                    htmlFor="editAgreementDetails"
                                    className="toggle-label"
                                >
                                    Cancel Edit
                                </label>
                            ) : (
                                <label
                                    htmlFor="editAgreementDetails"
                                    className="toggle-label"
                                >
                                    <i
                                        className="aha-icon-edit toggle-edit"
                                        title="Edit Agreement Details"
                                        aria-hidden="true"
                                    />
                                    <span className="sr-only">Edit</span>
                                </label>
                            )}
                            {editMode ? (
                                <div
                                    aria-live="polite"
                                    className="d-block d-md-none sr-only"
                                >
                                    Cancel editing agreement details
                                </div>
                            ) : (
                                <div
                                    aria-live="polite"
                                    className="d-block d-md-none sr-only"
                                >
                                    Edit agreement details
                                </div>
                            )}
                        </div>
                    )}
            </div>
            {!editMode ? (
                <div className="fd-table">
                    <table className="aui-responsive-table aui-table-cols">
                        <tbody>
                            <tr>
                                <th scope="row" className="aui-row-th">
                                    First and Last Name of Signer
                                </th>
                                <td className="aui-row-td">
                                    {agreementInfo.signerName || "N/A"}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" className="aui-row-th">
                                    Title of Signer
                                </th>
                                <td className="aui-row-td">
                                    {agreementInfo.signerTitle || "N/A"}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" className="aui-row-th">
                                    Email of Signer
                                </th>
                                <td className="aui-row-td">
                                    {agreementInfo.signerEmail || "N/A"}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" className="aui-row-th">
                                    Date
                                </th>
                                <td className="aui-row-td">
                                    {localDateToYYYYMMDDFormatString(
                                        signedDate
                                    ) || "N/A"}
                                </td>
                            </tr>
                            {params.programId !== CONSTANTS.EMS_PROGRAM_ID && (
                                <tr>
                                    <th scope="row" className="aui-row-th">
                                        Program Participant Tax ID (US site
                                        only)
                                    </th>
                                    <td className="aui-row-td">
                                        {agreementInfo.participantTaxId ||
                                            "N/A"}
                                    </td>
                                </tr>
                            )}
                            {!props.isEUP && !emsAdmin && (
                                <tr>
                                    <th scope="row" className="aui-row-th">
                                        User Signed Agreement
                                    </th>
                                    <td className="aui-row-td">
                                        <div className="d-flex align-items-start">
                                            {agreementFileName ? (
                                                <button
                                                    onClick={() =>
                                                        downloadUploadedAgreementInfo(
                                                            agreementInfo.agreementReferenceId
                                                        )
                                                    }
                                                    className="btn-text-link-uline agmt-title p-0 text-left"
                                                    aria-label={`Download ${agreementFileName} organization signed Agreement`}
                                                    type="button"
                                                >
                                                    {agreementFileName}
                                                </button>
                                            ) : (
                                                "N/A"
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            )}
                            {(agreementInfo.agreementReferenceId !==
                                agreementInfo.finalAgreementId ||
                                (props.isEUP &&
                                    params.programId !==
                                        CONSTANTS.EMS_PROGRAM_ID)) && (
                                <tr>
                                    <th scope="row" className="aui-row-th">
                                        Final Agreement
                                    </th>
                                    <td className="aui-row-td">
                                        <div className="d-flex align-items-start">
                                            <button
                                                onClick={() =>
                                                    downloadUploadedAgreementInfo(
                                                        agreementInfo.finalAgreementId
                                                    )
                                                }
                                                className="btn-text-link-uline agmt-title p-0 text-left"
                                                aria-label={`Download ${finalAgreementFileName} final Agreement`}
                                                type="button"
                                            >
                                                {finalAgreementFileName}
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            )}

                            <tr>
                                <th scope="row" className="aui-row-th">
                                    Permissions Agreement:
                                </th>
                                <td className="aui-row-td">
                                    <div
                                        role="group"
                                        aria-labelledby="permissionAgg"
                                    >
                                        <label
                                            className="sr-only"
                                            id="permissionAgg"
                                        >
                                            Permissions Agreement:
                                        </label>
                                        <div className="form-check mb-2">
                                            <input
                                                name="permissionEvents"
                                                onChange={handleChange}
                                                checked={
                                                    agreementInfo.permissionEvents ==
                                                    true
                                                }
                                                type="checkbox"
                                                value=""
                                                id="agreementRecognitionEvents"
                                                disabled
                                            />
                                            <label htmlFor="agreementRecognitionEvents">
                                                Recognition Events
                                            </label>
                                        </div>
                                        <div className="form-check mb-2">
                                            <input
                                                name="permissionMedia"
                                                onChange={handleChange}
                                                checked={
                                                    agreementInfo.permissionMedia ==
                                                    true
                                                }
                                                type="checkbox"
                                                value=""
                                                id="agreementWEbsiteAndMedia"
                                                disabled
                                            />
                                            <label
                                                htmlFor="agreementWEbsiteAndMedia"
                                                aria-label="American Heart Association website, digital media, mobile apps"
                                            >
                                                <span aria-hidden="true">
                                                    AHA{" "}
                                                </span>
                                                <span className="sr-only">
                                                    American heart Association
                                                </span>
                                                website, digital media, mobile
                                                apps
                                            </label>
                                        </div>
                                        <div className="form-check mb-2">
                                            <input
                                                name="permissionBanners"
                                                onChange={handleChange}
                                                checked={
                                                    agreementInfo.permissionBanners ==
                                                    true
                                                }
                                                type="checkbox"
                                                value=""
                                                id="agreementBanners"
                                                disabled
                                            />
                                            <label htmlFor="agreementBanners">
                                                Conference banners and signage
                                            </label>
                                        </div>
                                        <div className="form-check mb-2">
                                            <input
                                                name="permissionAds"
                                                onChange={handleChange}
                                                checked={
                                                    agreementInfo.permissionAds ==
                                                    true
                                                }
                                                type="checkbox"
                                                value=""
                                                id="agreementAds"
                                                disabled
                                            />
                                            <label htmlFor="agreementAds">
                                                Program promotion as permitted
                                            </label>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            {!agreementInfo.agreementReferenceId &&
                                (agreementInfo.permissionEvents == true ||
                                    agreementInfo.permissionMedia == true ||
                                    agreementInfo.permissionBanners == true ||
                                    agreementInfo.permissionAds == true) && (
                                    <tr>
                                        <th scope="row" className="aui-row-th">
                                            Exact Organization Name for
                                            Publications
                                        </th>
                                        <td className="aui-row-td">
                                            {agreementInfo?.publicationName}
                                        </td>
                                    </tr>
                                )}
                        </tbody>
                    </table>
                    {!props.isEUP && (
                        <div className="fb-table-btn d-flex justify-content-center py-4 flex-wrap">
                            <button
                                onClick={props.close}
                                type="button"
                                disabled={disabled}
                                className="btn btn-secondary btn-round fd-btn-1"
                                aria-describedby="admin-agreementdetails"
                            >
                                Close
                            </button>
                            <button
                                onClick={jumpToPrevTab}
                                type="button"
                                className="btn btn-secondary btn-round fd-btn-2"
                                disabled={disabled}
                                aria-describedby="admin-programdetails"
                            >
                                Previous
                            </button>
                            {!emsAdmin && (
                                <button
                                    className="btn btn-primary btn-round fd-btn-3"
                                    onClick={jumpToNextTab}
                                    aria-describedby="admin-billingdetails"
                                >
                                    Next
                                </button>
                            )}
                            {emsAdmin &&
                                hospital.approvalStatus === CONSTANTS.PENDING &&
                                hospital.isActive === true && (
                                    <button
                                        aria-label="Approve Organization"
                                        className={`btn btn-primary btn-round fd-btn-3 ${
                                            disabled
                                                ? CONSTANTS.BUTTON_SPINNER
                                                : ""
                                        }`}
                                        disabled={disabled}
                                        onClick={approve}
                                    >
                                        Approve
                                    </button>
                                )}
                            {isApprovalRestricted && (
                                <FocusTrap
                                    focusTrapOptions={{
                                        escapeDeactivates: false,
                                        clickOutsideDeactivates: false,
                                    }}
                                >
                                    <div
                                        className="modal fade show aui-modal approval-modal d-block"
                                        tabIndex={-1}
                                        aria-labelledby="detailsConfirmApprovalModal"
                                        aria-modal="true"
                                        role="dialog"
                                    >
                                        <div className="modal-dialog modal-dialog-centered modal-sm">
                                            <div className="modal-content">
                                                <div className="modal-body p-0">
                                                    <div className="modal-header">
                                                        <h2
                                                            className="h5 font-600"
                                                            id="detailsConfirmApprovalModal"
                                                        >
                                                            Warning
                                                        </h2>
                                                    </div>
                                                    <p className="pb-0">
                                                        Approving organization
                                                        is currently restricted
                                                        as measure lock is
                                                        active.
                                                    </p>
                                                    <div className="mt-4 d-flex justify-content-end">
                                                        <button
                                                            className="btn btn-primary btn-round btn-sm mx-2"
                                                            aria-label="close the modal"
                                                            onClick={
                                                                dismissMeasureLockedWaringPopUp
                                                            }
                                                        >
                                                            Ok
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </FocusTrap>
                            )}
                        </div>
                    )}
                </div>
            ) : (
                <div className="fd-form">
                    <form
                        onSubmit={submitAgreement}
                        aria-label="agreement detail"
                    >
                        <fieldset className="row" disabled={disabled}>
                            <div className="col-lg-10">
                                <div className="d-flex flex-row-reverse">
                                    <div className="pb-3 mand-field">
                                        <sup>*</sup>
                                        mandatory fields
                                    </div>
                                </div>
                                <div className="form-group row required">
                                    <div className="col-md-5 form-label">
                                        <label
                                            htmlFor="signername"
                                            className="col-form-label label-form"
                                        >
                                            First and Last Name of Signer
                                            <sup>*</sup>
                                        </label>
                                    </div>
                                    <div className="col-md-7">
                                        <input
                                            pattern={
                                                CONSTANTS.REGEX_ALLOW_ALPHABHET_SPACE
                                            }
                                            name="signerName"
                                            onChange={handleChange}
                                            defaultValue={
                                                agreementInfo.signerName
                                            }
                                            type="text"
                                            className="form-control"
                                            id="signername"
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="form-group row required">
                                    <div className="col-md-5 form-label">
                                        <label
                                            htmlFor="signertitle"
                                            className="col-form-label label-form"
                                        >
                                            Title of Signer
                                            <sup>*</sup>
                                        </label>
                                    </div>
                                    <div className="col-md-7">
                                        <input
                                            pattern={
                                                CONSTANTS.REGEX_ALLOW_ALPHABET_SPACE_DOT
                                            }
                                            name="signerTitle"
                                            onChange={handleChange}
                                            defaultValue={
                                                agreementInfo.signerTitle
                                            }
                                            type="text"
                                            className="form-control"
                                            id="signertitle"
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="form-group row required">
                                    <div className="col-md-5 form-label">
                                        <label
                                            htmlFor="signeremail"
                                            className="col-form-label label-form"
                                        >
                                            Email of Signer
                                            <sup>*</sup>
                                        </label>
                                    </div>
                                    <div className="col-md-7">
                                        <input
                                            pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$"
                                            name="signerEmail"
                                            onChange={handleChange}
                                            defaultValue={
                                                agreementInfo.signerEmail
                                            }
                                            type="email"
                                            className="form-control"
                                            id="signeremail"
                                            required
                                        />
                                    </div>
                                </div>
                                <div
                                    className={`form-group row ${
                                        params.programId !==
                                        CONSTANTS.EMS_PROGRAM_ID
                                            ? "required "
                                            : ""
                                    }`}
                                >
                                    {params.programId !==
                                    CONSTANTS.EMS_PROGRAM_ID ? (
                                        <div className="col-md-5 form-label">
                                            <label
                                                htmlFor="date"
                                                className="col-form-label label-form"
                                            >
                                                <span className="d-block mb-n2">
                                                    Agreement Signed Date
                                                    <sup>*</sup>
                                                </span>
                                                <i className="label-helptext">
                                                    (DD-MM-YYYY)
                                                </i>
                                            </label>
                                        </div>
                                    ) : (
                                        <div className="col-md-5 form-label">
                                            <label
                                                htmlFor="date"
                                                className="col-form-label"
                                            >
                                                <span className="d-block mb-n2">
                                                    Date
                                                </span>
                                            </label>
                                        </div>
                                    )}
                                    {params.programId !==
                                    CONSTANTS.EMS_PROGRAM_ID ? (
                                        <div className="col-md-7">
                                            <input
                                                name="signedDate"
                                                defaultValue={localDateToYYYYMMDDPlaceholderFormat(
                                                    signedDate
                                                )}
                                                onChange={modifySignedDate}
                                                type="date"
                                                className="form-control"
                                                id="date"
                                                min="1970-01-01"
                                                max={localDateToYYYYMMDDPlaceholderFormat(
                                                    new Date()
                                                )}
                                                required
                                                readOnly={
                                                    props.isCorporation ||
                                                    props.isEUP ||
                                                    emsAdmin
                                                }
                                            />
                                        </div>
                                    ) : (
                                        <div className="col-md-7 pt-2">
                                            <input
                                                name="signedDate"
                                                defaultValue={localDateToYYYYMMDDFormatString(
                                                    signedDate
                                                )}
                                                onChange={modifySignedDate}
                                                type="text"
                                                className="form-control"
                                                id="date"
                                                min="1970-01-01"
                                                max={localDateToYYYYMMDDFormatString(
                                                    new Date()
                                                )}
                                                required
                                                readOnly={
                                                    emsAdmin || props.isEUP
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                                {params.programId !==
                                    CONSTANTS.EMS_PROGRAM_ID && (
                                    <div className="form-group row">
                                        <div className="col-md-5 form-label">
                                            <label
                                                htmlFor="participantid"
                                                className="col-form-label"
                                            >
                                                Program Participant Tax ID (US
                                                site only)
                                            </label>
                                        </div>
                                        <div className="col-md-7">
                                            <input
                                                pattern={
                                                    CONSTANTS.REGEX_ALLOW_ALPHANUMERIC_PARTICIPANTID
                                                }
                                                name="participantTaxId"
                                                onChange={handleChange}
                                                defaultValue={
                                                    agreementInfo.participantTaxId
                                                }
                                                type="text"
                                                className="form-control"
                                                id="participantid"
                                            />
                                        </div>
                                    </div>
                                )}
                                {!props.isEUP && !emsAdmin && (
                                    <div className="form-group row mt-4">
                                        <div className="col-md-5 form-label">
                                            <label id="userSignedAgreement">
                                                User Signed Agreement
                                            </label>
                                        </div>
                                        <div className="col-md-7">
                                            <div className="d-flex align-items-start">
                                                {agreementFileName && (
                                                    <button
                                                        type="button"
                                                        onClick={() =>
                                                            downloadUploadedAgreementInfo(
                                                                agreementInfo.agreementReferenceId
                                                            )
                                                        }
                                                        className="btn-text-link-uline p-0 text-left"
                                                        aria-describedby="userSignedAgreement"
                                                    >
                                                        {agreementFileName}
                                                    </button>
                                                )}
                                                {!props.isCorporation &&
                                                    agreementInfo.agreementReferenceId ===
                                                        agreementInfo.finalAgreementId && (
                                                        <button
                                                            disabled={!editMode}
                                                            type="button"
                                                            // Add a class 'd-none' to hide this upload button
                                                            className="btn btn-text ml-3 font-red"
                                                            onClick={() => {
                                                                uploadAgreementShowModal();
                                                            }}
                                                            aria-label="Upload User signed Agreement"
                                                        >
                                                            <i
                                                                className="aha-icon-cloud-upload icon-bold pr-1 upload-agr-icon"
                                                                title="Upload User signed Agreement"
                                                            />
                                                        </button>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {(agreementInfo.agreementReferenceId !==
                                    agreementInfo.finalAgreementId ||
                                    (props.isEUP &&
                                        params.programId !==
                                            CONSTANTS.EMS_PROGRAM_ID)) &&
                                    !emsAdmin && (
                                        <div className="form-group row">
                                            <div className="col-md-5 form-label">
                                                <label>Final Agreement</label>
                                            </div>
                                            <div className="col-md-7">
                                                <div className="d-flex align-items-start">
                                                    <button
                                                        onClick={() =>
                                                            downloadUploadedAgreementInfo(
                                                                agreementInfo.finalAgreementId
                                                            )
                                                        }
                                                        className="btn-text-link-uline agmt-title p-0 text-left"
                                                        aria-label={`View / Download Agreement for ${agreementInfo.programName}`}
                                                        type="button"
                                                    >
                                                        {finalAgreementFileName}
                                                    </button>
                                                    {!props.isCorporation &&
                                                        !props.isEUP && (
                                                            <button
                                                                disabled={
                                                                    !editMode
                                                                }
                                                                type="button"
                                                                className="btn btn-text ml-3 font-red"
                                                                onClick={() => {
                                                                    uploadAgreementShowModal();
                                                                }}
                                                                aria-label="Upload Final Agreement"
                                                            >
                                                                <i
                                                                    className="aha-icon-cloud-upload icon-bold pr-1 upload-agr-icon"
                                                                    title="Upload Final Agreement"
                                                                />
                                                            </button>
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                <div
                                    className="form-group row"
                                    role="group"
                                    aria-labelledby="permissions-agreement"
                                >
                                    <div className="col-md-5 form-label">
                                        <label id="permissions-agreement">
                                            Permissions Agreement:
                                        </label>
                                    </div>
                                    <div className="col-md-7">
                                        <div>
                                            <div className="form-check mb-2">
                                                <input
                                                    name="permissionEvents"
                                                    onChange={handleChange}
                                                    checked={
                                                        agreementInfo.permissionEvents ==
                                                        true
                                                    }
                                                    type="checkbox"
                                                    value=""
                                                    id="agreementRecognitionEvents"
                                                    disabled={getDisabledInd()}
                                                />
                                                <label htmlFor="agreementRecognitionEvents">
                                                    Recognition Events
                                                </label>
                                            </div>
                                            <div className="form-check mb-2">
                                                <input
                                                    name="permissionMedia"
                                                    onChange={handleChange}
                                                    checked={
                                                        agreementInfo.permissionMedia ==
                                                        true
                                                    }
                                                    type="checkbox"
                                                    value=""
                                                    id="agreementWEbsiteAndMedia"
                                                    disabled={getDisabledInd()}
                                                />
                                                <label
                                                    htmlFor="agreementWEbsiteAndMedia"
                                                    aria-label="American Heart Association website, digital media, mobile apps"
                                                >
                                                    <span aria-hidden="true">
                                                        AHA{" "}
                                                    </span>
                                                    <span className="sr-only">
                                                        American heart
                                                        Association
                                                    </span>
                                                    website, digital media,
                                                    mobile apps
                                                </label>
                                            </div>
                                            <div className="form-check mb-2">
                                                <input
                                                    name="permissionBanners"
                                                    onChange={handleChange}
                                                    checked={
                                                        agreementInfo.permissionBanners ==
                                                        true
                                                    }
                                                    type="checkbox"
                                                    value=""
                                                    id="agreementBanners"
                                                    disabled={getDisabledInd()}
                                                />
                                                <label htmlFor="agreementBanners">
                                                    Conference banners and
                                                    signage
                                                </label>
                                            </div>
                                            <div className="form-check mb-2">
                                                <input
                                                    name="permissionAds"
                                                    onChange={handleChange}
                                                    checked={
                                                        agreementInfo.permissionAds ==
                                                        true
                                                    }
                                                    type="checkbox"
                                                    value=""
                                                    id="agreementAds"
                                                    disabled={getDisabledInd()}
                                                />
                                                <label htmlFor="agreementAds">
                                                    Program promotion as
                                                    permitted
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {!agreementInfo.agreementReferenceId &&
                                    (agreementInfo.permissionEvents == true ||
                                        agreementInfo.permissionMedia == true ||
                                        agreementInfo.permissionBanners ==
                                            true ||
                                        agreementInfo.permissionAds ==
                                            true) && (
                                        <div className="form-group row required">
                                            <div className="col-md-5 form-label">
                                                <label htmlFor="agrpublicationname">
                                                    Enter exact organization
                                                    name for publications
                                                </label>
                                            </div>
                                            <div className="col-md-7">
                                                <input
                                                    name="publicationName"
                                                    onChange={handleChange}
                                                    value={
                                                        agreementInfo.publicationName
                                                    }
                                                    type="text"
                                                    className="form-control"
                                                    id="agrpublicationname"
                                                    required
                                                    minLength={3}
                                                    maxLength={200}
                                                    aria-describedby="pubName"
                                                />
                                                <div
                                                    className="form-help"
                                                    id="pubName"
                                                >
                                                    (Enter 3 to 200 characters)
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                <div className="fb-table-btn row mx-0 fb-btn-edit">
                                    <div className="col-md-8 offset-md-4 px-0 px-md-3 mb-4 d-flex justify-content-start flex-column flex-sm-row">
                                        <button
                                            type="button"
                                            disabled={disabled}
                                            onClick={cancelEdit}
                                            className="btn btn-round btn-secondary fd-btn-1"
                                            aria-label="Cancel Editing agreement Details"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            disabled={buttonState}
                                            className={`btn btn-round btn-primary fd-btn-2 ${
                                                disabled
                                                    ? CONSTANTS.BUTTON_SPINNER
                                                    : ""
                                            }`}
                                            aria-label="Save agreement Details"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            )}
            {agreementUploadActivateInd && (
                <AgreementFileUpload
                    setAgreementUploadActivateInd={
                        setAgreementUploadActivateInd
                    }
                    agreementUploadActivateInd={agreementUploadActivateInd}
                    onSave={setFileData}
                    uploadId={userAgreementId}
                />
            )}
        </FacilityDetailsWrapper>
    );
};

export default AgreementDetails;
