import styled from "styled-components";

export const MeasuresWrapper = styled.div`
    margin-bottom: 249px;
    @media only screen and (min-width: 576px) {
        margin-bottom: 298px;
    }

    @media only screen and (min-width: 768px) {
        margin-bottom: 50px;
    }
    .aui-modal .modal-header {
        border-bottom: 2px solid rgb(193, 14, 33);
        padding-bottom: 0.5rem;
    }
    .measure-filters {
        color: #000;
        .measure-filter-item {
            flex-grow: 1;
            > div {
                width: 165px;
            }
            @media only screen and (min-width: 768px) {
                flex-grow: unset;
            }
        }
        .measure-filter-item + .measure-filter-item {
            margin-left: 16px;
        }
        .cst-select {
            color: #000;
            flex-grow: 1;
            @media only screen and (min-width: 768px) {
                width: 100%;
            }
        }
    }
    .hidden {
        display: none !important;
    }
    .aui-accordion-content1 {
        padding-top: 60px;
        @media only screen and (min-width: 768px) {
            padding-top: 0;
        }
    }
    .edit-measures-link {
        color: #c10e21;
        position: absolute;
        right: 0;
        top: -18px;
        .btn:hover {
            cursor: pointer;
            text-decoration: underline;
        }
        i:hover {
            cursor: pointer;
        }
        @media only screen and (min-width: 576px) {
            top: -42px;
        }
        @media only screen and (min-width: 768px) {
            top: -42px;
        }
    }
    .measure-banner {
        margin-top: 24px;
        margin-bottom: 24px;
    }
    .warning-message {
        border: 1px solid #8e6d00;
        background-color: #fef0d0;
        color: #815e0b;
        padding: 0.75rem 1.25rem;
        border-radius: 0.25rem;
        position: relative;
    }
    .measures-tab {
        .nav-item {
            display: inline-block;
            button {
                border: 1px solid #e3e3e3;
                border-bottom: none;
                border-radius: 0.25rem 0.25rem 0 0;
                padding: 10px 16px;
                @media only screen and (min-width: 576px) {
                    padding: 10px 22px;
                }
                &.active {
                    border: 1px solid #c10e21;
                    background: #c10e21;
                    color: #fff;
                }
            }
        }
        .nav-item + .nav-item {
            margin-left: 8px;
            @media only screen and (min-width: 576px) {
                margin-left: 14px;
            }
        }
    }
    .nav-link:focus {
        outline: none;
    }
`;
