import React, { createRef, useEffect, useRef, useState } from "react";
import CONSTANTS from "common/constants";
import { useDispatch } from "react-redux";
import store from "app/store";
import { BreadCrumb } from "components/BreadCrumb";
import TableRowCount from "components/TableRowCount";
import FocusTrap from "focus-trap-react";
import { useLocation } from "react-router-dom";
import Pagination from "components/Pagination";
import { titleCase } from "utils/common.utils";
import { showToast } from "components/Toast/toast.slice";
import { UserManagementWrapper } from "./styled";
import {
    dateToLocaleTimeString,
    dismissModal,
    localDateToYYYYMMDDFormatString,
    showModal,
    parseJwt,
} from "../../common/utils";
import { getHospitalById } from "../../api/hospitalApi";

interface users {
    userFirstName: string;
    userLastName: string;
    userContactEmail: string;
    isEUP?: any;
    corporationId: any;
    registryId: any;
}
interface Props {
    users: Array<users>;
    isTableLoading: boolean;
    inputUser: any;
    setInputUser: any;
    isUsersResponseAvailable: boolean;
    roleValue: string;
    pagination: any;
    breadcrumbItems?: any;
    addUserDetails: any;
    setUserEmail: any;
    userEmail: any;
    setDeleteUserId: any;
    deleteUserData: any;
    disabledInd: boolean;
    setAddUserActivateInd: any;
    addUserActivateInd: boolean;
    setDeleteUserActivateInd: any;
    deleteUserActivateInd: any;
    addUserDismissModal: any;
    deleteUserDismissModal: any;
    userLogs?: any;
    logsPagination?: any;
    isAdmin?: boolean;
    isEUP?: any;
    isEMSAdmin?: boolean;
    isCorporation?: any;
    userName?: any;
    setUserName?: any;
    corporationId?: any;
    registryId?: any;
    isRegistry?: any;
    settoggleState?: any;
    isInActive?: boolean;
}

export default function UserManagement({
    users,
    isTableLoading,
    inputUser,
    setInputUser,
    isUsersResponseAvailable,
    roleValue,
    pagination,
    breadcrumbItems,
    addUserDetails,
    setUserEmail,
    userEmail,
    setDeleteUserId,
    deleteUserData,
    disabledInd,
    setAddUserActivateInd,
    addUserActivateInd,
    setDeleteUserActivateInd,
    deleteUserActivateInd,
    addUserDismissModal,
    deleteUserDismissModal,
    userLogs,
    logsPagination,
    isAdmin,
    isEUP,
    isEMSAdmin,
    isCorporation,
    userName,
    setUserName,
    corporationId,
    registryId,
    isRegistry,
    settoggleState,
    isInActive,
}: Props) {
    const location = useLocation();
    const [validateDisabled, setValidateDisabled] = useState<boolean>(false);
    const [viewLogToggle, setViewLogToggle] = useState<boolean>(false);
    const addUserCancelButton = createRef<HTMLButtonElement>();
    const modalRef = useRef<HTMLDivElement | null>(null);
    const modalInnerRef = useRef<HTMLDivElement | null>(null);
    const deleteContentRef = useRef<HTMLDivElement | null>(null);
    const deleteUserCancelButton = createRef<HTMLButtonElement>();
    const userAccessToken = window.localStorage.getItem("userAccessToken");
    const hospitalId: any = localStorage.getItem("selectedHospitalId");
    const programId: any = localStorage.getItem("selectedProgramId");
    const categoryId: any = localStorage.getItem("selectedCategoryId");
    const [logText, setLogText] = useState("");

    useEffect(() => {
        if (CONSTANTS.WINDOW.AUI.Popover) {
            CONSTANTS.WINDOW.AUI.Popover.init();
        }
    });
    const throwErrorMessage = (message: string) => {
        const toast = {
            message,
        };
        store.dispatch(showToast(toast));
    };

    const userActionStatus = (actionStatus: any) => {
        let className = "";
        switch (actionStatus) {
            case CONSTANTS.ADDED:
                className = "aui-table-status-normal";
                break;
            case CONSTANTS.DELETED:
                className = "aui-table-status-deactivated";
                break;
            default:
                className = "aui-table-status-normal";
                break;
        }
        return className;
    };
    const isSafariBrowser = () => {
        if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
            return true;
        }
        return false;
    };

    const handleToggle = () => {
        setViewLogToggle((prevState) => !prevState);
    };
    const facilityLimit = isEUP || isCorporation || isRegistry ? 4 : 5;

    useEffect(() => {
        if (
            (addUserActivateInd || deleteUserActivateInd) &&
            modalInnerRef.current
        ) {
            modalInnerRef.current.setAttribute("tabIndex", "-1");
            modalInnerRef.current.focus();
        }
        if (deleteUserActivateInd && deleteContentRef.current) {
            deleteContentRef.current?.setAttribute("tabIndex", "-1");
            deleteContentRef.current?.focus();
        }
        const handleKeyDown = (event: any) => {
            if (event.key === "Escape") {
                setAddUserActivateInd(false);
                dismissModal();
                deleteUserDismissModal();
            }
        };
        const handleMouseDown = (event: any) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setAddUserActivateInd(false);
                dismissModal();
                deleteUserDismissModal();
            }
            if (modalRef.current && modalRef.current.contains(event.target)) {
                modalInnerRef.current?.removeAttribute("tabIndex");
                deleteContentRef.current?.removeAttribute("tabIndex");
            }
        };

        if (addUserActivateInd || deleteUserActivateInd) {
            document.addEventListener("keydown", handleKeyDown);
            document.addEventListener("mousedown", handleMouseDown);
        }

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
            document.removeEventListener("mousedown", handleMouseDown);
        };
    }, [addUserActivateInd, deleteUserActivateInd]);
    const changeView = () => {
        setViewLogToggle(!viewLogToggle);
        settoggleState(!viewLogToggle);
    };

    useEffect(() => {
        let text = "Organization User Management";

        if (viewLogToggle) {
            if (corporationId) {
                text = "Corporation User Management Logs";
            } else if (registryId) {
                text = "Registry User Management Logs";
            } else {
                text = "Organization User Management Logs";
            }
        } else if (corporationId) {
            text = "Corporation User Management";
        } else if (registryId) {
            text = "Registry User Management";
        }

        setLogText(text);
    }, [viewLogToggle]);

    return (
        <UserManagementWrapper>
            <div className="container">
                {breadcrumbItems && (
                    <div className="mb-n4">
                        <BreadCrumb breadcrumbItems={breadcrumbItems} />
                    </div>
                )}

                <div className="user-mgnt main-pg-heading d-flex justify-content-between align-items-end mb-3">
                    {isEUP ? (
                        isAdmin || isEMSAdmin ? (
                            <h2 className="h3 mb-0">
                                {viewLogToggle
                                    ? "Organization User Management Logs"
                                    : "Organization User Management"}
                            </h2>
                        ) : (
                            <h1 className="h3 mb-0">
                                {viewLogToggle
                                    ? "Organization User Management Logs"
                                    : "Organization User Management"}
                            </h1>
                        )
                    ) : isAdmin || isEMSAdmin ? (
                        <h2 className="h3 mb-0">{logText}</h2>
                    ) : isCorporation ? (
                        corporationId ? (
                            <h1 className="h3 mb-0">{logText}</h1>
                        ) : (
                            <h2 className="h3 mb-0">{logText}</h2>
                        )
                    ) : (
                        <h1 className="h3 mb-0">{logText}</h1>
                    )}

                    <div className="edit-btn-toggle">
                        <input
                            type="checkbox"
                            id="userMgtandViewlog"
                            className="edit-btn-checkbox"
                            role="button"
                            aria-pressed={viewLogToggle}
                            onClick={changeView}
                            onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === " ") {
                                    e.preventDefault();
                                    changeView();
                                }
                            }}
                        />
                        {!viewLogToggle ? (
                            <label
                                htmlFor="userMgtandViewlog"
                                className="toggle-label usr-viewLg"
                            >
                                View Log
                            </label>
                        ) : (
                            <label
                                htmlFor="userMgtandViewlog"
                                className="toggle-label usr-viewLg"
                            >
                                Back
                            </label>
                        )}
                        {viewLogToggle ? (
                            <div
                                aria-live="polite"
                                className="d-block d-md-none sr-only"
                            >
                                Back to User management
                            </div>
                        ) : (
                            <div
                                aria-live="polite"
                                className="d-block d-md-none sr-only"
                            >
                                View Logs
                            </div>
                        )}
                    </div>
                </div>
                {!viewLogToggle && (
                    <div className="user-mgt">
                        <div className="d-flex flex-row justify-content-end align-items-sm-center mb-2">
                            {isUsersResponseAvailable && (
                                <button
                                    id="adduserbutton"
                                    onClick={() => {
                                        if (users.length >= facilityLimit) {
                                            throwErrorMessage(
                                                "You have reached the maximum limit"
                                            );
                                        } else if (isInActive) {
                                            throwErrorMessage(
                                                corporationId
                                                    ? "Please activate the corporation for adding the user"
                                                    : "Please activate the registry for adding the user"
                                            );
                                        } else {
                                            setUserEmail("");
                                            setAddUserActivateInd(true);
                                            showModal();
                                        }
                                    }}
                                    className="btn btn-primary btn-round"
                                >
                                    Add User
                                </button>
                            )}
                            {/* Add User Modal */}
                            {addUserActivateInd ? (
                                <FocusTrap
                                    focusTrapOptions={{
                                        escapeDeactivates: false,
                                        clickOutsideDeactivates: false,
                                    }}
                                >
                                    <div
                                        className="modal fade show aui-modal adduser-modal"
                                        id="addusermodal"
                                        tabIndex={-1}
                                        aria-labelledby="addUserLabel"
                                        aria-modal="true"
                                        role="dialog"
                                        style={{
                                            display: addUserActivateInd
                                                ? "block"
                                                : "none",
                                        }}
                                    >
                                        <div
                                            className="modal-dialog modal-dialog-centered modal-md"
                                            ref={modalRef}
                                        >
                                            <div
                                                className="modal-content"
                                                ref={modalInnerRef}
                                                id="modalInnerRef"
                                            >
                                                <div className="modal-header mb-4">
                                                    <h2
                                                        className="h4"
                                                        id="addUserLabel"
                                                    >
                                                        Validate Email Address
                                                    </h2>
                                                    <button
                                                        type="button"
                                                        className="close"
                                                        aria-label="Close Validate email address modal"
                                                        onClick={
                                                            addUserDismissModal
                                                        }
                                                    >
                                                        <span
                                                            aria-hidden="true"
                                                            className="aha-icon-cross"
                                                        />
                                                    </button>
                                                </div>
                                                <form
                                                    className="reg-form"
                                                    onSubmit={addUserDetails}
                                                >
                                                    <div className="modal-body mb-0">
                                                        <div className="form-group row required mb-4">
                                                            <div className="col-12">
                                                                <input
                                                                    type="email"
                                                                    value={
                                                                        userEmail
                                                                    }
                                                                    onChange={(
                                                                        event
                                                                    ) => {
                                                                        setUserEmail(
                                                                            event
                                                                                .target
                                                                                .value
                                                                        );
                                                                    }}
                                                                    className="form-control"
                                                                    id="registeredEmailId"
                                                                    disabled={
                                                                        validateDisabled
                                                                    }
                                                                    required
                                                                    placeholder="Enter SSO Registered Email Address"
                                                                    pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="d-md-flex flex-row-reverse align-items-center confirmEmail-btns">
                                                            <div className="d-flex flex-sm-row flex-column">
                                                                <button
                                                                    ref={
                                                                        addUserCancelButton
                                                                    }
                                                                    type="button"
                                                                    className="btn btn-secondary btn-round"
                                                                    onClick={
                                                                        addUserDismissModal
                                                                    }
                                                                    aria-label="Cancel Validating Email address"
                                                                >
                                                                    Cancel
                                                                </button>
                                                                <button
                                                                    type="submit"
                                                                    disabled={
                                                                        validateDisabled
                                                                    }
                                                                    className={`btn btn-primary btn-round mt-4 mt-sm-0 ml-sm-4 ${
                                                                        validateDisabled
                                                                            ? CONSTANTS.BUTTON_SPINNER
                                                                            : ""
                                                                    }`}
                                                                    aria-label="Validate Email address"
                                                                >
                                                                    Validate
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </FocusTrap>
                            ) : null}
                        </div>
                        {isTableLoading ? (
                            <div
                                role="alert"
                                aria-live="assertive"
                                aria-label="User management table is loading"
                                className="sr-only"
                            />
                        ) : (
                            ""
                        )}
                        <div className="usr-table mb-5 mt-3">
                            <table
                                className={`aui-responsive-status-table ${
                                    isTableLoading
                                        ? CONSTANTS.TABLE_SPINNER
                                        : ""
                                }`}
                            >
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <div className="aui-th">
                                                First Name
                                            </div>
                                        </th>
                                        <th scope="col">
                                            <div className="aui-th">
                                                Last Name
                                            </div>
                                        </th>
                                        <th scope="col">
                                            <div className="aui-th">Email</div>
                                        </th>
                                        <th scope="col">
                                            <div className="aui-th">
                                                Created Date
                                            </div>
                                        </th>
                                        <th scope="col">
                                            <div className="aui-th justify-content-center">
                                                Action
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.length > 0 &&
                                        users.map(
                                            (user: any, index: number) => {
                                                return (
                                                    <tr
                                                        key={`user-${user.user.userId}`}
                                                        className="aui-table-status-normal"
                                                    >
                                                        <td data-title="First Name">
                                                            <div
                                                                className="aui-td"
                                                                id={`fname-${user.user.userId}`}
                                                            >
                                                                {
                                                                    user.user
                                                                        .userFirstName
                                                                }
                                                            </div>
                                                        </td>
                                                        <td data-title="Last Name">
                                                            <div
                                                                className="aui-td"
                                                                id={`lname-${user.user.userId}`}
                                                            >
                                                                {
                                                                    user.user
                                                                        .userLastName
                                                                }
                                                            </div>
                                                        </td>
                                                        <td data-title="Email">
                                                            <div className="aui-td">
                                                                {
                                                                    user.user
                                                                        .userContactEmail
                                                                }
                                                            </div>
                                                        </td>
                                                        <td data-title="Created Date">
                                                            <div className="aui-td">
                                                                {localDateToYYYYMMDDFormatString(
                                                                    new Date(
                                                                        user.createdAt
                                                                    )
                                                                )}
                                                            </div>
                                                        </td>
                                                        <td data-title="Action">
                                                            <div className="aui-td justify-content-center">
                                                                <div
                                                                    data-toggle="tooltip"
                                                                    data-placement="bottom"
                                                                    title="Delete"
                                                                    className="d-inline-block"
                                                                >
                                                                    <button
                                                                        className="btn btn-text usr-action-icon"
                                                                        onClick={() => {
                                                                            setDeleteUserId(
                                                                                user
                                                                                    .user
                                                                                    .userId
                                                                            );
                                                                            setUserEmail(
                                                                                user
                                                                                    .user
                                                                                    .userContactEmail
                                                                            );
                                                                            setUserName(
                                                                                `${user.user.userFirstName} ${user.user.userLastName}`
                                                                            );
                                                                            setDeleteUserActivateInd(
                                                                                true
                                                                            );
                                                                            showModal();
                                                                        }}
                                                                    >
                                                                        <i
                                                                            className="aha-icon-trash"
                                                                            aria-hidden="true"
                                                                        />
                                                                        <span className="sr-only">
                                                                            {
                                                                                user
                                                                                    .user
                                                                                    .userFirstName
                                                                            }{" "}
                                                                            {
                                                                                user
                                                                                    .user
                                                                                    .userLastName
                                                                            }
                                                                            delete
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                </tbody>
                            </table>
                            <div
                                role="alert"
                                aria-atomic="true"
                                className="table-emptystate-outer"
                            >
                                {users.length < 1 && (
                                    <div>No data to display</div>
                                )}
                            </div>
                            {/* Conformation modal */}
                            {deleteUserActivateInd ? (
                                <FocusTrap
                                    focusTrapOptions={{
                                        escapeDeactivates: false,
                                        clickOutsideDeactivates: false,
                                    }}
                                >
                                    <div
                                        className="modal fade show aui-modal"
                                        id="deleteusermodal"
                                        tabIndex={-1}
                                        aria-labelledby="addUserLabel"
                                        aria-modal="true"
                                        role="dialog"
                                        style={{
                                            display: deleteUserActivateInd
                                                ? "block"
                                                : "none",
                                        }}
                                    >
                                        <div
                                            className="modal-dialog modal-dialog-centered modal-sm"
                                            ref={modalRef}
                                        >
                                            <div
                                                className="modal-content"
                                                id="deleteContentRef"
                                                ref={modalInnerRef}
                                            >
                                                <div className="modal-body p-0">
                                                    {((isAdmin ||
                                                        isEMSAdmin ||
                                                        isCorporation) &&
                                                        users.length > 1) ||
                                                    ((isAdmin ||
                                                        isEMSAdmin ||
                                                        isCorporation) &&
                                                        users.length >= 2) ||
                                                    (((isCorporation &&
                                                        corporationId) ||
                                                        isRegistry ||
                                                        isEUP) &&
                                                        users.length >= 1) ? (
                                                        <>
                                                            <div className="modal-header">
                                                                <h2
                                                                    className="h5"
                                                                    id="addUserLabel"
                                                                >
                                                                    Confirm User
                                                                    Delete
                                                                </h2>
                                                                <button
                                                                    type="button"
                                                                    className="close"
                                                                    aria-label={`${
                                                                        users.length >
                                                                            1 &&
                                                                        "Close Confirmation modal"
                                                                    }`}
                                                                    onClick={
                                                                        deleteUserDismissModal
                                                                    }
                                                                >
                                                                    <span
                                                                        aria-hidden="true"
                                                                        className="aha-icon-cross"
                                                                    />
                                                                </button>
                                                            </div>
                                                            Are you sure you
                                                            want to proceed
                                                            deleting{" "}
                                                            <b>{userName}</b>?
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="modal-header">
                                                                <h2
                                                                    className="h4"
                                                                    id="addUserLabel"
                                                                >
                                                                    Warning
                                                                </h2>
                                                                <button
                                                                    type="button"
                                                                    className="close"
                                                                    aria-label="Close Warning Modal"
                                                                    onClick={
                                                                        deleteUserDismissModal
                                                                    }
                                                                >
                                                                    <span
                                                                        aria-hidden="true"
                                                                        className="aha-icon-cross"
                                                                    />
                                                                </button>
                                                            </div>
                                                            <span className="font-500">
                                                                User cannot be
                                                                deleted.
                                                            </span>{" "}
                                                            {corporationId
                                                                ? "A Corporation"
                                                                : registryId
                                                                ? "A Registry"
                                                                : "An Organization"}{" "}
                                                            must have at least
                                                            one user.
                                                        </>
                                                    )}
                                                    <div className="mt-4 d-flex justify-content-end">
                                                        {((isAdmin ||
                                                            isEMSAdmin ||
                                                            isCorporation) &&
                                                            users.length > 1) ||
                                                        ((isAdmin ||
                                                            isEMSAdmin ||
                                                            isCorporation) &&
                                                            users.length >=
                                                                2) ||
                                                        (((isCorporation &&
                                                            corporationId) ||
                                                            isRegistry ||
                                                            isEUP) &&
                                                            users.length >=
                                                                1) ? (
                                                            <>
                                                                <button
                                                                    disabled={
                                                                        disabledInd
                                                                    }
                                                                    className="btn btn-secondary btn-round btn-sm mx-2 px-4"
                                                                    ref={
                                                                        deleteUserCancelButton
                                                                    }
                                                                    onClick={
                                                                        deleteUserDismissModal
                                                                    }
                                                                >
                                                                    Cancel
                                                                </button>
                                                                <button
                                                                    disabled={
                                                                        disabledInd
                                                                    }
                                                                    className={`btn btn-primary btn-round btn-sm mx-2 ${
                                                                        disabledInd
                                                                            ? `${CONSTANTS.BUTTON_SPINNER}`
                                                                            : "px-4"
                                                                    }`}
                                                                    onClick={() => {
                                                                        deleteUserData();
                                                                    }}
                                                                >
                                                                    Confirm
                                                                </button>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <button
                                                                    disabled={
                                                                        disabledInd
                                                                    }
                                                                    className={`btn btn-primary btn-round btn-sm mx-2 px-4 ${
                                                                        disabledInd
                                                                            ? CONSTANTS.BUTTON_SPINNER
                                                                            : ""
                                                                    }`}
                                                                    ref={
                                                                        deleteUserCancelButton
                                                                    }
                                                                    onClick={
                                                                        deleteUserDismissModal
                                                                    }
                                                                >
                                                                    OK
                                                                </button>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </FocusTrap>
                            ) : null}
                        </div>
                    </div>
                )}
                {viewLogToggle && (
                    <div className="user-logs-mgt">
                        {isTableLoading ? (
                            <div
                                role="alert"
                                aria-live="assertive"
                                aria-label="View log table is loading"
                                className="sr-only"
                            />
                        ) : (
                            ""
                        )}
                        {logsPagination && (
                            <TableRowCount
                                isFirst={logsPagination.isFirst}
                                pageNumber={logsPagination.pageNumber}
                                pageSize={logsPagination.pageSize}
                                isLast={logsPagination.isLast}
                                totalCount={logsPagination.totalCount}
                                roleValue={roleValue}
                                logs
                            />
                        )}
                        <div className="usr-table-logs mb-5 mt-3">
                            <table
                                className={`aui-responsive-status-table ${
                                    isTableLoading
                                        ? CONSTANTS.TABLE_SPINNER
                                        : ""
                                }`}
                            >
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <div className="aui-th">
                                                User Details
                                            </div>
                                        </th>
                                        <th scope="col">
                                            <div className="aui-th">Action</div>
                                        </th>
                                        <th scope="col">
                                            <div className="aui-th">
                                                Date & Time
                                            </div>
                                        </th>
                                        <th scope="col">
                                            <div className="aui-th">
                                                Executed By
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userLogs?.length > 0 &&
                                        userLogs?.map(
                                            (user: any, index: number) => {
                                                const timeStr =
                                                    dateToLocaleTimeString(
                                                        user.created_at
                                                    );
                                                const formattedTime =
                                                    timeStr.replace(
                                                        /\b(am|pm)\b/gi,
                                                        (match) =>
                                                            match.toUpperCase()
                                                    );
                                                return (
                                                    <tr
                                                        key={`user-${user?.user?.userId}`}
                                                        className={userActionStatus(
                                                            user.action_type
                                                        )}
                                                    >
                                                        <td data-title="First Name">
                                                            <div className="aui-td">
                                                                <div>
                                                                    <div className="font-500">
                                                                        <span>
                                                                            {
                                                                                user.userFirstName
                                                                            }{" "}
                                                                        </span>
                                                                        <span>
                                                                            {
                                                                                user.userLastName
                                                                            }{" "}
                                                                        </span>
                                                                    </div>
                                                                    <span>
                                                                        {
                                                                            user.userEmail
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td data-title="Action">
                                                            <div className="aui-td">
                                                                User{" "}
                                                                {titleCase(
                                                                    user.action_type
                                                                )}
                                                            </div>
                                                        </td>
                                                        <td data-title="Date & Time">
                                                            <div className="aui-td">
                                                                {localDateToYYYYMMDDFormatString(
                                                                    new Date(
                                                                        user.created_at
                                                                    )
                                                                )}{" "}
                                                                |{" "}
                                                                {formattedTime}
                                                            </div>
                                                        </td>
                                                        <td data-title="Executed By">
                                                            <div className="aui-td">
                                                                {user.executedUserFirstName &&
                                                                user.executedUserLastName &&
                                                                user.executedUserRoleName ? (
                                                                    <div>
                                                                        <div className="font-500">
                                                                            {
                                                                                user.executedUserFirstName
                                                                            }{" "}
                                                                            {
                                                                                user.executedUserLastName
                                                                            }
                                                                        </div>
                                                                        {user.executedUserRoleName !==
                                                                        "AHA Admin" ? (
                                                                            <div>
                                                                                {user.executedUserRoleName ===
                                                                                    "Hospital Admin" ||
                                                                                user.executedUserRoleName ===
                                                                                    "End User"
                                                                                    ? "Organization User"
                                                                                    : user.executedUserRoleName}
                                                                            </div>
                                                                        ) : (
                                                                            <>
                                                                                <span aria-hidden="true">
                                                                                    AHA
                                                                                    Admin
                                                                                </span>
                                                                                <span className="sr-only">
                                                                                    {` A H A Admin`}
                                                                                </span>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                ) : (
                                                                    <div className="font-500">
                                                                        Admin
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                </tbody>
                            </table>
                            <div
                                role="alert"
                                aria-atomic="true"
                                className="table-emptystate-outer"
                            >
                                {userLogs.length < 1 && (
                                    <div>No data to display</div>
                                )}
                            </div>
                        </div>
                        {pagination && Number(pagination.totalPages) > 0 ? (
                            <Pagination
                                pageNumber={pagination.pageNumber}
                                pageSize={pagination.pageSize}
                                totalCount={pagination.totalCount}
                                totalPages={pagination.totalPages}
                                isFirst={pagination.isFirst}
                                isLast={pagination.isLast}
                                setPageNumber={pagination.setPageNumber}
                                paginationSetLimit={5}
                            />
                        ) : null}
                    </div>
                )}
            </div>
        </UserManagementWrapper>
    );
}
