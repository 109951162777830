import React, { useState, useEffect, useRef, useMemo } from "react";
import store from "app/store";
import { showToast } from "components/Toast/toast.slice";
import { useLocation, useParams, useHistory } from "react-router-dom";
import {
    getHospitalProgramMeasureMetrics,
    saveHospitalMeasureMetrics,
    saveEmsHospitalMeasure,
    saveEmsHospitalMeasureDraft,
} from "api/measuresApi";
import { getHospitalProgramDetails } from "api/hospitalProgramsAPI";
import { MeasuresDateFilter } from "components/MeasuresDateFilter";
import { useDispatch, useSelector } from "react-redux";
import {
    dismissModal,
    getQuarterStartAndEndDate,
    parseJwt,
    redirectionForErrorCode,
    showModal,
    wordSplit,
} from "common/utils";
import CONSTANTS from "common/constants";
import { BreadCrumb } from "components/BreadCrumb";
import EUPBanner from "components/EUPBanner";
import { getCertificationLabel, getEMSAwards } from "api/emsAPI";
import { getActiveServiceYear } from "api/bulkRenewApi";
import ServiceYearDropdown from "components/ServiceYearDropdown";
import MeasureEmptyState from "../../components/MeasureEmptyState";
import MeasuresCSVUpload from "../MeasuresCSVUpload/MeasuresCSVUploadPage";
import MeasureMetricTable from "../../components/MeasureTable/MeasureMetricTable";
import { MeasuresWrapper } from "./styled";
import { logger } from "../../utils/logger.utils";
import Navigator from "../../components/Navigator";
import { getHospitalById } from "../../api/hospitalApi";
import EMSMeasuresTable from "./EMSMeasuresTable/EMSMeasuresTable";
import { getHospitalProgramCertificate } from "../../api/certificateAPI";
import "styles/toggle-switch.scss";
import { setYear } from "components/MeasuresDateFilter/measuresDateFilter.slice";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import Tab from "components/Tab";
import FocusTrap from "focus-trap-react";
import { getPrograms } from "api/programs";

interface Props {
    isRegistry?: boolean;
    selectedCategoryIndex?: number;
    registryHospitalId?: number;
    registryProgramId?: number;
    registryCategoryId?: number;
    isCorporation?: any;
}

const Measures = (props: Props) => {
    const params: any = useParams();
    const location = useLocation();
    const hospitalId = props?.isRegistry
        ? props?.registryHospitalId
        : params.hospitalId;
    const programId = props?.isRegistry
        ? props?.registryProgramId
        : params.programId;
    const categoryId = props?.isRegistry
        ? props?.registryCategoryId
        : params.categoryId;

    const emsInd = categoryId == CONSTANTS.EMS_PROGRAM_CATEGORY_ID;
    const userAccessToken = window.localStorage.getItem("userAccessToken");
    const isEUP =
        userAccessToken &&
        parseJwt(userAccessToken).role_code ===
            CONSTANTS.USER_ROLES.HOSPITAL_ADMIN;
    const isEMS = programId === CONSTANTS.EMS_PROGRAM_ID;
    const [programName, setProgramName] = useState<any>("");
    const [disabled, setDisabled] = useState<any>(true);
    const measuresDateFilter = useSelector((state: any) => {
        return state.measuresDateFilter;
    });
    const [readOnly, setReadOnly] = useState<boolean>(true);
    const [isEditable, setIsEditable] = useState<boolean>(true);
    const [isMeasureLocked, setIsMeasureLocked] = useState<boolean>(false);
    const [measureLockYear, setMeasureLockYear] = useState<any>();
    const [timestamp, setTimestamp] = useState<number>(Date.now); // setTimestamp to refresh Measures Table
    const isAddMeasuresInProgress: boolean = false;
    const [isMeasureTableLoading, setIsMeasureTableLoading] =
        useState<boolean>(false);
    const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
    const [createMeasureMetrics, setCreateMeasureMetrics] =
        useState<boolean>(false);
    const [breadcrumbItems, setBreadcrumbItems] = useState<any>([]);
    const [hospitalMeasureMetrics, setHospitalMeasureMetrics] = useState({
        measuresData: [],
        metricsData: [],
        userData: true,
    });
    const [isTableLoading, setIsTableLoading] = useState<boolean>(true);
    const [measureLock, setMeasureLock] = useState<boolean>(false);
    const [isSaveInProgress, setIsSaveInProgress] = useState<boolean>(false);
    const [modifiedMeasureMetricData, setModifiedMeasureMetricData] =
        useState<any>(null);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(true);
    const [programCategoryId, setProgramCategoryId] = useState<any>();
    const [roleValue, setRoleValue] = useState<string>("none");
    const history = useHistory();
    const [programData, setProgramData] = useState<any>();
    const [certificateData, setCertificateData] = useState<any>();
    const [hospital, setHospital] = useState<any>({});
    const [submitTooltip, setSubmitTooltip] = useState<boolean>(false);
    const currentYear = new Date().getFullYear();
    const [selectedTab, setSelectedTab] = useState<any>(0);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [confirmPopUp, setConfirmPopUp] = useState<boolean>(false);
    const [isMeasureMetricsValues, setIsMeasureMetricsValues] =
        useState<boolean>(false);
    const [confirm, setConfirm] = useState<boolean>(false);
    const modalRef = useRef<HTMLDivElement | null>(null);
    const modalInnerRef = useRef<HTMLDivElement | null>(null);
    const deleteContentRef = useRef<HTMLDivElement | null>(null);
    const handleSelectedTab = (value: any) => {
        setSelectedTab(value);
    };

    useState<boolean>(false);
    const [countryId, setCountryId] = useState<any>();
    const [activeServiceYear, setActiveServiceYear] = useState<any>();
    const [certificateGrantedOn, setCertificateGrantedOn] =
        useState<string>("");
    const tabRef = useRef<any>(null);
    const [isEnterDataClicked, setIsEnterDataClicked] = useState<any>(false);
    const dispatch = useDispatch();
    const [programs, setPrograms] = useState<any>([]);
    const [isChecked, setIsChecked] = useState<boolean>(true);
    const [checkboxes, setCheckboxes] = useState<boolean[]>([]);
    const [uncheckConfirmActivateInd, setUncheckConfirmActivateInd] =
        useState<boolean>(false);
    const [selectedCheckboxId, setSelectedCheckboxId] = useState<any>(0);
    const [selectedGroupId, setSelectedGroupId] = useState<any>(0);
    const dismissConfirmModal = () => {
        setUncheckConfirmActivateInd(false);
        showModal();
    };
    const getDataByGroupId = (measuresData: any, groupId: any) => {
        const groupData = measuresData.filter(
            (m: any) => m.groupId === groupId
        );
        return groupData;
    };
    const resetMeasuresValToNull = (data: any, id: any) => {
        return data.map((itemData: any) =>
            itemData.map((item: any) => {
                if (item.groupId === id) {
                    item.numerator = null;
                    item.denominator = null;
                    item.values.map((val: any) => {
                        val.numerator = null;
                        val.denominator = null;
                        return val;
                    });
                    return item;
                }

                return item;
            })
        );
    };
    const clearValues = () => {
        const measuresData: any = [].concat(
            modifiedMeasureMetricData.measuresData
        );
        const updatedData: any = resetMeasuresValToNull(
            measuresData,
            selectedGroupId
        );
        setModifiedMeasureMetricData({
            ...modifiedMeasureMetricData,
            measuresData: updatedData,
        });
        setDisabled(false);
    };
    const confirmClearValue = async (e: any) => {
        dismissConfirmModal();
        e.preventDefault();
        setCheckboxes((prevState) => {
            const updatedCheckboxes = [...prevState];
            updatedCheckboxes[selectedCheckboxId] =
                !updatedCheckboxes[selectedCheckboxId];
            return updatedCheckboxes;
        });
        setIsChecked(!e.target.checked);
        clearValues();
    };
    const showConfirmModal = async () => {
        setUncheckConfirmActivateInd(true);
        showModal();
    };
    const isGroupHasValue = (groupId: any) => {
        const measuresData: any = [].concat(
            ...modifiedMeasureMetricData.measuresData
        );
        const measures: any = getDataByGroupId(measuresData, groupId);
        for (let i = 0; i < measures.length; i++) {
            for (let j = 0; j < 4; j++) {
                if (
                    isMeasureHasVlue(measures[i].values[j].numerator) ||
                    isMeasureHasVlue(measures[i].values[j].denominator)
                )
                    return true;
            }
        }
        return false;
    };
    const handleCheckboxChange = (event: any, id: any, groupId: any) => {
        if (event.target.checked === false) {
            if (isGroupHasValue(groupId)) showConfirmModal();
            else {
                setCheckboxes((prevState) => {
                    const updatedCheckboxes = [...prevState];
                    updatedCheckboxes[id] = !updatedCheckboxes[id];
                    return updatedCheckboxes;
                });
            }
            setSelectedCheckboxId(id);
            setSelectedGroupId(groupId);
        } else {
            setCheckboxes((prevState) => {
                const updatedCheckboxes = [...prevState];
                updatedCheckboxes[id] = !updatedCheckboxes[id];
                return updatedCheckboxes;
            });
        }
    };
    const isMeasureHasVlue = (nd: any) => {
        return !(nd === null || nd === "" || isNaN(nd));
    };
    const validateGroup = (measureGrp: any) => {
        const codesVal = `${getParsedValue(measureGrp[0])}  &  ${getParsedValue(
            measureGrp[1]
        )}`;
        for (let j = 0; j < 4; j++) {
            if (
                isMeasureHasVlue(measureGrp[0].values[j].numerator) &&
                isMeasureHasVlue(measureGrp[0].values[j].denominator)
            ) {
                for (let k = 0; k < 4; k++) {
                    if (
                        isMeasureHasVlue(measureGrp[1].values[k].numerator) &&
                        isMeasureHasVlue(measureGrp[1].values[k].denominator)
                    ) {
                        throwErrorMessage(
                            `Please enter values for only one of these measures, ${codesVal}. Please deselect the checkbox if you are not entering value for these measures.`
                        );
                        return false;
                    }
                }
            }
        }
        return true;
    };
    const checkInvalidCount = (values: any) => {
        let invalidCount = 0;
        for (let i = 0; i < values.length; i++) {
            if (
                !isMeasureHasVlue(values[i].numerator) &&
                !isMeasureHasVlue(values[i].denominator)
            ) {
                invalidCount++;
            }
        }
        return invalidCount;
    };
    const groupIdList = (measuresData: any) => {
        let groupsId: any = measuresData.map((g: any) => g.groupId);
        groupsId = groupsId.reduce((a: any, c: any) => {
            if (!a.includes(c)) {
                a.push(c);
            }
            return a;
        }, []);
        return groupsId;
    };
    const defaultCheckboxState = (groupLength: any = 4) => {
        const updatedCheckboxes = Array(groupLength).fill(true);
        setCheckboxes(updatedCheckboxes);
    };
    const updateCheckbox = (
        invalidCount: any,
        targetCount: any,
        index: any
    ) => {
        setCheckboxes((prevState) => {
            const updatedCheckboxes = [...prevState];
            if (invalidCount === targetCount) {
                updatedCheckboxes[index] = false;
            } else {
                updatedCheckboxes[index] = true;
            }
            return updatedCheckboxes;
        });
    };
    const checkGroupValForUncheck = (data: any) => {
        const measuresData = [].concat(...data);

        const groupsId = groupIdList(measuresData);

        for (let i = 0; i < groupsId.length; i++) {
            if (i == 0) continue;
            let measures: any = [];
            measures = getDataByGroupId(measuresData, groupsId[i]);
            let invalidCount = 0;
            for (let j = 0; j < measures.length; j++) {
                invalidCount += checkInvalidCount(measures[j].values);
            }
            updateCheckbox(invalidCount, measures.length * 4, i);
        }
    };

    const countryCode: any = localStorage.getItem(
        "selectedHospitalCountryCode"
    );
    const loadMeasuresPage = async () => {
        setIsTableLoading(true);
        if (isEnterDataClicked) {
            setIsEnterDataClicked(false);
        }
        if (CONSTANTS.WINDOW.AUI.Tooltip) {
            CONSTANTS.WINDOW.AUI.Tooltip.init();
        }
        const startDateAndEndDate: string | null = getQuarterStartAndEndDate(
            measuresDateFilter.year,
            measuresDateFilter.quarter,
            categoryId == CONSTANTS.EMS_PROGRAM_CATEGORY_ID
        );
        const year = startDateAndEndDate
            ? startDateAndEndDate.match(/\d{4}/)
            : null;
        setMeasureLockYear(year);

        if (startDateAndEndDate) {
            setHospitalMeasureMetrics({
                ...hospitalMeasureMetrics,
                measuresData: [],
                metricsData: [],
            });
            const hospitalProgramDetails = await getHospitalProgramDetails(
                programId,
                hospitalId
            );
            setProgramData(hospitalProgramDetails.data);
            setProgramCategoryId(hospitalProgramDetails.data.programCategoryId);
            const measureResult = new Promise(async (resolve, reject) => {
                const response = await getHospitalProgramMeasureMetrics(
                    hospitalId,
                    programId,
                    hospitalProgramDetails.data.programCategoryId,
                    startDateAndEndDate?.split("/")[0],
                    startDateAndEndDate?.split("/")[1]
                );
                resolve(response);
            });
            measureResult
                .then((response: any) => {
                    if (
                        response.statusCode == 200 &&
                        hospitalProgramDetails.statusCode == 200
                    ) {
                        setIsEditable(response.data.isEditable);
                        setIsMeasureLocked(
                            response?.data?.measureLockData?.isMeasureLocked
                        );
                        if (
                            response?.data?.measureLockData?.isMeasureLocked ==
                            true
                        ) {
                            setReadOnly(true);
                        }
                        setIsSubmitted(!response.data.isDraft);
                        setProgramName(hospitalProgramDetails.data.programName);

                        setHospitalMeasureMetrics(response.data);
                        if (
                            emsInd &&
                            (!createMeasureMetrics || !isMeasureMetricsValues)
                        ) {
                            defaultCheckboxState(
                                response.data.measuresData.length
                            );
                        } else {
                            checkGroupValForUncheck(response.data.measuresData);
                        }
                        setIsMeasureTableLoading(false);
                        setIsPageLoading(false);
                        setTimeout(() => {
                            setIsTableLoading(false);
                        }, 3000);
                    }
                    setIsMeasureTableLoading(false);
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                    setIsPageLoading(false);
                    setRoleValue("alert");
                    setTimeout(() => {
                        setIsTableLoading(false);
                    }, 3000);
                })
                .catch((err) => {
                    setIsMeasureTableLoading(false);
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                    setIsPageLoading(false);
                    setIsTableLoading(false);
                    logger(err);
                });
        }
    };

    useEffect(() => {
        setModifiedMeasureMetricData({ ...hospitalMeasureMetrics });
    }, [hospitalMeasureMetrics]);

    const isSafariBrowser = () => {
        if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        if (
            !hospitalMeasureMetrics ||
            hospitalMeasureMetrics?.measuresData?.length === 0
        ) {
            setIsPageLoading(true);
        }
        setIsMeasureTableLoading(true);
        if (
            categoryId == CONSTANTS.EMS_PROGRAM_CATEGORY_ID &&
            (certificateData?.isCertificateGenerated ||
                measuresDateFilter.year !== activeServiceYear)
        ) {
            setMeasureLock(true);
        } else {
            setMeasureLock(false);
        }
        if (measuresDateFilter.year && !isMeasureMetricsValues) {
            loadMeasuresPage();
        }
    }, [
        measuresDateFilter.year,
        measuresDateFilter.quarter,
        timestamp,
        isPageLoading,
        isEnterDataClicked,
        props.selectedCategoryIndex,
        props.registryHospitalId,
        props.registryProgramId,
        activeServiceYear,
        certificateData,
        isSubmitted,
        isMeasureMetricsValues,
    ]);

    useEffect(() => {
        setCountryId(localStorage.getItem("selectedHospitalCountryId"));
    }, []);

    useEffect(() => {
        if (
            categoryId == CONSTANTS.EMS_PROGRAM_CATEGORY_ID ||
            (emsInd && hospitalId && measuresDateFilter?.year)
        ) {
            getHospitalProgramCertificate(
                hospitalId,
                programId,
                categoryId,
                measuresDateFilter.year
            ).then((certResponse: any) => {
                if (certResponse.success) {
                    const programCertificate =
                        certResponse.data.programCertficate;
                    setCertificateData(programCertificate);
                    if (
                        emsInd &&
                        hospitalId &&
                        measuresDateFilter?.year &&
                        programCertificate.isCertificateGenerated
                    ) {
                        setCertificateGrantedOn(
                            programCertificate?.programCertificateGrantedOn ||
                                ""
                        );
                    }
                    if (
                        JSON.stringify(programCertificate) !== "{}" ||
                        measuresDateFilter.year !== activeServiceYear
                    ) {
                        setMeasureLock(true);
                    } else {
                        setMeasureLock(false);
                    }
                }
            });
        }

        if (isEUP) {
            const elementsArray: any = [];
            getHospitalById(hospitalId).then((hospitalData: any) => {
                setHospital(hospitalData.data.hospital);
                if (hospitalData.success) {
                    if (programId !== CONSTANTS?.EMS_PROGRAM_ID) {
                        elementsArray.push(
                            {
                                label: "Home",
                                returnPath: {
                                    pathname: CONSTANTS.ROUTES.USER_DASHBOARD,
                                    state: {
                                        pageNum: location?.state?.pageNum,
                                        search: location?.state?.search,
                                    },
                                },
                            },
                            {
                                label: hospitalData.data?.hospital
                                    ?.facilityName,
                                returnPath: {
                                    pathname:
                                        programId !== CONSTANTS?.EMS_PROGRAM_ID
                                            ? "/dashboard"
                                            : `/organizationDetails/${params.hospitalId}/programs/${params.programId}/categories/${params.categoryId}`,
                                    state: {
                                        pageNum: location?.state?.pageNum,
                                        search: location?.state?.search,
                                    },
                                },
                            },
                            {
                                label: "Measures",
                                returnPath: "",
                            }
                        );
                    } else {
                        elementsArray.push(
                            {
                                label: "Home",
                                returnPath: {
                                    pathname: CONSTANTS.ROUTES.USER_DASHBOARD,
                                    state: {
                                        pageNum: location?.state?.pageNum,
                                        search: location?.state?.search,
                                    },
                                },
                            },
                            {
                                label: hospitalData.data?.hospital
                                    ?.facilityName,
                                returnPath: {
                                    pathname:
                                        programId !== CONSTANTS?.EMS_PROGRAM_ID
                                            ? "/dashboard"
                                            : `/organizationDetails/${params.hospitalId}/programs/${params.programId}/categories/${params.categoryId}`,
                                    state: {
                                        pageNum: location?.state?.pageNum,
                                        search: location?.state?.search,
                                    },
                                },
                            }
                        );
                    }
                    setBreadcrumbItems(elementsArray);
                }
            });
        }
    }, [measuresDateFilter, activeServiceYear]);

    useEffect(() => {
        if (categoryId == CONSTANTS.EMS_PROGRAM_CATEGORY_ID) {
            getActiveServiceYear().then((response: any) => {
                setActiveServiceYear(response.data.activeServiceYear.year);
                dispatch(setYear(response.data.activeServiceYear.year));
            });
        }
    }, []);

    useEffect(() => {
        if (uncheckConfirmActivateInd && modalInnerRef.current) {
            modalInnerRef.current.setAttribute("tabIndex", "-1");
            modalInnerRef.current.focus();
        }

        // Keyboard navigation to handle escape key
        const handleKeyDown = (event: any) => {
            if (event.key === "Escape") {
                dismissConfirmModal(); // Call cancelAction on escape
            }
        };

        // Handle clicks outside the modal to dismiss it
        const handleMouseDown = (event: any) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                dismissConfirmModal(); // Call cancelAction when clicking outside
            }
            if (modalRef.current && modalRef.current.contains(event.target)) {
                modalInnerRef.current?.removeAttribute("tabIndex");
            }
        };

        if (uncheckConfirmActivateInd) {
            document.addEventListener("keydown", handleKeyDown);
            document.addEventListener("mousedown", handleMouseDown);
        }

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
            document.removeEventListener("mousedown", handleMouseDown);
        };
    }, [uncheckConfirmActivateInd]);
    useEffect(() => {
        if (emsInd) {
            if (!readOnly) {
                defaultCheckboxState(
                    hospitalMeasureMetrics.measuresData.length
                );
            } else {
                checkGroupValForUncheck(hospitalMeasureMetrics.measuresData);
            }
        }
    }, [readOnly, hospitalMeasureMetrics.measuresData]);
    const addMeasures = async (event: any) => {
        setRoleValue("none");
        event.preventDefault();
        if (emsInd) {
            defaultCheckboxState();
        }
        const startDateAndEndDate: string | null = getQuarterStartAndEndDate(
            measuresDateFilter.year,
            measuresDateFilter.quarter
        );
        if (startDateAndEndDate) {
            setCreateMeasureMetrics(true);
            setReadOnly(false);
            setDisabled(true);
        }
        setRoleValue("alert");
    };

    const handleEMSMeasureChange = (event: any) => {
        event.preventDefault();
        const changedMeasures: any = [].concat(
            modifiedMeasureMetricData.measuresData
        );
        const updatedMeasures = changedMeasures.map((measuresData: any) =>
            measuresData.map((measure: any) => {
                const measureObj = JSON.parse(JSON.stringify(measure));
                const quarter: string[] = ["Q1", "Q2", "Q3", "Q4"];
                for (let index = 0; index < quarter.length; index++) {
                    if (
                        `${measure.code}num${quarter[index]}` ==
                        event.target.name
                    ) {
                        measureObj.values[index].numerator = event.target.value;
                    }
                    if (
                        `${measure.code}den${quarter[index]}` ==
                        event.target.name
                    ) {
                        measureObj.values[index].denominator =
                            event.target.value;
                    }
                }
                return { ...measureObj };
            })
        );
        setModifiedMeasureMetricData({
            ...modifiedMeasureMetricData,
            measuresData: updatedMeasures,
        });

        const updatedMeasuresGroup: any = [].concat(...updatedMeasures);
        let notEmptyInput = false;
        const updatedMeasure = updatedMeasuresGroup;
        for (let i = 0; i < updatedMeasure.length; i++) {
            const numerDenomivalues = updatedMeasure[i].values;
            const measureValue = numerDenomivalues.some(
                (measure: any) => measure.denominator || measure.numerator
            );
            if (measureValue) {
                notEmptyInput = true;
                break;
            }
        }

        if (updatedMeasure && measuresDateFilter.year !== 2024) {
            // blocking measure submit for 2024
            let isInvalid = false;
            for (let i = 0; i < updatedMeasure.length; i++) {
                const measure: any = updatedMeasure[i];
                const measureValue: any = measure.values ? measure.values : [];
                if (measureValue.length && measure.isMandatory) {
                    for (let k = 0; k < measureValue.length; k++) {
                        if (
                            measureValue[k].numerator === "" ||
                            measureValue[k].numerator === null ||
                            measureValue[k].denominator === null ||
                            measureValue[k].denominator === ""
                        ) {
                            if (!isSubmitted) {
                                setSubmitTooltip(true);
                            }
                            isInvalid = true;
                            break;
                        }
                    }
                }
            }
            if (!isInvalid) {
                setSubmitTooltip(false); // Set to false if all the validations are passed
            }
        }
        if (notEmptyInput) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    };

    const handleMeasureChange = (event: any) => {
        event.preventDefault();
        const changedMeasures: any = [].concat(
            modifiedMeasureMetricData.measuresData
        );
        const updatedMeasures = changedMeasures.map((measuresData: any) =>
            measuresData.map((measure: any) =>
                `${measure.code}num` == event.target.name
                    ? {
                          ...measure,
                          numerator: event.target.value
                              ? event.target.value
                              : null,
                      }
                    : `${measure.code}den` == event.target.name
                    ? {
                          ...measure,
                          denominator: event.target.value
                              ? event.target.value
                              : null,
                      }
                    : { ...measure }
            )
        );

        setModifiedMeasureMetricData({
            ...modifiedMeasureMetricData,
            measuresData: updatedMeasures,
        });
        // Enable Submit If at least Measure Entered
        let notEmptyInput = false;
        for (let i = 0; i < updatedMeasures.length; i++) {
            if (updatedMeasures[i].length > 0) {
                const measureValue = updatedMeasures[i].some(
                    (measure: any) => measure.denominator || measure.numerator
                );
                if (measureValue) {
                    notEmptyInput = true;
                    break;
                }
            }
        }

        const metricData = modifiedMeasureMetricData.metricsData.length
            ? modifiedMeasureMetricData.metricsData[0]
            : [];

        if (metricData.length) {
            for (let i = 0; i < metricData.length; i++) {
                if (metricData[i].metricValue && !metricData[i].children) {
                    notEmptyInput = true;
                    break;
                } else if (metricData[i].children?.length > 0) {
                    const childValue = metricData[i].children.some(
                        (childItem: any) => childItem.metricValue
                    );
                    if (childValue) {
                        notEmptyInput = true;
                        break;
                    }
                }
            }
        }

        if (notEmptyInput) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    };

    const handleMetricChange = (event: any) => {
        event.preventDefault();
        const changedMetrics: any = [].concat(
            modifiedMeasureMetricData.metricsData
        );
        const updatedMetrics = changedMetrics.map((metricData: any) =>
            metricData.map((metricItem: any) =>
                `${metricItem.code}` == event.target.name
                    ? {
                          ...metricItem,
                          metricValue: event.target.value
                              ? event.target.value
                              : null,
                      }
                    : metricItem.children?.length > 0
                    ? {
                          ...metricItem,
                          children: metricItem.children.map((childItem: any) =>
                              childItem.code == event.target.name
                                  ? {
                                        ...childItem,
                                        metricValue: event.target.value
                                            ? event.target.value
                                            : null,
                                    }
                                  : { ...childItem }
                          ),
                      }
                    : { ...metricItem }
            )
        );

        setModifiedMeasureMetricData({
            ...modifiedMeasureMetricData,
            metricsData: updatedMetrics,
        });

        // Enable Submit If at least one Metric Entered
        let notEmptyInput = false;
        const metricGroupData: any = [];
        updatedMetrics.forEach((metrics: any) => {
            metricGroupData.push(...metrics);
        });
        for (let i = 0; i < metricGroupData.length; i++) {
            if (
                metricGroupData[i].metricValue &&
                !metricGroupData[i].children
            ) {
                notEmptyInput = true;
                break;
            } else if (metricGroupData[i].children?.length > 0) {
                const childValue = metricGroupData[i].children.some(
                    (childItem: any) => childItem.metricValue
                );
                if (childValue) {
                    notEmptyInput = true;
                    break;
                }
            }
        }

        for (
            let i = 0;
            i < modifiedMeasureMetricData.measuresData.length;
            i++
        ) {
            if (modifiedMeasureMetricData.measuresData[i].length > 0) {
                const measureValue = modifiedMeasureMetricData.measuresData[
                    i
                ].some(
                    (measure: any) => measure.denominator || measure.numerator
                );
                if (measureValue) {
                    notEmptyInput = true;
                    break;
                }
            }
        }
        if (notEmptyInput) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    };

    const throwErrorMessage = (message: string) => {
        const toast = {
            message,
            code: "Error:",
        };
        store.dispatch(showToast(toast));
    };

    const getParsedValue = (measure: any) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(measure.code, "text/xml");
        const codeValue =
            xmlDoc.getElementsByTagName("span")[0]?.childNodes[0]?.nodeValue;
        return codeValue;
    };

    const validateForGroupOne = (groupData: any) => {
        const groupOne = groupData
            .filter((m: any) => m.selectionType === CONSTANTS.ONE)
            .map((m: any) => m);
        const codesVal = `${getParsedValue(groupOne[0])}  &  ${getParsedValue(
            groupOne[1]
        )}`;
        let validate = false;
        for (let j = 0; j < groupOne.length; j++) {
            validate = groupOne[j].values.some((value: any) => {
                if (
                    value.numerator !== null &&
                    value.numerator !== "" &&
                    !isNaN(value.numerator) &&
                    value.denominator !== null &&
                    value.denominator !== "" &&
                    !isNaN(value.denominator)
                ) {
                    return true;
                }
            });
            if (validate) {
                break;
            }
        }
        if (!validate) {
            throwErrorMessage(
                `Please enter values for only one of these measures, ${codesVal}. Please deselect the checkbox if you are not entering value for these measures.`
            );
            return false;
        }
        if (!validate) return false;
        return true;
    };
    const validateForAllNull = (groupAll: any) => {
        let validate = false;
        validate = groupAll.values.some((value: any) => {
            if (
                value.numerator !== null &&
                !isNaN(value.numerator) &&
                value.numerator !== "" &&
                value.denominator !== null &&
                value.denominator !== "" &&
                !isNaN(value.denominator)
            ) {
                return true;
            }
        });
        return validate;
    };
    function isUniformlyFilled(data: any) {
        const allFilled = data.every(
            (item: any) =>
                item.numerator !== null &&
                item.numerator !== "" &&
                item.denominator !== null &&
                item.denominator !== "" &&
                Number(item.numerator) < Number(item.denominator)
        );

        return allFilled;
    }

    function generateErrorMessage(codeValues: any): string {
        let codeValuesArray = codeValues.map((item: any) => item.codeValue);

        const {
            hasAnyEmptyNumerator,
            hasAnyEmptyDenominator,
            hasNumeratorGreaterThanDenominator,
        } = codeValues.reduce(
            (result: any, item: any) => {
                if (item.hasEmptyNumerator) {
                    result.hasAnyEmptyNumerator = true;
                }
                if (item.hasEmptyDenominator) {
                    result.hasAnyEmptyDenominator = true;
                }
                if (item.hasNumeratorGreaterThanDenominator) {
                    result.hasNumeratorGreaterThanDenominator = true;
                }
                return result;
            },
            {
                hasAnyEmptyNumerator: false,
                hasAnyEmptyDenominator: false,
                hasNumeratorGreaterThanDenominator: false,
            }
        );

        const formattedValue =
            codeValuesArray.length === 1
                ? `${codeValuesArray[0]}`
                : `${codeValuesArray
                      .slice(0, -1)
                      .join(", ")} & ${codeValuesArray.at(-1)}`;

        const idLabels = `ID${codeValuesArray.length === 1 ? "" : "'s"}`;

        if (hasAnyEmptyNumerator || hasAnyEmptyDenominator) {
            codeValuesArray = codeValues
                .filter(
                    (el: any) =>
                        (hasAnyEmptyNumerator && el.hasEmptyNumerator) ||
                        (hasAnyEmptyDenominator && el.hasEmptyDenominator)
                )
                .map((item: any) => item.codeValue);

            const formattedValues =
                codeValuesArray.length === 1
                    ? `${codeValuesArray[0]}`
                    : `${codeValuesArray
                          .slice(0, -1)
                          .join(", ")} & ${codeValuesArray.at(-1)}`;

            const idLabel = `ID${codeValuesArray.length === 1 ? "" : "'s"}`;
            if (hasAnyEmptyNumerator && hasAnyEmptyDenominator) {
                return `Please ensure all the required values are filled for Measure ${idLabel}: ${formattedValues}`;
            }

            return `Please fill ${
                hasAnyEmptyNumerator ? "numerator" : "denominator"
            } for Measure ${idLabel}: ${formattedValues} for all quarters.`;
        }

        if (hasNumeratorGreaterThanDenominator) {
            codeValuesArray = codeValues
                .filter((el: any) => el.hasNumeratorGreaterThanDenominator)
                .map((item: any) => item.codeValue);

            const formattedValues =
                codeValuesArray.length === 1
                    ? `${codeValuesArray[0]}`
                    : `${codeValuesArray
                          .slice(0, -1)
                          .join(", ")} & ${codeValuesArray.at(-1)}`;

            const idLabel = `ID${codeValuesArray.length === 1 ? "" : "'s"}`;
            return `Numerator must be less than or equal to denominator for Measure ${idLabel}: ${formattedValues}.`;
        }

        return `Please ensure all the required values are filled for Measure ${idLabels}: ${formattedValue}.`;
    }

    const handleEmsMeasureSubmit = (event: any) => {
        event.preventDefault();
        let changedMeasures: any;
        let validate: boolean = true;
        if (submitTooltip) return false;
        validate = true;
        if (modifiedMeasureMetricData.measuresData) {
            changedMeasures = [].concat(
                ...modifiedMeasureMetricData.measuresData
            );
        }
        for (let i = 1; i < checkboxes.length; i++) {
            const measures = [].concat(...changedMeasures);
            if (checkboxes[1]) {
                validate = validateForGroupOne(measures);
                if (!validate) return false;

                validate = false;
                const groupOne = measures
                    .filter((m: any) => m.selectionType === CONSTANTS.ONE)
                    .map((m: any) => m);
                validate = validateGroup(groupOne);
                if (!validate) return false;
            }
            if (checkboxes[2]) {
                const groupAll = measures
                    .filter((m: any) => m.selectionType === CONSTANTS.ALL)
                    .map((m: any) => m);
                const codesVal = `${getParsedValue(groupAll[0])}`;
                validate = validateForAllNull(groupAll[0]);
                if (!validate) {
                    throwErrorMessage(
                        `Please enter values for ${codesVal}. Please deselect the checkbox if you are not entering value for this measure.`
                    );
                    return false;
                }
                if (!validate) return false;
            }
            if (checkboxes[3]) {
                const groupAll35 = measures
                    .filter((m: any) => m.groupId === 35)
                    .map((m: any) => m);
                const codesVal = `${getParsedValue(
                    groupAll35[0]
                )}  &  ${getParsedValue(groupAll35[1])}`;
                logger(checkboxes);
                for (let k = 0; k < groupAll35.length; k++) {
                    validate = validateForAllNull(groupAll35[k]);
                    if (!validate) {
                        continue;
                    }
                    if (validate) break;
                }
                if (!validate) {
                    throwErrorMessage(
                        `Please enter values for atleast one of these measures, ${codesVal}. Please deselect the checkbox if you are not entering value for these measures.`
                    );
                    return false;
                }
                if (!validate) return false;
            }
        }
        if (!validate) return false;

        const errorText: any = [];
        validate = true;
        for (let j = 0; j < checkboxes.length; j++) {
            if (checkboxes[j]) {
                for (let i = 0; i < changedMeasures.length; i++) {
                    const measure = changedMeasures[i];
                    const measureValue = measure.values || [];

                    if (measureValue.length) {
                        const isInvalid = measureValue.some((value: any) => {
                            return (
                                (value.numerator || value.denominator) &&
                                !isUniformlyFilled(measureValue)
                            );
                        });

                        if (isInvalid) {
                            const {
                                hasEmptyNumerator,
                                hasEmptyDenominator,
                                hasNumeratorGreaterThanDenominator,
                            } = measureValue.reduce(
                                (result: any, value: any) => {
                                    const { numerator, denominator } = value;

                                    if (
                                        numerator === null ||
                                        numerator === ""
                                    ) {
                                        result.hasEmptyNumerator = true;
                                    }
                                    if (
                                        denominator === null ||
                                        denominator === ""
                                    ) {
                                        result.hasEmptyDenominator = true;
                                    }
                                    if (
                                        numerator != null &&
                                        numerator != "" &&
                                        denominator != null &&
                                        denominator != "" &&
                                        Number(numerator) > Number(denominator)
                                    ) {
                                        result.hasNumeratorGreaterThanDenominator =
                                            true;
                                    }

                                    return result;
                                },
                                {
                                    hasEmptyNumerator: false,
                                    hasEmptyDenominator: false,
                                    hasNumeratorGreaterThanDenominator: false,
                                }
                            );

                            if (
                                hasEmptyNumerator ||
                                hasEmptyDenominator ||
                                hasNumeratorGreaterThanDenominator
                            ) {
                                errorText.push({
                                    codeValue: getParsedValue(measure),
                                    hasEmptyNumerator,
                                    hasEmptyDenominator,
                                    hasNumeratorGreaterThanDenominator,
                                });

                                validate = false;
                            }
                        }
                    }
                }

                if (errorText.length !== 0) {
                    if (submitTooltip && !isSubmitted) return false;
                    throwErrorMessage(generateErrorMessage(errorText));
                }

                if (!validate) return false;
            }
        }

        if (!validate) return false;
        const startDateAndEndDate: string | null = getQuarterStartAndEndDate(
            measuresDateFilter.year,
            measuresDateFilter.quarter,
            true
        );
        if (startDateAndEndDate) {
            setDisabled(true);
            setIsSaveInProgress(true);
            const measuresData = JSON.parse(JSON.stringify(changedMeasures));

            measuresData.map((measureItem: any) => {
                for (let i = 0; i < measureItem.values.length; i++) {
                    if (measureItem.values[i].numerator === "")
                        measureItem.values[i].numerator = null;
                    if (measureItem.values[i].denominator === "")
                        measureItem.values[i].denominator = null;
                }
                if (hospitalMeasureMetrics.userData == true) {
                    delete measureItem.startDate;
                    delete measureItem.endDate;
                }
                measureItem.code = getParsedValue(measureItem);
                delete measureItem.hospitalProgramMeasureId;
                delete measureItem.numerator;
                delete measureItem.denominator;
                delete measureItem.denominatorHelptext;
                delete measureItem.description;
                delete measureItem.groupId;
                delete measureItem.groupName;
                delete measureItem.metricHelptext;
                delete measureItem.name;
                delete measureItem.numeratorHelptext;
                delete measureItem.parentPropertyId;
                delete measureItem.propertyId;
                delete measureItem.type;
                delete measureItem.valueType;
                delete measureItem.isInput;
                delete measureItem.calculationType;
                delete measureItem.dashBoardExceptionalType;
                delete measureItem.isSubmitted;
                delete measureItem.isMandatory;
                delete measureItem.measureExpiryDate;
                delete measureItem.startDate;
                delete measureItem.endDate;
                delete measureItem.groupDescription;
                delete measureItem.selectionType;
                delete measureItem.userAction;
            });
            if (!isSubmitted) {
                hospitalMeasureMetrics.userData = false;
            }
            saveEmsHospitalMeasure(
                hospitalId,
                programId,
                categoryId,
                measuresData,
                startDateAndEndDate.substring(0, 4),
                hospitalMeasureMetrics.userData
            )
                .then((response: any) => {
                    if (response.success) {
                        getEMSAwards(
                            hospitalId,
                            programId,
                            categoryId,
                            measuresDateFilter.year
                        )
                            .then((response: any) => {
                                if (response.success) {
                                    const measureResult = new Promise(
                                        async (resolve) => {
                                            const response =
                                                await getHospitalProgramMeasureMetrics(
                                                    hospitalId,
                                                    programId,
                                                    programCategoryId,
                                                    startDateAndEndDate?.split(
                                                        "/"
                                                    )[0],
                                                    startDateAndEndDate?.split(
                                                        "/"
                                                    )[1]
                                                );
                                            resolve(response);
                                        }
                                    );
                                    measureResult.then((response: any) => {
                                        if (response.statusCode == 200) {
                                            setIsEditable(
                                                response.data.isEditable
                                            );
                                            setIsSubmitted(true);
                                            setHospitalMeasureMetrics(
                                                response.data
                                            );
                                        }
                                        if (
                                            programId ===
                                            CONSTANTS?.EMS_PROGRAM_ID
                                        ) {
                                            getHospitalProgramDetails(
                                                programId,
                                                hospitalId
                                            );
                                        }
                                    });
                                    setReadOnly(true);
                                    setIsSaveInProgress(false);
                                    const toast = {
                                        message:
                                            hospitalMeasureMetrics.userData ==
                                            true
                                                ? "Data successfully updated."
                                                : "Data successfully created.",
                                        code: "Success:",
                                        type: "success",
                                    };
                                    store.dispatch(showToast(toast));
                                    window.scrollTo(0, 0);
                                }
                            })
                            .catch((error: any) => {
                                if (!isSubmitted) {
                                    hospitalMeasureMetrics.userData = true;
                                }
                                redirectionForErrorCode(
                                    CONSTANTS.ROUTES.CORPORATION_DASHBOARD,
                                    error,
                                    history,
                                    2000
                                );
                                setDisabled(false);
                                setIsSaveInProgress(false);
                                logger(error);
                            });
                    }
                })
                .catch((error: any) => {
                    if (!isSubmitted) {
                        hospitalMeasureMetrics.userData = true;
                    }
                    redirectionForErrorCode(
                        CONSTANTS.ROUTES.CORPORATION_DASHBOARD,
                        error,
                        history,
                        2000
                    );
                    setDisabled(false);
                    setIsSaveInProgress(false);
                    logger(error);
                });
        }
    };

    const handleMeasureMetricSubmit = (event: any) => {
        event.preventDefault();
        let changedMeasures: any;
        let unifiedMetricData: any = [];
        let validate: boolean = true;
        if (modifiedMeasureMetricData.measuresData) {
            changedMeasures = [].concat(
                ...modifiedMeasureMetricData.measuresData
            );
            for (let i = 0; i < changedMeasures.length; i++) {
                const measure: any = changedMeasures[i];
                if (measure.numerator || measure.denominator) {
                    const codeValue = getParsedValue(measure);
                    if (
                        measure.numerator === null ||
                        measure.numerator === "" ||
                        isNaN(measure.numerator)
                    ) {
                        throwErrorMessage(
                            `Please fill numerator for measure ID - ${codeValue}.`
                        );
                        validate = false;
                        break;
                    }
                    if (
                        measure.denominator === null ||
                        measure.denominator === "" ||
                        isNaN(measure.denominator)
                    ) {
                        throwErrorMessage(
                            `Please fill denominator for measure ID - ${codeValue}.`
                        );
                        validate = false;
                        break;
                    }
                    if (
                        Number(measure.numerator) > Number(measure.denominator)
                    ) {
                        throwErrorMessage(
                            `Numerator must be less than or equal to denominator for measure ID - ${codeValue}.`
                        );
                        validate = false;
                        break;
                    }
                }
            }
        }

        if (!validate) return false; // Stopping API call, If no valid measure.

        // MAKE METRIC DATA INTO A UNIFIED ARRAY
        const changedMetrics: any = [].concat(
            ...modifiedMeasureMetricData.metricsData
        );
        changedMetrics.map((metric: any) => {
            if (metric.children && metric.children.length > 0) {
                unifiedMetricData.push(metric);
                const metricChildren = [].concat(...metric.children);
                unifiedMetricData = unifiedMetricData.concat(metricChildren);
            } else {
                unifiedMetricData.push(metric);
            }
        });

        const startDateAndEndDate: string | null = getQuarterStartAndEndDate(
            measuresDateFilter.year,
            measuresDateFilter.quarter
        );
        if (startDateAndEndDate) {
            setDisabled(true);
            setIsSaveInProgress(true);
            const measuresData = JSON.parse(JSON.stringify(changedMeasures));
            const metricsData = JSON.parse(JSON.stringify(unifiedMetricData));

            measuresData.map((measureItem: any) => {
                if (hospitalMeasureMetrics.userData == true) {
                    delete measureItem.startDate;
                    delete measureItem.endDate;
                }
                measureItem.code = getParsedValue(measureItem);
                measureItem.denominator =
                    typeof measureItem.denominator === "string" &&
                    measureItem.denominator.replace(/^0+/, "") === ""
                        ? parseInt(measureItem.denominator)
                        : measureItem.denominator;
                measureItem.numerator =
                    typeof measureItem.numerator === "string" &&
                    measureItem.numerator.replace(/^0+/, "") === ""
                        ? parseInt(measureItem.numerator)
                        : measureItem.numerator;
                delete measureItem.denominatorHelptext;
                delete measureItem.description;
                delete measureItem.groupId;
                delete measureItem.groupName;
                delete measureItem.metricHelptext;
                delete measureItem.name;
                delete measureItem.numeratorHelptext;
                delete measureItem.parentPropertyId;
                delete measureItem.propertyId;
                delete measureItem.type;
                delete measureItem.valueType;
                delete measureItem.isInput;
                delete measureItem.calculationType;
                delete measureItem.dashBoardExceptionalType;
                delete measureItem.isSubmitted;
                delete measureItem.isMandatory;
                delete measureItem.measureExpiryDate;
                delete measureItem.groupDescription;
                delete measureItem.selectionType;
                delete measureItem.userAction;
            });
            metricsData.map((metricItem: any) => {
                if (hospitalMeasureMetrics.userData == true) {
                    delete metricItem.startDate;
                    delete metricItem.endDate;
                }
                metricItem.code = getParsedValue(metricItem);
                metricItem.metricValue =
                    typeof metricItem.metricValue === "string" &&
                    metricItem.metricValue.replace(/^0+/, "") === ""
                        ? parseInt(metricItem.metricValue)
                        : metricItem.metricValue;
                delete metricItem.denominatorHelptext;
                delete metricItem.description;
                delete metricItem.groupId;
                delete metricItem.groupName;
                delete metricItem.metricHelptext;
                delete metricItem.name;
                delete metricItem.numeratorHelptext;
                delete metricItem.parentPropertyId;
                delete metricItem.propertyId;
                delete metricItem.type;
                delete metricItem.valueType;
                delete metricItem.numerator;
                delete metricItem.denominator;
                delete metricItem.notNeeded;
                delete metricItem.children;
                delete metricItem.isInput;
                delete metricItem.calculationType;
                delete metricItem.dashBoardExceptionalType;
                delete metricItem.isSubmitted;
                delete metricItem.isMandatory;
                delete metricItem.measureExpiryDate;
                delete metricItem.groupDescription;
                delete metricItem.selectionType;
                delete metricItem.userAction;
            });

            saveHospitalMeasureMetrics(
                hospitalId,
                programId,
                categoryId,
                measuresData,
                metricsData,
                startDateAndEndDate?.split("/")[0],
                startDateAndEndDate?.split("/")[1],
                hospitalMeasureMetrics.userData,
                measuresDateFilter.quarter
            )
                .then((response: any) => {
                    if (response.success) {
                        const measureResult = new Promise(async (resolve) => {
                            const response =
                                await getHospitalProgramMeasureMetrics(
                                    hospitalId,
                                    programId,
                                    programCategoryId,
                                    startDateAndEndDate?.split("/")[0],
                                    startDateAndEndDate?.split("/")[1]
                                );
                            resolve(response);
                        });
                        measureResult.then((response: any) => {
                            if (response.statusCode == 200) {
                                setIsEditable(response.data.isEditable);
                                setIsSubmitted(true);
                                setHospitalMeasureMetrics(response.data);
                            }
                        });
                        setReadOnly(true);
                        setIsSaveInProgress(false);
                        const toast = {
                            message:
                                hospitalMeasureMetrics.userData == true
                                    ? "Data successfully updated."
                                    : "Data successfully created.",
                            code: "Success:",
                            type: "success",
                        };
                        store.dispatch(showToast(toast));
                    }
                })
                .catch((error: any) => {
                    redirectionForErrorCode(
                        CONSTANTS.ROUTES.CORPORATION_DASHBOARD,
                        error,
                        history,
                        2000
                    );
                    setDisabled(false);
                    setIsSaveInProgress(false);
                    logger(error);
                });
        }
    };

    const saveDraft = (event: any) => {
        event.preventDefault();
        let changedMeasures: any;
        let validate: boolean = true;
        if (modifiedMeasureMetricData.measuresData) {
            changedMeasures = [].concat(
                ...modifiedMeasureMetricData.measuresData
            );
            for (let i = 0; i < changedMeasures.length; i++) {
                const measure: any = changedMeasures[i];
                const measureValue: any = measure.values ? measure.values : [];
                if (measureValue.length) {
                    for (let k = 0; k < measureValue.length; k++) {
                        if (
                            measureValue[k].numerator ||
                            measureValue[k].denominator
                        ) {
                            const codeValue = getParsedValue(measure);
                            if (
                                measureValue[k].numerator === null ||
                                measureValue[k].numerator === "" ||
                                isNaN(measureValue[k].numerator)
                            ) {
                                throwErrorMessage(
                                    `Please fill numerator for measure ID - ${codeValue}.`
                                );
                                validate = false;
                                break;
                            }
                            if (
                                measureValue[k].denominator === null ||
                                measureValue[k].denominator === "" ||
                                isNaN(measureValue[k].denominator)
                            ) {
                                throwErrorMessage(
                                    `Please fill denominator for measure ID - ${codeValue}.`
                                );
                                validate = false;
                                break;
                            }
                            if (
                                Number(measureValue[k].numerator) >
                                Number(measureValue[k].denominator)
                            ) {
                                throwErrorMessage(
                                    `Numerator must be less than or equal to denominator for measure ID - ${codeValue}.`
                                );
                                validate = false;
                                break;
                            }
                        }
                    }
                }
            }
            if (!validate) return false;
            if (checkboxes[1]) {
                const mGroup = changedMeasures.filter((g: any) => {
                    if (g.selectionType === CONSTANTS.ONE) {
                        return g;
                    }
                });
                validate = validateGroup(mGroup);
                if (!validate) return false;
            }
        }
        if (!validate) return false; // Stopping API call, If no valid measure.

        const startDateAndEndDate: string | null = getQuarterStartAndEndDate(
            measuresDateFilter.year,
            measuresDateFilter.quarter,
            true
        );
        if (startDateAndEndDate) {
            setDisabled(true);
            setIsSaveInProgress(true);
            const measuresData = JSON.parse(JSON.stringify(changedMeasures));
            measuresData.map((val: any) => {
                for (let i = 0; i < val.values.length; i++) {
                    if (val.values[i].numerator === "")
                        val.values[i].numerator = null;
                    if (val.values[i].denominator === "")
                        val.values[i].denominator = null;
                }
            });

            measuresData.map((measureItem: any) => {
                if (hospitalMeasureMetrics.userData == true) {
                    delete measureItem.startDate;
                    delete measureItem.endDate;
                }
                measureItem.code = getParsedValue(measureItem);
                delete measureItem.hospitalProgramMeasureId;
                delete measureItem.numerator;
                delete measureItem.denominator;
                delete measureItem.denominatorHelptext;
                delete measureItem.description;
                delete measureItem.groupId;
                delete measureItem.groupName;
                delete measureItem.metricHelptext;
                delete measureItem.name;
                delete measureItem.numeratorHelptext;
                delete measureItem.parentPropertyId;
                delete measureItem.propertyId;
                delete measureItem.type;
                delete measureItem.valueType;
                delete measureItem.isInput;
                delete measureItem.calculationType;
                delete measureItem.dashBoardExceptionalType;
                delete measureItem.isSubmitted;
                delete measureItem.isMandatory;
                delete measureItem.measureExpiryDate;
                delete measureItem.groupDescription;
                delete measureItem.selectionType;
                delete measureItem.userAction;
            });

            saveEmsHospitalMeasureDraft(
                hospitalId,
                programId,
                categoryId,
                measuresData,
                startDateAndEndDate.substring(0, 4)
            )
                .then((response: any) => {
                    if (response.success) {
                        const measureResult = new Promise(async (resolve) => {
                            const response =
                                await getHospitalProgramMeasureMetrics(
                                    hospitalId,
                                    programId,
                                    programCategoryId,
                                    startDateAndEndDate?.split("/")[0],
                                    startDateAndEndDate?.split("/")[1]
                                );
                            resolve(response);
                        });
                        measureResult.then((response: any) => {
                            if (response.statusCode == 200) {
                                setIsEditable(response.data.isEditable);
                                setHospitalMeasureMetrics(response.data);
                                checkGroupValForUncheck(
                                    response.data.measuresData
                                );
                            }
                        });
                        setReadOnly(true);
                        setIsSaveInProgress(false);
                        setIsSubmitted(false);
                        const toast = {
                            message:
                                hospitalMeasureMetrics.userData == true
                                    ? "Draft data successfully updated."
                                    : "Data is successfully saved as draft.",
                            code: "Success:",
                            type: "success",
                        };
                        store.dispatch(showToast(toast));
                        window.scrollTo(0, 0);
                    }
                })
                .catch((error: any) => {
                    redirectionForErrorCode(
                        CONSTANTS.ROUTES.CORPORATION_DASHBOARD,
                        error,
                        history,
                        2000
                    );
                    setDisabled(false);
                    setIsSaveInProgress(false);
                    logger(error);
                });
        }
    };

    const enableEdit = () => {
        if (readOnly == false) {
            setDisabled(true);
        }
        setReadOnly(!readOnly);
    };

    const hideUploadDataButton =
        categoryId == CONSTANTS.EMS_PROGRAM_CATEGORY_ID &&
        (!!isMeasureLocked ||
            certificateData?.isCertificateGenerated ||
            measuresDateFilter.year != activeServiceYear);

    const measureMetricValueIndicator = () => {
        if (readOnly) {
            return false;
        }
        const hasMetricValue =
            hospitalMeasureMetrics?.userData === false
                ? modifiedMeasureMetricData?.metricsData
                      .flatMap((val: any) =>
                          val.some((val: any) => {
                              return (
                                  val.metricValue !== null &&
                                  val?.metricValue !== "" &&
                                  val?.metricValue !== undefined
                              );
                          })
                      )
                      .some(Boolean)
                : !readOnly;
        const hasChildValue =
            hospitalMeasureMetrics?.userData === false
                ? modifiedMeasureMetricData?.metricsData
                      ?.flatMap((val: any) =>
                          val?.flatMap((val: any) =>
                              val?.children?.some(
                                  (child: any) =>
                                      child?.metricValue !== null &&
                                      child?.metricValue !== "" &&
                                      child?.metricValue !== undefined
                              )
                          )
                      )
                      .some(Boolean)
                : !readOnly;

        const hasEmsMeasureValue =
            hospitalMeasureMetrics?.userData === false
                ? modifiedMeasureMetricData?.measuresData
                      ?.flatMap((val: any) => {
                          return val?.flatMap((val: any) =>
                              val?.values?.some(
                                  (val: any) =>
                                      (val?.numerator &&
                                          val?.numerator !== null &&
                                          val?.numerator !== "") ||
                                      (val?.denominator &&
                                          val?.denominator !== null &&
                                          val?.denominator !== "")
                              )
                          );
                      })
                      .some(Boolean)
                : !readOnly;

        const hasNonEmsMeasureValue =
            hospitalMeasureMetrics?.userData === false
                ? modifiedMeasureMetricData?.measuresData
                      ?.flatMap((val: any) => {
                          return val?.some(
                              (val: any) =>
                                  hospitalMeasureMetrics?.userData === false &&
                                  ((val?.denominator &&
                                      val?.denominator !== null &&
                                      val?.denominator !== "" &&
                                      val?.denominator !== undefined) ||
                                      (val?.numerator &&
                                          val?.numerator !== null &&
                                          val?.numerator !== "" &&
                                          val?.numerator !== undefined))
                          );
                      })
                      .some(Boolean)
                : !readOnly;

        return (
            hasMetricValue ||
            hasChildValue ||
            hasEmsMeasureValue ||
            hasNonEmsMeasureValue
        );
    };
    const measureMetricHasValues = useMemo(
        () => measureMetricValueIndicator(),
        [modifiedMeasureMetricData, readOnly]
    );

    const uploadDataObject: any = {
        id: "spreadsheet",
        title: "Upload Data",
        onClick: () => {
            setIsEnterDataClicked(false);
            setSubmitTooltip(false);
            setDisabled(true);
            if (hospitalMeasureMetrics?.userData === true || isSubmitted) {
                setShowPopup(false);
            }
            if (measureMetricHasValues) {
                setShowPopup(true);
                showModal();
            }
        },
        disabled:
            categoryId == CONSTANTS.EMS_PROGRAM_CATEGORY_ID &&
            (!!isMeasureLocked ||
                certificateData?.isCertificateGenerated ||
                measuresDateFilter.year != activeServiceYear),
        content: (
            <div
                className="pt-3 justify-content-start program-list flex-column"
                id="measuresUploadData"
            >
                <div className="tabContent">
                    <div>
                        <div className="col-12 p-0">
                            <MeasuresCSVUpload
                                isRegistry={props?.isRegistry}
                                registryHospitalId={props?.registryHospitalId}
                                registryProgramId={props?.registryProgramId}
                                registryCategoryId={props?.registryCategoryId}
                                measeureSelectedYear={measuresDateFilter.year}
                                countryId={countryId}
                            />
                        </div>
                    </div>
                </div>
            </div>
        ),
    };

    const filteredTabsOnContent = [
        {
            id: "data",
            title: "Enter Data",
            onClick: () => {
                setTimestamp(Date.now());
                if (
                    (hospitalMeasureMetrics?.userData === true ||
                        isSubmitted) &&
                    confirmPopUp
                ) {
                    setReadOnly(true);
                }
            },
            content: (
                <div
                    className="pt-3 justify-content-start program-list flex-column"
                    id="measuresEnterData"
                >
                    <div className="tabContent">
                        <div>
                            <div className="col-12 p-0">
                                {isEditable && (
                                    <div className="edit-measures-link d-flex align-items-center">
                                        <div className="edit-btn-toggle">
                                            <input
                                                type="checkbox"
                                                id="editMeasureDetails"
                                                className="edit-btn-checkbox"
                                                role="button"
                                                aria-pressed={readOnly}
                                                onClick={() => {
                                                    setModifiedMeasureMetricData(
                                                        JSON.parse(
                                                            JSON.stringify(
                                                                hospitalMeasureMetrics
                                                            )
                                                        )
                                                    );

                                                    isMeasureLocked
                                                        ? setReadOnly(true)
                                                        : enableEdit();
                                                }}
                                            />
                                            {categoryId !=
                                                CONSTANTS.EMS_PROGRAM_CATEGORY_ID ||
                                            (!isMeasureLocked &&
                                                (JSON.stringify(
                                                    certificateData
                                                ) === "{}" ||
                                                    !(
                                                        certificateData &&
                                                        certificateData.isCertificateGenerated
                                                    )) &&
                                                measuresDateFilter.year ==
                                                    activeServiceYear) ? (
                                                <label
                                                    htmlFor="editMeasureData"
                                                    className="toggle-label"
                                                >
                                                    <>
                                                        {readOnly ? (
                                                            <label
                                                                htmlFor="editMeasureDetails"
                                                                className="toggle-label"
                                                            >
                                                                Edit Data
                                                            </label>
                                                        ) : (
                                                            <label
                                                                htmlFor="editMeasureDetails"
                                                                className="toggle-label"
                                                            >
                                                                Cancel Edit
                                                            </label>
                                                        )}
                                                        {readOnly ? (
                                                            <div
                                                                aria-live="polite"
                                                                className="d-block d-md-none sr-only"
                                                            >
                                                                Edit Measure
                                                                Data
                                                            </div>
                                                        ) : (
                                                            <div
                                                                aria-live="polite"
                                                                className="d-block d-md-none sr-only"
                                                            >
                                                                Cancel Editing
                                                                Measure Data
                                                            </div>
                                                        )}
                                                    </>
                                                </label>
                                            ) : null}
                                        </div>
                                    </div>
                                )}
                                {categoryId !=
                                CONSTANTS.EMS_PROGRAM_CATEGORY_ID ? (
                                    <MeasureMetricTable
                                        data={modifiedMeasureMetricData}
                                        isMeasureTableLoading={
                                            isMeasureTableLoading
                                        }
                                        handleMeasureChange={
                                            handleMeasureChange
                                        }
                                        handleMetricChange={handleMetricChange}
                                        readOnly={readOnly}
                                        submit={handleMeasureMetricSubmit}
                                        disabled={disabled}
                                        isSaveInProgress={isSaveInProgress}
                                        isPageLoading={isPageLoading}
                                    />
                                ) : (
                                    <EMSMeasuresTable
                                        data={modifiedMeasureMetricData}
                                        handleEMSMeasureChange={
                                            handleEMSMeasureChange
                                        }
                                        submit={handleEmsMeasureSubmit}
                                        isTableLoading={isTableLoading}
                                        readOnly={readOnly}
                                        isSaveInProgress={isSaveInProgress}
                                        saveDraft={saveDraft}
                                        isSubmitted={isSubmitted}
                                        submitTooltip={submitTooltip}
                                        disabled={disabled}
                                        isMeasureTableLoading={
                                            isMeasureTableLoading
                                        }
                                        handleOnchangeCheck={
                                            handleCheckboxChange
                                        }
                                        checkboxes={checkboxes}
                                        isPageLoading={isPageLoading}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ),
        },
    ].filter(Boolean);

    if (!hideUploadDataButton) {
        filteredTabsOnContent.push(uploadDataObject);
    }

    const getProgramsList = () => {
        getPrograms(countryCode, true).then((response: any) => {
            if (response.statusCode == 200) {
                setPrograms(response.data);
            }
        });
    };
    useEffect(() => {
        getProgramsList();
    }, []);

    const dismissPopUp = () => {
        setShowPopup(false);
        setIsMeasureMetricsValues(true);
        dismissModal();
        if (tabRef.current) {
            tabRef.current.handleTabClick("data", 0);
        }
    };

    const confirmUploadTabPopUp = () => {
        if (tabRef.current) {
            tabRef.current.handleTabClick("spreadsheet", 1);
        }
        setShowPopup(false);
        setConfirmPopUp(true);
        setIsMeasureMetricsValues(false);
        setIsMeasureTableLoading(false);
        dismissModal();
    };

    useEffect(() => {
        if (confirmPopUp) {
            if (tabRef.current) {
                tabRef.current.handleTabClick("spreadsheet", 1);
            }
            setShowPopup(false);
        }
        setConfirm(confirmPopUp);
    }, [confirmPopUp, confirm, isMeasureMetricsValues]);

    useEffect(() => {
        if (showPopup && modalInnerRef.current) {
            modalInnerRef.current.setAttribute("tabIndex", "-1");
            modalInnerRef.current.focus();
        }

        const handleKeyDown = (event: any) => {
            if (event.key === "Escape") {
                dismissPopUp();
                dismissModal();
            }
        };

        const handleMouseDown = (event: any) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                dismissPopUp();
            }
            if (modalRef.current && modalRef.current.contains(event.target)) {
                modalInnerRef.current?.removeAttribute("tabIndex");
            }
        };

        if (showPopup) {
            document.addEventListener("keydown", handleKeyDown);
            document.addEventListener("mousedown", handleMouseDown);
        }

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
            document.removeEventListener("mousedown", handleMouseDown);
        };
    }, [showPopup]);

    const dismissConfirmPopUp = () => {
        setShowPopup(false);
        showModal();
    };
    return (
        <MeasuresWrapper>
            {!props?.isRegistry && !props?.isCorporation && !isEMS ? (
                <Navigator tabName="measures" />
            ) : undefined}
            {isEUP && (
                <div className="container mb-n4">
                    <BreadCrumb breadcrumbItems={breadcrumbItems} />
                </div>
            )}
            {props?.isRegistry || props?.isCorporation ? undefined : (
                <div className="container">
                    <div className="main-pg-heading mb-2 d-flex justify-content-between align-items-center">
                        {props?.isCorporation ? (
                            ""
                        ) : (
                            <h1 className="h3 mb-0" id="measuresTitle">
                                Measures
                            </h1>
                        )}
                        {categoryId == CONSTANTS.EMS_PROGRAM_CATEGORY_ID && (
                            <ServiceYearDropdown
                                emsInd={
                                    categoryId ==
                                    CONSTANTS.EMS_PROGRAM_CATEGORY_ID
                                }
                                hospitalId={hospitalId}
                            />
                        )}
                    </div>
                </div>
            )}
            {hospital &&
                programData &&
                programData.programCategoryId ==
                    CONSTANTS.EMS_PROGRAM_CATEGORY_ID && (
                    <div className="container measure-banner">
                        <EUPBanner
                            hospital={hospital}
                            programData={programData}
                            certificateGrantedOn={certificateGrantedOn}
                            isCorporation={props?.isCorporation}
                            isRegistry={props?.isRegistry}
                        />
                    </div>
                )}
            {(isSubmitted || !isSaveInProgress) &&
                !isMeasureLocked &&
                !isMeasureTableLoading &&
                hospitalMeasureMetrics &&
                hospitalMeasureMetrics.userData &&
                categoryId == CONSTANTS.EMS_PROGRAM_CATEGORY_ID &&
                certificateData?.isCertificateGenerated && (
                    <div className="container">
                        <div className="warning-message fade show">
                            <i
                                className="aha-icon-warning fs-1 mr-2"
                                aria-hidden="true"
                            />
                            {measuresDateFilter.year} Measure Edit is disabled
                            since the submission deadline for this year has
                            passed.
                        </div>
                    </div>
                )}

            {isMeasureLocked &&
                !isMeasureTableLoading &&
                hospitalMeasureMetrics &&
                hospitalMeasureMetrics.userData &&
                categoryId == CONSTANTS.EMS_PROGRAM_CATEGORY_ID &&
                (measuresDateFilter?.year === activeServiceYear
                    ? certificateData?.isCertificateGenerated
                    : JSON.stringify(certificateData) !== "{}") && (
                    <div className="container">
                        <div className="warning-message fade show">
                            <i
                                className="aha-icon-warning fs-1 mr-2"
                                aria-hidden="true"
                            />
                            {measuresDateFilter.year} Measure Edit is disabled
                            since the submission deadline for this year has
                            passed.
                        </div>
                    </div>
                )}

            {isMeasureLocked &&
                measuresDateFilter.year == activeServiceYear &&
                hospitalMeasureMetrics &&
                hospitalMeasureMetrics.userData &&
                !certificateData?.programCertificateGrantedOn && (
                    <div className="container">
                        <div className="warning-message fade show" role="note">
                            <i
                                className="aha-icon-warning p-1 fs-1"
                                aria-hidden="true"
                            />
                            {measuresDateFilter.year} Measures Currently Locked.
                            Please Contact
                            <span className="abbr-code mx-2">
                                {wordSplit("AHA")}
                            </span>
                            Admin.
                        </div>
                    </div>
                )}
            {programName && (
                <div className="container d-lg-flex justify-content-between align-items-center my-3">
                    <h2 className="h4 mt-1 mb-0">{programName}</h2>
                    {emsInd || (
                        <MeasuresDateFilter
                            emsInd={emsInd}
                            hospitalId={hospitalId}
                            setCreateMeasureMetrics={setCreateMeasureMetrics}
                            hospitalMeasureMetrics={hospitalMeasureMetrics}
                            setReadOnly={setReadOnly}
                            setIsMeasureMetricsValues={
                                setIsMeasureMetricsValues
                            }
                        />
                    )}
                </div>
            )}

            {isPageLoading ? (
                <div>
                    <span className="sr-only">
                        {categoryId != CONSTANTS.EMS_PROGRAM_CATEGORY_ID
                            ? `Measures loading for year ${measuresDateFilter.year} and quarter Q${measuresDateFilter.quarter}`
                            : `Measures loading for year ${measuresDateFilter.year}`}
                    </span>
                </div>
            ) : (
                <>
                    {createMeasureMetrics ||
                    (hospitalMeasureMetrics &&
                        hospitalMeasureMetrics.userData == true) ? (
                        <>
                            <div
                                role={roleValue}
                                aria-live="assertive"
                                className="sr-only measure-alert"
                            >
                                Measures table loaded
                            </div>
                            <div className="container">
                                <div>
                                    <div className="measures-tab">
                                        <Tab
                                            handleSelectedTab={
                                                handleSelectedTab
                                            }
                                            tabTitle={filteredTabsOnContent}
                                            tabGroup="measures"
                                            tabLabel="measuresTitle measuresProgram"
                                            setShowpopup={setShowPopup}
                                            setReadOnly={setReadOnly}
                                            showModal={showModal}
                                            setIsEnterDataClicked={
                                                setIsEnterDataClicked
                                            }
                                            ref={tabRef}
                                            readOnly={readOnly}
                                            confirmPopUp={confirm}
                                            setConfirmPopUP={setConfirmPopUp}
                                            measureMetricHasValues={
                                                measureMetricHasValues
                                            }
                                            isMeasureMetricsValues={
                                                isMeasureMetricsValues
                                            }
                                            userData={
                                                hospitalMeasureMetrics?.userData ===
                                                true
                                            }
                                        />
                                    </div>
                                </div>
                                {showPopup && (
                                    <FocusTrap
                                        focusTrapOptions={{
                                            escapeDeactivates: false,
                                            clickOutsideDeactivates: false,
                                        }}
                                    >
                                        <div
                                            className="modal fade show aui-modal d-block"
                                            id="lockUnlockModal"
                                            tabIndex={-1}
                                            aria-labelledby="lockUnlockLabel"
                                            aria-modal="true"
                                            role="dialog"
                                        >
                                            <div
                                                className="modal-dialog modal-dialog-centered modal-sm"
                                                ref={modalRef}
                                            >
                                                <div
                                                    className="modal-content"
                                                    id="exportProgramContentRef"
                                                    ref={modalInnerRef}
                                                >
                                                    <div className="modal-header">
                                                        <h2
                                                            className="h5 mb-0"
                                                            id="bulkRenewModalLabel"
                                                        >
                                                            Confirm Navigation
                                                        </h2>
                                                        <button
                                                            id="closeExportModal"
                                                            type="button"
                                                            className="close"
                                                            aria-label="Close renewing organization modal"
                                                            onClick={
                                                                dismissConfirmPopUp
                                                            }
                                                        >
                                                            <span
                                                                aria-hidden="true"
                                                                className="aha-icon-cross"
                                                            />
                                                        </button>
                                                    </div>
                                                    <div className="modal-body p-0">
                                                        <p>
                                                            You have unsaved
                                                            changes that will be
                                                            lost if you decide
                                                            to continue.{" "}
                                                        </p>
                                                        <div className="mt-2 d-flex justify-content-end">
                                                            <button
                                                                className="btn btn-secondary btn-round btn-sm mx-2"
                                                                data-dismiss="modal"
                                                                onClick={() =>
                                                                    dismissPopUp()
                                                                }
                                                            >
                                                                Cancel
                                                            </button>
                                                            <button
                                                                className="btn btn-primary btn-round btn-sm mx-2"
                                                                data-dismiss="modal"
                                                                onClick={() =>
                                                                    confirmUploadTabPopUp()
                                                                }
                                                            >
                                                                Confirm
                                                            </button>
                                                            <div />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </FocusTrap>
                                )}
                            </div>
                        </>
                    ) : isMeasureLocked &&
                      (!isSubmitted || isSaveInProgress) &&
                      (JSON.stringify(certificateData) === "{}" ||
                          !(
                              certificateData &&
                              certificateData.isCertificateGenerated
                          )) ? (
                        <>
                            <div className="measure-emptystate d-flex justify-content-center flex-column text-center align-items-center py-4">
                                <img
                                    src="/images/folder-lock-icon.svg"
                                    alt="folder icon for e m s"
                                    className="mb-1 folder-icon"
                                />
                                <p className="h5 mb-0">
                                    <span className="font-bold">
                                        {" "}
                                        {measuresDateFilter.year} Measures
                                        Currently Locked.
                                    </span>
                                    <br /> Please Contact
                                    <span className="abbr-code-bold mx-2">
                                        {wordSplit("AHA")}
                                    </span>
                                    Admin
                                </p>
                            </div>
                        </>
                    ) : !createMeasureMetrics &&
                      (!isSubmitted || isSaveInProgress) &&
                      categoryId == CONSTANTS.EMS_PROGRAM_CATEGORY_ID &&
                      certificateData?.isCertificateGenerated ? (
                        <>
                            <div className="measure-emptystate d-flex justify-content-center flex-column text-center align-items-center py-4">
                                <img
                                    src="/images/folder-lock-icon.svg"
                                    alt="folder icon for e m s"
                                    className="mb-1 folder-icon"
                                />
                                <p className="h5 mb-0 font-400">
                                    {measuresDateFilter.year} Measure Edit is
                                    disabled
                                    <br />
                                    since the submission deadline for this year
                                    has passed.
                                </p>
                            </div>
                        </>
                    ) : (
                        <>
                            <MeasureEmptyState
                                add={addMeasures}
                                isEditable={isEditable}
                                isAddMeasuresInProgress={
                                    isAddMeasuresInProgress
                                }
                                measureLock={measureLock}
                            />
                        </>
                    )}
                </>
            )}
            {uncheckConfirmActivateInd && (
                <FocusTrap
                    focusTrapOptions={{
                        escapeDeactivates: false,
                        clickOutsideDeactivates: false,
                    }}
                >
                    <div
                        className="modal fade show aui-modal d-block"
                        id="confirmationModal"
                        tabIndex={-1}
                        aria-labelledby="modalConfirmationLabel"
                        aria-modal="true"
                        role="dialog"
                    >
                        <div
                            className="modal-dialog modal-dialog-centered modal-sm"
                            ref={modalRef}
                        >
                            <div
                                className="modal-content award-modal"
                                ref={modalInnerRef}
                            >
                                <div className="modal-header">
                                    <h2
                                        className="h4"
                                        id="modalConfirmationLabel"
                                    >
                                        Confirmation
                                    </h2>
                                    <button
                                        id="closeExportModal"
                                        type="button"
                                        className="close"
                                        aria-label="Close confirmation modal"
                                        onClick={dismissConfirmModal}
                                    >
                                        <span
                                            aria-hidden="true"
                                            className="aha-icon-cross"
                                        />
                                    </button>
                                </div>
                                <div className="modal-body p-0">
                                    <div className="d-flex">
                                        <div className="pt-2">
                                            <p>
                                                Are you sure you want to
                                                deselect and clear all values
                                                entered?
                                            </p>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end mt-3 flex-column flex-sm-row ">
                                        <button
                                            type="button"
                                            className="btn btn-round btn-secondary fd-btn-1"
                                            aria-label="Cancel confirmation"
                                            onClick={dismissConfirmModal}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-primary btn-round  mt-4  mt-sm-0 ml-3"
                                            onClick={confirmClearValue}
                                        >
                                            Confirm
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </FocusTrap>
            )}
        </MeasuresWrapper>
    );
};

export default Measures;
