import React, { useEffect, useState } from "react";
import CONSTANTS from "common/constants";
import Dropdown from "components/Dropdown";
import { useParams, useHistory } from "react-router-dom";
import { updateHospital } from "api/hospitalApi";
import { showToast } from "components/Toast/toast.slice";
import { useDispatch } from "react-redux";
import { logger } from "utils/logger.utils";
import { getStates } from "api/countriesAPI";
import SearchDropdown from "components/SearchDropdown";
import { redirectionForErrorCode } from "common/utils";
import { FacilityDetailsWrapper } from "./styled";
import "styles/toggle-switch.scss";

interface Props {
    hospital: any;
    setActiveTab?: any;
    activeTab?: any;
    setHospital: any;
    close?: any;
    isCorporation?: any;
    orderId?: any;
    isEUP?: any;
}

export const HospitalDetails = (props: Props) => {
    const [editMode, setEditMode] = useState<boolean>(false);
    const [modifiedHospitalData, setModifiedHospitalData] = useState<any>({
        medicalDirectorTitle: "Mr.",
    });
    const [isRegistrationInProgress, setIsRegistrationInProgress] =
        useState<boolean>(false);
    const [hospital, setHospital] = useState<any>(props.hospital);
    const [disabled, setDisabled] = useState<any>(false);
    const [buttonState, setButtonState] = useState<boolean>(true);
    const [states, setStates] = useState<[]>([]);
    const params: any = useParams();
    const dispatch = useDispatch();
    const hospitalId = props.isEUP
        ? localStorage.getItem("selectedHospitalId")
        : params.hospitalId;
    const history = useHistory();
    const programId: any = localStorage.getItem("selectedProgramId");

    const getStatesList = (countryCode: string) => {
        getStates(countryCode).then((response) => {
            if (response.statusCode === 200) {
                const stateList = response.data;

                if (stateList.length) {
                    const stateMap: any = [];
                    stateList.map((country: any) => {
                        stateMap.push({
                            label: country.name,
                            value: country.name,
                        });
                    });
                    setStates(stateMap);
                }
            }
        });
    };

    useEffect(() => {
        setHospital(props.hospital);
        setModifiedHospitalData({
            ...modifiedHospitalData,
            medicalDirectorTitle: props.hospital.medicalDirectorTitle || "Mr.",
        });
    }, [props.hospital]);

    useEffect(() => {
        if (props.hospital.countryCode === CONSTANTS.DOMESTIC_COUNTRY_CODE) {
            getStatesList(props.hospital.countryCode);
        }
    }, [props.hospital?.countryCode]);

    const handleFacilityChange = (event: any) => {
        setButtonState(false);
        if (event.target.name === "website") {
            setModifiedHospitalData({
                ...modifiedHospitalData,
                [event.target.name]: event.target.value.toLowerCase(),
            });
        } else {
            if (event.target.name == "city") {
                event.target.value = event.target.value.replace(/[><]/g, "");
            }
            setModifiedHospitalData({
                ...modifiedHospitalData,
                [event.target.name]:
                    event.target.value.trim() == "healthRecord_yes"
                        ? true
                        : event.target.value.trim() == "healthRecord_no"
                        ? false
                        : event.target.type == "textarea" ||
                          event.target.name == "facilityName" ||
                          event.target.name == "city" ||
                          event.target.name == "state"
                        ? event.target.value.replace(/[><]/g, "")
                        : event.target.value.trim(),
            });
        }
    };

    const jumpToNextTab = () => {
        props.setActiveTab(props.activeTab === null ? 1 : props.activeTab + 1);
        window.scrollTo(0, CONSTANTS.SCROLLTO_VALUE);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        setDisabled(true);
        setIsRegistrationInProgress(true);

        let requestBody: any = { ...modifiedHospitalData };

        delete requestBody.hospitalId;
        delete requestBody.facilityCode;
        delete requestBody.createdAt;
        delete requestBody.approvalStatus;
        delete requestBody.isActive;
        delete requestBody.approvedDate;
        delete requestBody.rejectedDate;
        delete requestBody.reviewedBy;
        delete requestBody.isDeleted;
        delete requestBody.createdBy;
        delete requestBody.updatedAt;
        delete requestBody.updatedBy;

        if (
            modifiedHospitalData.medicalDirectorName == "" ||
            modifiedHospitalData.medicalDirectorName == null
        ) {
            requestBody = {
                ...requestBody,
                medicalDirectorTitle: null,
                medicalDirectorName: null,
            };
        }
        if (!modifiedHospitalData.healthRecord) {
            delete modifiedHospitalData.erhName;
            delete requestBody.erhName;
        }
        if (hospitalId) {
            updateHospital(hospitalId, requestBody)
                .then((response: any) => {
                    if (response.success) {
                        setDisabled(false);
                        setIsRegistrationInProgress(false);
                        if (response.success) {
                            setHospital(
                                JSON.parse(JSON.stringify(modifiedHospitalData))
                            );

                            const updatedData = { ...modifiedHospitalData };
                            updatedData.updatedAt = null;
                            updatedData.updatedBy = null;
                            if (
                                response &&
                                response.data &&
                                response.data.updatedResult &&
                                response.data.updatedResult.updatedAt
                            ) {
                                updatedData.updatedAt =
                                    response.data.updatedResult.updatedAt;
                            }
                            if (
                                response &&
                                response.data &&
                                response.data.updatedResult &&
                                response.data.updatedResult.updatedBy
                            ) {
                                updatedData.updatedBy =
                                    response.data.updatedResult.updatedBy;
                            }
                            props.setHospital(updatedData);

                            setEditMode(!editMode);
                            const toast = {
                                message: "Data successfully updated.",
                                code: "Success:",
                                type: "success",
                            };
                            dispatch(showToast(toast));
                        } else {
                            throw response.error;
                        }
                    }
                })
                .catch((error: any) => {
                    setIsRegistrationInProgress(false);
                    redirectionForErrorCode(
                        CONSTANTS.ROUTES.CORPORATION_DASHBOARD,
                        error,
                        history,
                        2000
                    );
                    dispatch(showToast(error));
                    logger("error is :: ", error);
                    setDisabled(false);
                });
        }
    };

    const cancelEdit = () => {
        setModifiedHospitalData(hospital);
        setEditMode(!editMode);
    };

    const modifyEditMode = () => {
        setButtonState(true);
        if (!editMode) {
            setModifiedHospitalData({
                ...JSON.parse(JSON.stringify(hospital)),
                ...modifiedHospitalData,
            });
            setEditMode(!editMode);
        } else {
            cancelEdit();
        }
    };

    return (
        <FacilityDetailsWrapper aria-label="Organization Detail tab Content">
            <div className="fd-title-border d-flex justify-content-between">
                <h3 className="h4 font-500">Organization Details</h3>
                {(props.orderId || props.isCorporation || props.isEUP) &&
                    hospital.isActive == true && (
                        <div className="edit-btn-toggle">
                            <input
                                type="checkbox"
                                id="editHospitalDetails"
                                className="edit-btn-checkbox"
                                role="button"
                                aria-pressed={editMode}
                                onClick={modifyEditMode}
                            />
                            {editMode ? (
                                <label
                                    htmlFor="editHospitalDetails"
                                    className="toggle-label"
                                >
                                    Cancel Edit
                                </label>
                            ) : (
                                <label
                                    htmlFor="editHospitalDetails"
                                    className="toggle-label"
                                >
                                    <i
                                        className="aha-icon-edit toggle-edit"
                                        title="Edit Organization Details"
                                        aria-hidden="true"
                                    />
                                    <span className="sr-only">Edit</span>
                                </label>
                            )}
                            {editMode ? (
                                <div
                                    aria-live="polite"
                                    className="d-block d-md-none sr-only"
                                >
                                    Cancel editing organization details
                                </div>
                            ) : (
                                <div
                                    aria-live="polite"
                                    className="d-block d-md-none sr-only"
                                >
                                    Edit organization details
                                </div>
                            )}
                        </div>
                    )}
            </div>

            {!editMode ? (
                <div className="fd-table">
                    <table className="aui-responsive-table aui-table-cols">
                        <tbody>
                            <tr>
                                <th scope="row" className="aui-row-th">
                                    Organization Name
                                </th>
                                <td className="aui-row-td">
                                    {hospital.facilityName || "N/A"}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" className="aui-row-th">
                                    Location
                                </th>
                                <td className="aui-row-td">
                                    {hospital.countryName || "N/A"}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" className="aui-row-th">
                                    Street Address
                                </th>
                                <td className="aui-row-td">
                                    {hospital.streetAddress || "N/A"}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" className="aui-row-th">
                                    City
                                </th>
                                <td className="aui-row-td">
                                    {hospital.city || "N/A"}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" className="aui-row-th">
                                    State / Province
                                </th>
                                <td className="aui-row-td">
                                    {hospital.state || "N/A"}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" className="aui-row-th">
                                    Zip Code / Postal Code
                                </th>
                                <td className="aui-row-td">
                                    {hospital.zipCode || "N/A"}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" className="aui-row-th">
                                    Website
                                </th>
                                <td className="aui-row-td">
                                    {hospital.website || "N/A"}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" className="aui-row-th">
                                    Primary Contact Name
                                </th>
                                <td className="aui-row-td">
                                    {hospital.primaryContactTitle}{" "}
                                    {hospital.primaryContactName}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" className="aui-row-th">
                                    Primary Contact Phone Number
                                </th>
                                <td className="aui-row-td">
                                    {hospital.primaryContactPhoneNumber ||
                                        "N/A"}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" className="aui-row-th">
                                    Primary Contact Email Address
                                </th>
                                <td className="aui-row-td">
                                    {hospital.primaryEmail || "N/A"}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" className="aui-row-th">
                                    Signatory Name
                                </th>
                                <td className="aui-row-td">
                                    {hospital.signatoryNameTitle}{" "}
                                    {hospital.signatoryName}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" className="aui-row-th">
                                    Signatory Email Address
                                </th>
                                <td className="aui-row-td">
                                    {hospital.signatoryEmail || "N/A"}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" className="aui-row-th">
                                    Medical Director Name
                                </th>
                                <td className="aui-row-td">
                                    {hospital.medicalDirectorName
                                        ? `${hospital.medicalDirectorTitle} ${hospital.medicalDirectorName}`
                                        : "N/A"}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" className="aui-row-th">
                                    Medical Director Email Address
                                </th>
                                <td className="aui-row-td">
                                    {hospital.medicalDirectorEmail || "N/A"}
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" className="aui-row-th">
                                    Electronic Health Record
                                </th>
                                <td className="aui-row-td">
                                    {hospital.healthRecord
                                        ? `Yes - ${hospital.erhName}`
                                        : "No"}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {!props.isEUP && (
                        <div className="fb-table-btn d-flex justify-content-center py-4 flex-column flex-sm-row">
                            <button
                                type="button"
                                onClick={props.close}
                                className="btn btn-secondary btn-round fd-btn-1"
                                aria-describedby="admin-facilitydetails"
                            >
                                Close
                            </button>
                            <button
                                type="button"
                                onClick={jumpToNextTab}
                                className="btn btn-primary btn-round fd-btn-2"
                                aria-describedby="admin-programdetails"
                            >
                                Next
                            </button>
                        </div>
                    )}
                </div>
            ) : (
                <div className="fd-form">
                    <form
                        onSubmit={handleSubmit}
                        aria-label="organization detail"
                    >
                        <div className="row">
                            <div className="col-lg-10 d-flex flex-row-reverse">
                                <div className="pb-3 mand-field">
                                    <sup>*</sup>
                                    mandatory fields
                                </div>
                            </div>
                        </div>
                        <fieldset className="row" disabled={disabled}>
                            <div className="col-lg-10">
                                <div className="form-group row required">
                                    <div className="col-md-4 form-label">
                                        <label
                                            htmlFor="facilityName"
                                            className="label-form"
                                        >
                                            Organization Name
                                            <sup>*</sup>
                                        </label>
                                    </div>
                                    <div className="col-md-8">
                                        <input
                                            onChange={handleFacilityChange}
                                            readOnly={
                                                (!props.isCorporation &&
                                                    props.hospital
                                                        .approvalStatus !=
                                                        CONSTANTS.REGISTRED) ||
                                                programId ==
                                                    CONSTANTS.EMS_PROGRAM_ID
                                            }
                                            type="text"
                                            className="form-control"
                                            id="facilityName"
                                            name="facilityName"
                                            maxLength={
                                                CONSTANTS.CERTIFICATE_FACILITY_NAME_LIMIT
                                            }
                                            required
                                            value={
                                                modifiedHospitalData.facilityName
                                            }
                                            aria-describedby="facilityName"
                                        />
                                        <div
                                            className="form-help"
                                            id="facilityName"
                                        >
                                            (Enter 3 to{" "}
                                            {
                                                CONSTANTS.CERTIFICATE_FACILITY_NAME_LIMIT
                                            }{" "}
                                            characters)
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row required">
                                    <div className="col-md-4 form-label">
                                        <label
                                            htmlFor="location"
                                            className="label-form"
                                        >
                                            Location
                                            <sup>*</sup>
                                        </label>
                                    </div>
                                    <div className="col-md-8">
                                        <input
                                            onChange={handleFacilityChange}
                                            type="text"
                                            className="form-control"
                                            id="location"
                                            name="location"
                                            readOnly
                                            required
                                            defaultValue={
                                                modifiedHospitalData.countryName
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="form-group row required">
                                    <div className="col-md-4 form-label">
                                        <label
                                            htmlFor="streetAddress"
                                            className="label-form"
                                        >
                                            Street Address
                                            <sup>*</sup>
                                        </label>
                                    </div>
                                    <div className="col-md-8">
                                        <textarea
                                            onChange={handleFacilityChange}
                                            className="form-control"
                                            id="streetAddress"
                                            name="streetAddress"
                                            required
                                            defaultValue={
                                                modifiedHospitalData.streetAddress
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="form-group row required">
                                    <div className="col-md-4 form-label">
                                        <label
                                            htmlFor="city"
                                            className="label-form"
                                        >
                                            City<sup>*</sup>
                                        </label>
                                    </div>
                                    <div className="col-md-8">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="city"
                                            id="city"
                                            required
                                            value={modifiedHospitalData.city}
                                            onChange={handleFacilityChange}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row required">
                                    <div className="col-md-4 form-label">
                                        <label
                                            className="label-form"
                                            htmlFor="state-facilityDetails"
                                            id="state-facilityDetails-label"
                                        >
                                            State / Province
                                            <sup>*</sup>
                                        </label>
                                    </div>
                                    <div className="col-md-8">
                                        {hospital.countryCode ==
                                        CONSTANTS.DOMESTIC_COUNTRY_CODE ? (
                                            <SearchDropdown
                                                id="state-facilityDetails"
                                                items={states}
                                                selectedValue={
                                                    modifiedHospitalData.state
                                                }
                                                callParentOnSelect={(
                                                    value: any
                                                ) => {
                                                    setModifiedHospitalData({
                                                        ...modifiedHospitalData,
                                                        state: value,
                                                    });
                                                    setButtonState(false);
                                                }}
                                                disabled={
                                                    isRegistrationInProgress
                                                }
                                                label="State / Province"
                                                placeholder="Select"
                                                mandatoryInd
                                            />
                                        ) : (
                                            <>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="state"
                                                    id="state"
                                                    required
                                                    value={
                                                        modifiedHospitalData.state
                                                    }
                                                    onChange={
                                                        handleFacilityChange
                                                    }
                                                />
                                                <div
                                                    className="form-help"
                                                    id="fac-state"
                                                >
                                                    (Enter 2 to 30 characters)
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="form-group row required">
                                    <div className="col-md-4 form-label">
                                        <label
                                            htmlFor="zipCode"
                                            className="label-form"
                                        >
                                            Zip Code / Postal Code
                                            <sup>*</sup>
                                        </label>
                                    </div>
                                    <div className="col-md-8">
                                        <input
                                            minLength={2}
                                            maxLength={9}
                                            pattern="\d*"
                                            type="text"
                                            className="form-control"
                                            name="zipCode"
                                            id="zipCode"
                                            required
                                            defaultValue={
                                                modifiedHospitalData.zipCode
                                            }
                                            onChange={handleFacilityChange}
                                            aria-describedby="facilityZipCode"
                                        />
                                        <div
                                            className="form-help"
                                            id="facilityZipCode"
                                        >
                                            (Enter 2 to 9 characters)
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-4 form-label">
                                        <label htmlFor="website">Website</label>
                                    </div>
                                    <div className="col-md-8">
                                        <input
                                            pattern={
                                                CONSTANTS.REGEX_ALLOW_WEBSITE
                                            }
                                            type="text"
                                            className="form-control"
                                            name="website"
                                            id="website"
                                            defaultValue={
                                                modifiedHospitalData.website
                                            }
                                            onChange={handleFacilityChange}
                                        />
                                    </div>
                                </div>
                                <div
                                    className="form-group row required mb-md-3 mb-0"
                                    role="group"
                                    aria-labelledby="pricontact"
                                >
                                    <div className="col-md-4 form-label">
                                        <label
                                            id="pricontact"
                                            className="label-form"
                                        >
                                            Primary Contact Name
                                            <sup>*</sup>
                                        </label>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="d-flex form-group">
                                            <div className="st-field pr-0 mr-3 title-dropdown">
                                                <Dropdown
                                                    id="primaryContactNameTitle"
                                                    items={
                                                        CONSTANTS.TITLE_DROPDOWN
                                                    }
                                                    selectedValue={
                                                        modifiedHospitalData.primaryContactTitle
                                                    }
                                                    callParentOnSelect={(
                                                        value: any
                                                    ) => {
                                                        setModifiedHospitalData(
                                                            {
                                                                ...modifiedHospitalData,
                                                                primaryContactTitle:
                                                                    value,
                                                            }
                                                        );
                                                        setButtonState(false);
                                                    }}
                                                    disabled={
                                                        isRegistrationInProgress
                                                    }
                                                    ariaInnerLabelValue="Title"
                                                />
                                            </div>
                                            <div className="flex-grow-1">
                                                <label
                                                    htmlFor="primaryContactName"
                                                    className="visuallyhidden"
                                                >
                                                    Primary Contact Name
                                                </label>
                                                <input
                                                    pattern={
                                                        CONSTANTS.REGEX_ALLOW_ALPHABHET_SPACE
                                                    }
                                                    type="text"
                                                    className="form-control"
                                                    name="primaryContactName"
                                                    id="primaryContactName"
                                                    required
                                                    defaultValue={
                                                        modifiedHospitalData.primaryContactName
                                                    }
                                                    onChange={
                                                        handleFacilityChange
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex form-group">
                                            <div className="st-field pr-0 mr-3">
                                                <label
                                                    htmlFor="primaryContactPhoneNumber"
                                                    className="label-form"
                                                >
                                                    Phone
                                                    <sup>*</sup>
                                                </label>
                                            </div>
                                            <div className="flex-grow-1">
                                                <input
                                                    minLength={10}
                                                    maxLength={20}
                                                    pattern="\+?\d*"
                                                    type="text"
                                                    className="form-control"
                                                    name="primaryContactPhoneNumber"
                                                    id="primaryContactPhoneNumber"
                                                    required
                                                    defaultValue={
                                                        modifiedHospitalData.primaryContactPhoneNumber
                                                    }
                                                    onChange={
                                                        handleFacilityChange
                                                    }
                                                    aria-describedby="facilityPhoneNo"
                                                />
                                                <div
                                                    className="form-help"
                                                    id="facilityPhoneNo"
                                                >
                                                    (Enter 10 to 20 characters)
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex form-group">
                                            <label
                                                className="st-field pr-0 mr-3 label-form"
                                                htmlFor="primaryEmail"
                                            >
                                                Email
                                                <sup>*</sup>
                                            </label>
                                            <div className="flex-grow-1">
                                                <input
                                                    pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$"
                                                    type="email"
                                                    className="form-control"
                                                    name="primaryEmail"
                                                    id="primaryEmail"
                                                    required
                                                    defaultValue={
                                                        modifiedHospitalData.primaryEmail
                                                    }
                                                    onChange={
                                                        handleFacilityChange
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div
                                            className="form-group row required mb-md-3 mb-0"
                                            role="group"
                                            aria-labelledby="signatory"
                                        >
                                            <div className="col-md-4 form-label">
                                                <label
                                                    id="signatory"
                                                    className="label-form"
                                                >
                                                    Signatory Name
                                                    <sup>*</sup>
                                                </label>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="d-flex form-group">
                                                    <div className="st-field pr-0 mr-3 title-dropdown">
                                                        <Dropdown
                                                            id="signatoryNameTitle"
                                                            items={
                                                                CONSTANTS.TITLE_DROPDOWN
                                                            }
                                                            selectedValue={
                                                                modifiedHospitalData.signatoryNameTitle
                                                            }
                                                            callParentOnSelect={(
                                                                value: any
                                                            ) => {
                                                                setModifiedHospitalData(
                                                                    {
                                                                        ...modifiedHospitalData,
                                                                        signatoryNameTitle:
                                                                            value,
                                                                    }
                                                                );
                                                                setButtonState(
                                                                    false
                                                                );
                                                            }}
                                                            disabled={
                                                                isRegistrationInProgress
                                                            }
                                                            ariaInnerLabelValue="Title"
                                                        />
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <label
                                                            htmlFor="signatoryName"
                                                            className="visuallyhidden"
                                                        >
                                                            Signatory Name
                                                        </label>
                                                        <input
                                                            pattern={
                                                                CONSTANTS.REGEX_ALLOW_ALPHABHET_SPACE
                                                            }
                                                            type="text"
                                                            className="form-control"
                                                            name="signatoryName"
                                                            id="signatoryName"
                                                            required
                                                            defaultValue={
                                                                modifiedHospitalData.signatoryName
                                                            }
                                                            onChange={
                                                                handleFacilityChange
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="d-flex form-group">
                                                    <label
                                                        className="st-field pr-0 mr-3 label-form"
                                                        htmlFor="signatoryEmail"
                                                    >
                                                        Email
                                                        <sup>*</sup>
                                                    </label>
                                                    <div className="flex-grow-1">
                                                        <input
                                                            pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$"
                                                            type="email"
                                                            className="form-control"
                                                            name="signatoryEmail"
                                                            id="signatoryEmail"
                                                            required
                                                            defaultValue={
                                                                modifiedHospitalData.signatoryEmail
                                                            }
                                                            onChange={
                                                                handleFacilityChange
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div
                                            className="form-group row mb-md-3 mb-0"
                                            role="group"
                                            aria-labelledby="medicalDirector"
                                        >
                                            <div className="col-md-4 form-label">
                                                <label id="medicalDirector">
                                                    Medical Director Name (If
                                                    applicable)
                                                </label>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="d-flex form-group">
                                                    <div className="st-field pr-0 mr-3 title-dropdown">
                                                        <Dropdown
                                                            id="medicalDirectorTitle"
                                                            items={
                                                                CONSTANTS.TITLE_DROPDOWN
                                                            }
                                                            selectedValue={
                                                                modifiedHospitalData.medicalDirectorTitle
                                                            }
                                                            callParentOnSelect={(
                                                                value: any
                                                            ) => {
                                                                setModifiedHospitalData(
                                                                    {
                                                                        ...modifiedHospitalData,
                                                                        medicalDirectorTitle:
                                                                            value,
                                                                    }
                                                                );
                                                                setButtonState(
                                                                    false
                                                                );
                                                            }}
                                                            disabled={
                                                                isRegistrationInProgress
                                                            }
                                                            ariaInnerLabelValue="Title"
                                                        />
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <label
                                                            htmlFor="medicalDirectorName"
                                                            className="visuallyhidden"
                                                        >
                                                            Medical Director
                                                            Name
                                                        </label>
                                                        <input
                                                            pattern={
                                                                CONSTANTS.REGEX_ALLOW_ALPHABHET_SPACE
                                                            }
                                                            type="text"
                                                            className="form-control"
                                                            name="medicalDirectorName"
                                                            id="medicalDirectorName"
                                                            defaultValue={
                                                                modifiedHospitalData.medicalDirectorName
                                                            }
                                                            onChange={
                                                                handleFacilityChange
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="d-flex form-group">
                                                    <label
                                                        className="st-field pr-0 mr-3"
                                                        htmlFor="medicalDirectorEmail"
                                                    >
                                                        Email
                                                    </label>
                                                    <div className="flex-grow-1">
                                                        <input
                                                            pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$"
                                                            type="email"
                                                            className="form-control"
                                                            name="medicalDirectorEmail"
                                                            id="medicalDirectorEmail"
                                                            defaultValue={
                                                                modifiedHospitalData.medicalDirectorEmail
                                                            }
                                                            onChange={
                                                                handleFacilityChange
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="form-group row required"
                                    aria-labelledby="ehrrecord"
                                    role="group"
                                >
                                    <div className="col-md-4 form-label">
                                        <label
                                            id="ehrrecord"
                                            aria-label="Does your site use an Electronic Health Record(E H R)?"
                                            className="label-form"
                                        >
                                            <span className="d-block">
                                                Does your site use an Electronic
                                                Health Record(EHR)?
                                                <sup>*</sup>
                                            </span>
                                            <i className="label-helptext">
                                                (Select NO if you are an EMS
                                                organization.)
                                            </i>
                                        </label>
                                    </div>
                                    <div className="col-md-8">
                                        <div
                                            className="d-flex flex-row flex-wrap"
                                            role="radiogroup"
                                        >
                                            <div className="form-radio mr-4">
                                                <input
                                                    required
                                                    type="radio"
                                                    name="healthRecord"
                                                    value="healthRecord_yes"
                                                    id="recored-yes"
                                                    defaultChecked={
                                                        modifiedHospitalData.healthRecord ===
                                                        true
                                                    }
                                                    onChange={
                                                        handleFacilityChange
                                                    }
                                                />
                                                <label
                                                    htmlFor="recored-yes"
                                                    id="yes-record-ehr"
                                                >
                                                    Yes
                                                </label>
                                            </div>
                                            <div
                                                className="form-radio ml-4"
                                                aria-labelledby="yes-record-ehr"
                                            >
                                                <input
                                                    value="healthRecord_no"
                                                    type="radio"
                                                    name="healthRecord"
                                                    id="recored-no"
                                                    defaultChecked={
                                                        modifiedHospitalData.healthRecord ===
                                                        false
                                                    }
                                                    onChange={
                                                        handleFacilityChange
                                                    }
                                                />
                                                <label htmlFor="recored-no">
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                        {modifiedHospitalData.healthRecord ===
                                            true && (
                                            <div className="ehr form-group row p-2 mt-3 mx-0 align-items-center d-block d-md-flex">
                                                <label
                                                    className=" p-0 pr-3"
                                                    htmlFor="erhName"
                                                    aria-label="Name of E H R"
                                                >
                                                    Name of EHR
                                                </label>
                                                <div className="flex-grow-1">
                                                    <input
                                                        pattern={
                                                            CONSTANTS.REGEX_ALLOW_ALPHANUMERIC_AND_SPACE_ONLY
                                                        }
                                                        type="text"
                                                        className="form-control"
                                                        name="erhName"
                                                        id="erhName"
                                                        defaultValue={
                                                            modifiedHospitalData.erhName
                                                        }
                                                        required={
                                                            modifiedHospitalData.healthRecord ===
                                                            true
                                                        }
                                                        onChange={
                                                            handleFacilityChange
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="form-group row" />
                                <div className="fb-table-btn row mx-0 fb-btn-edit">
                                    <div className="col-md-8 offset-md-4 px-0 px-md-2 d-flex flex-sm-row flex-column">
                                        <button
                                            type="button"
                                            onClick={cancelEdit}
                                            className="btn btn-round btn-secondary fd-btn-1"
                                            aria-label="cancel editing organization Details"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            aria-label="Save organization Details"
                                            disabled={buttonState}
                                            className={`btn btn-round btn-primary fd-btn-2 ${
                                                disabled
                                                    ? CONSTANTS.BUTTON_SPINNER
                                                    : ""
                                            }`}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            )}
        </FacilityDetailsWrapper>
    );
};

export default HospitalDetails;
