import styled from "styled-components";

export const RegistryManagementWrapper = styled.div`
    .reg-magnt {
        margin-bottom: 36px;
    }
    .registry-tab {
        .nav-item {
            margin-right: 2px;
            @media only screen and (min-width: 576px) {
                margin-right: 14px;
            }
            button {
                border: 1px solid #e3e3e3;
                padding: 12px 8px;
                line-height: 1;
                font-weight: 500;
                @media only screen and (min-width: 576px) {
                    padding: 12px 20px;
                }
                &.active {
                    background-color: #c10e21;
                    color: #ffffff;
                    border-color: #c10e21;
                }
            }
        }
    }
`;
