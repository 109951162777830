import React, { useEffect, useState, useRef } from "react";
import CONSTANTS from "common/constants";
import {
    getSubscriptionDetails,
    getPaymentStatus,
    updatePaymentStatus,
    approveHospital,
    renewFacility,
} from "api/adminDashboardAPI";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { hideToast, showToast } from "components/Toast/toast.slice";
import {
    dateToYYYYMMDDFormatString,
    calculateExtendedDate,
    calculateGracePeriod,
    localDateToYYYYMMDDFormatString,
    localDateToYYYYMMDDPlaceholderFormat,
    calculateExtendedDateYYMMMDD,
    dateToYYYYMMDDFormatStringISO,
    dateToYYYYMMDDPlaceholderFormatStringISO,
    calculatNumOfDaysBetweenDates,
    showModal,
    dismissModal,
} from "common/utils";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import FocusTrap from "focus-trap-react";
import store from "app/store";
import DocumentServiceAPI from "api/documentsAPI";
import { FacilityDetailsWrapper } from "./styled";
import DynamicModal from "../../../components/DynamicModal";
import { programSubscription, skipPayment } from "../../../api/paymentAPI";
import { sleep } from "../../../common/utils";
import "styles/toggle-switch.scss";
import webWorkerScript from "../../../common/webWorker";

interface Props {
    setActiveTab: any;
    activeTab: any;
    close: any;
    setPaymentInfo: any;
    hospital: any;
    programCategoryName?: string;
    price?: any;
    isCorporation?: any;
    setPaymentComplete?: any;
    paymentComplete?: boolean;
    certificateExpiryDate?: any;
    detailsInd?: boolean;
}

export const PaymentDetails = ({
    detailsInd,
    setActiveTab,
    activeTab,
    close,
    setPaymentInfo,
    hospital,
    programCategoryName,
    price,
    isCorporation,
    setPaymentComplete,
    paymentComplete,
    certificateExpiryDate,
}: Props) => {
    const [editMode, setEditMode] = useState<boolean>(false);
    const [paymentDetails, setPaymentDetails] = useState<any>("");
    const [prevPaymentState, setPrevPaymentState] = useState<any>("");
    const [paymentMode, setPaymentMode] = useState<any>("");
    const [disabled, setDisabled] = useState<any>(false);
    const [orderId, setOrderId] = useState<any>();
    const [documentapprovalStatus, setDocumentApprovalSatus] =
        useState<boolean>(false);
    const [documentCount, setDocumentCount] = useState<Number>();
    const [buttonState, setButtonState] = useState<boolean>(true);
    const params: any = useParams();
    const dispatch = useDispatch();
    const [docTabInd, setDocTabInd] = useState<boolean>(false);
    const hospitalProgramDetails: any = {
        hospitalId: params.hospitalId,
        programId: params.programId,
        categoryId: params.categoryId,
    };
    const extendedRefDate: any = useRef(null);

    const [isExpiring, setIsExpiring] = useState<boolean>(false);
    const [
        exportProgramMeasureActivateInd,
        setExportProgramMeasureActivateInd,
    ] = useState<boolean>(false);
    const documentServiceAPI = new DocumentServiceAPI();
    const renewBtnRestrict = localStorage.getItem("renewBtnRestrict");
    const exportBtnRestrictStatus = renewBtnRestrict === "true";
    const [renewButtonDisabled, setRenewButtonDisabled] = useState<boolean>(
        exportBtnRestrictStatus
    );
    const [statusAfterRenew, setStatusAfterRenew] = useState<boolean>(false);
    const modalRef = useRef<HTMLDivElement | null>(null);
    const renewContentRef = useRef<HTMLDivElement | null>(null);
    const [payOfflineInd, setPayOfflineInd] = useState<boolean>(false);
    const [focusState, setFocusState] = useState<boolean>(false);

    useEffect(() => {
        if (certificateExpiryDate) {
            const days = calculatNumOfDaysBetweenDates(
                new Date(),
                certificateExpiryDate
            );
            if ((days > 0 && days <= 50) || days === 0) setIsExpiring(true);
            else setIsExpiring(false);
            if (detailsInd) {
                documentServiceAPI
                    .getDocumentCount(
                        params.hospitalId,
                        params.programId,
                        params.categoryId
                    )
                    .then((response: any) => {
                        if (response.success) {
                            setDocumentApprovalSatus(
                                response.data.documentsPendingForApproval
                            );
                            setDocumentCount(response.data.documentsCount);
                        }
                    });
            }
        }
    }, [certificateExpiryDate, detailsInd]);

    useEffect(() => {
        getSubscriptionDetails(
            params.hospitalId,
            params.programId,
            params.categoryId
        )
            .then(async (response: any) => {
                if (response.success) {
                    setOrderId(response.data.orderId);
                    if (response.data.orderId) {
                        getPaymentStatus(response.data.orderId).then(
                            (res: any) => {
                                setPrevPaymentState(res.data);
                                setPaymentDetails(res.data);
                                setPaymentMode(res.data.modeOfPayment);
                                setPaymentInfo(res.data);
                            }
                        );
                    }
                }
            })
            .catch((error: any) => {
                dispatch(showToast(error));
            });
    }, [paymentComplete]);

    const navigateToDocTab = () => {
        dismissRenewalModal();
        setDocTabInd(true);
    };

    useEffect(() => {
        if (docTabInd) {
            document.getElementById("doctab")?.click();
            setDocTabInd(false);
        }
    }, [docTabInd]);

    const handleChange = (event: any) => {
        setPaymentDetails({
            ...paymentDetails,
            [event.target.name]: event.target.value,
        });
        setButtonState(false);
        if (event.target.name === "paymentDueDate") {
            extendedRefDate.current.value = calculateExtendedDate(
                event.target.value,
                paymentDetails.gracePeriod
            );
        }
    };

    const handleSave = (event: any) => {
        event.preventDefault();
        setDisabled(true);
        let updatedPaymentDetails;

        if (
            paymentMode === CONSTANTS.PAYMENT_OFFLINE &&
            hospital?.approvalStatus === CONSTANTS.PENDING
        ) {
            const { paymentDate, paymentDueDate, amountPaid }: any = {
                ...paymentDetails,
            };

            updatedPaymentDetails = { paymentDate, paymentDueDate, amountPaid };
        }

        if (
            (paymentMode === CONSTANTS.PAYMENT_OFFLINE ||
                paymentMode === CONSTANTS.PAYMENT_ONLINE) &&
            hospital?.approvalStatus === CONSTANTS.REGISTRED
        ) {
            const { extendedDatePayment }: any = { ...paymentDetails };
            let gracePeriod: any;
            if (extendedDatePayment) {
                gracePeriod = calculateGracePeriod(
                    dateToYYYYMMDDFormatString(extendedDatePayment),
                    dateToYYYYMMDDFormatString(paymentDetails.paymentDueDate)
                );
            } else {
                gracePeriod = paymentDetails.gracePeriod;
            }
            updatedPaymentDetails = { gracePeriod };
        }

        updatePaymentStatus(
            hospitalProgramDetails,
            updatedPaymentDetails,
            orderId
        )
            .then((response: any) => {
                if (response.success) {
                    setPrevPaymentState({ ...paymentDetails });
                    setPaymentDetails({ ...paymentDetails });
                    const toast = {
                        message: "Data successfully updated.",
                        code: "Success:",
                        type: "success",
                    };

                    setEditMode(!editMode);
                    dispatch(showToast(toast));
                    setDisabled(false);

                    const updatedData = { ...paymentDetails };
                    updatedData.updatedAt = null;
                    updatedData.updatedBy = null;
                    if (
                        response &&
                        response.data &&
                        response.data.updatedResult &&
                        response.data.updatedResult.updatedAt
                    ) {
                        updatedData.updatedAt =
                            response.data.updatedResult.updatedAt;
                    }
                    if (
                        response &&
                        response.data &&
                        response.data.updatedResult &&
                        response.data.updatedResult.updatedBy
                    ) {
                        updatedData.updatedBy =
                            response.data.updatedResult.updatedBy;
                    }

                    setPaymentInfo(updatedData);
                }
            })
            .catch((error: any) => {
                setDisabled(false);
                dispatch(showToast(error));
            });
    };

    const jumpToPrevTab = () => {
        if (activeTab > 0) {
            setActiveTab(activeTab - 1);
            window.scrollTo(0, CONSTANTS.SCROLLTO_VALUE);
        }
    };

    const handleEditbutton = () => {
        setPaymentDetails({ ...prevPaymentState });
        setEditMode(!editMode);
        setButtonState(true);
    };

    const handleSkipPaymentSubmit = async (event: any) => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        setDisabled(true);
        Promise.all([
            skipPayment(params.hospitalId, params.programId, params.categoryId),
            programSubscription(
                params.hospitalId,
                params.programId,
                params.categoryId,
                CONSTANTS.PAYMENT_OFFLINE
            ),
        ])
            .then((responses: any) => {
                if (responses[0].success && responses[1].success) {
                    setPaymentComplete(true);
                }
                setDisabled(false);
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
                setPayOfflineInd(false);
                dismissModal();
            })
            .catch((error: any) => {
                if (
                    error.details &&
                    error.details[0]?.message === CONSTANTS.PAYMENT_BY_FACILITY
                ) {
                    sleep(1000).then(() => {
                        window.location.reload();
                    });
                }
                setDisabled(false);
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
            });
    };

    const approve = () => {
        setDisabled(true);
        approveHospital(
            hospital.hospitalId,
            params.programId,
            params.categoryId,
            "APPROVED"
        )
            .then((response) => {
                setDisabled(false);
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
                if (response.success && response.statusCode === 200) {
                    dispatch(
                        showToast({
                            visibility: true,
                            title: "Organization Approved:",
                            message: `Organization ${hospital.facilityName} successfully approved for the ${programCategoryName} program.`,
                            type: "success",
                        })
                    );
                    close();
                }
            })
            .catch((error) => {
                setDisabled(false);
                store.dispatch(showToast(error));
            });
    };

    const handleRenewelEvent = () => {
        setExportProgramMeasureActivateInd(true);
        showModal();
    };
    const dismissRenewalModal = () => {
        setExportProgramMeasureActivateInd(false);
        dismissModal();
    };
    useEffect(() => {
        if (CONSTANTS.WINDOW.AUI.Tooltip) {
            CONSTANTS.WINDOW.AUI.Tooltip.init();
        }
    });

    const isSafariBrowser = () => {
        if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
            return true;
        }
        return false;
    };

    const eventHandler = (e: any) => {
        e.preventDefault();
    };

    const handleRenewalThread = (URL: any) => {
        const worker = new Worker(webWorkerScript);
        const WAIT_TIME = 5000;
        setRenewButtonDisabled(true);
        worker.addEventListener("message", (e: any) => {
            const type = e?.data?.type;
            const status = e?.data?.status;
            if (type === "apiResponse") {
                if (status === CONSTANTS.RENEW_CONFIRM_RESPONSE.IN_PROGRESS) {
                    localStorage.setItem("renewBtnRestrict", "true");
                    worker.postMessage({
                        type: "start",
                        authToken: localStorage.getItem("userAccessToken"),
                        URL,
                        time: WAIT_TIME,
                    });
                } else if (
                    status === CONSTANTS.RENEW_CONFIRM_RESPONSE.SUCCESS
                ) {
                    store.dispatch(hideToast(false));
                    localStorage.setItem("renewBtnRestrict", "false");
                    setStatusAfterRenew(true);
                    setRenewButtonDisabled(false);
                    const toast = {
                        message: `${hospital.facilityName} renewing successfully completed.`,
                        code: "Success:",
                        type: "success",
                    };
                    dispatch(showToast(toast));
                } else if (status === CONSTANTS.RENEW_CONFIRM_RESPONSE.FAILED) {
                    store.dispatch(hideToast(false));
                    localStorage.setItem("renewBtnRestrict", "false");
                    setRenewButtonDisabled(false);
                    const alertPopup = document.getElementById("alertPopup");
                    const msg = document.getElementById(
                        "faild-message"
                    ) as HTMLElement;
                    if (msg) {
                        msg.innerHTML = "";
                    }
                    document
                        .getElementById("faild-message")
                        ?.append(
                            `${hospital.facilityName} renewing failed. Please try again`
                        );
                    alertPopup?.classList.remove("popupHide");
                }
            }
        });
        const toast = {
            message: `${hospital.facilityName} renewing is in progress. Please don’t refresh this page`,
            code: "Success:",
            type: "warning",
        };
        dispatch(showToast(toast));
        worker.postMessage({
            type: "start",
            authToken: localStorage.getItem("userAccessToken"),
            URL,
            time: WAIT_TIME,
        });
    };
    const confirmRenew = (e: any) => {
        dismissRenewalModal();
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        e.preventDefault();
        renewFacility(params.hospitalId, params.programId, params.categoryId)
            .then((response) => {
                if (response.success) {
                    const URL = `/admin/renewFacilities/hospitals/${params.hospitalId}/${response.data.processId}`;
                    handleRenewalThread(URL);
                }
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
            })
            .catch(() => {
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
            });
    };

    useEffect(() => {
        if (exportProgramMeasureActivateInd && renewContentRef.current) {
            renewContentRef.current.setAttribute("tabIndex", "-1");
            renewContentRef.current.focus();
        }
        const handleKeyDown = (event: any) => {
            if (event.key === "Escape") {
                dismissRenewalModal();
            }
        };

        const handleMouseDown = (event: any) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                dismissRenewalModal();
            }
            if (modalRef.current && modalRef.current.contains(event.target)) {
                renewContentRef.current?.removeAttribute("tabIndex");
            }
        };

        if (exportProgramMeasureActivateInd) {
            document.addEventListener("keydown", handleKeyDown);
            document.addEventListener("mousedown", handleMouseDown);
        }

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
            document.removeEventListener("mousedown", handleMouseDown);
        };
    }, [exportProgramMeasureActivateInd]);

    return (
        <FacilityDetailsWrapper aria-label="Payment Detail tab Content">
            <div className="fd-title-border d-flex justify-content-between">
                <h3 className="h4 font-500">Payment Details</h3>
                {!(
                    paymentMode === CONSTANTS.PAYMENT_ONLINE &&
                    hospital?.approvalStatus === CONSTANTS.PENDING
                ) &&
                hospital.isActive == true &&
                orderId &&
                !isCorporation ? (
                    <div className="edit-btn-toggle">
                        <input
                            type="checkbox"
                            id="editPaymentDetails"
                            className="edit-btn-checkbox"
                            role="button"
                            aria-pressed={editMode}
                            onClick={handleEditbutton}
                        />
                        {editMode ? (
                            <label
                                htmlFor="editPaymentDetails"
                                className="toggle-label"
                            >
                                Cancel Edit
                            </label>
                        ) : (
                            <label
                                htmlFor="editPaymentDetails"
                                className="toggle-label"
                            >
                                <i
                                    className="aha-icon-edit toggle-edit"
                                    title="Edit Payment Details"
                                    aria-hidden="true"
                                />
                                <span className="sr-only">Edit</span>
                            </label>
                        )}
                        {editMode ? (
                            <div
                                aria-live="polite"
                                className="d-block d-md-none sr-only"
                            >
                                Cancel editing payment details
                            </div>
                        ) : (
                            <div
                                aria-live="polite"
                                className="d-block d-md-none sr-only"
                            >
                                Edit payment details
                            </div>
                        )}
                    </div>
                ) : null}
            </div>

            {!editMode ? (
                <div className="fd-table">
                    <table className="aui-responsive-table aui-table-cols">
                        <tbody>
                            {orderId || !isCorporation ? (
                                <>
                                    <tr>
                                        <th scope="row" className="aui-row-th">
                                            Payment Status
                                        </th>
                                        <td className="aui-row-td">
                                            {paymentDetails.paymentStatus ||
                                                "N/A"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" className="aui-row-th">
                                            Mode of Payment
                                        </th>
                                        <td className="aui-row-td">
                                            {paymentDetails.modeOfPayment ||
                                                "N/A"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" className="aui-row-th">
                                            Payment Date
                                        </th>
                                        <td className="aui-row-td">
                                            {(paymentDetails?.paymentDate
                                                ? paymentDetails?.paymentDate.includes(
                                                      "T00:00:00.000Z"
                                                  ) ||
                                                  !paymentDetails?.paymentDate.includes(
                                                      "T"
                                                  )
                                                    ? dateToYYYYMMDDFormatStringISO(
                                                          paymentDetails.paymentDate
                                                      )
                                                    : localDateToYYYYMMDDFormatString(
                                                          paymentDetails.paymentDate
                                                      )
                                                : "") || "N/A"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" className="aui-row-th">
                                            Payment Due Date
                                        </th>
                                        <td className="aui-row-td">
                                            {(paymentDetails?.paymentDueDate
                                                ? dateToYYYYMMDDFormatStringISO(
                                                      paymentDetails.paymentDueDate
                                                  )
                                                : "") || "N/A"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" className="aui-row-th">
                                            Extended date for payment
                                        </th>
                                        <td className="aui-row-td">
                                            {(paymentDetails?.extendedDatePayment
                                                ? localDateToYYYYMMDDFormatString(
                                                      paymentDetails.extendedDatePayment
                                                  )
                                                : paymentDetails &&
                                                  paymentDetails.gracePeriod &&
                                                  paymentDetails.paymentDueDate
                                                ? calculateExtendedDateYYMMMDD(
                                                      paymentDetails.paymentDueDate,
                                                      paymentDetails.gracePeriod
                                                  )
                                                : "") || "N/A"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" className="aui-row-th">
                                            {paymentDetails?.paymentStatus ===
                                            CONSTANTS.PENDING
                                                ? "Amount"
                                                : "Amount Paid"}
                                        </th>
                                        <td className="aui-row-td">
                                            {(paymentDetails?.amountPaid
                                                ? `$${paymentDetails.amountPaid}`
                                                : "") || "N/A"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" className="aui-row-th">
                                            Payment Made By
                                        </th>
                                        <td className="aui-row-td">
                                            {paymentDetails?.paymentMadeBy ===
                                            CONSTANTS.USER_ROLES
                                                .CORPORATION_ADMIN
                                                ? `Corporation - ${paymentDetails?.paymentMadeByCorporation}`
                                                : paymentDetails?.paymentMadeBy ===
                                                  CONSTANTS.USER_ROLES
                                                      .HOSPITAL_ADMIN
                                                ? `Organization - ${hospital.facilityName}`
                                                : "N/A"}
                                        </td>
                                    </tr>
                                    {hospital?.approvalStatus ===
                                    CONSTANTS.REGISTRED ? (
                                        <tr>
                                            <th
                                                scope="row"
                                                className="aui-row-th"
                                            >
                                                Payment Verified By
                                            </th>
                                            <td className="aui-row-td">
                                                {(paymentDetails?.paymentVerifiedBy
                                                    ? paymentDetails.paymentVerifiedBy
                                                    : "") || "N/A"}
                                            </td>
                                        </tr>
                                    ) : (
                                        ""
                                    )}
                                </>
                            ) : (
                                <>
                                    <tr>
                                        <th scope="row" className="aui-row-th">
                                            Payment Status
                                        </th>
                                        <td className="aui-row-td">
                                            {paymentDetails.paymentStatus ||
                                                "Pending"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" className="aui-row-th">
                                            Amount to be paid
                                        </th>
                                        <td className="aui-row-td">
                                            {price || price === 0
                                                ? `$${price}`
                                                : "N/A"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" className="aui-row-th">
                                            Mode of Payment
                                        </th>
                                        <td className="aui-row-td">
                                            <div
                                                className="d-flex flex-row flex-wrap"
                                                role="radiogroup"
                                            >
                                                <div className="form-radio mr-4">
                                                    <input
                                                        required
                                                        checked
                                                        type="radio"
                                                        name="paymentMode"
                                                        value="paymentMode_yes"
                                                        id="paymentrecored-yes"
                                                    />
                                                    <label
                                                        htmlFor="paymentrecored-yes"
                                                        id="yes-record"
                                                    >
                                                        Offline
                                                    </label>
                                                </div>
                                                {/* <div
                                                className="form-radio ml-4"
                                                aria-labelledby="yes-record"
                                            >
                                                <input
                                                    value="paymentMode_no"
                                                    type="radio"
                                                    name="paymentMode"
                                                    id="paymentrecored-no"
                                                />
                                                <label htmlFor="paymentrecored-no">
                                                    Online
                                                </label>
                                            </div> */}
                                            </div>
                                        </td>
                                    </tr>
                                </>
                            )}
                        </tbody>
                    </table>
                    <div
                        className={`fb-table-btn d-flex justify-content-center py-4 flex-column flex-sm-row ${
                            hospital.approvalStatus === CONSTANTS.PENDING
                                ? ""
                                : "last-tab-btns"
                        }`}
                    >
                        {hospital.approvalStatus === CONSTANTS.PENDING &&
                            hospital.isActive === true && (
                                <button
                                    className="btn btn-secondary btn-round fd-btn-1"
                                    onClick={close}
                                    aria-describedby="admin-paymentdetails"
                                >
                                    Close
                                </button>
                            )}
                        {isExpiring && !statusAfterRenew && (
                            <button
                                className="btn btn-secondary btn-round fd-btn-1"
                                onClick={close}
                                aria-describedby="admin-paymentdetails"
                            >
                                Close
                            </button>
                        )}
                        <button
                            className="btn btn-secondary btn-round fd-btn-2"
                            aria-describedby="admin-billingdetails"
                            onClick={jumpToPrevTab}
                        >
                            Previous
                        </button>
                        {!isExpiring &&
                            hospital.approvalStatus != CONSTANTS.PENDING && (
                                <button
                                    className="btn btn-primary btn-round fd-btn-1"
                                    onClick={close}
                                    aria-describedby="admin-paymentdetails"
                                >
                                    Close
                                </button>
                            )}
                        {!isCorporation && orderId
                            ? hospital.approvalStatus === CONSTANTS.PENDING &&
                              hospital.isActive === true && (
                                  <button
                                      aria-label="Approve Organization"
                                      className={`btn btn-primary btn-round fd-btn-3 ${
                                          disabled
                                              ? CONSTANTS.BUTTON_SPINNER
                                              : ""
                                      }`}
                                      disabled={disabled}
                                      onClick={approve}
                                  >
                                      Approve
                                  </button>
                              )
                            : isCorporation &&
                              !orderId && (
                                  <button
                                      type="button"
                                      className={`btn btn-primary btn-round fd-btn-3 billing-offline-btn ${
                                          disabled
                                              ? CONSTANTS.BUTTON_SPINNER
                                              : ""
                                      }`}
                                      disabled={disabled}
                                      onClick={() => setPayOfflineInd(true)}
                                      data-toggle="modal"
                                      data-target="#confirmationModal"
                                  >
                                      Proceed to Payment
                                  </button>
                              )}
                        <div
                            id="renewTooltip"
                            className="d-flex text-nowrap renew-btn"
                        >
                            {!statusAfterRenew && isExpiring && (
                                <button
                                    className="btn btn-primary btn-round fd-btn-1 renew-button"
                                    onClick={() => {
                                        isExpiring &&
                                            !documentapprovalStatus &&
                                            !renewButtonDisabled &&
                                            !statusAfterRenew &&
                                            handleRenewelEvent();
                                    }}
                                    aria-describedby="renewBtnTooltip"
                                >
                                    Renew
                                </button>
                            )}
                            <div
                                className="tooltip renew-tooltip"
                                role="tooltip"
                                id="renewBtnTooltip"
                            >
                                <div className="arrow" />
                                {isExpiring && documentapprovalStatus && (
                                    <div className="tooltip-inner">
                                        Please approve or reject documents in
                                        pending status to renew the
                                        organization.
                                    </div>
                                )}
                                {isExpiring &&
                                    !documentapprovalStatus &&
                                    renewButtonDisabled && (
                                        <div className="tooltip-inner">
                                            Please wait for the current
                                            organization renewing to complete.
                                        </div>
                                    )}
                            </div>
                        </div>

                        {isExpiring &&
                            !documentapprovalStatus &&
                            statusAfterRenew && (
                                <button
                                    className="btn btn-primary btn-round fd-btn-1"
                                    aria-describedby="admin-paymentdetails"
                                    onClick={close}
                                >
                                    Close
                                </button>
                            )}
                        {payOfflineInd && (
                            <DynamicModal
                                modalText={
                                    <div>
                                        You have chosen to <b>pay offline</b>.
                                        We will connect you with payment
                                        details.
                                    </div>
                                }
                                dismissText="Cancel"
                                actionText="Confirm"
                                actionFunction={handleSkipPaymentSubmit}
                                setPayOfflineInd={setPayOfflineInd}
                                payOfflineInd={payOfflineInd}
                                setFocusState={setFocusState}
                            />
                        )}
                        {exportProgramMeasureActivateInd && (
                            <FocusTrap
                                focusTrapOptions={{
                                    escapeDeactivates: false,
                                    clickOutsideDeactivates: false,
                                }}
                            >
                                <div
                                    className="modal fade show aui-modal d-block"
                                    id="exportProgramMeasuresModal"
                                    tabIndex={-1}
                                    aria-labelledby="renewModalLabel"
                                    aria-modal="true"
                                    role="dialog"
                                >
                                    <div
                                        className="modal-dialog modal-dialog-centered modal-md"
                                        ref={modalRef}
                                    >
                                        <div
                                            className="modal-content"
                                            id="renewContentRef"
                                            ref={renewContentRef}
                                        >
                                            <div className="modal-header">
                                                <h2
                                                    className="h4"
                                                    id="renewModalLabel"
                                                >
                                                    Confirm Organization Renewal
                                                </h2>
                                                <button
                                                    id="closeRenewModal"
                                                    type="button"
                                                    className="close"
                                                    aria-label="Close renewing organization modal"
                                                    onClick={
                                                        dismissRenewalModal
                                                    }
                                                >
                                                    <span
                                                        aria-hidden="true"
                                                        className="aha-icon-cross"
                                                    />
                                                </button>
                                            </div>
                                            <div className="modal-body p-0">
                                                <div className="d-flex">
                                                    <div className="pb-3">
                                                        <p>
                                                            Are you sure you
                                                            want to proceed with
                                                            renewing
                                                            <b className="mx-1">
                                                                {
                                                                    hospital.facilityName
                                                                }
                                                            </b>
                                                            and archiving all
                                                            the existing
                                                            <Link
                                                                to={{
                                                                    pathname: `/admin/organization-management/organization-details/${params.hospitalId}/program/${params.programId}/category/${params.categoryId}`,
                                                                }}
                                                                className="font-red mx-1"
                                                                style={{
                                                                    cursor: "pointer",
                                                                    textDecoration:
                                                                        "underline",
                                                                }}
                                                                onClick={
                                                                    navigateToDocTab
                                                                }
                                                                aria-label={`${documentCount} documents of ${hospital.facilityName}`}
                                                            >
                                                                {documentCount}{" "}
                                                                document(s)
                                                            </Link>
                                                            and the certificate?
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-end mt-4 flex-column flex-sm-row ">
                                                    <button
                                                        className="btn btn-round btn-secondary fd-btn-1"
                                                        aria-label="Cancel renewing organization"
                                                        onClick={
                                                            dismissRenewalModal
                                                        }
                                                        id="cancelRenewConfirmation"
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        className="btn btn-primary btn-round  mt-4  mt-sm-0"
                                                        onClick={confirmRenew}
                                                        aria-label="Confirm renewing organization"
                                                        id="confirmRenewConfirmation"
                                                    >
                                                        Confirm
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </FocusTrap>
                        )}
                    </div>
                </div>
            ) : (
                <div className="fd-form">
                    <form aria-label="payment detail" onSubmit={handleSave}>
                        <fieldset className="row" disabled={disabled}>
                            <div className="col-lg-10">
                                <div className="d-flex flex-row-reverse">
                                    <div className="pb-3 mand-field">
                                        <sup>*</sup>
                                        mandatory fields
                                    </div>
                                </div>
                                <div className="form-group row required">
                                    <div className="col-md-4 form-label">
                                        <label
                                            htmlFor="paymentStatus"
                                            className="label-form"
                                        >
                                            Payment Status
                                            <sup>*</sup>
                                        </label>
                                    </div>
                                    <div className="col-md-8">
                                        <input
                                            readOnly
                                            type="text"
                                            className="form-control"
                                            id="paymentStatus"
                                            name="paymentStatus"
                                            pattern={
                                                CONSTANTS.REGEX_ALLOW_ALPHANUMERIC
                                            }
                                            required
                                            defaultValue={
                                                paymentDetails.paymentStatus
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="form-group row required">
                                    <div className="col-md-4 form-label">
                                        <label
                                            htmlFor="modeOfPayment"
                                            className="label-form"
                                        >
                                            Mode of Payment
                                            <sup>*</sup>
                                        </label>
                                    </div>
                                    <div className="col-md-8">
                                        <input
                                            readOnly
                                            type="text"
                                            className="form-control"
                                            id="modeOfPayment"
                                            name="modeOfPayment"
                                            pattern={
                                                CONSTANTS.REGEX_ALLOW_ALPHANUMERIC
                                            }
                                            required
                                            defaultValue={
                                                paymentDetails.modeOfPayment
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="form-group row required">
                                    <div className="col-md-4 form-label">
                                        <label
                                            htmlFor="paymentDate"
                                            className="label-form"
                                        >
                                            <span className="d-block mb-n2">
                                                Payment Date
                                                <sup>*</sup>
                                            </span>
                                            <i className="label-helptext">
                                                (DD-MM-YYYY)
                                            </i>
                                        </label>
                                    </div>
                                    <div className="col-md-8">
                                        {paymentMode ===
                                            CONSTANTS.PAYMENT_OFFLINE &&
                                        hospital?.approvalStatus ===
                                            CONSTANTS.PENDING ? (
                                            <input
                                                type="date"
                                                className="form-control"
                                                id="paymentDate"
                                                name="paymentDate"
                                                required
                                                onChange={handleChange}
                                                defaultValue={
                                                    paymentDetails?.paymentDate
                                                        ? paymentDetails?.paymentDate.includes(
                                                              "T00:00:00.000Z"
                                                          ) ||
                                                          !paymentDetails?.paymentDate.includes(
                                                              "T"
                                                          )
                                                            ? dateToYYYYMMDDPlaceholderFormatStringISO(
                                                                  paymentDetails.paymentDate
                                                              )
                                                            : localDateToYYYYMMDDPlaceholderFormat(
                                                                  paymentDetails.paymentDate
                                                              )
                                                        : ""
                                                }
                                            />
                                        ) : (
                                            <input
                                                readOnly
                                                type="date"
                                                className="form-control"
                                                id="paymentDate"
                                                name="paymentDate"
                                                required
                                                defaultValue={
                                                    paymentDetails?.paymentDate
                                                        ? paymentDetails?.paymentDate.includes(
                                                              "T00:00:00.000Z"
                                                          ) ||
                                                          !paymentDetails?.paymentDate.includes(
                                                              "T"
                                                          )
                                                            ? dateToYYYYMMDDPlaceholderFormatStringISO(
                                                                  paymentDetails.paymentDate
                                                              )
                                                            : localDateToYYYYMMDDPlaceholderFormat(
                                                                  paymentDetails.paymentDate
                                                              )
                                                        : ""
                                                }
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="form-group row required">
                                    <div className="col-md-4 form-label">
                                        <label
                                            htmlFor="paymentDueDate"
                                            className="label-form"
                                        >
                                            <span className="d-block mb-n2">
                                                Payment Due Date
                                                <sup>*</sup>
                                            </span>
                                            <i className="label-helptext">
                                                (DD-MM-YYYY)
                                            </i>
                                        </label>
                                    </div>
                                    <div className="col-md-8">
                                        {paymentMode ===
                                            CONSTANTS.PAYMENT_OFFLINE &&
                                        hospital?.approvalStatus ===
                                            CONSTANTS.PENDING ? (
                                            <input
                                                type="date"
                                                className="form-control"
                                                id="paymentDueDate"
                                                name="paymentDueDate"
                                                required
                                                min={
                                                    paymentDetails?.paymentDate
                                                        ? new Date(
                                                              paymentDetails.paymentDate
                                                          ) >= new Date()
                                                            ? localDateToYYYYMMDDPlaceholderFormat(
                                                                  paymentDetails.paymentDate
                                                              )
                                                            : localDateToYYYYMMDDPlaceholderFormat(
                                                                  new Date()
                                                              )
                                                        : ""
                                                }
                                                onChange={handleChange}
                                                defaultValue={
                                                    paymentDetails?.paymentDueDate
                                                        ? dateToYYYYMMDDFormatString(
                                                              paymentDetails.paymentDueDate
                                                          )
                                                        : ""
                                                }
                                            />
                                        ) : (
                                            <input
                                                type="date"
                                                readOnly
                                                className="form-control"
                                                id="paymentDueDateReadOnly"
                                                name="paymentDueDate"
                                                required
                                                defaultValue={
                                                    paymentDetails?.paymentDueDate
                                                        ? dateToYYYYMMDDFormatString(
                                                              paymentDetails.paymentDueDate
                                                          )
                                                        : ""
                                                }
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="form-group row required">
                                    <div className="col-md-4 form-label">
                                        <label
                                            htmlFor="extendedDatePayment"
                                            className="label-form"
                                        >
                                            <span className="d-block mb-n2">
                                                Extended date for payment
                                                <sup>*</sup>
                                            </span>
                                            <i className="label-helptext">
                                                (DD-MM-YYYY)
                                            </i>
                                        </label>
                                    </div>
                                    <div className="col-md-8">
                                        {(paymentMode ===
                                            CONSTANTS.PAYMENT_OFFLINE ||
                                            paymentMode ===
                                                CONSTANTS.PAYMENT_ONLINE) &&
                                        hospital?.approvalStatus ===
                                            CONSTANTS.REGISTRED ? (
                                            <input
                                                type="date"
                                                className="form-control"
                                                id="extendedDatePayment"
                                                name="extendedDatePayment"
                                                required
                                                onChange={handleChange}
                                                min={
                                                    paymentDetails?.paymentDueDate
                                                        ? dateToYYYYMMDDFormatString(
                                                              paymentDetails.paymentDueDate
                                                          )
                                                        : ""
                                                }
                                                defaultValue={
                                                    (paymentDetails?.extendedDatePayment
                                                        ? localDateToYYYYMMDDPlaceholderFormat(
                                                              paymentDetails.extendedDatePayment
                                                          )
                                                        : paymentDetails &&
                                                          paymentDetails.gracePeriod &&
                                                          paymentDetails.paymentDueDate
                                                        ? calculateExtendedDate(
                                                              paymentDetails.paymentDueDate,
                                                              paymentDetails.gracePeriod
                                                          )
                                                        : "") || "N/A"
                                                }
                                            />
                                        ) : (
                                            <input
                                                type="date"
                                                readOnly
                                                className="form-control"
                                                id="extendedDatePayment"
                                                name="extendedDatePayment"
                                                required
                                                ref={extendedRefDate}
                                                defaultValue={
                                                    (paymentDetails?.extendedDatePayment
                                                        ? localDateToYYYYMMDDPlaceholderFormat(
                                                              paymentDetails.extendedDatePayment
                                                          )
                                                        : paymentDetails &&
                                                          paymentDetails.gracePeriod &&
                                                          paymentDetails.paymentDueDate
                                                        ? calculateExtendedDate(
                                                              paymentDetails.paymentDueDate,
                                                              paymentDetails.gracePeriod
                                                          )
                                                        : "") || "N/A"
                                                }
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="form-group row required">
                                    <div className="col-md-4 form-label">
                                        <label
                                            htmlFor="amountPaid"
                                            className="label-form"
                                        >
                                            {paymentDetails?.paymentStatus ===
                                            CONSTANTS.PENDING
                                                ? "Amount"
                                                : "Amount Paid"}
                                            <sup>*</sup>
                                        </label>
                                    </div>
                                    <div className="col-md-8">
                                        {paymentMode ===
                                            CONSTANTS.PAYMENT_OFFLINE &&
                                        hospital?.approvalStatus ===
                                            CONSTANTS.PENDING ? (
                                            <input
                                                type="number"
                                                min="0"
                                                max="99999999"
                                                step=".01"
                                                className="form-control"
                                                id="amountPaid"
                                                name="amountPaid"
                                                pattern="^\d*(\.\d{0,2})?$"
                                                required
                                                onChange={handleChange}
                                                defaultValue={
                                                    paymentDetails?.amountPaid
                                                        ? paymentDetails.amountPaid
                                                        : ""
                                                }
                                            />
                                        ) : (
                                            <input
                                                type="text"
                                                readOnly
                                                className="form-control"
                                                id="amountPaid"
                                                name="amountPaid"
                                                pattern="^\d*(\.\d{0,2})?$"
                                                required
                                                defaultValue={
                                                    paymentDetails?.amountPaid
                                                        ? paymentDetails.amountPaid
                                                        : ""
                                                }
                                            />
                                        )}
                                    </div>
                                </div>
                                {hospital?.approvalStatus ===
                                CONSTANTS.REGISTRED ? (
                                    <div className="form-group row required">
                                        <div className="col-md-4 form-label">
                                            <label
                                                htmlFor="paymentVerifiedBy"
                                                className="label-form"
                                            >
                                                Payment Verified By
                                                <sup>*</sup>
                                            </label>
                                        </div>
                                        <div className="col-md-8">
                                            <input
                                                readOnly
                                                type="text"
                                                className="form-control"
                                                id="paymentVerifiedBy"
                                                name="paymentVerifiedBy"
                                                pattern={
                                                    CONSTANTS.REGEX_ALLOW_ALPHANUMERIC
                                                }
                                                required
                                                defaultValue={
                                                    paymentDetails.paymentVerifiedBy
                                                }
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}
                                <div className="fb-table-btn row mx-0 fb-btn-edit">
                                    <div className="col-md-8 offset-md-4 px-0 px-md-3 mb-4 d-flex justify-content-start flex-column flex-sm-row">
                                        <button
                                            type="submit"
                                            className="btn btn-round btn-secondary fd-btn-1"
                                            aria-label="Cancel Editing payment Details"
                                            onClick={handleEditbutton}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            disabled={
                                                buttonState ||
                                                (paymentMode ===
                                                    CONSTANTS.PAYMENT_ONLINE &&
                                                    hospital?.approvalStatus ===
                                                        CONSTANTS.PENDING)
                                            }
                                            className={`btn btn-round btn-primary fd-btn-2 ${
                                                disabled
                                                    ? CONSTANTS.BUTTON_SPINNER
                                                    : ""
                                            }`}
                                            aria-label="Save payment Details"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            )}
        </FacilityDetailsWrapper>
    );
};

export default PaymentDetails;
