import { dismissModal, showModal, wordSplit } from "common/utils";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import FocusTrap from "focus-trap-react";
import { getUserList } from "api/usersApi";
import { logger } from "utils/logger.utils";
import { activateOrDeactivateCorporation } from "../../../api/corporationAPI";
import CONSTANTS from "../../../common/constants";
import { setPageLoadingStatus } from "../../../components/Loader/loader.slice";
import { showToast } from "../../../components/Toast/toast.slice";
import "../../styles/styles.scss";
import { CorporationFormWrapper } from "./styled";

interface Props {
    corporationDetails: any;
    corporationMangangementTab: any;
    corporationMangangementPage: any;
    profileMode: any;
    corporationFacilities?: any;
}

export const ViewCorporation = ({
    corporationDetails,
    corporationMangangementTab,
    corporationMangangementPage,
    profileMode,
    corporationFacilities,
}: Props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { corporationId }: any = useParams();
    const [registeredEmailId, setregisteredEmailId] = useState<string>(
        corporationDetails.primaryContactEmailAddress
    );
    const [corporationUsersCount, setCorporationUsersCount] = useState<any>();
    const [modifiedRegisteredMailId, setModifiedRegisteredMailId] =
        useState<boolean>(false);
    const [activateInd, setActivateInd] = useState<boolean>(false);
    const [deactivateActivateInd, setDeactivateActivateInd] =
        useState<boolean>(false);
    const modalRef = useRef<HTMLDivElement | null>(null);
    const activateContentRef = useRef<HTMLDivElement | null>(null);
    const deactivateContentRef = useRef<HTMLDivElement | null>(null);
    const [validateDisabled, setValidateDisabled] = useState<boolean>(false);
    let closeCorporationDeactivateModalElement: any = null;

    const dismissCorpoationModal = () => {
        setActivateInd(false);
        dismissModal();
    };
    const deactivateCorporationDismissModal = () => {
        setDeactivateActivateInd(false);
        dismissModal();
    };

    const resetActivateCorporationPopupModal = () => {
        setActivateInd(true);
        showModal();
        setregisteredEmailId("");
        setModifiedRegisteredMailId(false);
    };
    const deactivateCorporationPopupModal = () => {
        setDeactivateActivateInd(true);
        showModal();
    };
    const handleRegisteredEmailIdChange = (event: any) => {
        setModifiedRegisteredMailId(true);
        setregisteredEmailId(event.target.value.trim());
    };
    const handleCorporationActivateSubmit = (event: any) => {
        event.preventDefault();
        setValidateDisabled(true);
        activateOrDeactivateCorporation({
            corporationId,
            registeredEmailId:
                !registeredEmailId && !modifiedRegisteredMailId
                    ? corporationDetails.primaryContactEmailAddress
                    : registeredEmailId,
            activationStatus: "ACTIVE",
        })
            .then((data: any) => {
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
                if (data.success == true) {
                    corporationDetails.registeredEmailId = registeredEmailId;
                    dispatch(
                        showToast({
                            visibility: true,
                            title: "SUCCESS!",
                            message: `Corporation activated successfully.`,
                            type: "success",
                        })
                    );
                    dismissCorpoationModal();
                    history.push("/corporation/corporation-management", {
                        t: corporationMangangementTab,
                        p: corporationMangangementPage,
                    });
                }
                setValidateDisabled(false);
            })
            .catch(() => {
                setValidateDisabled(false);
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
            });
    };

    const deactivateCorporation = () => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        activateOrDeactivateCorporation({
            corporationId,
            activationStatus: "INACTIVE",
        })
            .then((data: any) => {
                closeCorporationDeactivateModalElement.click();
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
                if (data.success == true) {
                    dispatch(
                        showToast({
                            visibility: true,
                            title: "DEACTIVATED!",
                            message: `Corporation Deactivated Successfully.`,
                            type: "success",
                        })
                    );
                    history.push("/corporation/corporation-management", {
                        t: corporationMangangementTab,
                        p: corporationMangangementPage,
                    });
                }
            })
            .catch(() => {
                closeCorporationDeactivateModalElement.click();
                dispatch(setPageLoadingStatus({ isPageLoading: false }));
            });
    };

    useEffect(() => {
        getUserList("", 1, 5, false, "", false, corporationId)
            .then((res: any) => {
                if (res.success && res.statusCode === 200) {
                    setCorporationUsersCount(res.data.users.length);
                }
            })
            .catch((error: any) => {
                logger(error);
            });
    }, []);
    useEffect(() => {
        if (activateInd && activateContentRef.current) {
            activateContentRef.current.setAttribute("tabIndex", "-1");
            activateContentRef.current.focus();
        }
        if (deactivateActivateInd && deactivateContentRef.current) {
            deactivateContentRef.current.setAttribute("tabIndex", "-1");
            deactivateContentRef.current.focus();
        }
        const handleKeyDown = (event: any) => {
            if (event.key === "Escape") {
                dismissCorpoationModal();
                deactivateCorporationDismissModal();
            }
        };
        const handleMouseDown = (event: any) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                dismissCorpoationModal();
                deactivateCorporationDismissModal();
            }
            if (modalRef.current && modalRef.current.contains(event.target)) {
                activateContentRef.current?.removeAttribute("tabIndex");
                deactivateContentRef.current?.removeAttribute("tabIndex");
            }
        };

        if (activateInd || deactivateActivateInd) {
            document.addEventListener("keydown", handleKeyDown);
            document.addEventListener("mousedown", handleMouseDown);
        }

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
            document.removeEventListener("mousedown", handleMouseDown);
        };
    }, [activateInd, deactivateActivateInd]);

    return (
        <CorporationFormWrapper className="fd-table">
            <table className="aui-responsive-table aui-table-cols">
                <tbody>
                    <tr>
                        <th scope="row" className="aui-row-th">
                            Corporation Code
                        </th>
                        <td className="aui-row-td">
                            <span className="abbr-code">
                                {wordSplit(
                                    corporationDetails.corporationCode || "N/A"
                                )}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="aui-row-th">
                            Corporation Name
                        </th>
                        <td className="aui-row-td">
                            {corporationDetails.corporationName || "N/A"}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="aui-row-th">
                            Street Address
                        </th>
                        <td className="aui-row-td">
                            {corporationDetails.corporationStreetAddress ||
                                "N/A"}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="aui-row-th">
                            Location
                        </th>
                        <td className="aui-row-td">
                            {corporationDetails.countryName || "N/A"}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="aui-row-th">
                            State
                        </th>
                        <td className="aui-row-td">
                            {corporationDetails.state || "N/A"}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="aui-row-th">
                            Description
                        </th>
                        <td className="aui-row-td">
                            {corporationDetails.description || "N/A"}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="aui-row-th">
                            Website
                        </th>
                        <td className="aui-row-td">
                            {corporationDetails.website || "N/A"}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="aui-row-th">
                            Primary Contact Name
                        </th>
                        <td className="aui-row-td">
                            {corporationDetails.primaryContactName || "N/A"}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="aui-row-th">
                            Primary Contact Phone Number
                        </th>
                        <td className="aui-row-td">
                            {corporationDetails.primaryContactPhoneNumber ||
                                "N/A"}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="aui-row-th">
                            Primary Contact Email Address
                        </th>
                        <td className="aui-row-td">
                            {corporationDetails.primaryContactEmailAddress ||
                                "N/A"}
                        </td>
                    </tr>
                    {corporationDetails.activationStatus ==
                        CONSTANTS.ACTIVE && (
                        <tr>
                            <th scope="row" className="aui-row-th">
                                Registered Email Address
                            </th>
                            <td className="aui-row-td">
                                {corporationDetails.registeredEmailId || "N/A"}
                            </td>
                        </tr>
                    )}
                    <tr>
                        <th scope="row" className="aui-row-th">
                            Total Organizations Registered under this
                            Corporation
                        </th>
                        <td className="aui-row-td">
                            {corporationDetails.hospitalCount || 0}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className="aui-row-th">
                            Programs Associated with
                        </th>
                        <td className="aui-row-td">
                            <ul className="aui-tag-list">
                                {corporationDetails.corporationProgramDetails
                                    ?.length > 0 &&
                                    corporationDetails.corporationProgramDetails.map(
                                        (program: any, index: any) => (
                                            <li
                                                key={index}
                                                className="aui-tag-item d-inine-block"
                                            >
                                                {program.programName}
                                                <span className="sr-only">
                                                    &nbsp;,
                                                </span>
                                            </li>
                                        )
                                    )}
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
            {profileMode !== true && (
                <div className="corp-table-btn py-4 d-flex flex-column flex-sm-row">
                    <button
                        type="button"
                        className="btn btn-secondary btn-round corp-btn-1"
                        onClick={(event: any) => {
                            event.preventDefault();
                            history.push(
                                "/corporation/corporation-management",
                                {
                                    t: corporationMangangementTab,
                                    p: corporationMangangementPage,
                                }
                            );
                        }}
                    >
                        Close
                    </button>
                    {corporationDetails?.activationStatus === "ACTIVE" ? (
                        <button
                            type="button"
                            className="btn btn-primary btn-round corp-btn-2"
                            aria-label={`Deactivate ${corporationDetails.corporationName} corporation`}
                            onClick={deactivateCorporationPopupModal}
                        >
                            Deactivate
                        </button>
                    ) : (
                        <button
                            type="button"
                            className="btn btn-primary btn-round corp-btn-2"
                            aria-label={`Activate ${corporationDetails.corporationName} corporation`}
                            onClick={resetActivateCorporationPopupModal}
                        >
                            Activate
                        </button>
                    )}
                </div>
            )}
            {/* Confirm Email Address Modal */}
            {corporationDetails?.activationStatus !== "ACTIVE"
                ? activateInd && (
                      <>
                          <FocusTrap
                              focusTrapOptions={{
                                  escapeDeactivates: false,
                                  clickOutsideDeactivates: false,
                              }}
                          >
                              <div
                                  className="modal fade show aui-modal confirmEmail-modal d-block"
                                  id="confirmEmailAddressModal"
                                  tabIndex={-1}
                                  aria-labelledby="confirmEmailLabel"
                                  aria-modal="true"
                                  role="dialog"
                              >
                                  <div
                                      className="modal-dialog modal-dialog-centered modal-md"
                                      ref={modalRef}
                                  >
                                      <div
                                          className="modal-content"
                                          id="activateContentRef"
                                          ref={activateContentRef}
                                      >
                                          <div className="modal-header mb-4">
                                              <h2
                                                  className="h4"
                                                  id="confirmEmailLabel"
                                              >
                                                  Confirm Email Address
                                              </h2>
                                              <button
                                                  type="button"
                                                  className="close"
                                                  onClick={
                                                      dismissCorpoationModal
                                                  }
                                                  aria-label="Close Confirm email address modal"
                                              >
                                                  <span
                                                      aria-hidden="true"
                                                      className="aha-icon-cross"
                                                  />
                                              </button>
                                          </div>
                                          <form
                                              className="reg-form"
                                              onSubmit={
                                                  handleCorporationActivateSubmit
                                              }
                                          >
                                              <div className="modal-body mb-0">
                                                  <div className="d-sm-flex justify-content-between">
                                                      <p className="confirmEmail-title">
                                                          Please enter the SSO
                                                          registered email
                                                          address
                                                      </p>
                                                      <div className="pb-3 font-14 mand-field">
                                                          <sup>*</sup>
                                                          mandatory fields
                                                      </div>
                                                  </div>
                                                  <div className="form-group row required mb-4">
                                                      <div className="col-md-5 col-lg-4 form-label">
                                                          <label
                                                              htmlFor="registeredEmailId"
                                                              className="label-form"
                                                          >
                                                              Registered Email
                                                              ID<sup>*</sup>
                                                          </label>
                                                      </div>
                                                      <div className="col-md-7 col-lg-8">
                                                          <input
                                                              type="email"
                                                              className="form-control"
                                                              id="registeredEmailId"
                                                              value={
                                                                  !registeredEmailId &&
                                                                  !modifiedRegisteredMailId
                                                                      ? corporationDetails.primaryContactEmailAddress ||
                                                                        ""
                                                                      : registeredEmailId
                                                              }
                                                              required
                                                              onChange={
                                                                  handleRegisteredEmailIdChange
                                                              }
                                                              pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$"
                                                          />
                                                          <span
                                                              className="sr-only"
                                                              id="registeredEmailIdHelpText"
                                                          >
                                                              Only valid email
                                                              address allowed
                                                          </span>
                                                      </div>
                                                  </div>
                                                  <div className="d-flex flex-sm-row flex-column w-100 justify-content-end align-items-center confirmEmail-btns">
                                                      <button
                                                          type="button"
                                                          className="btn btn-secondary btn-round"
                                                          onClick={
                                                              dismissCorpoationModal
                                                          }
                                                      >
                                                          Cancel
                                                      </button>
                                                      <button
                                                          type="submit"
                                                          disabled={
                                                              validateDisabled
                                                          }
                                                          className={`btn btn-primary btn-round ml-sm-4 mt-3 mt-sm-0 ${
                                                              validateDisabled
                                                                  ? CONSTANTS.BUTTON_SPINNER
                                                                  : ""
                                                          }`}
                                                          aria-label="Confirm Email address"
                                                      >
                                                          Confirm
                                                      </button>
                                                  </div>
                                              </div>
                                          </form>
                                      </div>
                                  </div>
                              </div>
                          </FocusTrap>
                      </>
                  )
                : deactivateActivateInd && (
                      <FocusTrap
                          focusTrapOptions={{
                              escapeDeactivates: false,
                              clickOutsideDeactivates: false,
                          }}
                      >
                          <div
                              className="modal fade show aui-modal d-block"
                              id="deactivateFacConfirmationModal"
                              tabIndex={-1}
                              aria-labelledby="deactivateModalLabel"
                              aria-modal="true"
                              role="dialog"
                          >
                              <div
                                  className="modal-dialog modal-dialog-centered modal-sm"
                                  ref={modalRef}
                              >
                                  <div
                                      className="modal-content"
                                      id="deactivateContentRef"
                                      ref={deactivateContentRef}
                                  >
                                      <div className="modal-body p-0">
                                          <div className="text-center">
                                              <p id="deactivateModalLabel">
                                                  Are you sure you want to
                                                  deactivate the Corporation &
                                                  Remove all the{" "}
                                                  {corporationUsersCount
                                                      ?.toString()
                                                      .padStart(2, "0")}{" "}
                                                  user(s) linked to it?
                                              </p>
                                              <div className="mt-4 d-flex justify-content-center">
                                                  <button
                                                      type="button"
                                                      className="btn btn-secondary btn-round btn-sm mx-2"
                                                      data-dismiss="modal"
                                                      ref={(input) => {
                                                          closeCorporationDeactivateModalElement =
                                                              input;
                                                      }}
                                                      aria-label="cancel deactivating the corporation"
                                                      onClick={
                                                          deactivateCorporationDismissModal
                                                      }
                                                  >
                                                      Cancel
                                                  </button>
                                                  <button
                                                      onClick={
                                                          deactivateCorporation
                                                      }
                                                      type="button"
                                                      className="btn btn-primary btn-round btn-sm mx-2"
                                                      aria-label="confirm deactivating the corporation"
                                                  >
                                                      Confirm
                                                  </button>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </FocusTrap>
                  )}
        </CorporationFormWrapper>
    );
};

export default ViewCorporation;
