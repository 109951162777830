import React, { useEffect, useState } from "react";
import { HospitalDetailWrapper } from "../../Admin/ApproveHospital/styled";
import { useLocation, useParams } from "react-router-dom";
import "../../styles/styles.scss";
import { getHospitalById } from "api/hospitalApi";
import { getHospitalProgramDetails } from "api/hospitalProgramsAPI";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { useDispatch } from "react-redux";
import { logger } from "../../../utils/logger.utils";
import OrganizationDetails from "../OrganizationDetails/OrganizationDetails";
import EMSAdminSidebar from "../Sidebar/Sidebar";

export const ApproveEmsProgramHospital = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { hospitalId, programId }: any = useParams();
    const [hospital, setHospital] = useState<any>({});
    const [programData, setProgramData] = useState<any>();
    const [returnPath, setReturnPath] = useState<any>(
        "emsadmin/organization-management"
    );
    useEffect(() => {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        Promise.all([
            getHospitalById(hospitalId),
            getHospitalProgramDetails(programId, hospitalId),
        ])
            .then((response: any) => {
                if (response[0].success && response[1].success) {
                    setHospital(response[0].data.hospital);
                    setProgramData(response[1].data);
                    dispatch(setPageLoadingStatus({ isPageLoading: false }));
                }
            })
            .catch((err) => {
                dispatch(setPageLoadingStatus({ isPageLoading: true }));
                logger(err);
            });
        if (location?.state?.backToOrganizationManagement) {
            setReturnPath({
                pathname: location?.state?.pathname
                    ? location?.state?.pathname
                    : location?.state?.backToOrganizationManagement,
                state: {
                    adminOrganizationMangemnetTab:
                        location?.state?.adminOrganizationMangemnetTab,
                    adminOrganizationMangemnetPage:
                        location?.state?.adminOrganizationMangemnetPage,
                    pageSort: location?.state?.pageSort,
                },
            });
        }
    }, []);
    return (
        <HospitalDetailWrapper className="container-fluid p-0 d-flex flex-grow-1">
            <div className="row no-gutters w-100 flex-grow-1">
                <EMSAdminSidebar activeTab="organizationManagement" />
                <section className="d-flex admin-main-content container select-text">
                    <div className="row no-gutters w-100 admin-main">
                        <OrganizationDetails
                            hospital={hospital}
                            returnPath={returnPath}
                            programData={programData}
                        />
                    </div>
                </section>
            </div>
        </HospitalDetailWrapper>
    );
};
export default ApproveEmsProgramHospital;
