import styled from "styled-components";

export const OrganizationManagementWrapper = styled.div`
    .cst-w {
        width: 59px;
        margin-left: 5px;
    }
    .dashboard-fac-magnt {
        margin-bottom: 24px;
    }
    .tab-filled {
        .tab-filled-item {
            border-radius: unset;
            @media only screen and (min-width: 768px) {
                margin-right: 14px;
                border-radius: 4px;
            }
            button {
                padding: 12px 6px;
                line-height: 1;
                border: 1px solid #e3e3e3;
                font-weight: 500;
                background-color: #fff;
                @media only screen and (min-width: 576px) {
                    padding: 12px;
                }
                @media only screen and (min-width: 768px) {
                    padding: 15px 20px;
                }
                &.active {
                    border: 1px solid #c10e21;
                    background: #c10e21;
                    color: #fff;
                }
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .search-filter + .state-filter,
    .award-filter + .measure-filter {
        @media only screen and (min-width: 576px) {
            margin-left: 2%;
        }
    }
    .search-filter + .state-filter,
    .state-filter + .award-filter,
    .award-filter + .measure-filter {
        @media only screen and (min-width: 1200px) {
            margin-left: 12px;
        }
    }
    .lock-unlock {
        position: relative;
        .lock-admin-icon {
            font-size: 18px;
        }
    }
    .lock-unlock-modal {
        .modal-header {
            border-bottom-color: #c10e21;
        }
        .lock-unlock-info {
            font-weight: 600;
            margin-bottom: 20px;
        }
        .lock-unlock-check {
            margin-bottom: 18px;
        }
        .lock-unlock-btns {
            margin-top: 24px;
            .btn + .btn {
                margin-left: 24px;
            }
        }
    }
    .service-year {
        > div {
            > div {
                width: 100%;
                > div {
                    width: 100%;
                }
            }
        }
    }
    .export-admin {
        position: relative;
        .export-admin-icon {
            font-size: 18px;
        }
    }
    .export-export-btn {
        .btn {
            width: 100%;
            @media only screen and (min-width: 576px) {
                width: auto;
            }
        }
    }
    .form-radio ul li {
        list-style: none;
    }
    .aui-modal .modal-header {
        border-bottom: 2px solid rgb(193, 14, 33);
        padding-bottom: 0.5rem;
    }
    .aui-modal .modal-content {
        padding: 1rem;
    }
    .global-export-tooltip {
        position: absolute;
        top: 100%;
        right: 0;
        display: none;
    }
    .global-export-btn {
        &:focus,
        &:hover {
            & + .global-export-tooltip {
                &:before,
                &:after {
                    left: auto;
                    right: 30px;
                }
                display: block;
                .tooltip-inner {
                    @media only screen and (min-width: 992px) {
                        width: 190px;
                    }
                }
            }
        }
    }
    .global-lock-tooltip {
        position: absolute;
        top: 100%;
        right: 0;
        display: none;
    }
    .global-lock-btn {
        &:focus,
        &:hover {
            & + .global-lock-tooltip {
                &:before,
                &:after {
                    left: auto;
                    right: 30px;
                }
                display: block;
                .tooltip-inner {
                    text-align: left;
                    width: 165px;
                    max-width: unset;
                    @media only screen and (min-width: 768px) {
                        width: 235px;
                    }
                }
            }
        }
    }
    .serviceYear {
        font-size: 1rem;
    }
    .ems-org-mgnt {
        .menu-cst-select-dropdown {
            min-width: 160px;
        }
    }
    .user-management {
        .container {
            max-width: unset;
        }
    }
`;
