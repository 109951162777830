import styled from "styled-components";

export const NewOrganizationConfirmationWrapper = styled.div`
    .facility-confirm-modal {
        .modal-content {
            padding: 20px;
            @media only screen and (min-width: 768px) {
                padding: 42px 72px;
            }
        }
        .modal-body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
        }
        .register-header {
            line-height: 1.2;
            padding-bottom: 11px;
            margin-bottom: 28px;
            position: relative;
            align-self: center;
            @media only screen and (min-width: 768px) {
                line-height: 1;
            }
            &:before {
                content: "";
                width: calc(100% - 48px);
                height: 2px;
                background: #c10e21;
                position: absolute;
                bottom: 0;
                left: 24px;
            }
        }
        .facility-confirm-btns {
            .btn {
                display: block;
                width: 100%;
                @media only screen and (min-width: 576px) {
                    display: inline-block;
                    width: auto;
                }
            }
        }
    }
`;
