import { getCurrentQuarter } from "common/utils";
import { getMeasureMetricChartData } from "api/dashboardAPI";
import Dropdown from "components/Dropdown";
import GroupedMultiSelectDropdown from "components/GroupedMultiSelectDropdown";
import React, { useEffect, useState } from "react";
import CONSTANTS from "common/constants";
import store from "app/store";
import { showToast } from "components/Toast/toast.slice";
import { any } from "prop-types";
import DropdownMenu from "components/DropdownMenu";
import { GraphFilterWrapper } from "./styled";

interface Props {
    chartGroups: any;
    selectedCharts: any;
    setSelectedCharts: any;
    groupHeadings: any;
    chartsDisable: boolean;
    setFromYear: any;
    setFromQuarter: any;
    setToYear: any;
    setToQuarter: any;
    setRedrawInd: any;
    redrawInd: boolean;
    setInterval: any;
    submitDisabled: boolean;
    setSubmitDisabled: any;
    exportData: any;
    exportDashBoardAsPdf?: any;
    metricChartDetails: any;
    measurechartDetails: any;
}

export const GraphFilter = ({
    chartGroups,
    selectedCharts,
    setSelectedCharts,
    groupHeadings,
    chartsDisable,
    setFromYear,
    setFromQuarter,
    setToYear,
    setToQuarter,
    setRedrawInd,
    redrawInd,
    setInterval,
    submitDisabled,
    setSubmitDisabled,
    exportData,
    exportDashBoardAsPdf,
    metricChartDetails,
    measurechartDetails,
}: Props) => {
    const currentYear = new Date().getFullYear();
    const currentQuarter = getCurrentQuarter();
    const [selectedInterval, setSelectedInterval] = useState<any>(
        CONSTANTS.INTERVAL.QUARTERLY
    );
    const [selectedFromYear, setselectedFromYear] = useState<number>(
        currentYear - 1
    );
    const [selectedToYear, setselectedToYear] = useState<number>(
        currentQuarter == 1 ? currentYear - 1 : currentYear
    );
    const [selectedFromQuarter, setselectedFromQuarter] =
        useState<any>(currentQuarter);
    const [selectedToQuarter, setselectedToQuarter] = useState<any>(
        currentQuarter == 1 ? currentQuarter + 3 : currentQuarter - 1
    );
    const [disableInd, setDisableInd] = useState<boolean>(false);
    const [exportDisableInd, setExportDisableInd] = useState<boolean>(false);

    useEffect(() => {
        if (CONSTANTS.WINDOW.AUI.Popover) {
            CONSTANTS.WINDOW.AUI.Popover.init();
        }
    });

    useEffect(() => {
        let allIdsHidden = true;
        if (measurechartDetails) {
            for (
                let measureIndex = 0;
                measureIndex < measurechartDetails.length;
                measureIndex++
            ) {
                if (!measurechartDetails[measureIndex].hideInd) {
                    allIdsHidden = false;
                    break;
                }
            }
        } else {
            allIdsHidden = false;
        }
        if (metricChartDetails) {
            for (
                let metricIndex = 0;
                metricIndex < metricChartDetails.length;
                metricIndex++
            ) {
                if (!metricChartDetails[metricIndex].hideInd) {
                    allIdsHidden = false;
                    break;
                }
            }
        }
        if (measurechartDetails || metricChartDetails) {
            setExportDisableInd(allIdsHidden);
        }
    }, [metricChartDetails, measurechartDetails]);

    const getYears = () => {
        const years = [];
        for (let i = currentYear; i >= 2019; i--) {
            years.push(i);
        }
        const yearsDropDownMap: any = [];
        if (years && years.length > 0) {
            years.forEach((year: any) => {
                yearsDropDownMap.push({ label: year, value: year });
            });
        }
        return yearsDropDownMap;
    };
    const getQuaters = (selectedYears: any) => {
        const quarterMap = new Map();
        if (selectedYears === currentYear) {
            for (let i = 1; i <= currentQuarter; i++) {
                quarterMap.set(`Q${i}`, i);
            }
        } else {
            quarterMap.set("Q1", 1);
            quarterMap.set("Q2", 2);
            quarterMap.set("Q3", 3);
            quarterMap.set("Q4", 4);
        }
        const quarterDropDownMap: any = [];
        quarterMap.forEach((v: any, k: any) => {
            quarterDropDownMap.push({ label: k, value: v });
        });
        return quarterDropDownMap;
    };
    const yearsDropdown: Array<object> = getYears();
    const quartersFromDropdown: Array<object> = getQuaters(selectedFromYear);
    const quartersToDropdown: Array<object> = getQuaters(selectedToYear);
    const changeInterval = (value: any) => {
        setSubmitDisabled(false);
        setSelectedInterval(value);
    };
    const changeFromYear = (value: any) => {
        setSubmitDisabled(false);
        setselectedFromYear(value);
        setselectedFromQuarter(1);
    };
    const changeFromQuarter = (value: any) => {
        setSubmitDisabled(false);
        setselectedFromQuarter(value);
    };
    const changeToYear = (value: any) => {
        setSubmitDisabled(false);
        setselectedToYear(value);
        setselectedToQuarter(1);
    };
    const changeToQuarter = (value: any) => {
        setSubmitDisabled(false);
        setselectedToQuarter(value);
    };
    const checkFromToDate = (message: any) => {
        const toast = {
            message,
            code: "Error",
        };
        if (selectedFromYear > selectedToYear) {
            store.dispatch(showToast(toast));
        } else if (
            selectedInterval === CONSTANTS.INTERVAL.QUARTERLY &&
            selectedFromYear == selectedToYear &&
            selectedFromQuarter > selectedToQuarter
        ) {
            store.dispatch(showToast(toast));
        } else {
            setInterval(selectedInterval);
            setFromYear(selectedFromYear);
            setToYear(selectedToYear);
            setToQuarter(selectedToQuarter);
            setFromQuarter(selectedFromQuarter);
            setRedrawInd(!redrawInd);
        }
    };
    const intervalDropdown: Array<{ label: string; value: string }> = [
        { label: CONSTANTS.QUARTERLY, value: CONSTANTS.INTERVAL.QUARTERLY },
        { label: CONSTANTS.ANNUAL, value: CONSTANTS.INTERVAL.ANNUAL },
    ];

    const isSafariBrowser = () => {
        if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        if (CONSTANTS.WINDOW.AUI.Tooltip) {
            CONSTANTS.WINDOW.AUI.Tooltip.init();
        }
    });

    const sortDropdown = [
        { label: "Export as PDF", value: "ExportPDF" },
        { label: "Export as Excel", value: "ExportExcel" },
    ];
    const exportValues = (value: any) => {
        if (value === "ExportPDF") {
            exportDashBoardAsPdf();
        }
        if (value === "ExportExcel") {
            exportData();
        }
    };
    const dropDownItem = (
        <>
            <i
                className="font-red aha-icon-export mr-1 mr-sm-0"
                aria-hidden="true"
            />
            <span className="d-sm-none font-red export-text">Export</span>
        </>
    );
    return (
        <>
            <h2 id="dashboardFilters" className="h7 mb-2 font-600">
                Filter By
            </h2>
            <GraphFilterWrapper
                className="graph-filter"
                role="group"
                aria-labelledby="dashboardFilters"
            >
                <div className="d-lg-flex align-items-end">
                    <div className="d-flex flex-grow-1 flex-wrap">
                        <div className="chart-prog chart-filter-item">
                            <label id="dropdown-label-chartProgram">
                                Select Chart
                            </label>
                            <GroupedMultiSelectDropdown
                                items={chartGroups}
                                selectedValue={selectedCharts}
                                callParentOnSelect={(value: any) => {
                                    setSubmitDisabled(false);
                                    setSelectedCharts(value);
                                    setDisableInd(value.length === 0);
                                }}
                                groupHeadings={groupHeadings}
                                id="chartProgram"
                                isLoading={chartsDisable}
                                disabled={chartsDisable}
                            />
                        </div>
                        <div className="chart-interval chart-filter-item">
                            <label
                                htmlFor="chartInterval"
                                id="chartInterval-label"
                            >
                                Interval
                            </label>
                            <Dropdown
                                items={intervalDropdown}
                                selectedValue={selectedInterval}
                                callParentOnSelect={changeInterval}
                                id="chartInterval"
                            />
                        </div>
                        <div
                            className="chart-from-duration chart-filter-item"
                            role="group"
                            aria-labelledby="chartDurationYear-label-title"
                        >
                            <label id="chartDurationYear-label-title">
                                From
                            </label>
                            <div className="d-flex">
                                <div className="duration-year">
                                    <Dropdown
                                        items={yearsDropdown}
                                        selectedValue={selectedFromYear}
                                        callParentOnSelect={changeFromYear}
                                        id="chartDurationYear"
                                        ariaInnerLabelValue="year"
                                    />
                                </div>
                                <div className="duration-q">
                                    {selectedInterval ===
                                        CONSTANTS.INTERVAL.ANNUAL && (
                                        <Dropdown
                                            items={[{ label: "--", value: "" }]}
                                            selectedValue=""
                                            callParentOnSelect=""
                                            id="chartDurationQuarterDisabled"
                                            disabled
                                            ariaInnerLabelValue="quarter"
                                        />
                                    )}
                                    {selectedInterval ===
                                        CONSTANTS.INTERVAL.QUARTERLY && (
                                        <Dropdown
                                            items={quartersFromDropdown}
                                            selectedValue={selectedFromQuarter}
                                            callParentOnSelect={
                                                changeFromQuarter
                                            }
                                            id="chartDurationQuarter"
                                            ariaInnerLabelValue="quarter"
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div
                            className="chart-to-duration chart-filter-item"
                            role="group"
                            aria-labelledby="chartDurationYearTo-label-title"
                        >
                            <label id="chartDurationYearTo-label-title">
                                To
                            </label>
                            <div className="d-flex">
                                <div className="duration-year">
                                    <Dropdown
                                        items={yearsDropdown}
                                        selectedValue={selectedToYear}
                                        callParentOnSelect={changeToYear}
                                        id="chartDurationYearTo"
                                        ariaInnerLabelValue="year"
                                    />
                                </div>
                                <div className="duration-q">
                                    {selectedInterval ===
                                        CONSTANTS.INTERVAL.ANNUAL && (
                                        <Dropdown
                                            items={[{ label: "--", value: "" }]}
                                            selectedValue=""
                                            callParentOnSelect=""
                                            id="chartDurationQuearterToDisabled"
                                            disabled
                                            ariaInnerLabelValue="quarter"
                                        />
                                    )}
                                    {selectedInterval ===
                                        CONSTANTS.INTERVAL.QUARTERLY && (
                                        <Dropdown
                                            items={quartersToDropdown}
                                            selectedValue={selectedToQuarter}
                                            callParentOnSelect={changeToQuarter}
                                            id="chartDurationQuearterTo"
                                            ariaInnerLabelValue="quarter"
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="d-flex flex-column flex-sm-row align-items-center"
                        id="btns"
                    >
                        {disableInd && (
                            <div id="submit-charts-btn">
                                <button
                                    className="btn btn-primary btn-round chart-filter-btn mt-4 mt-lg-0"
                                    data-toggle="popover"
                                    data-trigger="hover focus"
                                    data-placement="bottom"
                                    data-content="Please select at least one chart to submit."
                                    data-container="#submit-charts-btn"
                                >
                                    Submit
                                </button>
                            </div>
                        )}
                        {!disableInd && (
                            <button
                                disabled={submitDisabled}
                                className="btn btn-primary btn-round chart-filter-btn mt-4 mt-lg-0"
                                onClick={() => {
                                    checkFromToDate(
                                        "Invalid Date Range. 'From Date' cannot be after 'To Date'."
                                    );
                                }}
                            >
                                Submit
                            </button>
                        )}
                        {!disableInd && !exportDisableInd && (
                            <div
                                className="export-dropdown mt-4 mt-lg-0 ml-2"
                                data-toggle="tooltip"
                                data-placement="top"
                                data-trigger="hover focus"
                                title="Export Dashboard"
                                data-container="#btns"
                            >
                                <DropdownMenu
                                    id="sortItems"
                                    items={sortDropdown}
                                    selectedValue="ExportPDF"
                                    callParentOnSelect={(value: any) =>
                                        exportValues(value)
                                    }
                                    dropdownArrow={false}
                                    dropDownItem={dropDownItem}
                                    ariaInnerLabelValue="Export Dashboard"
                                />
                            </div>
                        )}
                        {disableInd && (
                            <div
                                id="export-charts-btn"
                                className="dropdown chart-filter-export"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Export Dashboard"
                                data-container="#btns"
                            >
                                <button
                                    className="btn btn-text font-red mt-4 mt-lg-0 ml-sm-3"
                                    data-toggle="popover"
                                    data-trigger="hover focus"
                                    data-placement="bottom"
                                    data-content="Select at least one chart to export."
                                    data-container="#export-charts-btn"
                                >
                                    <i
                                        className="aha-icon-export mr-1 mr-sm-0"
                                        aria-label="export"
                                    />
                                    <span className="d-sm-none">Export</span>
                                </button>
                            </div>
                        )}
                        {exportDisableInd && !disableInd && (
                            <div
                                id="export-charts-btn"
                                className="dropdown chart-filter-export"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Export Dashboard"
                                data-container="#btns"
                            >
                                <button
                                    className="btn btn-text font-red mt-4 mt-lg-0 ml-sm-3"
                                    data-toggle="popover"
                                    data-trigger="hover focus"
                                    data-placement="bottom"
                                    data-content="Please select at least one measure/metric to export."
                                    data-container="#export-charts-btn"
                                >
                                    <i
                                        className="aha-icon-export mr-1 mr-sm-0"
                                        aria-label="export"
                                    />
                                    <span className="d-sm-none">Export</span>
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </GraphFilterWrapper>
        </>
    );
};

export default GraphFilter;
