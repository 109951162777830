import styled from "styled-components";

export const AwardLogsViewWrapper = styled.div`
    .arrow-size {
        font-size: 0.7rem;
    }
    .limit-text {
        display: block;
        display: -webkit-box;
        max-width: 100%;
        max-height: 38px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .award-logs-table {
        thead {
            @media only screen and (min-width: 992px) {
                th:nth-child(1) {
                    width: 28%;
                }
                th:nth-child(2) {
                    width: 30%;
                }
                th:nth-child(3) {
                    width: 22%;
                }
                th:nth-child(4) {
                    width: 20%;
                }
            }
        }
    }
`;
