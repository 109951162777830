import styled from "styled-components";

export const DropdownMenuWrapper = styled.div`
    .menu-cst-select {
        width: 100%;
        word-break: break-all;
        word-break: break-word;
        position: relative;
    }
    .menu-cst-select-fld {
        height: 40px;
        border-radius: 2px;
        width: 100%;
        cursor: pointer;
        position: relative;
        &:focus {
            outline: 2px solid #f7983a;
            box-shadow: 0 0 4px 4px #f7983a;
        }
    }
    .menu-cst-select-required {
        display: none;
    }
    .required {
        .menu-cst-select-not-required {
            display: none;
        }
        .menu-cst-select-required {
            display: inline-block;
            &:after {
                content: "required";
                width: 1px;
                height: 1px;
                overflow: hidden;
                display: inline-block;
            }
        }
        .menu-cst-select
            .menu-cst-multiselect-fld
            .menu-cst-grouped-select-required {
            display: inline-block;
        }
    }
    .menu-cst-selected-item {
        width: 100%;
        height: 100%;
        text-align: left;
        overflow: hidden;
        word-break: break-all;
        background: transparent;
        padding: 0;
        cursor: pointer;
        white-space: nowrap;
        text-overflow: ellipsis;
        z-index: 3;
        border: none;
        &.menu-cst-selected-arrow {
            padding: 6px 27px 6px 12px;
        }
        > i:first-child {
            font-size: 20px;
            color: #c10e21;
        }
    }
    .menu-cst-arrow {
        width: 25px;
        height: 35px;
        display: inline-block;
        background: transparent;
        position: absolute;
        top: 1px;
        right: 1px;
        bottom: 1px;
        font-size: 8px;
        padding-right: 6px;
        z-index: 2;
        &:before {
            line-height: 36px;
            margin: 0 auto;
            display: block;
        }
        &.menu-cst-arrow-up {
            transform: rotate(180deg);
            transition: all 0.3s ease;
        }
        &.menu-cst-arrow-down {
            transform: rotate(0deg);
            transition: all 0.3s ease;
        }
    }
    .menu-cst-select-dropdown {
        // width: 100%;
        min-width: 210px;
        max-height: 292px;
        border: 1px solid #bcc3ca;
        overflow: auto;
        position: absolute;
        margin-top: 5px;
        right: 0;
        background-color: #fff;
        z-index: 99999;
        list-style-type: none;
        padding: 4px 0 1px;
        box-shadow: 0 0 1.125rem #00000029;
        border-radius: 2px;
        .menu-cst-item {
            border-bottom: 1px solid #e3e3e3;
            cursor: pointer;
            padding: 6px 8px;
            margin: 0 2px;
            &:focus {
                outline: 2px solid #f7983a;
                box-shadow: 0 0 4px 4px #f7983a;
            }
            &.menu-cst-item-disabled {
                pointer-events: none;
                color: #979797;
                background-color: #f8f8f8;
            }
        }
        .menu-cst-item li:last-child {
            border-bottom: 0;
        }
        .menu-cst-item-selected {
            color: #c10e21;
        }
        .form-check-bordered {
            z-index: -1;
        }
    }
    .menu-cst-select-disabled {
        cursor: not-allowed;
        .menu-cst-select-fld {
            border: 1px solid #777979;
            pointer-events: none;
        }
    }
    .menu-cst-select-loading {
        position: relative;
        cursor: not-allowed;
        &:after {
            border: 3px solid #222328;
            border-radius: 50%;
            border-top: 3px solid transparent;
            width: 22px;
            height: 22px;
            -webkit-animation: aui-btn-spin 2s linear infinite;
            animation: aui-btn-spin 2s linear infinite;
            content: "";
            display: inline-block;
            position: absolute;
            margin-left: 10px;
            top: 8px;
            right: 9px;
            z-index: 4;
        }
        .menu-cst-selected-item {
            padding-right: 60px;
            background-color: #f8f8f8;
        }
        .menu-cst-select-fld {
            pointer-events: none;
        }
        .menu-cst-multiselect-fld {
            padding-right: 36px;
            background-color: #f8f8f8;
        }
        .menu-cst-arrow {
            display: none;
        }
    }
    .disable-title {
        .menu-cst-select-dropdown {
            li:first-child {
                pointer-events: none;
            }
        }
    }
    .menu-cst-item .menu-cst-icon {
        color: #c10e21;
        display: inline-block;
        margin-right: 6px;
        font-size: 16px;
    }
`;
