import React, { useEffect, useState } from "react";
import HospitalBanner from "pages/Admin/HospitalBanner/HospitalBanner";
import MeasuresDateFilter from "components/MeasuresDateFilter";
import MeasureMetricTable from "components/MeasureTable/MeasureMetricTable";
import EMSMeasuresTable from "pages/Measures/EMSMeasuresTable/EMSMeasuresTable";
import { useParams } from "react-router";
import CONSTANTS from "common/constants";
import ServiceYearDropdown from "components/ServiceYearDropdown";
import { HospitalManagementMearuresWrapper } from "./styled";

interface Props {
    hospital: any;
    programName: string;
    hospitalMeasures: any;
    isMeasureTableLoading: boolean;
    emsAdminInd?: boolean;
    programData?: any;
    certificateData?: any;
}

export const HospitalMeasuresTab = ({
    hospital,
    programName,
    hospitalMeasures,
    isMeasureTableLoading,
    emsAdminInd,
    programData,
    certificateData,
}: Props) => {
    const params: any = useParams();

    const [checkboxes, setCheckboxes] = useState<boolean[]>([]);
    const handleCheckboxChange = (event: any, id: any, groupId: any) => {};
    const [createMeasureMetrics, setCreateMeasureMetrics] =
        useState<boolean>(false);
    const [readOnly, setReadOnly] = useState<boolean>(true);

    const isMeasureHasVlue = (nd: any) => {
        return !(nd === null || nd === "" || isNaN(nd));
    };
    const getDataByGroupId = (measuresData: any, groupId: any) => {
        const groupData = measuresData.filter(
            (m: any) => m.groupId === groupId
        );
        return groupData;
    };
    const checkInvalidCount = (values: any) => {
        let invalidCount = 0;
        for (let i = 0; i < values.length; i++) {
            if (
                !isMeasureHasVlue(values[i].numerator) &&
                !isMeasureHasVlue(values[i].denominator)
            ) {
                invalidCount++;
            }
        }
        return invalidCount;
    };
    const updateCheckbox = (
        invalidCount: any,
        targetCount: any,
        index: any
    ) => {
        setCheckboxes((prevState) => {
            const updatedCheckboxes = [...prevState];
            if (invalidCount === targetCount) {
                updatedCheckboxes[index] = false;
            } else {
                updatedCheckboxes[index] = true;
            }
            return updatedCheckboxes;
        });
    };
    const checkGroupValForUncheck = (data: any) => {
        const measuresData = [].concat(...data);
        let groupsId: any = measuresData.map((g: any) => g.groupId);
        groupsId = groupsId.reduce((a: any, c: any) => {
            if (!a.includes(c)) {
                a.push(c);
            }
            return a;
        }, []);
        for (let i = 0; i < groupsId.length; i++) {
            if (i == 0) continue;
            let measures: any = [];
            measures = getDataByGroupId(measuresData, groupsId[i]);
            let invalidCount = 0;
            for (let j = 0; j < measures.length; j++) {
                invalidCount += checkInvalidCount(measures[j].values);
            }
            updateCheckbox(invalidCount, measures.length * 4, i);
        }
    };
    useEffect(() => {
        if (hospitalMeasures?.measuresData && emsAdminInd) {
            const updatedCheckboxes = new Array(
                hospitalMeasures?.measuresData.length
            ).fill(true);
            setCheckboxes(updatedCheckboxes);
            checkGroupValForUncheck(hospitalMeasures?.measuresData);
        }
    }, [hospitalMeasures?.measuresData]);

    return (
        <HospitalManagementMearuresWrapper>
            <h2 className="h3 mb-4 d-lg-none">Measures</h2>
            <HospitalBanner
                hospitalName={hospital.facilityName}
                programName={programName}
                countryName={hospital.countryName}
                programId={params?.programId}
                hospital={hospital}
                emsAdminInd
                programData={programData}
                certificateData={certificateData}
            />
            <div className="d-xl-flex justify-content-between align-items-center measure-block-title">
                <h3 className="h3 mb-xl-0 mb-4">{programName} Measures</h3>
                <div className="d-flex">
                    {params.categoryId == CONSTANTS.EMS_PROGRAM_CATEGORY_ID && (
                        <ServiceYearDropdown
                            emsInd={
                                params.categoryId ==
                                CONSTANTS.EMS_PROGRAM_CATEGORY_ID
                            }
                            hospitalId={params.hospitalId}
                        />
                    )}
                    <MeasuresDateFilter
                        emsInd={emsAdminInd}
                        hospitalId={hospital.hospitalId}
                        setCreateMeasureMetrics={setCreateMeasureMetrics}
                        setReadOnly={setReadOnly}
                    />
                </div>
            </div>

            {hospitalMeasures?.userData == true ? (
                <div className="admin-measure-table">
                    {params.programId === CONSTANTS.EMS_PROGRAM_ID ? (
                        <EMSMeasuresTable
                            data={hospitalMeasures}
                            isMeasureTableLoading={isMeasureTableLoading}
                            readOnly
                            handleOnchangeCheck={handleCheckboxChange}
                            checkboxes={checkboxes}
                        />
                    ) : (
                        <MeasureMetricTable
                            data={hospitalMeasures}
                            isMeasureTableLoading={isMeasureTableLoading}
                            readOnly
                        />
                    )}
                </div>
            ) : (
                <span className="mb-xl-0 mb-7 ml-3">No available data</span>
            )}
        </HospitalManagementMearuresWrapper>
    );
};

export default HospitalMeasuresTab;
